// EJEMPLO DE NOMENCLATURA: ACT_ + NOMBRE DEL MODULO (Action File) + Acción propiamente dicha
// ACT_LOGIN_REGISTER
// ACT_EMPLEADOS_ADD
// ACT_EMPLEADOS_DELETE

// Para actions que solo cambian props.
// CHPROPS_LOGIN
// CHPROPS_EMPLEADOS

export const SUCCESS_LOGIN = "SUCCESS_LOGIN";

export const ACT_AUTH_LOGIN_USER = "ACT_AUTH_LOGIN_USER";
export const ACT_AUTH_CREATE_USER = "ACT_AUTH_CREATE_USER";
export const CHPROPS_GLOBAL = "CHPROPS_GLOBAL";
export const CHPROPS_UPDATE_APP = "CHPROPS_UPDATE_APP";
export const ACT_AUTH_LOGOUT = "ACT_AUTH_LOGOUT";
export const ACT_USER_DATA = "ACT_USER_DATA";
export const ACT_SPLASH = "ACT_SPLASH";
export const ACT_PRODUCER_CHANGE = "ACT_PRODUCER_CHANGE";
export const ACT_AUTH_USER_CHANGE = "ACT_AUTH_USER_CHANGE";
export const CHANGE_APP = "CHANGE_APP";
// export const  = ''
