import React from "react";
import { Text, TouchableOpacity, Image, StyleSheet, View } from "react-native";
import Util from "../common/Util";
import L from "../common/Layout";
import Colors from "../common/Colors";
import Fonts from "../common/Fonts";
import { Icon } from "native-base";

const MainButtonIcon = ({
  onPress,
  text,
  textStyle,
  refer,
  primaryColor,
  outline,
  buttonStyle,
  iconName,
  iconType,
  image,
  imageSource
}) => {
  const onPressButton = () => {
    onPress(refer);
  };

  let { buttonMain } = styles;
  let buttonColor = {
    elevation: outline ? 0 : 2,
    backgroundColor: outline
      ? "transparent"
      : primaryColor
      ? Colors.cerulean
      : "white"
  };

  let textColor = {
    color: !primaryColor ? Colors.cerulean : "white"
  };

  return (
    <TouchableOpacity
      onPress={onPress}
      style={[buttonMain, buttonColor, buttonStyle]}>
      {image ? (
        <View style={[styles.imageContainer]}>
          <Image source={imageSource} style={[styles.imageStyle]} />
        </View>
      ) : (
        <Icon name={iconName} type={iconType} style={[styles.icon]} />
      )}
      <Text style={[styles.buttonText, textColor, textStyle]} numberOfLines={1}>
        {text}
      </Text>
    </TouchableOpacity>
  );
};

MainButtonIcon.defaultProps = {
  primaryColor: true,
  outline: false
};

const styles = StyleSheet.create({
  buttonMain: {
    height: L.hProm(44),
    width: "80%",
    flexDirection: "row",
    alignSelf: "center",
    alignItems: "center",
    borderRadius: 22,
    marginVertical: L.h(8),
    paddingHorizontal: L.w(17)
  },
  buttonMainOutLine: {},
  buttonText: {
    color: "white",
    fontSize: L.h(16),
    //fontFamily: Fonts.MEDIUM,
    flex: 3.5
  },
  icon: {
    color: Colors.cerulean,
    fontSize: L.h(25),
    flex: 1
  },
  imageContainer: {
    flex: 1
  },
  imageStyle: {
    height: L.h(19)
  }
});

export default MainButtonIcon;
