import FirebaseConnector from "../base/FirebaseConnector";
import APIConnector from "../base/Connector";
import Settings from "../base/Settings";
const PATH = "users";
const PATHPROFILE = "profiles";
const TICKETS = "0events";
const UTRANSACTIONS = "0userTransactions";
const TRANSACTIONS = "0transactions";

const firebaseConnector = new FirebaseConnector({ timeout: 30000 });
const apiConnector = new APIConnector({ timeout: 30000 });

export default class AuthConfig {
  static get endpoint() {
    return Settings.CloudFunctions();
  }

  static get endpointValidateDNI() {
    return `${AuthConfig.endpoint}/validateDNI`;
  }
  static get endpointValidateEmail() {
    return `${AuthConfig.endpoint}/validateEmail`;
  }
  static get endpointSendCode() {
    return `${AuthConfig.endpoint}/sms/sendCode`;
  }

  static get endpointValidateCode() {
    return `${AuthConfig.endpoint}/sms/validateCode`;
  }
  static get endpointDeleteUser() {
    return `${AuthConfig.endpoint}/user/delete`;
  }

  static get FirebaseConnector() {
    return firebaseConnector;
  }

  static get APIConnector() {
    return apiConnector;
  }

  static get pathUsers() {
    return PATH;
  }
  static get pathTickets() {
    return TICKETS;
  }

  static get pathUserTransactions() {
    return UTRANSACTIONS;
  }
  static get pathTransactions() {
    return TRANSACTIONS;
  }

  static get pathProfile() {
    return PATHPROFILE;
  }
}
