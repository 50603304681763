import React, { Component } from "react";
import { View, ImageBackground, Text, StyleSheet } from "react-native";
import { Actions } from "react-native-router-flux"; 
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import L from "../../common/Layout";
import Colors from "../../common/Colors";
import Fonts from "../../common/Fonts";
import I18n from "i18next"
import MainButton from "../../components/MainButton";
import MainButtonIcon from "../../components/MainButtonIcon";
import scanIcon from "../../common/assets/scanIcon.png";
import VTHeader from "../../components/VTHeader";
import RegisterConstants from "./RegisterConstants";
import { actLogout } from "../../actions/AuthActions";
const BG_IMG = require("../../common/assets/background_login.jpg");
import { reduxForm } from "redux-form";

class RegisterB extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  onRegisterError() {
    // console.log('error callback for login')
  }

  render() {
    const { socialMedia, actLogout } = this.props;
    const onPressRight = socialMedia ? actLogout : null;
    return (
      <ImageBackground source={BG_IMG} style={{ flex: 1 }} resizeMode="cover">
        <View style={localStyle.container}>
          <VTHeader {...RegisterConstants.headerProps("B", onPressRight)} />
          <View style={localStyle.mainContent}>
            <Text style={localStyle.textInfo}>
              {I18n.t("register.signInSteps.textChooseOption")}
            </Text>
            <MainButtonIcon
              image
              imageSource={scanIcon}
              primaryColor={false}
              onPress={() => {
                // const { actionChangeAuthProps } = this.props;
                // actionChangeAuthProps({
                //   verifiedUser: true
                // });
                Actions.scanner({
                  socialMedia: socialMedia,
                  navigationScreen: "scanData"
                });
              }}
              text={I18n.t("register.signInSteps.textScan")}
              buttonStyle={{ marginTop: L.h(66) }}
            />
            <Text
              style={[
                localStyle.textInfo,
                {
                  marginTop: L.h(50),
                  textAlign: "center",
                  marginBottom: L.h(8)
                }
              ]}>
              {I18n.t("register.signInSteps.textOr")}
            </Text>
            <MainButton
              primaryColor={false}
              text={I18n.t("register.signInSteps.textLoadManually")}
              buttonStyle={{ width: L.w(265), marginTop: L.h(20) }}
              onPress={() => {
                // const { actionChangeAuthProps } = this.props;
                // actionChangeAuthProps({
                //   verifiedUser: false
                // });
                if (this.props.socialMedia) {
                  Actions.push("registerD", { socialMedia: true });
                } else {
                  Actions.registerC();
                }
              }}
            />
          </View>
        </View>
      </ImageBackground>
    );
  }
}
const localStyle = StyleSheet.create({
  closeButton: {
    padding: 10,
    position: "absolute",
    top: L.h(25),
    left: L.w(15)
  },
  closeIcon: {
    fontSize: L.h(30),
    color: "white"
  },
  container: {
    flex: 1
  },
  mainContent: {
    padding: L.h(85),
    paddingHorizontal: L.w(44)
  },
  logo: {
    width: L.w(141),
    marginTop: L.h(40),
    alignSelf: "center"
  },
  textInfo: {
    fontSize: L.h(25),
    fontFamily: Fonts.DBOLD,
    color: Colors.textRegisterB
  }
});

function mapStateToProps(store) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {  
      actLogout
    },
    dispatch
  );
}
export default reduxForm({
  form: "register",
  destroyOnUnmount: false
})(connect(mapStateToProps, mapDispatchToProps)(RegisterB));
