import React, { PureComponent } from "react";
//WEB99 import { Content } from "native-base";
import { Actions } from "react-native-router-flux";
import {
  Text,
  View,
  Image,
  StyleSheet,
  ImageBackground,
  ActivityIndicator,
  Keyboard,
} from "react-native";
import { bindActionCreators } from "redux";
import { reduxForm, Field } from "redux-form";
import AppMem from "../../common/AppMem";
import { connect } from "react-redux";
import L from "../../common/Layout";
import Fonts from "../../common/Fonts";
import VTHeader from "../../components/VTHeader";
import { codeGeneratorActions, actLogout } from "../../actions";
import renderInput from "../../components/LabelCustomInput";
import MainButton from "../../components/MainButton";
import { required } from "../../common/Validations";
import Colors from "../../common/Colors";
import I18n from "i18next"
const BG_IMG = require("../../common/assets/background_login.jpg");

class ValidatePhone extends PureComponent {
  constructor() {
    super();
    this.state = {
      intervalId: null,
      phone: "",
      code: "",
      error_phone: false,
      count: null,
      showCountDown: false,
      textButtomEnviar: I18n.t("validatePhone.sendCode"),
      codeSended: false,
    };
    this.submitPhone = this.submitPhone.bind(this);
    this.callbackCode = this.callbackCode.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  componentDidMount() {
    //DataMem.closeAppLoader();
    let { user } = this.props;
    this.props.initialize({
      phone: user.proPhone,
    });
    setTimeout(() => {
      AppMem.closeAppLoader();
    }, 2000);
  }

  submitPhone(values) {
    Keyboard.dismiss();
    // Actions.validateCode();
    let { codeGeneratorActions } = this.props;
    this.setState({ codeSended: true });
    codeGeneratorActions(values.phone, this.callbackCode, this.errorCallback);
  }
  callbackCode() {
    let { socialMedia, profile, emailVerified } = this.props;
    this.setState({ codeSended: false });
    Actions.push("validateCode", { socialMedia, profile, emailVerified });
  }

  errorCallback() {
    this.setState({ codeSended: false });
    let modalProps = {
      title: I18n.t("validatePhone.validateError"),
      type: "error",
      style: { height: L.hProm(250) },
      buttonOk: { text: I18n.t("global.accept"), action: () => null },
    };
    DataMem.openModal("alert", modalProps);
  }

  getInputProps() {
    let props = {
      borderColor: Colors.registerFieldColor,
      labelStyle: { color: Colors.registerFieldColor },
      inputStyle: {
        color: Colors.registerFieldColor,
        fontSize: L.h(17),
      },
      errorIconStyle: { color: Colors.registerFieldColor },
      borderBottomColor: Colors.registerFieldColor,
      blurOnSubmit: false,
      withRef: true,
    };
    return props;
  }

  async signOut() {
    let { actLogout } = this.props;
    await actLogout();
    Actions.reset("login");
  }

  headerProps() {
    let { profile } = this.props;
    return {
      leftButtons: [
        {
          onPress: profile ? Actions.pop : this.signOut,
          icon: {
            name: profile ? "chevron-left" : "times",
            type: "FontAwesome",
            style: {
              color: Colors.mainTitleColor,
              fontSize: L.h(23),
              marginLeft: L.w(8),
            },
            text: "",
            textStyle: {
              fontSize: L.h(18),
              color: "white",
            },
          },
        },
      ],
      rightButtons: [],
      titleStyle: {
        fontSize: L.h(20),
        color: Colors.mainTitleColor,
      },
      title: profile
        ? I18n.t("validatePhone.telephone")
        : I18n.t("validatePhone.register"),
      noShadow: true,
      noStatusBar: true,
      backgroundColor: "transparent",
    };
  }

  render() {
    let { handleSubmit, user } = this.props;
    //console.log(" >>>>>>>>>> user", user);
    let { codeSended } = this.state;
    return (
      <ImageBackground source={BG_IMG} style={{ flex: 1 }} resizeMode="cover">
        <View style={{ flex: 1 }}>
          {/* <StatusBarView /> */}
          <VTHeader {...this.headerProps()} />
         
           
            <View style={{ width: "80%", alignSelf: "center" }}>
              <Text style={styles.ingreseSuNmeroDe}>
                {I18n.t("validatePhone.enterPhoneNumber")}
              </Text>
              <Field
                style={{ marginBottom: L.h(40) }}
                placeholder="Teléfono"
                autoCapitalize="none"
                name="phone"
                type="text"
                component={renderInput}
                maxLength={12}
                keyboardType={"numeric"}
                validate={[required]}
                onSubmitEditing={handleSubmit(this.submitPhone)}
                {...this.getInputProps()}
              />
              <Text style={styles.ingreseSuNmeroDe2}>
                {I18n.t("validatePhone.enterPhoneNumber2")}
              </Text>
              {codeSended ? (
                <View style={{ alignSelf: "center" }}>
                  <ActivityIndicator size="large" color="orange" />
                </View>
              ) : (
                <MainButton
                  text={I18n.t("validatePhone.validatePhoneMainButton")}
                  style={{ marginBottom: L.h(34) }}
                  onPress={handleSubmit(this.submitPhone)}
                  textStyle={{
                    fontFamily: Fonts.DBOLD,
                    fontSize: L.h(16),
                    letterSpacing: 0,
                    textAlign: "center",
                    color: "#ffffff",
                  }}
                />
              )}
            </View>
           
          <Image
            // source={require("../../common/assets/bgBottom1.png")}
            resizeMode="contain"
            style={{ position: "absolute", right: 0, bottom: L.h(60) }}
          />
        </View>
      </ImageBackground>
    );
  }
}
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  ingreseSuNmeroDe: {
    fontFamily: Fonts.DREGULAR,
    fontSize: L.h(23),
    letterSpacing: -0.55,
    color: Colors.confirmWhatsappCode,
    marginTop: L.h(55),
    marginBottom: L.h(30),
  },
  ingreseSuNmeroDe2: {
    fontFamily: Fonts.DREGULAR,
    fontSize: L.h(12),
    letterSpacing: -0.29,
    color: Colors.confirmWhatsappCode,
    marginTop: L.h(8),
    marginBottom: L.h(30),
    textAlign: "center",
  },
});

const mapStateToProps = (state) => {
  const { code, sended_phone } = state.authReducer;
  const { user } = state.authReducer;
  return { code, sended_phone, user };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      codeGeneratorActions,
      actLogout,
      // codeValidationActions,
      // actionsignOut
    },
    dispatch
  );
}

export default reduxForm({
  form: "validatePhone",
  destroyOnUnmount: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ValidatePhone)
);
