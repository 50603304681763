import RRPPService from "../provider/rrpp/RRPPService";
import * as actions from "./ActionTypes";

export function actEventStatsRRPP(eventId, rrppId, callback) {
  return (dispatch) => {
    RRPPService.eventStats(eventId, rrppId)
      .then((response) => {
        // console.log("responseresponseresponse", response)
        dispatch({
          type: "ACT_RRPP_CHANGE",
          props: { eventRRPPStats: response },
        });

        callback && callback();
      })
      .catch((e) => {
        console.log(" >>>>>>>>>>e ", e);
      });
  };
}

export function transferRRPPTickets(
  eventId,
  userDni,
  ticketTypeSelected,
  qnty,
  isGift,
  callback
) {
  //   console.log(
  //     "eventId, userDni, ticketTypeSelected, qnty",
  //     eventId,
  //     userDni,
  //     ticketTypeSelected,
  //     qnty
  //   );

  return (dispatch) => {
    RRPPService.transferTicket(
      eventId,
      userDni,
      ticketTypeSelected,
      qnty,
      isGift
    )
      .then((response) => {
        // console.log("response123123123", response);

        callback && callback(response);
      })
      .catch((e) => {
        console.log("ERRORERRORERROR", e);

        callback && callback("ERROR");
      });
  };
}

export function transferRRPPTicketsNoRegister(
  eventId,
  userDni,
  ticketTypeSelected,
  qnty,
  email,
  isGift,
  callback
) {
  //   console.log(
  //     "eventId, userDni, ticketTypeSelected, qnty",
  //     eventId,
  //     userDni,
  //     ticketTypeSelected,
  //     qnty
  //   );

  return (dispatch) => {
    RRPPService.transferTicketNoRegister(
      eventId,
      userDni,
      ticketTypeSelected,
      qnty,
      email,
      isGift
    )
      .then((response) => {
        // console.log("response123123123", response);

        callback && callback(response);
      })
      .catch((e) => {
        console.log("ERRORERRORERROR", e);

        callback && callback("ERROR");
      });
  };
}
