import React, { Component } from "react";
import { StyleSheet, View, Text, TouchableOpacity } from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import IconMA from "react-native-vector-icons/MaterialIcons";
import LinearGradient from "react-native-linear-gradient";
import Fonts from "../../common/Fonts";
import Colors from "../../common/Colors";
import L from "../../common/Layout";

let styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    width: "100%",
  },
  button: {
    padding: L.hProm(12),
    justifyContent: "center",
    alignItems: "center",
  },
  buttonCancel: {
    borderRightWidth: 1,
    borderColor: "rgb(221, 222, 222)",
    flex: 0.99,
  },
  buttonText: {
    fontSize: L.h(18),
    color: "rgb(240, 61, 68)",
    fontFamily: Fonts.MEDIUM,
    textAlign: "center",
  },
  modalContainer: {
    flex: 1,
    width: "100%",
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  linearGradient: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 3,
  },
  iconContainer: {
    backgroundColor: "rgba(255,255,255,0.1)",
    width: L.wProm(40),
    height: L.hProm(40),
    borderRadius: L.hProm(40) / 2,
    alignItems: "center",
    justifyContent: "center",
  },
  iconStyle: {
    color: "white",
    fontSize: L.h(30),
  },
  modalMsg: {
    alignSelf: "center",
    color: "rgb(98, 101, 101)",
    fontSize: L.h(16),
    fontFamily: Fonts.MEDIUM,
    textAlign: "center",
  },
  bodyContainer: {
    flex: 1.3,
    alignItems: "center",
    justifyContent: "center",
    padding: L.hProm(8),
  },
  modalHeader: {
    height: L.hProm(58),
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  footerContainer: {
    height: L.hProm(48),
    alignItems: "center",
    justifyContent: "center",
    borderTopWidth: 1,
    borderTopColor: "rgb(221, 222, 222)",
    flexDirection: "row",
    alignItems: "center",
  },
});
const icon = {
  error: "times-circle-o",
  success: "check-square-o",
  warning: "exclamation-triangle",
};
class VTAlertModal extends Component {
  _renderButton = (text, onPress, color) => (
    <TouchableOpacity onPress={onPress} style={{ flex: 1, width: "100%" }}>
      <View style={styles.button}>
        <Text style={[styles.buttonText, { color }]} numberOfLines={1}>
          {text}
        </Text>
      </View>
    </TouchableOpacity>
  );

  _renderButtonCancel = (text, onPress, color) => (
    <TouchableOpacity onPress={onPress} style={{ flex: 1, width: "100%" }}>
      <View style={[styles.button, styles.buttonCancel]}>
        <Text
          style={[styles.buttonText, { color: "rgb(98, 101, 101)" }]}
          numberOfLines={1}
        >
          {text}
        </Text>
      </View>
    </TouchableOpacity>
  );

  _renderModalContent(modalMsg, buttonOk, buttonCancel, close, type) {
    let iconName = "";
    let primaryColor = "";
    let secondaryColor = "";
    switch (type) {
      case "error":
        iconName = "cancel";
        primaryColor = "rgb(240, 61, 68)";
        secondaryColor = "rgb(174, 24, 30)";
        break;
      case "warning":
        iconName = "error";
        primaryColor = "rgb(255, 188, 0)";
        secondaryColor = "rgb(204, 140, 0)";
        break;
      case "success":
        iconName = "check-circle";
        primaryColor = "rgb(40, 185, 129)";
        secondaryColor = "rgb(12, 113, 48)";
        break;
      case "custom":
        iconName = "check-circle";
        primaryColor = Colors.primaryAlertCustomModal;
        secondaryColor = Colors.secondaryAlertCustomModal;
        break;
      default:
        iconName = "close-circle";
        primaryColor = "rgb(240, 61, 68)";
        secondaryColor = "rgb(174, 24, 30)";
        break;
    }
    return (
      <View style={styles.modalContainer}>
        <View style={[styles.modalHeader, { backgroundColor: primaryColor }]}>
          <LinearGradient
            colors={[primaryColor, secondaryColor]}
            style={styles.linearGradient}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
          >
            <View style={styles.iconContainer}>
              <IconMA style={styles.iconStyle} name={iconName} />
            </View>
          </LinearGradient>
        </View>
        <View style={styles.bodyContainer}>
          <Text style={styles.modalMsg}>{modalMsg}</Text>
        </View>
        <View style={styles.footerContainer}>
          {buttonCancel
            ? this._renderButtonCancel(
                buttonCancel.text,
                () => {
                  buttonCancel.action();
                  close();
                },
                primaryColor
              )
            : null}
          {this._renderButton(
            buttonOk.text,
            () => {
              buttonOk.action && buttonOk.action();
              close();
            },
            primaryColor
          )}
        </View>
      </View>
    );
  }

  render() {
    const { title, buttonOk, buttonCancel, close, type } = this.props;
    return (
      <View style={styles.container}>
        {this._renderModalContent(title, buttonOk, buttonCancel, close, type)}
      </View>
    );
  }
}

export default VTAlertModal;
