import React, { useState, useEffect } from "react";
// import { useStripe } from "@stripe/stripe-react-native";
import { View, Text } from "react-native";
import { Icon } from "native-base";
import { Actions } from "react-native-router-flux";
import L from "../../common/Layout";
import Fonts from "../../common/Fonts";
import Colors from "../../common/Colors";
import IconButton from "../../components/IconButton";
import VTHeader from "../../components/VTHeader";
import { TOP_PADDING_IOS_FONT } from "../../common/Constants";
import I18n from "i18next";
export default function StripeScreen({
  preferenceData,
  onSuccesBuy,
  onErrorBuy,
}) {
  // const { initPaymentSheet, presentPaymentSheet } = useStripe();
  // const [loading, setLoading] = useState(false);

  // const fetchPaymentSheetParams = async () => {
  //   const { paymentIntent, ephemeralKey, customer } = preferenceData;

  //   return {
  //     paymentIntent,
  //     ephemeralKey,
  //     customer,
  //   };
  // };

  // const initializePaymentSheet = async () => {
  //   const {
  //     paymentIntent,
  //     ephemeralKey,
  //     customer,
  //     publishableKey,
  //   } = await fetchPaymentSheetParams();

  //   const { error } = await initPaymentSheet({
  //     customerId: customer,
  //     customerEphemeralKeySecret: ephemeralKey,
  //     paymentIntentClientSecret: paymentIntent,
  //     // Set `allowsDelayedPaymentMethods` to true if your business can handle payment
  //     //methods that complete payment after a delay, like SEPA Debit and Sofort.
  //     allowsDelayedPaymentMethods: true,
  //   });
  //   if (!error) {
  //     setLoading(true);
  //   }
  // };

  // const openPaymentSheet = async () => {
  //   const response = await presentPaymentSheet();
  //   const { error } = response;
  //   if (error) {
  //     onErrorBuy();
  //   } else {
  //     const { id } = preferenceData;
  //     onSuccesBuy({ payload: { id_preferencia_pago: id } });
  //   }
  // };

  // useEffect(() => {
  //   initializePaymentSheet();
  // }, []);

  // const getHeaderProps = (headerProps) => {
  //   return {
  //     leftButtons: [
  //       {
  //         onPress: () => Actions.pop(),
  //         icon: {
  //           name: "chevron-left",
  //           type: "FontAwesome",
  //           style: {
  //             color: Colors.mainTitleColor,
  //             fontSize: L.h(23),
  //             marginLeft: L.w(8),
  //           },
  //         },
  //       },
  //     ],
  //     titleStyle: {
  //       fontFamily: Fonts.MEDIUM,
  //       fontSize: L.h(20),
  //       color: Colors.mainTitleColor,
  //       top: Platform.OS === "ios" ? TOP_PADDING_IOS_FONT : 0,
  //     },
  //     noShadow: true,
  //     noStatusBar: true,
  //     moreButtonColor: "white",
  //     ...headerProps,
  //   };
  // };
  // const icon = (
  //   <Icon
  //     name="credit-card"
  //     type="FontAwesome"
  //     style={{
  //       color: Colors.secondary_color,
  //       fontSize: L.h(25),
  //     }}
  //   />
  // );
  return (
    <View style={{ flex: 1, backgroundColor: Colors.backgroundCardTypeSelect }}>
      {/* <VTHeader
        {...getHeaderProps({
          backgroundColor: "transparent",
          title: I18n.t("stripeScreen.doPay"),
        })}
      />
      <View style={{ flex: 1 }}>
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            marginTop: L.hProm(32),
          }}
        >
          <Text
            style={{
              color: Colors.titleCardTypeSelect,
              fontFamily: Fonts.DREGULAR,
            }}
          >
            {I18n.t("stripeScreen.selectPaymentMethod")}
          </Text>
        </View>
        <View
          style={{ alignItems: "center", justifyContent: "center" }}
          loading={loading}
          isEmpty={false}
          floatButton={null}
          keyboardShouldPersistTaps={"always"}
        >
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-around",
              marginTop: L.h(28),
            }}
          >
            <IconButton
              onPress={() => openPaymentSheet()}
              label={I18n.t("stripeScreen.payWithStripe")}
              icon={icon}
              containerStyle={{
                backgroundColor: Colors.buttonCardTypeSelect,
                width: L.wProm(300),
              }}
              textStyle={{
                fontFamily: Fonts.DREGULAR,
                color: Colors.buttonTextCardTypeSelect,
              }}
            />
          </View>
        </View>
      </View> */}
    </View>
  );
}
