import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, Icon, Text } from 'native-base'; //WEB99 Content
import { View, TouchableOpacity, Image, ImageBackground } from 'react-native';
import styles from './styles';
import { Actions } from 'react-native-router-flux';
import L from '../../common/Layout';
import I18n from 'i18next';
import AppMem from '../../common/AppMem';
import logo from '../../common/assets/logo.png';
import Colors from '../../common/Colors';
import firebase from 'react-native-firebase';
import toast from 'react-hot-toast';

import {
  sendAgainVerificationEmail,
  actLogout,
} from '../../actions/AuthActions';

const BG_IMG = require('../../common/assets/background_login.jpg');

class ActivateAccount extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onPressClose = this.onPressClose.bind(this);
  }

  // componentDidMount() {
  //   setTimeout(() => {
  //     if (AppMem.appLoader) {
  //       AppMem.closeAppLoader();
  //       Actions.activateAccount();
  //     }
  //   }, 500);
  // }

  async onPressClose() {
    await this.props.actLogout(() => {
      console.log(' >>>>>>>>>> logiin');
      setTimeout(() => {
        Actions.push('login');
      }, 2000);
    });
  }

  onPressConfirm = () => {
    let { sendAgainVerificationEmail } = this.props;
    // AppMem.openAppLoader('bars', {
    //   text: I18n.t('activateAccount.loaderText'),
    //   color: Colors.secondary_color,
    // });

    sendAgainVerificationEmail(this.callback);
  };

  callback(e) {
    console.log(' >>>>>>>>>> e', e);
    if (!e) {
      let currentUser = firebase.auth().currentUser;
      toast.success(
        `${I18n.t('activateAccount.sendTextTitle')}${currentUser.email}`,
        { duration: 10000 }
      );
    } else {
      toast.error(
        `Ya enviamos un e-mail de verificación, aguarda unos minutos para reenviar nuevamente`,
        { duration: 10000 }
      );
    }
    // setTimeout(() => {
    //   let modalProps = {
    //     title: `${I18n.t('activateAccount.sendTextTitle')}${
    //       currentUser._user.email
    //     }`,
    //     type: 'success',
    //     buttonOk: {
    //       text: I18n.t('activateAccount.sendTextConfirm'),
    //       action: () => null,
    //     },
    //   };
    //   AppMem.openModal('alert', modalProps);
    // }, 200);
  }

  render() {
    return (
      <section
        class="event-about padding-top padding-bottom"
        style={{ backgroundColor: Colors.backgroundHistory }}
      >
        <div class="container">
          <div class="row justify-content-between flex-wrap-reverse">
            <div class="col-lg-7 col-xl-6">
              <div class="event-about-content">
                <div class="section-header-3 left-style m-0">
                  <span class="cate">Registo completado</span>
                  <h2 class="title">¡Bienvenido! Verifica tu Cuenta</h2>
                  <p>
                    Tu cuenta ha sido creada exitosamente. Sin embargo, antes de
                    comenzar, necesitamos que actives tu cuenta. Hemos enviado
                    un correo electrónico de activación a la dirección
                    proporcionada durante el registro. Por favor, revisa tu
                    bandeja de entrada y sigue las instrucciones en el correo
                    electrónico para activar tu cuenta. Si no encuentras el
                    correo electrónico, asegúrate de revisar la carpeta de
                    correo no deseado (spam). ¡Gracias por unirte a nosotros!
                    Estamos emocionados de tenerte como parte de nuestra
                    comunidad.
                  </p>
                  <a
                    //  href=""
                    class="custom-button"
                    onClick={this.onPressClose}
                    style={{ cursor: 'pointer' }}
                  >
                    Ya active mi cuenta, Ingresar.
                  </a>
                  <a
                    //href=""
                    //class="custom-button"
                    style={{
                      marginTop: 20,
                      marginLeft: 6,
                      color: Colors.blueberry,
                      cursor: 'pointer',
                    }}
                    onClick={this.onPressConfirm}
                  >
                    Enviar nuevamente el email de activación
                  </a>
                </div>
              </div>
            </div>
            <div class="col-lg-5 col-md-7">
              <div class="event-about-thumb">
                <img
                  src={require('../../common/assets/activar-cuenta-image.png')}
                  alt="sports"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  render1() {
    return (
      <Container
        style={[
          styles.container,
          { marginBottom: L.h(0), backgroundColor: Colors.blueberry },
        ]}
      >
        <ImageBackground source={BG_IMG} style={{ flex: 1 }} resizeMode="cover">
          {/* <View>
                        <Grid style={styles.topGrid}>
                            <Col style={styles.firstCol}>
                                <Button transparent onPress={() => Actions.pop()}>
                                    <Icon name="close" style={styles.closeIcon} />
                                </Button>
                            </Col>
                        </Grid>
                    </View> */}
          <View>
            <View
              style={{
                marginTop: L.h(70),
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Image
                style={styles.imgLogo}
                source={logo}
                resizeMode="contain"
              />
            </View>
            <View style={{ alignSelf: 'center', marginTop: L.h(20) }}>
              <Text style={styles.textLogo}>
                {I18n.t('register.activateAccount.active')}{' '}
                {I18n.t('register.activateAccount.yourAcc')}
              </Text>
            </View>
            <View
              style={[
                styles.paragraph,
                { marginTop: L.h(37), paddingHorizontal: L.w(20) },
              ]}
            >
              <Text style={[styles.paragraphText, { textAlign: 'center' }]}>
                {I18n.t('register.activateAccount.activeMsg1')}{' '}
                {I18n.t('register.activateAccount.activeMsg2')}{' '}
                {I18n.t('register.activateAccount.activeMsg3')}
              </Text>
              <Text style={styles.paragraphText} />
              <Text style={styles.paragraphText} />
            </View>
          </View>
          <View style={{ marginTop: L.h(20), alignItems: 'center' }}>
            <TouchableOpacity
              onPress={this.onPressConfirm}
              style={[styles.button, styles.activate]}
            >
              <Text style={styles.activateTxt}>
                {I18n.t('register.activateAccount.mailError')}
              </Text>
            </TouchableOpacity>
          </View>
          <View
            style={[
              styles.paragraph,
              {
                marginTop: L.h(11),
                flexDirection: 'row',
                justifyContent: 'center',
                backgroundColor: 'rgb(65,174,43)',
                paddingVertical: L.h(10),
              },
            ]}
          >
            <Icon name="checkmark" style={styles.checkIcon} />
            <Text style={styles.paragraphTextInfo}>
              {I18n.t('register.activateAccount.mailSend')}
            </Text>
          </View>
          <TouchableOpacity
            onPress={this.onPressClose}
            style={[
              styles.button,
              styles.activate,
              { alignSelf: 'center', marginTop: L.h(60) },
            ]}
          >
            <Text style={styles.activateTxt}>
              {I18n.t('register.activateAccount.mailMsg')}
            </Text>
          </TouchableOpacity>
        </ImageBackground>
      </Container>
    );
  }
}

function mapStateToProps(store) {
  return {
    userId: store.authReducer.userId,
    user: store.authReducer.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      sendAgainVerificationEmail,
      actLogout,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActivateAccount);
