// TODO Estilar y terminar la parte de la galería
import React from "react";
import {
  Dimensions,
  PermissionsAndroid,
  Platform,
  View,
  ScrollView,
  
  TouchableOpacity,
  Image,
  ActivityIndicator
} from "react-native";
//WEB99 CameraRoll,
import { Container, Icon, Button } from "native-base";
import { Actions } from "react-native-router-flux";
import { RNCamera } from "react-native-camera";
import L from "../../common/Layout";
import Colors from "../../common/Colors";
import IconMA from "react-native-vector-icons/MaterialIcons";
import I18n from "i18next"

let { height } = Dimensions.get("window");
let screen = Dimensions.get("window");

export default class Camera extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      canUseIt: false,
      showCam: false,
      loading: true,
      selected: null,
      images: [],
      flipCamera: false
    };
    this.camera = null;
    this.gallery = null;
    this.imageCropPicker = this.imageCropPicker.bind(this);
    this.storeImages = this.storeImages.bind(this);
  }

  componentWillMount() {
    this.canUseIt();
  }

  renderImageTumbs() {
    return (
      <View
        style={{
          width: screen.width,
          flex: 1,
          position: "absolute",
          paddingTop: L.h(7),
          bottom: L.h(80),
          left: 0,
          height: L.hProm(88),
          backgroundColor: Colors.black_20,
          justifyContent: "center",
          paddingLeft: L.w(8)
        }}>
        <ScrollView style={{}} horizontal>
          <View style={{ flexDirection: "row" }}>
            {this.state.images.map(image => (
              <TouchableOpacity
                onPress={() => this.props.onImageSelect(image.uri, true)}>
                <Image
                  style={{
                    width: L.wProm(72),
                    height: L.hProm(72),
                    marginRight: L.w(8)
                  }}
                  source={{ uri: image.uri }}
                />
              </TouchableOpacity>
            ))}
          </View>
        </ScrollView>
        {/* <Image  style={{width:L.wProm(72),height:L.hProm(72)}} source={{ uri: this.state.images[0] && this.state.images[0].uri}} /> */}
      </View>
    );
  }

  componentDidMount() {
    const fetchParams = {
      first: 20,
      assetType: "Photos",
      groupTypes: "All"
    };
    //WEB99 CameraRoll.getPhotos(fetchParams, this.storeImages, null);
  }

  async canUseIt() {
    let canUse = false;
    try {
      if (Platform.OS == "android") {
        const granted = await PermissionsAndroid.request(
          PermissionsAndroid.PERMISSIONS.CAMERA,
          {
            title: I18n.t("camera.cameraPermissions"),
            message: I18n.t("camera.cameraPermissionsUsage")
          }
        );
        if (granted === PermissionsAndroid.RESULTS.GRANTED) {
          canUse = true;
        }
      } else {

      }
    } catch (e) {
      console.warn("ERROR", e);
    }
    this.setState({ canUseIt: canUse, showCam: canUse, loading: false });
  }

  async takePhoto() {
    if (this.camera) {
      const options = {
        quality: 0.5,
        forceUpOrientation: true,
        fixOrientation: true
      };
      const data = await this.camera.takePictureAsync(options);
      this.props.onImageSelect(data.uri);
    }
  }

  storeImages(data) {
    const assets = data.edges;
    const images = assets.map(asset => asset.node.image);
    this.setState({
      images
    });
  }

  onTapImage(event) {
    let image = event.nativeEvent.selected;
    this.props.onImageSelect(image.uri);
    Actions.pop();
  }

  getSelectedImages(images, current) {
    let num = images.length;

    this.setState({
      num,
      selected: images
    });
  }

  imageCropPicker() {
    // ImagePicker.openPicker({
    //   width: 300,
    //   height: 400,
    //   cropping: true
    // }).then(image => {
    //   this.props.onImageSelect(image.path, true);
    // });
  }

  getContent() {
    if (this.state.loading) {
      return (
        <ActivityIndicator
          size="large"
          color="green"
          style={{ top: height / 2.2 }}
        />
      );
    }
    if ((Platform.OS == 'ios' ? true : this.state.canUseIt) && (Platform.OS == 'ios' ? true : this.state.showCam)) {
      return (
        <RNCamera
          ref={ref => {
            this.camera = ref;
          }}
          style={{
            flex: 1,
            justifyContent: "flex-end",
            alignItems: "center"
          }}
          type={
            this.state.flipCamera
              ? RNCamera.Constants.Type.front
              : RNCamera.Constants.Type.back
          }
          permissionDialogTitle="Permission to use camera"
          permissionDialogMessage="We need your permission to use your camera phone"
        />
      );
    }
    return this.imageCropPicker();
  }

  onPressFlipCamera(bool) {
    this.setState({ flipCamera: !bool });
  }

  render() {
    return (
      <Container>
        {this.getContent()}
        <TouchableOpacity
          style={{
            width: L.wProm(40),
            height: L.hProm(40),
            alignItems: "center",
            justifyContent: "center",
            position: "absolute",
            top: 24,
            left: 0
          }}
          onPress={Actions.pop}>
          <IconMA
            name="chevron-left"
            type="MaterialIcons"
            style={{ fontSize: L.h(45), color: "white" }}
          />
        </TouchableOpacity>
        {this.renderImageTumbs()}
        {(Platform.OS == 'ios' ? true : this.state.showCam) ? (
          <View
            style={{
              width: screen.width,
              height: L.hProm(70),
              position: "absolute",
              bottom: 0,
              left: 0,
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: "row"
            }}>
            <Button
              transparent
              onPress={() =>
                this.setState(
                  { showCam: (Platform.OS == 'ios' ? true : this.state.showCam) },
                  this.imageCropPicker()
                )
              }
              style={{
                alignSelf: "center",
                justifyContent: "flex-start"
              }}>
              <Icon
                name="image"
                style={{ fontSize: L.h(30), color: "white" }}
              />
            </Button>
            <Button
              transparent
              onPress={this.takePhoto.bind(this)}
              style={{
                alignSelf: "center",
                justifyContent: "center"
                // left: -L.wProm(20),
              }}>
              <Icon
                name="aperture"
                style={{ fontSize: L.h(40), color: Colors.rouge }}
              />
            </Button>
            <Button
              transparent
              style={{
                alignSelf: "center",
                justifyContent: "flex-end"
              }}
              onPress={this.onPressFlipCamera.bind(
                this,
                this.state.flipCamera
              )}>
              <Icon
                name="reverse-camera"
                style={{ fontSize: L.h(40), color: "white" }}
              />
            </Button>
          </View>
        ) : null}
      </Container>
    );
  }
}
