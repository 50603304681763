import firebase from 'react-native-firebase';
import { Platform } from 'react-native';
import authConfig from './AuthConfig';
const pathUser = authConfig.pathUsers;
const pathProfile = authConfig.pathProfile;
const pathTickets = authConfig.pathTickets;
const pathUserTransactions = authConfig.pathUserTransactions;
import { LoginManager } from 'react-native-fbsdk';
import { GoogleSignin } from '@react-native-google-signin/google-signin';
import appleAuth, {
  AppleAuthRequestOperation,
} from '@invertase/react-native-apple-authentication';
import Util from '../../common/Util';

export default class AuthService {
  static deleteAccount() {
    let token = null;
    return new Promise(async (resolve, reject) => {
      try {
        if (firebase.auth() && firebase.auth().currentUser) {
          await firebase
            .auth()
            .currentUser.getIdToken(true)
            .then(data => {
              token = data;
            });

          let endpoint = `${authConfig.endpointDeleteUser}`;
          let response = await authConfig.APIConnector.delete(endpoint, {
            headers: { Authorization: 'Bearer ' + token },
          });

          if (response.error) {
            reject(response);
          } else {
            resolve(response);
          }
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  static changeEmail(newMail, password) {
    return new Promise(async (resolve, reject) => {
      var user = firebase.auth().currentUser;
      const credential = firebase.auth.EmailAuthProvider.credential(
        user._user.providerData[0].email,
        password
      );
      await user
        .reauthenticateWithCredential(credential)
        .then(authData => {
          user
            .updateEmail(newMail)
            .then(function() {
              resolve('OK');
            })
            .catch(function(error) {
              reject(error);
            });
        })
        .catch(authError => {
          reject(authError);
        });
    });
  }

  static codeGenerator(phone, countryCode) {
    return new Promise(async (resolve, reject) => {
      try {
        let token = await Util.getCurrentToken();
        let endpoint = `${authConfig.endpointSendCode}`;
        let response = await authConfig.APIConnector.post(endpoint, {
          headers: { Authorization: 'Bearer ' + token },
          body: JSON.stringify({ phone, countryCode }),
        });
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  static validateCode(code) {
    return new Promise(async (resolve, reject) => {
      try {
        let token = await Util.getCurrentToken();
        let endpoint = `${authConfig.endpointValidateCode}`;
        let response = await authConfig.APIConnector.post(endpoint, {
          headers: { Authorization: 'Bearer ' + token },
          body: JSON.stringify({ code }),
        });
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  static verifyDni(data) {
    return new Promise(async (resolve, reject) => {
      let endpoint =
        authConfig.endpointValidateDNI + '?document=' + data.proDNI;
      return authConfig.APIConnector.get(endpoint)
        .then(res => {
          if (res.exist) {
            reject({ code: 'user-exist' });
          } else {
            resolve('OK');
          }
        })
        .catch(e => {
          reject(e);
        });
    });
  }

  static saveDNIData(data) {
    return new Promise(async (resolve, reject) => {
      let endpoint =
        authConfig.endpointValidateDNI + '?document=' + data.proDNI;
      return authConfig.APIConnector.get(endpoint)
        .then(res => {
          if (res.exist) {
            reject({ code: 'user-exist' });
          } else {
            authConfig.FirebaseConnector.updateWithMerge(
              pathProfile,
              firebase.auth().currentUser._user.uid,
              data
            )
              .then(() => {
                resolve('OK');
              })
              .catch(e => {
                console.log(' >>>>>>>>>> ERROR AL GRABAR', e);
                reject(e);
              });
          }
        })
        .catch(e => {
          reject(e);
        });
    });
  }

  static saveEmailData(data) {
    return new Promise(async (resolve, reject) => {
      let endpoint =
        authConfig.endpointValidateEmail + '?email=' + data.proEmail;
      return authConfig.APIConnector.get(endpoint)
        .then(res => {
          if (res.exist) {
            reject({ code: 'user-exist' });
          } else {
            authConfig.FirebaseConnector.updateWithMerge(
              pathProfile,
              firebase.auth().currentUser._user.uid,
              data
            )
              .then(() => {
                resolve('OK');
              })
              .catch(e => {
                console.log(' >>>>>>>>>> ERROR AL GRABAR', e);
                reject(e);
              });
          }
        })
        .catch(e => {
          console.log(' >>>>>>>>>> catch', e);
          reject(e);
        });
    });
  }

  static saveFCMToken(fcmToken) {
    return new Promise(async (resolve, reject) => {
      authConfig.FirebaseConnector.updateWithMergeFCMArray(
        pathProfile,
        firebase.auth().currentUser._user.uid,
        fcmToken
      )
        .then(() => {
          resolve('OK');
        })
        .catch(e => {
          reject(e);
        });
    });
  }

  static async getMyNextEvents() {
    //Remover esta funcion, llevarla al backend, aca no va a perfomar por mucho tiempo.
    let dataToResolve = {};
    let user = firebase.auth().currentUser;
    if (Platform.OS == 'web') {
      user._user = user;
    }

    try {
      const eventsData = await authConfig.FirebaseConnector.getSubCollection(
        pathUserTransactions,
        user._user.uid,
        'events'
      );

      for (const eachEvent of eventsData) {
        dataToResolve[eachEvent.id] = eachEvent;
        const eventCollection = await authConfig.FirebaseConnector.getGrandChildCollection(
          pathUserTransactions,
          user.uid,
          'events',
          eachEvent.id,
          'tickets'
        );
        dataToResolve[eachEvent.id].tickets = eventCollection;
      }
      return dataToResolve;
    } catch (error) {
      throw error;
    }
  }

  static changePassword(password, newPassword) {
    return new Promise(async (resolve, reject) => {
      var user = firebase.auth().currentUser;
      let providerData =
        Platform.OS == 'web'
          ? user.providerData[0].email
          : user._user.providerData[0].email;
      const credential = firebase.auth.EmailAuthProvider.credential(
        providerData,
        password
      );
      await user
        .reauthenticateWithCredential(credential)
        .then(data => {
          user
            .updatePassword(newPassword)
            .then(function(data) {
              resolve('OK');
            })
            .catch(function(error) {
              console.log('ltira errrorr', error);
              reject(error);
            });
        })
        .catch(e => {
          reject(e);
        });
    });
  }

  static uploadFoto(file) {
    return new Promise((resolve, reject) => {
      firebase
        .storage()
        .ref()
        .child(
          `/images/${firebase.auth().currentUser._user.uid}/${new Date() /
            1000}`
        )
        .putFile(file)
        .then(uploadedFile => {
          authConfig.FirebaseConnector.updateWithMerge(
            pathProfile,
            firebase.auth().currentUser._user.uid,
            {
              proProfileImage: uploadedFile.downloadURL,
            }
          ).then(() => {
            resolve('ok');
          });
        })
        .catch(errorerr => {
          reject(errorerr);
        });
    });
  }

  static saveProfileData(data) {
    return new Promise(async (resolve, reject) => {
      authConfig.FirebaseConnector.updateWithMerge(
        pathProfile,
        firebase.auth().currentUser._user.uid,
        data
      )
        .then(() => {
          resolve('OK');
        })
        .catch(e => {
          reject(e);
        });
    });
  }

  static saveEditProfileData(data) {
    return new Promise(async (resolve, reject) => {
      try {
        let dniRes = data.proDNI ? await this.verifyDni(data) : 'OK';
        let user =
          Platform.OS == 'web'
            ? firebase.auth().currentUser
            : firebase.auth().currentUser._user;
        if (dniRes == 'OK') {
          authConfig.FirebaseConnector.updateWithMerge(
            pathProfile,
            user.uid,
            data
          )
            .then(() => {
              resolve('OK');
            })
            .catch(e => {
              reject(e);
            });
        } else {
          console.log(' >>>>>>>>>> dniRes', dniRes);
          reject(dniRes);
        }
      } catch (e) {
        console.log(' >>>>>>>>>> e', e);
        reject(e);
      }
    });
  }

  static login(user, password) {
    return new Promise(async (resolve, reject) => {
      firebase
        .auth()
        .signInWithEmailAndPassword(user, password)
        .then(user => {
          if (Platform.OS == 'web') {
            let userData = user.user;
            user.user._user = userData; // Para copiar la estructura de mobile
          }

          user = user.user;
          authConfig.FirebaseConnector.updateWithMerge(
            pathProfile,
            user._user.uid,
            {
              proInactiveAccount: false,
            }
          );

          resolve(user);
        })
        .catch(e => {
          reject(e);
        });
    });
  }

  static listenerProfileById(id, callback) {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await authConfig.FirebaseConnector.listener(
          pathProfile,
          id,
          callback
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  static ticketTypeSelectedListener(callback) {
    return authConfig.FirebaseConnector.getListenerEvtTicketTypeSelected(
      pathTickets,
      callback
    );
  }

  static listenerTickets(callback) {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await authConfig.FirebaseConnector.listenerCollection(
          pathTickets,
          callback
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  static sendVerificationEmail() {
    return new Promise(async (resolve, reject) => {
      try {
        let user = firebase.auth().currentUser;

        user
          .sendEmailVerification()
          .then(() => {
            resolve('OK');
          })
          .catch(error => {
            reject(error);
          });
      } catch (e) {
        reject(e);
      }
    });
  }

  static addFCMToken(userId) {
    return new Promise(async (resolve, reject) => {
      try {
        await firebase
          .messaging()
          .getToken()
          .then(token => {
            authConfig.Connector.updateWithMerge(pathProfile, userId, {
              FCMToken: token,
            });
          });
      } catch (e) {
        reject(e);
      }
    });
  }

  static getEvents() {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await authConfig.FirebaseConnector.getRootCollection(
          pathTickets
        );
        resolve(response);
      } catch (error) {
        console.log(' >>>>>>>>>> e', error);
        reject(error);
      }
    });
  }
  static getUserData(id) {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await authConfig.FirebaseConnector.get(pathProfile, id);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  static createSocialAccount(userData) {
    return new Promise(async (resolve, reject) => {
      try {
        let nUser = Object.assign({}, userData);
        authConfig.FirebaseConnector.update(
          pathUser,
          firebase.auth().currentUser._user.uid,
          {
            createdUser: true,
          }
        );
        authConfig.FirebaseConnector.updateWithMerge(
          pathProfile,
          firebase.auth().currentUser._user.uid,
          nUser
        );
        resolve('OK');
      } catch (e) {
        reject(e);
      }
    });
  }

  static createAccount(userData) {
    return new Promise(async (resolve, reject) => {
      let endpoint =
        authConfig.endpointValidateDNI + '?document=' + userData.proDNI;
      return authConfig.APIConnector.get(endpoint)
        .then(res => {
          if (res.exist) {
            reject({ code: 'user-exist' });
          } else {
            firebase
              .auth()
              .createUserWithEmailAndPassword(
                userData.proEmail,
                userData.proPassword
              )
              .then(user => {
                if (Platform.OS == 'web') {
                  let userData = user.user;
                  user.user._user = userData; // Para copiar la estructura de mobile
                }
                let nUser = Object.assign({}, userData);
                delete nUser.proPassword;
                authConfig.FirebaseConnector.update(
                  pathUser,
                  user.user._user.uid,
                  {
                    createdUser: true,
                  }
                );
                authConfig.FirebaseConnector.updateWithMerge(
                  pathProfile,
                  user.user._user.uid,
                  nUser
                );
                resolve(user.user);
              })
              .catch(e => {
                reject(e);
              });
          }
        })
        .catch(e => {
          reject(e);
        });
    });
  }

  static validateTokenService(token) {
    return new Promise(async (resolve, reject) => {
      try {
        let endpoint = authConfig.validateToken_endPoint; // De
        let response = await fetch(endpoint, {
          method: 'GET',
        });
        resolve(response && response.json());
      } catch (e) {
        console.log('catcher', e);
        reject(e);
      }
    });
  }

  static async appleLogout() {
    await appleAuth.performRequest({
      requestedOperation: AppleAuthRequestOperation.LOGOUT,
      requestedScopes: [],
    });
  }

  static signOut() {
    return new Promise(async (resolve, reject) => {
      try {
        await firebase
          .auth()
          .signOut()
          .then(async () => {
            LoginManager.logOut();
            await GoogleSignin.signOut();
            //this.appleLogout();
            resolve('OK');
          })
          .catch(e => {
            resolve('OK');
            //console.log(" >>>>>>>>>> error al desloguear ", e);
          });
      } catch (e) {
        reject(e);
      }
    });
  }

  static getUser(id) {
    return new Promise(async (resolve, reject) => {
      try {
        let endpoint = authConfig.getUser_endPonint;
        let response = await fetch(endpoint, {
          method: 'GET',
        });
        resolve(response && response.json());
      } catch (e) {
        console.log('catcher', e);
        reject(e);
      }
    });
  }

  static passwordRecovery(email) {
    return new Promise((resolve, reject) => {
      try {
        var auth = firebase.auth();
        var emailAddress = email;

        auth
          .sendPasswordResetEmail(emailAddress)
          .then(function() {
            resolve('OK');
          })
          .catch(function(error) {
            reject(error);
          });
      } catch (e) {
        reject(e);
      }
    });
  }
}
