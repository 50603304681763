import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  Image,
  ScrollView,
  RefreshControl,
  ActivityIndicator,
} from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Actions } from 'react-native-router-flux';
import _ from 'lodash';
import Fonts from '../../common/Fonts';
import L from '../../common/Layout';
import CardBenefit from '../../components/CardBenefit';
import I18n from 'i18next';
import {
  getMyLoyalyProgram,
  getLoyalyUseMethods,
} from '../../actions/AppActions';
import Colors from '../../common/Colors';

class MainBenefits extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      refresh: false,
      loadingProgram: true,
      loadingMethods: true,
    };
    this.onRefresh = this.onRefresh.bind(this);
  }

  componentWillMount() {
    this.onRefresh();
  }

  onRefresh() {
    let { getMyLoyalyProgram, getLoyalyUseMethods } = this.props;
    getMyLoyalyProgram(() => this.setState({ loadingProgram: false }));
    getLoyalyUseMethods(() => this.setState({ loadingMethods: false }));
  }

  render() {
    let { loyalyUseMethods = [], loyaly = {} } = this.props;
    let { loadingProgram, loadingMethods } = this.state;
    const { points: loyalyPoints = 0 } = loyaly;
    if (loadingProgram || loadingMethods)
      return (
        <ActivityIndicator
          size={'large'}
          style={{ marginTop: L.h(16) }}
          color={Colors.darkIndigo}
        />
      );
    return (
      <View style={{ flex: 1 }}>
        <View style={styles.currentPointsContainer}>
          <Image
            source={require('../../common/assets/pointsIconCoin.png')}
            resizeMode="contain"
            style={{ height: L.h(18) }}
          />
          <Text style={styles.currentPointsText}>
            {I18n.t('mainBenefits.mainText')}{' '}
            <Text style={{ fontFamily: Fonts.DBOLD }}>{loyalyPoints}</Text>{' '}
            {I18n.t('mainBenefits.mainText2')}
          </Text>
        </View>
        <ScrollView
          style={{ flex: 1 }}
          refreshControl={
            <RefreshControl
              refreshing={this.state.refresh}
              onRefresh={this.onRefresh}
            />
          }
        >
          <View style={{ paddingHorizontal: L.h(21), paddingTop: L.h(20) }}>
            <View>
              <Text style={styles.bigTitle}>
                {I18n.t('mainBenefits.exchangeYourPoints')}
              </Text>
              <Text style={styles.bigTitle}>
                {I18n.t('mainBenefits.exchangeYourPointsBenefits')}
              </Text>
            </View>
          </View>
          {_.isEmpty(loyalyUseMethods) ? (
            <Image
              source={require('../../common/assets/emptyStateBenefits.png')}
              resizeMode="contain"
              style={{ alignSelf: 'center' }}
            />
          ) : (
            <View style={styles.listItemContainer}>
              {loyalyUseMethods.map(benefit => {
                let {
                  fc_title,
                  fc_description,
                  fc_points,
                  category = [],
                } = benefit;
                let { cp_title = '' } = category[0];

                return (
                  <CardBenefit
                    typeBenefit={fc_title}
                    cp_title={cp_title}
                    titleBenefit={fc_description}
                    textPoints={fc_points}
                    onPress={() => Actions.push('detailBenefits', { benefit })}
                  />
                );
              })}
            </View>
          )}
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  currentPointsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: Colors.benefitsSeparatorPoints,
    paddingVertical: L.h(10),
    paddingHorizontal: L.w(15),
  },
  currentPointsText: {
    fontSize: L.h(14),
    fontFamily: Fonts.DREGULAR,
    color: Colors.benefitsSeparatorPointsText,
  },
  bigTitle: {
    fontSize: L.h(23),
    fontFamily: Fonts.DBOLD,
    color: Colors.benefitsTitle,
    lineHeight: L.h(30),
  },
  listItemContainer: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    paddingHorizontal: L.w(16),
    paddingTop: L.h(15),
  },
});

function mapStateToProps(store) {
  return {
    loyalyUseMethods: store.appReducer.loyalyUseMethods,
    loyaly: store.appReducer.loyaly,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { getMyLoyalyProgram, getLoyalyUseMethods },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainBenefits);
