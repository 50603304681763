import React from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Image,
  ImageBackground,
  Dimensions,
} from "react-native";
import Icon from "react-native-vector-icons/Ionicons";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Actions } from "react-native-router-flux";
import Colors from "../../common/Colors";
import VTHeader from "../../components/VTHeader";
import RegisterConstants from "./RegisterConstants";
import Util from "../../common/Util";
import Fonts from "../../common/Fonts";
import { actVerifyDni } from "../../actions/AuthActions";
import L from "../../common/Layout";
import I18n from "i18next"
import CardDataMultiple from "../../components/CardDataMultiple";
import CardRowList from "../../components/CardRowList";
import * as Constants from "../../common/Constants";
import { reduxForm, change } from "redux-form";
import UserExistPopUp from "../../components/UserExistPopUp";
let screen = Dimensions.get("window");
import AppMem from "../../common/AppMem";
import _ from "lodash";
import ErrorManager from "../../common/ErrorManager";
const BG_IMG = require("../../common/assets/background_login.jpg");

//WEB99
const CC_ARGENTINA = '';
const ACCOUNT_STATE_VERIFIED= '';
const ACCOUNT_STATE_PENDING= '';

class ScanDataRegister extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.onPressForward = this.onPressForward.bind(this);
    this.onDeleteAccountSuccess = this.onDeleteAccountSuccess.bind(this);
    this.verifyDni = this.verifyDni.bind(this);
    this.callback = this.callback.bind(this);
  }

  onDeleteAccountSuccess(scanData) {
    let gender = scanData.gender === "M" ? 2 : 1;
    this.props.dispatch(
      change("register", "proName", Util.autoCapitalize(scanData.name))
    );
    this.props.dispatch(
      change("register", "proLastName", Util.autoCapitalize(scanData.lastname))
    );
    this.props.dispatch(change("register", "proDNI", scanData.doc_number));
    this.props.dispatch(
      change("register", "doc_type_id", scanData.doc_type_id)
    );
    this.props.dispatch(change("register", "proGender", gender));
    AppMem.closeAppLoader();
    Actions.push("registerE", { scanData: true, existingUserDeleted: true });
  }

  onDeleteAccountError(error) {
    AppMem.closeAppLoader();
    ErrorManager.message(error);
  }

  verifyDni() {
    let age = this.props.scanData.birthDate;
    if (Util.ageValidation(age)) {
      AppMem.openAppLoader("bars", {
        text: I18n.t("register.loaderText"),
        color: Colors.secondary_color,
      });
      let { actVerifyDni } = this.props;
      actVerifyDni({ proDNI: this.props.scanData.doc_number }, this.callback);
    } else {
      const modalProps = {
        title: I18n.t("register.modalTextError"),
        type: "warning",
        buttonOk: {
          text: I18n.t("register.modalTextButtonAccept"),
          action: () => {},
        },
      };
      AppMem.openModal("alert", modalProps);
    }
  }

  callback(error) {
    let { scanData } = this.props;
    let gender = scanData.gender === "M" ? 1 : 2;
    AppMem.closeAppLoader();
    if (error != "OK") {
      AppMem.openModal(<UserExistPopUp />, {
        style: {
          height: L.hProm(310),
          borderRadius: 12,
          width: screen.width - L.wProm(60),
        },
        swipeToClose: true,
      });
    } else {
      this.props.dispatch(
        change("register", "proName", Util.autoCapitalize(scanData.name))
      );
      this.props.dispatch(
        change(
          "register",
          "proLastName",
          Util.autoCapitalize(scanData.lastname)
        )
      );
      this.props.dispatch(change("register", "proDNI", scanData.doc_number));
      // this.props.dispatch(
      //   change("register", "doc_country_id", scanData.doc_country_id)
      // );
      this.props.dispatch(
        change("register", "proDniType", scanData.doc_type_id)
      );
      this.props.dispatch(change("register", "proGender", gender));
      let formatBirthDate = Util.formatBirthDateSimple(
        scanData.birthDate,
        "DD/MM/YYYY"
      );
      this.props.dispatch(change("register", "proBirthDate", formatBirthDate));
      Actions.push("registerE");
      //Actions.registerE();
    }
  }

  onPressForward(scanData) {
    // verificar si el dni ya existe y ver el estado de la cuenta
    //const { actionGetUserByDocumentCCode } = this.props;
    AppMem.openAppLoader("customLoader");
    actionGetUserByDocumentCCode(
      CC_ARGENTINA,
      scanData.doc_number,
      (userData) => {
        AppMem.closeAppLoader();
        if (
          _.get(userData, "personalData.account_state", null) ===
          ACCOUNT_STATE_VERIFIED
        ) {
          const modalProps = {
            title: I18n.t("register.modalTextError2"),
            type: "warning",
            buttonOk: {
              text: I18n.t("register.modalTextButtonAccept"),
              action: () => {},
            },
          };
          AppMem.openModal("alert", modalProps);
          return;
        }

        if (
          _.get(userData, "personalData.account_state", null) ===
          ACCOUNT_STATE_PENDING
        ) {
          Actions.confirmUser({
            userData: userData,
            onPressNotMe: () => {
              const modalProps = {
                title: I18n.t("register.modalTextNotMe"),
                type: "success",
                buttonOk: {
                  text: I18n.t("register.modalTextButton"),
                  action: () => {
                    AppMem.openAppLoader("customLoader");
                    // eliminar cuenta
                    const { actionDeleteAccount } = this.props;
                    actionDeleteAccount(
                      _.get(userData, "personalData.uid_user", null),
                      () => {
                        this.onDeleteAccountSuccess(scanData);
                      },
                      this.onDeleteAccountError
                    );
                  },
                },
                buttonCancel: {
                  text: I18n.t("register.modalTextButtonNo"),
                  action: () => {},
                },
              };
              AppMem.openModal("alert", modalProps);
            },
            onPressMe: () => {
              const modalProps = {
                title: I18n.t("register.modalTextValidateAccount"),
                type: "success",
                buttonOk: {
                  text: I18n.t("register.modalTextButtonAccept"),
                  action: () => {
                    Actions.popTo("login");
                  },
                },
              };
              AppMem.openModal("alert", modalProps);
            },
          });
          return;
        }

        let gender = scanData.gender === "M" ? 2 : 1;
        if (!this.props.socialMedia) {
          this.props.dispatch(
            change("register", "name", Util.autoCapitalize(scanData.name))
          );
          this.props.dispatch(
            change(
              "register",
              "lastname",
              Util.autoCapitalize(scanData.lastname)
            )
          );
          this.props.dispatch(
            change("register", "doc_number", scanData.doc_number)
          );
          // this.props.dispatch(
          //   change("register", "doc_country_id", scanData.doc_country_id)
          // );
          this.props.dispatch(
            change("register", "doc_type_id", scanData.doc_type_id)
          );
          this.props.dispatch(change("register", "gender", gender));
          Actions.push("registerE", { scanData: true });
        } else {
          Actions.push("registerD", {
            scanData: scanData,
            socialMedia: this.props.socialMedia,
          });
        }
      }
    );
  }

  render() {
    let imgSize = L.wProm(120);
    let { scanData } = this.props;
    return (
      <ImageBackground source={BG_IMG} style={{ flex: 1 }} resizeMode="cover">
        <View style={localStyle.container}>
          {<VTHeader {...RegisterConstants.headerProps("Z")} />}
          <View style={localStyle.mainContent}>
            <Text style={localStyle.textInfo}>
              {I18n.t("register.scanData")}
            </Text>
            <Image
              source={require("../../common/assets/profileIos.png")}
              style={{
                width: imgSize,
                height: imgSize,
                borderRadius: imgSize / 2,
                borderWidth: 3,
                borderColor: "white",
                alignSelf: "center",
                marginTop: L.h(24),
                marginBottom: L.h(24),
              }}
            />
            <CardDataMultiple
              iconName={"md-create"}
              //onPress={Actions.contactEdit}
              buttonIcon={false}
              marginBottom={0}
              title={I18n.t("register.scanDataDNI")}
              document={scanData.doc_number}
            >
              <CardRowList
                simple
                label={I18n.t("register.scanDataName")}
                value={Util.autoCapitalize(scanData.name)}
              />
              <CardRowList
                simple
                label={I18n.t("register.scanDataSurname")}
                value={Util.autoCapitalize(scanData.lastname)}
              />
              <CardRowList
                simple
                label={I18n.t("register.scanDataBirthdate")}
                value={scanData.birthDate}
              />
              <CardRowList
                simple
                label={I18n.t("global.gender")}
                value={
                  scanData.gender === "M"
                    ? I18n.t("register.scanDataGenderMan")
                    : I18n.t("register.scanDataGenderWoman")
                }
              />
            </CardDataMultiple>
            <TouchableOpacity
              style={localStyle.buttonAction}
              onPress={this.verifyDni}
            >
              <Icon
                name="md-arrow-round-forward"
                style={localStyle.buttonActionIcon}
              />
              <Text style={localStyle.buttonActionText}>
                {I18n.t("global.after")}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </ImageBackground>
    );
  }
}

ScanDataRegister.defaultProps = {
  scanData: {
    name: "PABLO MICHAEL ALEJANDRO",
    lastname: "MORONI",
    doc_country_id: 1,
    doc_number: "35109778",
    gender: "M",
    doc_type_id: 1,
  },
};

const localStyle = StyleSheet.create({
  closeButton: {
    padding: 10,
    position: "absolute",
    top: L.h(25),
    left: L.w(15),
  },
  closeIcon: {
    fontSize: L.h(30),
    color: "white",
  },
  container: {
    flex: 1,
    //backgroundColor: Colors.cerulean
  },
  mainContent: {
    paddingTop: L.h(15),
    paddingHorizontal: L.w(44),
  },
  logo: {
    width: L.w(141),
    marginTop: L.h(40),
    alignSelf: "center",
  },
  titleText: {
    fontSize: L.h(17),
    padding: 10,
    top: L.h(30),
    textAlign: "center",
    color: "white",
  },
  textInfo: {
    fontSize: L.h(25),
    fontFamily: Fonts.DBOLD,
    color: "white",
  },
  buttonAction: {
    padding: L.h(10),
    top: L.h(15),
    alignItems: "center",
    justifyContent: "center",
    //backgroundColor: "red"
  },
  buttonActionIcon: {
    fontSize: L.h(100),
    color: "white",
    marginBottom: L.h(-20),
  },
  buttonActionText: {
    fontSize: L.h(21),
    fontFamily: Fonts.DBOLD,
    color: "white",
  },
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // actionGetUserByDocumentCCode,
      // actionDeleteAccount
      actVerifyDni,
    },
    dispatch
  );
}
export default reduxForm({
  form: "register",
  destroyOnUnmount: false,
})(
  connect(
    null,
    mapDispatchToProps
  )(ScanDataRegister)
);
