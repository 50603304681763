import React from 'react';

const Footer = () => (
  <section className="footer-section">
    <div className="container">
      <div className="footer-top">
        <div className="logo">
          <a href="index-1.html">
            {/* <img
              src={require("../../common/assets/poweredQuickpass.png")}
              alt="footer"
              width="70"
              height="60"
            /> */}
          </a>
        </div>
        <ul className="social-icons">
          {/* <li>
            <a >
              <i className="fab fa-facebook-f" />
            </a>
          </li>
          <li>
            <a  className="active">
              <i className="fab fa-twitter" />
            </a>
          </li> */}
          {/* <li>
            <a >
              <i className="fab fa-pinterest-p" />
            </a>
          </li> */}
          {/* <li>
            <a >
              <i className="fab fa-google" />
            </a>
          </li> */}
          {/* <li>
            <a >
              <i className="fab fa-instagram" />
            </a>
          </li> */}
        </ul>
      </div>
      <div className="footer-bottom">
        <div className="footer-bottom-area">
          <div className="left">
            <p>
              Copyright © 2024. All Rights Reserved By{' '}
              <a href="https://www.instagram.com/quickpassapps/" target='_blank'>QUICKPASS </a>
            </p>
          </div>
          <ul className="social-icons">
            <li>
              <a href="https://www.instagram.com/quickpassapps/" target='_blank'>
                <i className="fab fa-instagram" />
              </a>
            </li>
          </ul>
          {/* <ul className="links">
            <li>
              <a >About</a>
            </li>
            <li>
              <a >Terms Of Use</a>
            </li>
            <li>
              <a >Privacy Policy</a>
            </li>
            <li>
              <a >FAQ</a>
            </li>
            <li>
              <a >Feedback</a>
            </li>
          </ul> */}
        </div>
      </div>
    </div>
  </section>
);

export default Footer;
