import React from "react";
import {
  View,
  Text,
  TouchableOpacity,
  StyleSheet,
  ScrollView,
  RefreshControl,
} from "react-native";
import moment from "moment";
import { Actions } from "react-native-router-flux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { connect } from "react-redux";
import Util from "../../common/Util";
import Header from "../../components/VTHeader";
import L from "../../common/Layout";
import Fonts from "../../common/Fonts";
import Colors from "../../common/Colors";
import {
  actEventStats,
  actTicketsInfo,
  actGetRRPPDirectDetail,
  actGetRRPPReferencedDetail,
  actGetProducerDetail,
} from "../../actions/ProducerActions";
import EventCard from "../../components/EventCard";
import { Toast } from "native-base";
import Config from "react-native-config";
import Icon from "react-native-vector-icons/MaterialIcons";
import I18n from "i18next";

class StatisticsDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      select: false,
      searchOk: false,
      eventStatsLoader: false,
      refreshing: false,
    };
    this.callbackEventStats = this.callbackEventStats.bind(this);
    this.getStatics = this.getStatics.bind(this);
    this._onRefresh = this._onRefresh.bind(this);
  }

  componentDidMount() {
    this.setState({ eventStatsLoader: true });
    this.getStatics();
  }

  getStatics() {
    let {
      actEventStats,
      actGetProducerDetail,
      actTicketsInfo,
      event,
      actGetRRPPDirectDetail,
      actGetRRPPReferencedDetail,
    } = this.props;
    let eventId = event.id;
    actEventStats(eventId, this.callbackEventStats);
    actTicketsInfo(eventId);
    actGetRRPPDirectDetail(eventId);
    actGetRRPPReferencedDetail(eventId);
    actGetProducerDetail(eventId);
  }

  _onRefresh() {
    this.setState({ refreshing: true });
    this.getStatics();
  }

  callbackEventStats() {
    this.setState({ eventStatsLoader: false, refreshing: false });
  }

  renderType(et) {
    return (
      <View key={et.typeId}>
        <View
          style={[
            styles.subheaderDetail,
            {
              backgroundColor: et.tktColor ? et.tktColor : Colors.twilight,
            },
          ]}
        >
          <Text style={[styles.subheaderText, { flex: 1 }]}>
            {et.tktProducerName
              ? et.tktProducerName.toUpperCase()
              : et.typeId
              ? et.typeId.toUpperCase()
              : ""}
          </Text>
        </View>
        <View style={[{ marginTop: L.h(17) }]}>
          <View style={styles.cardContainer}>
            <View style={styles.singleRowDetail}>
              <Text style={styles.textDetail}>
                {I18n.t("statisticsDetail.ticketInSell")}
              </Text>
              <Text style={styles.textDetailRight}>{et.tktTotalQt}</Text>
            </View>
            <View style={styles.singleRowDetail}>
              <Text style={styles.textDetail}>
                {I18n.t("statisticsDetail.ticketSoldByRrpp")}
              </Text>
              <Text style={styles.textDetailRight}>
                {et.tktTotalQtSellByRrpp}
              </Text>
            </View>
            <View style={styles.singleRowDetail}>
              <Text style={styles.textDetail}>
                {I18n.t("statisticsDetail.ticketSoldByRrppAmount")}
              </Text>
              <Text style={styles.textDetailRight}>
                $ {et.tktTotalAmountSellByRrpp || 0}
              </Text>
            </View>
            <View style={styles.singleRowDetail}>
              <Text style={styles.textDetail}>
                {I18n.t("statisticsDetail.ticketSoldByProducer")}
              </Text>
              <Text style={styles.textDetailRight}>
                {et.tktTotalQtSellByProductor}
              </Text>
            </View>
            <View style={styles.singleRowDetail}>
              <Text style={styles.textDetail}>
                {I18n.t("statisticsDetail.ticketSoldByProducerAmount")}
              </Text>
              <Text style={styles.textDetailRight}>
                $ {et.tktTotalAmountSellByProductor || 0}
              </Text>
            </View>
            <View style={styles.singleRowDetail}>
              <Text style={[styles.textDetail]}>
                {I18n.t("statisticsDetail.sellByApp")}
              </Text>
              <Text style={[styles.textDetailRight]}>
                {et.tktTotalQtSellByApp}
              </Text>
            </View>
            <View style={styles.singleRowDetail}>
              <Text style={[styles.textDetail]}>
                {I18n.t("statisticsDetail.sellByAppAmount")}
              </Text>
              <Text style={[styles.textDetailRight]}>
                $ {et.tktTotalAmountSellByApp || 0}
              </Text>
            </View>
            <View style={styles.singleRowDetail}>
              <Text style={[styles.textDetail, { color: Colors.sellGreen }]}>
                {I18n.t("statisticsDetail.alreadySold")}
              </Text>
              <Text
                style={[styles.textDetailRight, { color: Colors.sellGreen }]}
              >
                {et.tktTotalQtSell}
              </Text>
            </View>
            <View style={styles.singleRowDetail}>
              <Text style={[styles.textDetail, { color: Colors.sellGreen }]}>
                {I18n.t("statisticsDetail.alreadySoldAmount")}
              </Text>
              <Text
                style={[styles.textDetailRight, { color: Colors.sellGreen }]}
              >
                $ {et.tktTotalAmountSell || 0}
              </Text>
            </View>
            <View style={styles.singleRowDetail}>
              <Text style={styles.textDetail}>
                {I18n.t("statisticsDetail.invites")}
              </Text>
              <Text style={styles.textDetailRight}>{et.tktGiftQt}</Text>
            </View>
            <View style={styles.singleRowDetail}>
              <Text style={styles.textDetail}>
                {I18n.t("statisticsDetail.exchanges")}
              </Text>
              <Text style={styles.textDetailRight}>{et.tktLoyalyQt}</Text>
            </View>
          </View>
          <View
            style={[
              styles.cardContainer,
              { marginVertical: L.h(16), marginHorizontal: L.w(16) },
              styles.shadow,
            ]}
          >
            <View style={styles.singleRowDetail}>
              <Text style={[styles.textDetail, { color: Colors.sellGreen }]}>
                {I18n.t("statisticsDetail.amountForProducer")}
              </Text>
              <Text
                style={[styles.textDetailRight, { color: Colors.sellGreen }]}
              >
                ${_.round(et.tktTotalAmountProducer, 2)}
              </Text>
            </View>
          </View>
          <Text style={styles.comText}>
            {I18n.t("statisticsDetail.statisticLabel")}
          </Text>
        </View>
      </View>
    );
  }

  render() {
    let { eventStatsLoader, refreshing } = this.state;
    let {
      event,
      eventStats,
      ticketTypes,
      directRRPPDetail,
      producerRRPPDetail,
      referencedRRPPDetail,
    } = this.props;
    let isVencido = moment(new Date()).isAfter(
      Util.timestampToDate(event.evtEndDate.seconds)
    );
    let isActive = event.evtState == "active" ? true : false;
    let ticketsList = _.orderBy(ticketTypes, ["id"], "desc");

    return (
      <View style={styles.container}>
        <Header
          title={I18n.t("statisticsDetail.statisticTitle")}
          rightButtons={[
            {
              onPress: () => {
                !isVencido && isActive
                  ? Actions.transferTicketRRPP({
                      eventId: event.id,
                      ticket: ticketsList,
                      eventName: event.evtTitle,
                    })
                  : Toast.show({
                      text: I18n.t("statisticsDetail.overdueOrUnactiveEvent"),
                      buttonText: I18n.t("statisticsDetail.accept"),
                      type: "danger",
                      position: "bottom",
                    });
              },
              icon: {
                name: isVencido || !isActive ? null : "ticket",
                type: "MaterialIcons",
                style: {
                  color: Colors.producerHeaderTitleText,
                  fontSize: L.h(20),
                },
                text:
                  isVencido || !isActive
                    ? I18n.t("statisticsDetail.overdueEvent")
                    : I18n.t("statisticsDetail.alreadyToSell"),
                textStyle: {
                  fontSize: L.h(17),
                  color: Colors.producerHeaderTitleText,
                  fontFamily: Fonts.DREGULAR,
                },
              },
            },
          ]}
          leftButtons={[
            {
              onPress: Actions.pop,
              icon: {
                name: "chevron-left",
                type: "MaterialIcons",
                style: {
                  color: Colors.producerHeaderTitleText,
                  fontSize: L.h(25),
                },
                text: Util.isIos() ? "" : "",
                textStyle: {
                  fontSize: L.h(18),
                  color: Colors.producerHeaderTitleText,
                },
              },
            },
          ]}
          titleStyle={{
            fontFamily: Fonts.MEDIUM,
            fontSize: L.h(18),
            color: Colors.producerHeaderTitleText,
            top: Platform.OS === "ios" ? 2 : 0,
          }}
          rightButtonStyle={[
            !isVencido && isActive
              ? {
                  backgroundColor: Colors.rrppButtonSell,
                  borderRadius: L.h(30),
                  height: L.h(37),
                  alignItems: "center",
                  justifyContent: "center",
                }
              : null,
          ]}
        />
        <ScrollView
          keyboardShouldPersistTaps="always"
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={this._onRefresh}
            />
          }
        >
          <View style={{ marginHorizontal: L.w(18) }}>
            <EventCard eventData={event} type={2} />
            <View style={styles.titleCardContainer}>
              <View style={styles.ticketSubTitle}>
                <Text style={styles.subTitleText}>
                  {I18n.t("statisticsDetail.organize")}{" "}
                  {Config.PRODUCER_NAME.toUpperCase()}
                </Text>
              </View>
            </View>
            <Text style={styles.warningText}>
              {I18n.t("statisticsDetail.resume")}
            </Text>
          </View>
          {eventStatsLoader ? null : (
            <View style={styles.shadow}>
              <View
                style={{
                  flex: 1,
                  marginHorizontal: L.w(18),
                }}
              >
                <View style={styles.cardContainer}>
                  <View style={styles.singleRowDetail}>
                    <Text style={styles.textDetail}>
                      {I18n.t("statisticsDetail.ticketInSell")}
                    </Text>
                    <Text style={styles.textDetailRight}>
                      {eventStats && eventStats.statsTotalQt
                        ? eventStats && eventStats.statsTotalQt
                        : 0}
                    </Text>
                  </View>
                  <TouchableOpacity
                    style={styles.singleRowDetail}
                    onPress={() =>
                      Actions.statisticsRRPP({
                        direct: directRRPPDetail,
                        event,
                      })
                    }
                  >
                    <Text style={styles.textDetailRRPP}>Ventas por RRPP</Text>
                    <Text style={styles.textDetailRightRRPP}>
                      {eventStats && eventStats.statsTotalQtSellByRrpp}{" "}
                      <Icon
                        name="arrow-forward"
                        style={{ fontSize: 14, color: Colors.midBlue }}
                      />
                    </Text>
                  </TouchableOpacity>
                  <View style={styles.singleRowDetail}>
                    <Text style={styles.textDetail}>Ventas por RRPP Monto</Text>
                    <Text style={styles.textDetailRight}>
                      ${" "}
                      {(eventStats && eventStats.statsTotalAmountSellByRrpp) ||
                        0}{" "}
                    </Text>
                  </View>
                  <View style={styles.singleRowDetail}>
                    <Text style={styles.textDetail}>Ventas por Productor</Text>
                    <Text style={styles.textDetailRight}>
                      {eventStats && eventStats.statsTotalQtSellByProductor}{" "}
                    </Text>
                  </View>
                  <View style={styles.singleRowDetail}>
                    <Text style={styles.textDetail}>
                      Ventas por Productor Monto
                    </Text>
                    <Text style={styles.textDetailRight}>
                      ${" "}
                      {(eventStats &&
                        eventStats.statsTotalAmountSellByProductor) ||
                        0}{" "}
                    </Text>
                  </View>
                  {/* <TouchableOpacity
                    style={styles.singleRowDetail}
                    onPress={() =>
                      Actions.statisticsProducer({ detail: producerRRPPDetail, event })
                    }
                  >
                    <Text style={styles.textDetailRRPP}>
                      Ventas por Productor
                    </Text>
                    <Text style={styles.textDetailRightRRPP}>
                      {eventStats && eventStats.statsTotalQtSellByProductor}{" "}
                      <Icon
                        name="arrow-forward"
                        style={{ fontSize: 14, color: Colors.midBlue }}
                      />
                    </Text>
                  </TouchableOpacity> */}
                  <View style={styles.singleRowDetail}>
                    <Text style={styles.textDetail}>Venta por Aplicación</Text>
                    <Text style={styles.textDetailRight}>
                      {eventStats && eventStats.statsTotalQtSellByApp}
                    </Text>
                  </View>
                  <View style={styles.singleRowDetail}>
                    <Text style={styles.textDetail}>
                      Venta por Aplicación Monto
                    </Text>
                    <Text style={styles.textDetailRight}>
                      ${" "}
                      {(eventStats && eventStats.statsTotalAmountSellByApp) ||
                        0}
                    </Text>
                  </View>
                  <View style={styles.singleRowDetail}>
                    <Text
                      style={[styles.textDetail, { color: Colors.sellGreen }]}
                    >
                      {I18n.t("statisticsDetail.alreadySold")}
                    </Text>
                    <Text
                      style={[
                        styles.textDetailRight,
                        { color: Colors.sellGreen },
                      ]}
                    >
                      {eventStats && eventStats.statsTotalQtSell}
                    </Text>
                  </View>
                  <View style={styles.singleRowDetail}>
                    <Text
                      style={[styles.textDetail, { color: Colors.sellGreen }]}
                    >
                      {I18n.t("statisticsDetail.alreadySoldAmount")}
                    </Text>
                    <Text
                      style={[
                        styles.textDetailRight,
                        { color: Colors.sellGreen },
                      ]}
                    >
                      $ {(eventStats && eventStats.statsTotalAmountSell) || 0}
                    </Text>
                  </View>
                  <TouchableOpacity
                    style={styles.singleRowDetail}
                    onPress={() =>
                      Actions.statisticsRRPP({
                        referenced: referencedRRPPDetail,
                        event,
                      })
                    }
                  >
                    <Text style={styles.textDetailRRPP}>
                      {I18n.t("statisticsDetail.ticketReferencedByRrpp")}
                    </Text>
                    <Text style={styles.textDetailRightRRPP}>
                      {eventStats && eventStats.statsTotalQtReferencedByRrpp}{" "}
                      <Icon
                        name="arrow-forward"
                        style={{ fontSize: 14, color: Colors.midBlue }}
                      />
                    </Text>
                  </TouchableOpacity>
                  <View style={styles.singleRowDetail}>
                    <Text style={[styles.textDetail, { color: "orange" }]}>
                      {I18n.t("statisticsDetail.invites")}
                    </Text>
                    <Text style={[styles.textDetailRight, { color: "orange" }]}>
                      {eventStats && eventStats.statsGiftQt}
                    </Text>
                  </View>
                  <View style={styles.singleRowDetail}>
                    <Text style={[styles.textDetail, { color: "orange" }]}>
                      {I18n.t("statisticsDetail.exchanges")}
                    </Text>
                    <Text style={[styles.textDetailRight, { color: "orange" }]}>
                      {eventStats && eventStats.statsLoyalyQt}
                    </Text>
                  </View>
                </View>
              </View>
              <View style={styles.earnContainer}>
                <Text style={styles.earnText}>Ganancias del productor</Text>
                <Text style={styles.earnMainValue}>
                  {eventStats && eventStats.statsTotalAmountProducer
                    ? `$${_.round(
                        eventStats && eventStats.statsTotalAmountProducer,
                        2
                      )}`
                    : `$${0}`}
                </Text>
              </View>
            </View>
          )}
          <Text style={[styles.warningText, { marginBottom: L.h(5) }]}>
            {I18n.t("statisticsDetail.detailByTicketType")}
          </Text>
          {eventStats &&
            eventStats.statsByType &&
            eventStats.statsByType.map((et) => {
              return this.renderType(et);
            })}
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.backgroundProducerDetail,
  },
  text14Med: {
    color: Colors.commonDetailText,
    fontSize: L.h(14),
    fontFamily: Fonts.MEDIUM,
  },
  shadow: {
    elevation: 2,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  shadow2: {
    elevation: 2,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  dateContainer: {
    flexDirection: "row",
    marginTop: L.h(20),
    marginBottom: L.h(10),
    justifyContent: "space-between",
    marginHorizontal: L.w(5),
  },
  titleCardContainer: {
    marginHorizontal: L.h(4),
    backgroundColor: "white",
    marginVertical: L.h(12),
  },
  ticketTitle: {
    color: Colors.darkIndigo,
    marginHorizontal: L.w(50),
    textAlign: "center",
    marginBottom: L.h(3),
    fontFamily: Fonts.MEDIUM,
    fontSize: L.h(14),
  },
  ticketTitlePlace: {
    color: Colors.darkIndigo,
    marginHorizontal: L.w(50),
    textAlign: "center",
    marginBottom: L.h(12),
    fontFamily: Fonts.MEDIUM,
    fontSize: L.h(14),
  },
  ticketSubTitle: {
    backgroundColor: Colors.secondary_color,
    paddingVertical: L.h(5),
    borderRadius: 4,
  },
  subTitleText: {
    marginHorizontal: L.w(20),
    textAlign: "center",
    fontSize: L.h(13),
    color: Colors.subtitleTextProducerDetail,
    fontFamily: Fonts.MEDIUM,
  },
  warningText: {
    fontSize: L.h(14),
    fontFamily: Fonts.DSEMIBOLD,
    color: Colors.resumeTextProducerDetail,
    marginHorizontal: L.w(30),
    textAlign: "center",
    marginVertical: L.h(16),
  },
  singleRowDetail: {
    flexDirection: "row",
    paddingVertical: L.h(10),
    justifyContent: "space-between",
    borderBottomColor: Colors.black_08,
    borderBottomWidth: 1,
    paddingHorizontal: L.h(10),
  },
  cardContainer: {
    borderRadius: 4,
    elevation: 2,
    margin: L.hProm(4),
    backgroundColor: "white",
  },
  textDetail: {
    fontSize: L.h(13),
    fontFamily: Fonts.DREGULAR,
    color: Colors.greyishBrownThree,
  },
  textDetailRRPP: {
    fontSize: L.h(13),
    fontFamily: Fonts.DREGULAR,
    color: Colors.midBlue,
  },
  textDetailRight: {
    fontSize: L.h(13),
    fontFamily: Fonts.MEDIUM,
    color: Colors.greyishBrownThree,
  },
  textDetailRightRRPP: {
    fontSize: L.h(13),
    fontFamily: Fonts.MEDIUM,
    color: Colors.midBlue,
    alignItems: "center",
  },
  earnContainer: {
    backgroundColor: Colors.trueGreen,
    marginTop: L.h(20),
    marginBottom: L.h(20),
    paddingVertical: L.h(10),
    paddingHorizontal: L.w(30),
    flexDirection: "row",
    justifyContent: "space-between",
  },
  earnText: {
    color: Colors.earnTextProducerDetail,
    fontSize: L.h(13),
    fontFamily: Fonts.DREGULAR,
  },
  earnMainValue: {
    color: Colors.earnValueProducerDetail,
    fontSize: L.h(16),
    fontFamily: Fonts.DSEMIBOLD,
  },
  subheaderDetail: {
    backgroundColor: Colors.twilight,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: L.h(7),
    paddingHorizontal: L.w(18),
    marginTop: L.h(10),
  },
  subheaderText: {
    color: "white",
    fontSize: L.h(13),
  },
  comText: {
    color: Colors.tangerine,
    fontSize: L.h(13),
    fontFamily: Fonts.DREGULAR,
    textAlign: "center",
    marginBottom: L.h(25),
  },
});

function mapStateToProps({ authReducer, producerReducer }) {
  return {
    user: authReducer.user,
    eventStats: producerReducer.eventStats,
    producerRRPPDetail: producerReducer.producerRRPPDetail,
    referencedRRPPDetail: producerReducer.referencedRRPPDetail,
    directRRPPDetail: producerReducer.directRRPPDetail,
    ticketTypes: producerReducer.ticketTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actGetRRPPDirectDetail,
      actGetRRPPReferencedDetail,
      actGetProducerDetail,
      actEventStats,
      actTicketsInfo,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticsDetail);
