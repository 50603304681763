import React, { Component } from "react";
import {
  View, 
  TouchableOpacity,
  StyleSheet,
  Text,
  Platform
} from "react-native";
import { ViewPropTypes } from 'deprecated-react-native-prop-types';
import Colors from "../common/Colors";
import Fonts from "../common/Fonts";
import PropTypes from "prop-types";

const Segment = ({
  isTabActive,
  index,
  badge,
  text,
  firstTabStyle,
  lastTabStyle,
  tabStyle,
  activeTabStyle,
  tabTextStyle,
  activeTabTextStyle,
  tabBadgeContainerStyle,
  activeTabBadgeContainerStyle,
  tabBadgeStyle,
  activeTabBadgeStyle,
  onTabPress,
  height
}) => {
  return (
    <TouchableOpacity
      style={[
        { height },
        styles.tabStyle,
        tabStyle,
        isTabActive ? [styles.activeTabStyle, activeTabStyle] : {},
        firstTabStyle,
        lastTabStyle
      ]}
      onPress={() => onTabPress(index)}
      activeOpacity={1}
    >
      <View style={styles.segmentContainer}>
        <Text
          style={[
            styles.tabTextStyle,
            tabTextStyle,
            isTabActive ? [styles.activeTabTextStyle, activeTabTextStyle] : {}
          ]}
          numberOfLines={1}
          ellipsizeMode="tail"
        >
          {text}
        </Text>
        {badge ? (
          <View
            style={[
              styles.tabBadgeContainerStyle,
              tabBadgeContainerStyle,
              isTabActive
                ? [
                    styles.activeTabBadgeContainerStyle,
                    activeTabBadgeContainerStyle
                  ]
                : {}
            ]}
          >
            <Text
              style={[
                styles.tabBadgeStyle,
                tabBadgeStyle,
                isTabActive
                  ? [styles.activeTabBadgeStyle, activeTabBadgeStyle]
                  : {}
              ]}
            >
              {badge}
            </Text>
          </View>
        ) : null}
      </View>
    </TouchableOpacity>
  );
};

const handleTabPress = (index, selectedIndex, onTabPress) => {
  if (selectedIndex !== index) {
    onTabPress(index);
  }
};

const SegmentControl = ({
  selectedIndex,
  values,
  badges,
  borderRadius,
  tabsContainerStyle,
  tabStyle,
  activeTabStyle,
  tabTextStyle,
  activeTabTextStyle,
  tabBadgeContainerStyle,
  activeTabBadgeContainerStyle,
  tabBadgeStyle,
  activeTabBadgeStyle,
  onTabPress,
  height
}) => {
  const firstTabStyle = [
    {
      borderRightWidth: 0,
      borderTopLeftRadius: borderRadius,
      borderBottomLeftRadius: borderRadius
    }
  ];
  const lastTabStyle = [
    {
      borderLeftWidth: 1,
      borderTopRightRadius: borderRadius,
      borderBottomRightRadius: borderRadius
    }
  ];

  return (
    <View
      style={[styles.tabsContainerStyle, tabsContainerStyle, { borderRadius }]}
      removeClippedSubviews={false}
    >
      {values.map((item, index) => {
        return (
          <Segment
            key={index}
            index={index}
            badge={badges && badges[index] ? badges[index] : false}
            isTabActive={selectedIndex === index}
            text={item}
            onTabPress={index =>
              handleTabPress(index, selectedIndex, onTabPress)
            }
            firstTabStyle={index === 0 ? firstTabStyle : {}}
            lastTabStyle={index === values.length - 1 ? lastTabStyle : {}}
            tabStyle={[
              tabStyle,
              index !== 0 && index !== values.length - 1
                ? { borderWidth: 0 }
                : {}
            ]}
            activeTabStyle={activeTabStyle}
            tabTextStyle={tabTextStyle}
            activeTabTextStyle={activeTabTextStyle}
            tabBadgeContainerStyle={tabBadgeContainerStyle}
            activeTabBadgeContainerStyle={activeTabBadgeContainerStyle}
            tabBadgeStyle={tabBadgeStyle}
            activeTabBadgeStyle={activeTabBadgeStyle}
            height={height}
          />
        );
      })}
    </View>
  );
};

SegmentControl.propTypes = {
  values: PropTypes.array.isRequired,
  badges: PropTypes.array,
  onTabPress: PropTypes.func.isRequired,
  selectedIndex: PropTypes.number.isRequired,
  tabsContainerStyle: (Platform.OS === 'web') ? '' :ViewPropTypes.style,
  activeTabBadgeContainerStyle: (Platform.OS === 'web') ? '' : Text.propTypes.style,
  tabBadgeStyle: (Platform.OS === 'web') ? '' : Text.propTypes.style,
  activeTabBadgeStyle: (Platform.OS === 'web') ? '' : Text.propTypes.style,
  borderRadius: PropTypes.number,
  tabStyle: (Platform.OS === 'web') ? '' :ViewPropTypes.style,
  activeTabStyle: (Platform.OS === 'web') ? '' :ViewPropTypes.style,
  tabTextStyle: (Platform.OS === 'web') ? '' : Text.propTypes.style,
  activeTabTextStyle: (Platform.OS === 'web') ? '' : Text.propTypes.style,
  tabBadgeContainerStyle: (Platform.OS === 'web') ? '' : Text.propTypes.style,
  height: PropTypes.number.isRequired 
};

const styles = StyleSheet.create({
  tabsContainerStyle: {
    backgroundColor: "transparent",
    flexDirection: "row"
  },
  tabStyle: {
    paddingVertical: 5,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    //borderColor: "black",
    backgroundColor: Colors.producerMainTabBackground,
    borderColor: Colors.producerMainTabBorder
  },
  activeTabStyle: {
    //backgroundColor: "red",
    borderBottomWidth: 5,
    borderRightWidth: 0,
    borderColor: Colors.producerMainActiveTabBorder
  },
  tabTextStyle: {
    color: Colors.producerMainTabText,
    fontFamily: Fonts.DSEMIBOLD
  },
  activeTabTextStyle: {
    color: Colors.producerMainActiveTabText,
    fontFamily: Fonts.DSEMIBOLD
  },
  tabBadgeContainerStyle: {
    borderRadius: 20,
    backgroundColor: "#0082C0",
    paddingLeft: 5,
    paddingRight: 5,
    marginLeft: 5,
    marginBottom: 0,
    borderWidth: 0
  },
  activeTabBadgeContainerStyle: {
    backgroundColor: "white"
  },
  tabBadgeStyle: {
    color: "white",
    fontSize: 11,
    marginTop: 2,
    fontFamily: Fonts.DBOLD
  },
  activeTabBadgeStyle: {
    color: "black",
    fontFamily: Fonts.DBOLD
  },
  segmentContainer: {
    flexDirection: "row"
  }
});

export default SegmentControl;
