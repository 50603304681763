import Settings from "../base/Settings";

export default class ProducerConfig {
  static get endpoint() {
    return Settings.CloudFunctions();
  }

  static get endpointGetEventStats() {
    return `${ProducerConfig.endpoint}/events/statistics`;
  }
  static get endpointGetDirectDetailRrpp() {
    return `${ProducerConfig.endpoint}/events/detail-direct-rrpp`;
  }
  static get endpointGetReferencedDetailRrpp() {
    return `${ProducerConfig.endpoint}/events/detail-referenced-rrpp`;
  }
  static get endpointGetDetailProducer() {
    return `${ProducerConfig.endpoint}/events/detail-direct-rrpp`;
  }

  static get rrppTicketInfoEndpoint() {
    return `${ProducerConfig.endpoint}/relpub/stats-producer`;
  }
}
