import Settings from "../base/Settings";
import APIConnector from "../base/Connector";
import FirebaseConnector from "../base/FirebaseConnector";
const connector = new FirebaseConnector({ timeout: 30000 });
const apiConnector = new APIConnector({ timeout: 30000 });

export default class RRPPConfig {
  static get endpoint() {
    return Settings.CloudFunctions();
  }
  static get FirebaseConnector() {
    return connector;
  }
  static get APIConnector() {
    return apiConnector;
  }
  static get transferTicket() {
    return `${RRPPConfig.endpoint}/relpub/buyTicket`;
  }

  static get rrppStats() {
    return `${RRPPConfig.endpoint}/relpub/stats`;
  }

  static get transferTicketNoReg() {
    return `${RRPPConfig.endpoint}/relpub/buyTicket`;
  }
}
