import _ from "lodash";
import * as actions from "../actions/ActionTypes";

const initialState = {
  user: {},
  userId: null,
  claims: {},
  showingSplash: true,
  tokenOK: false,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case actions.ACT_AUTH_USER_CHANGE:
      return Object.assign({}, state, action.props);
    case "ACT_AUTH_GET_USER_DATA":
      let stateCloned = _.cloneDeep(state);
      stateCloned.user = action.user;
      return { ...stateCloned };
    case actions.ACT_AUTH_CREATE_USER:
      return Object.assign({}, state, { newUser: action.props });
    case actions.ACT_AUTH_LOGOUT:
      let logoutState = _.cloneDeep(state);
      logoutState.user = action.user;
      logoutState.userId = action.userId;
      logoutState.claims = action.claims;
      return { ...logoutState };
    case actions.ACT_USER_DATA:
      return Object.assign({}, state, {
        userData: action.userData,
        tokenOK: action.tokenOK,
      });
    case actions.ACT_SPLASH:
      return Object.assign({}, state, { showingSplash: action.showingSplash });
    default:
      return state;
  }
}
