import React, { Component } from 'react';
import {
  View,
  Dimensions,
  Text,
  StyleSheet,
  TouchableOpacity,
  ActivityIndicator,
} from 'react-native';
import L from '../common/Layout';
import Colors from '../common/Colors';
import Fonts from '../common/Fonts';
import { Icon } from 'native-base';
import Pdf from 'react-native-pdf';
import AppMem from '../common/AppMem';
import Config from 'react-native-config';
import I18n from 'i18next';

const TERMS_PDF_URL = Config.TERMS_PDF_URL;
class VTTermsModal extends Component {
  constructor(props) {
    super(props);
    this.state = { modalProps: null, loader: false, check: false };
    this._isOpen = false;
    this._mustBeOpen = false;
    this.onPressCheck = this.onPressCheck.bind(this);
  }

  close() {
    this._isOpen = false;
    this._mustBeOpen = false;
    // this.refs.modal.close();
  }

  open(props) {
    this._isOpen = true;
    this._mustBeOpen = props.mustBeOpen;
    this.setState({ modalProps: props });
    //this.refs.modal.open();
  }

  get isOpen() {
    return this._isOpen;
  }

  get mustBeOpen() {
    return this._mustBeOpen;
  }

  showLoader() {
    this.setState({ loader: true });
  }

  hideLoader() {
    this.setState({ loader: false });
  }

  onPressCheck() {
    this.setState({ check: !this.state.check });
  }

  /*web99 TODOWEB
 <Pdf
            source={source}
            onLoadComplete={(numberOfPages, filePath) => {
              console.log(`number of pages: ${numberOfPages}`);
            }}
            onPageChanged={(page, numberOfPages) => {
              console.log(`current page: ${page}`);
            }}
            onError={(error) => {
              console.log("Error PDF", error);
            }}
            style={{ flex: 1, width: "100%" }}
          />
  */

  render() {
    const source = {
      uri: TERMS_PDF_URL,
      cache: true,
    };
    return (
      <View
        //ref="modal"
        backdropPressToClose={false}
        swipeToClose={false}
        style={[
          this.props.logoStyle,
          { marginTop: 150, height: 300, width: 300 },
        ]}
        backdropOpacity={0.5}
      >
        <View
          style={{
            height: L.hProm(50),
            justifyContent: 'center',
            paddingLeft: L.w(16),
            borderBottomWidth: 0.5,
            borderColor: Colors.black_12,
            borderTopStartRadius: 4,
            borderTopEndRadius: 4,
            backgroundColor: Colors.ypfColor,
          }}
        >
          <Text
            style={{
              fontSize: L.h(16),
              fontFamily: Fonts.MEDIUM,
              color: Colors.primary_color,
            }}
          >
            {I18n.t('vtTermsModal.terms')} {Config.PRODUCER_NAME}
          </Text>
        </View>
        <View style={{ flex: 1 }} />
        <View
          style={{
            height: L.hProm(95),
            paddingHorizontal: L.w(16),
            borderTopWidth: 0.5,
            borderColor: Colors.black_12,
            backgroundColor: Colors.ypfColor,
            borderBottomStartRadius: 4,
            borderBottomEndRadius: 4,
          }}
        >
          <TouchableOpacity
            style={{
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: L.h(8),
            }}
            onPress={this.onPressCheck}
          >
            <Icon
              type="FontAwesome"
              name={this.state.check ? 'check-square-o' : 'square-o'}
              style={{ fontSize: L.h(30), color: Colors.primary_color }}
            />
            <Text style={{ color: Colors.greyishBrown, marginLeft: L.w(16) }}>
              {I18n.t('vtTermsModal.accept')} {Config.PRODUCER_NAME}
            </Text>
          </TouchableOpacity>
          <View
            style={{
              alignItems: 'center',
              flexDirection: 'row',
              justifyContent: this.state.loader ? 'center' : 'flex-end',
              //borderWidth: 1
            }}
          >
            {this.state.loader ? (
              <ActivityIndicator
                size="large"
                color="white"
                style={{ alignSelf: 'center' }}
              />
            ) : (
              [
                <TouchableOpacity
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginRight: L.w(16),
                  }}
                  disabled={!this.props.cancelButton ? true : false}
                  onPress={() => AppMem.closeModal()}
                  key={0}
                >
                  <Text
                    style={{
                      textAlign: 'center',
                      fontSize: L.h(16),
                      fontFamily: Fonts.MEDIUM,
                      color: !this.props.cancelButton
                        ? 'white'
                        : Colors.greyishBrownThree,
                      marginVertical: L.w(16),
                      marginHorizontal: L.h(8),
                    }}
                  >
                    {I18n.t('vtTermsModal.cancel')}
                  </Text>
                </TouchableOpacity>,
                <TouchableOpacity
                  key={1}
                  onPress={() => {
                    this.props.onPressAccept && this.props.onPressAccept();
                  }}
                  disabled={!this.state.check}
                >
                  <Text
                    style={{
                      textAlign: 'center',
                      fontSize: L.h(16),
                      fontFamily: Fonts.MEDIUM,
                      color: this.state.check ? Colors.primary_color : 'grey',
                      marginVertical: L.w(16),
                      marginHorizontal: L.h(8),
                    }}
                  >
                    {I18n.t('vtTermsModal.confirm')}
                  </Text>
                </TouchableOpacity>,
              ]
            )}
          </View>
        </View>
      </View>
    );
  }
}

VTTermsModal.defaultProps = {
  logoStyle: {
    width: '90%',
    height: L.hProm(550),
    backgroundColor: 'white',
    borderRadius: 5,
  },
};
const localStyle = StyleSheet.create({});

export default VTTermsModal;
