"Use strict";
import momentTZ from "moment-timezone";
import moment from "moment";
import firebase from "react-native-firebase";
import { Platform, Dimensions } from "react-native";
const screen = Dimensions.get("window");
import _ from "lodash";
import Config from "react-native-config";

class Util {
  static isIos() {
    if (Platform.OS === "ios") return true;
    return false;
  }

  static timestampToDate(timestamp) {
    return moment.unix(timestamp).toDate();
  }

  static isIosXGen() {
    if (Platform.OS === "ios" && screen.height > 800) return true;
    return false;
  }

  static ageValidation(bd) {
    var eighteenYearsAgo = moment().subtract("years", 18);
    var birthday = moment(bd);
    if (!birthday.isValid()) {
      console.log(" >>>>>>>>>> invalid date");
      return "invalid date";
    } else if (eighteenYearsAgo.isAfter(birthday)) {
      console.log(" >>>>>>>>>> true");
      return true;
    } else {
      console.log(" >>>>>>>>>> false");
      return false;
    }
  }

  static formatPrice(num, showDecimals = false, decimals = 2) {
    if (isNaN(num)) {
      return "";
    }
    var sign = num < 0 ? "-" : "";
    num = Math.abs(num).toFixed(decimals);
    var parts = num.split(".");
    var intPart = parts[0];
    var formattedIntPart = "";
    for (var i = intPart.length - 1, j = 0; i >= 0; i--, j++) {
      if (j % 3 === 0 && j !== 0) {
        formattedIntPart = "." + formattedIntPart;
      }
      formattedIntPart = intPart[i] + formattedIntPart;
    }
    if (showDecimals) {
      var decimalPart = parts[1];
      return sign + formattedIntPart + "," + decimalPart;
    } else {
      return sign + formattedIntPart;
    }
  }

  static isRegular() {
    if (screen.height >= 667) return true;
    return false;
  }

  static formatBirthDate(values, date) {
    let newValues = Object.assign({}, values);
    let birthDate = moment(newValues.proBirthDate, date).format();
    newValues.proBirthDate = new Date(birthDate);
    let formValues = Config.TENANT_HAS_BIRTHDATE_MIGRATED ? newValues : values;
    return formValues;
  }

  static formatBirthDateSimple(value, date) {
    let birthDate = moment(value, date).format();
    let formatBirth = new Date(birthDate);
    let formValue = Config.TENANT_HAS_BIRTHDATE_MIGRATED ? formatBirth : value;
    return formValue;
  }

  static orderByDateSeconds(array, type) {
    //console.log(" >>>>>>>>>> array", array);
    let order = type == 1 ? ["asc", "desc"] : ["desc", "asc"];
    let sortArr = _.orderBy(
      array,
      [
        (o) => {
          return o.evtDateTime && o.evtDateTime.seconds;
        },
      ],
      order
    );
    return sortArr;
  }
  static orderMovementsByDateSeconds(array, type) {
    //console.log(" >>>>>>>>>> array", array);
    let order = type == 1 ? ["asc", "desc"] : ["desc", "asc"];
    let sortArr = _.orderBy(
      array,
      [
        (o) => {
          return o.mo_date && o.mo_date._seconds;
        },
      ],
      order
    );
    return sortArr;
  }

  static totalRelAmount(key, data) {
    let acc = null;
    data &&
      data.map((ed) => {
        return (acc += ed[key]);
      });
    return acc ? acc : 0;
  }

  static totalNoRelAmount(key, data, tot) {
    let acc = null;
    let total = tot ? tot : 0;
    data &&
      data.map((ed) => {
        return (acc += ed[key]);
      });
    return acc ? total - acc : total;
  }

  static getCurrentClaims() {
    return new Promise(async (resolve, reject) => {
      try {
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then((idTokenResult) => {
            if (idTokenResult.claims) {
              resolve(idTokenResult.claims);
            } else {
              reject({ code: "user-no-exist" });
            }
          })
          .catch((error) => {
            reject(error);
          });
      } catch (error) {
        reject(error);
      }
    });
  }

  static getCurrentToken() {
    return new Promise(async (resolve, reject) => {
      try {
        let resp =
          firebase.auth().currentUser &&
          firebase.auth().currentUser.getIdToken(true);
        //console.log(" >>>>>>>>>> resp", resp);
        resolve(resp);
      } catch (error) {
        reject(error);
      }
    });
  }

  static getCurrentUID() {
    return new Promise(async (resolve, reject) => {
      try {
        let { currentUser } = firebase.auth();
        if (currentUser && currentUser.uid) {
          resolve(currentUser.uid);
        } else {
          reject({ code: "user-no-exist" });
        }
      } catch (error) {
        reject(error);
      }
    });
  }

  static isAvailableTickeType(tktVisible, tktInitDate, tktEndDate) {
    //TODO remover pasando toda esta info al backend
    let todayBA = momentTZ()
      .tz("America/Buenos_Aires")
      .format("MM/DD/YYYY hh:mm A");
    let ticketInitDate = moment(tktInitDate).format("MM/DD/YYYY hh:mm A");
    let ticketEndDate = moment(tktEndDate).format("MM/DD/YYYY hh:mm A");

    return (
      tktVisible &&
      moment(todayBA).isAfter(ticketInitDate) &&
      moment(todayBA).isBefore(ticketEndDate)
    );
  }
  static autoCapitalize(string) {
    let text = string
      .toLowerCase()
      .split(" ")
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(" ");
    return text;
  }
  static trimSpace(text) {
    let trimText = text
      .replace(/(^\s*)|(\s*$)/gi, "") // removes leading and trailing spaces
      .replace(/[ ]{1,}/gi, "") // replaces multiple spaces
      .replace(/\n +/, "\n");
    return trimText;
  }

  static splitFullName(displayName) {
    let name = displayName
      .split(" ")
      .slice(0, -1)
      .join(" ");
    let lastname = displayName
      .split(" ")
      .slice(-1)
      .join(" ");
    let splitName = { name, lastname };
    return splitName;
  }
}

export default Util;
