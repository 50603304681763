import React, { Component } from 'react';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import SplashScreen from 'react-native-splash-screen';
import * as RNLocalize from 'react-native-localize';
import moment from 'moment';
import 'moment/locale/es';
const { languageCode } = RNLocalize.getLocales()[0];
moment.locale(languageCode);
import './i18n';
import { MenuContext } from 'react-native-menu';
import AppRoot from './AppRoot';
import { StatusBar, SafeAreaView, Platform } from 'react-native';
import reducers from './reducers';
import Util from './common/Util';
import codePush from 'react-native-code-push';
console.disableYellowBox = true;
import { StripeProvider } from '@stripe/stripe-react-native';
import Config from 'react-native-config';

class App extends React.PureComponent {
  constructor(props) {
    super(props);
    /* INIT_BIND_HANDLER */
    if (!Util.isIos()) {
      StatusBar.setTranslucent(true);
      StatusBar.setBackgroundColor('rgba(0, 0, 0, 0.2)');
    }
  }

  componentWillUnmount() {
    /* INIT_INJECT_COMPONENTWILL_UNMOUNT */
  }

  async componentDidMount() {
    /* INIT_INJECT_COMPONENTDID_MOUNT */
    if (Platform.OS != 'web') SplashScreen.hide();
  }

  /* INIT_NOTIFICATION_HANDLER_FUNCTIONS */
  //WEB99 SafeAreaView lo tengo que reemplazar por un wrapper.
  render() {
    const store = createStore(reducers, {}, applyMiddleware(ReduxThunk));
    console.log(' >>>>>>>>>> appweb');
    return (
      <Provider store={store}>
        {Platform.OS != 'web' ? (
          <MenuContext style={{ flex: 1 }}>
            {Config.APP_PAYMENT_GATEWAY === 'stripe' ? (
              <StripeProvider
                publishableKey={`${Config.STRIPE_PUBLISHABLE_KEY}`}
                merchantIdentifier={Config.APP_ID}
              >
                <AppRoot />
              </StripeProvider>
            ) : (
              <AppRoot />
            )}
          </MenuContext>
        ) : (
          <AppRoot />
        )}
      </Provider>
    );
  }
}

let exportObject = {};
if (Platform.OS == 'web') {
  exportObject = App;
} else {
  let codePushOptions = {
    checkFrequency: codePush.CheckFrequency.ON_APP_RESUME,
  };
  exportObject = codePush(codePushOptions)(App);
}

export default exportObject;
