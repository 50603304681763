import React, { Component } from 'react';
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity
} from 'react-native';
import { Spinner, Button } from 'native-base';

let styles = StyleSheet.create( {
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center'
  },
  button: {
    backgroundColor: 'lightblue',
    padding: 12,
    margin: 16,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    borderColor: 'rgba(0, 0, 0, 0.1)'
  }
} );

class VTConfirmationModal extends Component {
    _renderButton = ( text, onPress ) => (
	<TouchableOpacity onPress={onPress}>
		<View style={styles.button}>
			<Text>{text}</Text>
		</View>
	</TouchableOpacity>
    );

    _renderModalContent = ( modalMsg, buttonOk, buttonCancel, close ) => (
	<View>
		<Text style={{ alignSelf: 'center' }}>{modalMsg}</Text>
		<View style={{ flexDirection: 'row' }}>
			{this._renderButton( buttonCancel.text, () => {
	  buttonCancel.action();
	  close();
			} )}
			{this._renderButton( buttonOk.text, () => {
	  buttonOk.action();
	  close();
			} )}
		</View>
	</View>
    );

    render() {
      const {
        title, buttonOk, buttonCancel, close
      } = this.props;
      return (
	<View style={styles.container}>
		{this._renderModalContent( title, buttonOk, buttonCancel, close )}
	</View>
      );
    }
}

export default VTConfirmationModal;
