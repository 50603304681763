import { Component } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";

const USER_TOKEN = "@userToken:key";
const EVENTS_OFFLINE = "@eventsOffline:key";

class AppAsyncStorage {
  static async setUserToken(token) {
    try {
      return await AsyncStorage.setItem(USER_TOKEN, token);
    } catch (error) {
      console.error(`AsyncStorage#setUser error: ${error.message}`);
    }
  }

  static async getUserToken() {
    return await AsyncStorage.getItem(USER_TOKEN);
  }

  static async removeUserToken() {
    return await AsyncStorage.removeItem(USER_TOKEN);
  }

  static setMyEventsOffline(events) {
    return AsyncStorage.setItem(EVENTS_OFFLINE, JSON.stringify(events));
  }

  static async getMyEventsOffline() {
    return AsyncStorage.getItem(EVENTS_OFFLINE).then((result) => {
      if (result) {
        try {
          result = JSON.parse(result);
        } catch (e) {
          console.error(
            `AsyncStorage#getItem error deserializing JSON for key: ${EVENTS_OFFLINE}`,
            e.message
          );
        }
      }
      return result;
    });
  }

  static setItem(key, value) {
    return AsyncStorage.setItem(key, JSON.stringify(value));
  }

  static async getItem(key, JSONparse = true) {
    return AsyncStorage.getItem(key).then((result) => {
      if (result) {
        try {
          if (JSONparse) result = JSON.parse(result);
        } catch (e) {
          console.error(
            `AsyncStorage#getItem error deserializing JSON for key: ${key}`,
            e.message
          );
        }
      }
      return result;
    });
  }

  static async removeItem(key) {
    return AsyncStorage.removeItem(key);
  }
}
export default  AppAsyncStorage;
