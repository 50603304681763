/*
AppMem.modalGlobalRef.open(<VTUpdateAppModal />,{width:Util.isIos()?screen.width-L.wProm(80):screen.width-L.wProm(40),height:L.hProm(Util.isIos()?311:320),borderRadius:Util.isIos()?13:2}, false)
*/
import React, { Component, PropTypes } from "react";
import {
  Animated,
  Dimensions,
  Image,
  ScrollView,
  StyleSheet,
  Text,
  TouchableOpacity,
  View,
  Platform,
  TouchableHighlight,
} from "react-native";
import I18n from "i18next";
import LinearGradient from "react-native-linear-gradient";
import L from "../common/Layout";
import Fonts from "../common/Fonts";
// import PureComponent from '~/components/PureComponent';
// import THButtonIcon from '~/components/THButtonIcon';
// import Fonts from '~/common/Fonts';
import Colors from "../common/Colors";
// import IconApp from '~/components/IconApp';
// import Util from '~/common/Util';
// import Constant from '~/common/Constant';
let screen = Dimensions.get("window");
const Icon = require("react-native-vector-icons/Ionicons");

let DURATION = 200;

class VTUpdateAppModal extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {};
    this.renderPopItem = this.renderPopItem.bind(this);
  }

  componentDidMount() {
    //  this.show();
  }

  renderPopItem() {
    let { maintenance, message } = this.props;
    return (
      <View style={[localStyle.popContainer, this.props.style]}>
        <LinearGradient
          colors={["rgb(51,49,128)", "rgb(51,49,150)"]}
          style={localStyle.topImg}
          start={{ x: 0, y: 0 }}
          end={{ x: 1, y: 0 }}
        >
          <Image
            source={require("../common/assets/upgrade.png")}
            resizeMode="contain"
            style={localStyle.upgradeImg}
          />
        </LinearGradient>
        <View style={localStyle.footerItem}>
          <Text
            style={[
              localStyle.upgradeVersion,
              {
                marginTop: maintenance ? L.h(8) : L.h(15),
              },
            ]}
            numberOfLines={1}
          >
            {maintenance ? null : I18n.t("global.upgradeVersion")}
          </Text>
          <Text
            style={[
              localStyle.upgradeInfo,
              { fontSize: maintenance && message ? L.h(18) : L.h(16) },
            ]}
            numberOfLines={3}
          >
            {maintenance && message ? message : I18n.t("global.upgradeInfo")}
          </Text>
          {maintenance ? null : (
            <TouchableOpacity
              onPress={this.props.onPress}
              underlayColor="transparent"
              style={localStyle.upgradeBtn}
            >
              <Text style={localStyle.upgrade}>
                {I18n.t("global.upgrade").toUpperCase()}
              </Text>
            </TouchableOpacity>
          )}
        </View>
      </View>
    );
  }

  // Constant.STATUS_BAR_HEIGHT

  render() {
    return <View style={[localStyle.container]}>{this.renderPopItem()}</View>;
  }
}

// VTUpdateAppModal.defaultProps={
// };

let localStyle = StyleSheet.create({
  container: { width: "100%" },
  footerItem: {
    // backgroundColor: "white",
    height: L.hProm(144),
    paddingHorizontal: L.w(16),
  },
  popContainer: {
    height: L.hProm(320),
    width: "100%",
    borderRadius: 2,
    elevation: 2,
    marginBottom: L.h(2),
    backgroundColor: "transparent",
  },
  topImg: {
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    height: L.hProm(176),
    alignItems: "center",
    justifyContent: "flex-end",
    overflow: "hidden",
  },
  upgradeVersion: {
    fontFamily: Fonts.MEDIUM,
    fontSize: L.h(18),
    color: "black",
    textAlign: "center",
    marginTop: L.h(15),
  },
  upgradeInfo: {
    // fontFamily:Fonts.REGULAR,
    // fontSize:L.h(16),
    fontSize: L.h(16),
    // color:Colors.black_54,
    color: "rgb(98, 101, 101)",
    lineHeight: L.hInt(20),
    textAlign: "center",
    marginTop: L.h(12),
    marginHorizontal: L.w(16),
  },
  upgrade: {
    fontFamily: Fonts.MEDIUM,
    fontSize: L.h(14),
    lineHeight: L.hInt(24),
    marginVertical: L.h(8),
    marginHorizontal: L.w(16),
    color: "rgb(234, 100, 34)",
  },
  upgradeImg: {
    width: L.wProm(170),
    height: L.hProm(145),
  },
  upgradeBtn: {
    alignSelf: "center",
    marginTop: L.h(16),
  },
});

export default VTUpdateAppModal;
