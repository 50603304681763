// TODO Colocar las correspondientes en IOS
// (Platform.OS === 'ios') ? "helvetica" : "sans-serif-light";
import { Platform } from "react-native";

const isIos = Platform.OS === "ios";

export default {
  MEDIUM: isIos ? "TitilliumWeb-SemiBold" : "TitilliumWeb-SemiBold",
  DSEMIBOLD: isIos ? "TitilliumWeb-SemiBold" : "TitilliumWeb-SemiBold",
  DREGULAR: isIos ? "TitilliumWeb-Light" : "TitilliumWeb-Light",
  DLIGHT: isIos ? "TitilliumWeb-Light" : "TitilliumWeb-Light",
  DBOLD: isIos ? "TitilliumWeb-SemiBold" : "TitilliumWeb-SemiBold",
};
