import React from "react";
import { StyleSheet, Image, Text, TouchableOpacity, View } from "react-native";
import L from "../common/Layout";
import Fonts from "../common/Fonts";
import I18n from "i18next"
import Colors from "../common/Colors";

const CardBenefit = ({
  onPress,
  typeBenefit,
  cp_title,
  imageBenefit,
  titleBenefit,
  textPoints,
}) => {
  // This will be removed in next loyaly api versions.
  const CATEGORY = {
    drink: "TRAGO",
    drinkKeops: "VASO GRANDE",
    drinkKeops2: "MONJITA MILLER",
    bottleKeops: "BOTELLA ESPUMANTE MUMM",
    bottleKeops2: "BOTELLA VODKA SERNOVA",
    voucherKeops: "VOUCHER KEOPS",
    ticket: "ENTRADA",
    bottle: "BOTELLA",
    voucher: "VOUCHER",
    disccount: "DESCUENTO",
  };
  switch (cp_title) {
    case CATEGORY.drinkKeops:
      imageBenefit = (
        <Image
          source={require("../common/assets/vasoBenefit.jpg")}
          resizeMode="contain"
          style={styles.imageStyle}
        />
      );
      break;
    case CATEGORY.drinkKeops2:
      imageBenefit = (
        <Image
          source={require("../common/assets/monjitaBenefit.jpg")}
          resizeMode="contain"
          style={styles.imageStyle}
        />
      );
      break;
    case CATEGORY.bottleKeops:
      imageBenefit = (
        <Image
          source={require("../common/assets/mummBenefit.jpg")}
          resizeMode="contain"
          style={styles.imageStyle}
        />
      );
      break;
    case CATEGORY.bottleKeops2:
      imageBenefit = (
        <Image
          source={require("../common/assets/sernovaBenefit.jpg")}
          resizeMode="contain"
          style={styles.imageStyle}
        />
      );
      break;
    case CATEGORY.voucherKeops:
      imageBenefit = (
        <Image
          source={require("../common/assets/voucherBenefit.jpg")}
          resizeMode="contain"
          style={styles.imageStyle}
        />
      );
      break;
    case CATEGORY.bottle:
      imageBenefit = (
        <Image
          source={require("../common/assets/botellaBenefit.png")}
          resizeMode="contain"
          style={styles.imageStyle}
        />
      );
      break;
    case CATEGORY.bottle:
      imageBenefit = (
        <Image
          source={require("../common/assets/botellaBenefit.png")}
          resizeMode="contain"
          style={styles.imageStyle}
        />
      );
      break;
    case CATEGORY.disccount:
      imageBenefit = (
        <Image
          source={require("../common/assets/botellasBenefit.png")}
          resizeMode="contain"
          style={styles.imageStyle}
        />
      );
      break;
    case CATEGORY.drink:
      imageBenefit = (
        <Image
          source={require("../common/assets/tragoBenefit.jpg")}
          resizeMode="contain"
          style={styles.imageStyle}
        />
      );
      break;
    case CATEGORY.ticket:
      imageBenefit = (
        <Image
          source={require("../common/assets/entradaBenefit.jpg")}
          resizeMode="contain"
          style={styles.imageStyle}
        />
      );
      break;
    case CATEGORY.voucher:
      imageBenefit = (
        <Image
          source={require("../common/assets/voucherBenefit1.png")}
          resizeMode="contain"
          style={styles.imageStyle}
        />
      );
      break;







    case CATEGORY.voucher:
      imageBenefit = (
        <Image
          source={require("../common/assets/voucherBenefit1.png")}
          resizeMode="contain"
          style={styles.imageStyle}
        />
      );
      break;
    default:
      imageBenefit = (
        <Image
          source={require("../common/assets/exampleBenefitImage.png")}
          resizeMode="contain"
          style={styles.imageStyle}
        />
      );
      break;
  }
  return (
    <TouchableOpacity style={styles.listItemContent} onPress={onPress}>
      <View style={{paddingBottom:L.h(10)}}>
        <Text style={styles.typeBenefit}>
          {typeBenefit ? typeBenefit : I18n.t("cardBenefit.typeBenefit")}
        </Text>
        <View>
          {imageBenefit}
        </View>
        <Text style={styles.titleBenefit}>
          {titleBenefit ? titleBenefit : I18n.t("cardBenefit.titleBenefit")}
        </Text>
      </View>
      <View style={styles.buttonContainer}>
        <View style={styles.buttonPointsContainer}>
          <Image
            source={require("../common/assets/pointsIconCoin.png")}
            resizeMode="contain"
            style={{ height: L.h(23) }}
          />
          <Text style={styles.textPoints}>
            {textPoints ? textPoints : I18n.t("cardBenefit.textPoints")}
          </Text>
        </View>
        <Text style={styles.buttonText}>{I18n.t("cardBenefit.buttonText")}</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  listItemContent: {
    backgroundColor: Colors.cardBenefitBackground,
    width: "45%",
    height: L.h(250),
    flexDirection: "column",
    justifyContent: "space-between",
    borderTopRightRadius: L.h(12),
    borderBottomLeftRadius: L.h(12),
    paddingTop: L.h(7),
    marginBottom: L.h(15),
    elevation: 4,
    shadowColor: "rgba(0, 0, 0, 0.25)",
    shadowOffset: {
      width: 0,
      height: 4,
    },
    shadowRadius: 4,
    shadowOpacity: 1,
  },
  typeBenefit: {
    color: Colors.cardBenefitType,
    fontSize: L.h(14),
    fontFamily: Fonts.DREGULAR,
    paddingVertical: L.h(5),
    paddingLeft: L.w(10),
  },
  titleBenefit: {
    color: Colors.cardBenefitTitle,
    fontSize: L.h(14),
    lineHeight: L.h(16),
    fontFamily: Fonts.DBOLD,
    paddingLeft: L.w(10),
    paddingTop: L.h(10),
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomLeftRadius: L.h(12),
    paddingVertical: L.h(6),
    paddingHorizontal: L.w(12),
    backgroundColor: Colors.cardBenefitButtonBackground,
  },
  buttonPointsContainer: {
    flexDirection: "row",
  },
  textPoints: {
    color: Colors.cardBenefitButtonText,
    fontSize: L.h(14),
    fontFamily: Fonts.DBOLD,
    paddingLeft: L.w(5),
  },
  buttonText: {
    color: Colors.cardBenefitButtonText,
    fontSize: L.h(14),
    fontFamily: Fonts.DBOLD,
  },
  imageStyle:{ 
    height: L.h(106), 
    width:"100%"  
  }
});

export default CardBenefit;
