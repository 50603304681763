import React, { useState } from "react";
import Icon from "react-native-vector-icons/MaterialIcons";
import L from "../../common/Layout";
import Fonts from "../../common/Fonts";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";

function ListHeaderComponent({ countries, lang, onPress }) {
  return (
    <View style={styles.headerStyles}>
      <Text>Paises populares</Text>
    </View>
  );
}

export default function CountryCodePicker({
  onPress,
  style,
  inputStyle,
  labelStyle,
  borderBottomColor,
  countryCode,
  disabled,
  containerStyle,
  popularCountries = ["ar", "cl", "uy", "py"],
}) {
  const [show, setShow] = useState(false);
  const [countryPhoneCode, setCountryCode] = useState(countryCode);

  return (
    <View style={[{ flex: 1 }, containerStyle]}>
    </View>
  );
}

const styles = StyleSheet.create({
  picker: {
    width: L.wProm(50),
    height: L.hProm(30),
    flexDirection: "row",
    alignItems: "center",
    marginBottom: L.h(3),
    marginTop: L.h(1),
  },
  pickerText: {
    color: "white",
    fontSize: L.h(18),
    fontFamily: Fonts.DREGULAR,
  },
  icon: { color: "white", fontSize: L.h(30) },
  headerStyles: {
    paddingBottom: L.h(20),
  },
  bottomLine: { height: 2, backgroundColor: "white", width: "90%" },
  label: {
    fontSize: L.h(12),
    color: "white",
    marginTop: L.h(11),
    fontFamily: Fonts.DLIGHT,
  },
});
