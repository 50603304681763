import React, { PureComponent } from 'react';
import { View, TouchableOpacity, Text, StyleSheet, Image } from 'react-native';
import Icon from 'react-native-vector-icons/Ionicons';
import Icon2 from 'react-native-vector-icons/FontAwesome';
import { Actions } from 'react-native-router-flux';
import Fonts from '../common/Fonts';
import Colors from '../common/Colors';
import L from '../common/Layout';
import moment from 'moment';
import Util from '../common/Util';

export default class EventTickets extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let { eventData, type, style, onPress, footerText } = this.props;
    return (
      <TouchableOpacity
        activeOpacity={type !== 2 ? 0.8 : 1}
        style={[styles.eventContainer, style]}
        onPress={onPress}
      >
        <View style={styles.eventDate}>
          <Text style={styles.eventYear}>
            {moment(
              Util.timestampToDate(
                eventData.evtDateTime && eventData.evtDateTime.seconds
              )
            ).format('YYYY')}
          </Text>
          <Text style={styles.eventDay}>
            {moment(
              Util.timestampToDate(
                eventData.evtDateTime && eventData.evtDateTime.seconds
              )
            ).format('DD')}
          </Text>
          <Text style={styles.eventMonth}>
            {moment(
              Util.timestampToDate(
                eventData.evtDateTime && eventData.evtDateTime.seconds
              )
            ).format('MMM')}
          </Text>
        </View>
        <View style={styles.eventDescription}>
          <View style={styles.eventImageContainer}>
            <Image
              style={styles.eventImage}
              resizeMode="cover"
              source={{ uri: eventData.evtImg }}
            />
          </View>
          <View style={styles.eventText}>
            <Text style={styles.eventName}>{eventData.evtTitle}</Text>
            <Text style={styles.eventHour}>{eventData.evtPlace}</Text>
            <Text
              style={[styles.eventTicket, { color: Colors.secondary_color }]}
            >
              {footerText}
            </Text>
          </View>
        </View>
        <View
          style={{ flex: 1, justifyContent: 'center', alignItems: 'flex-end' }}
        >
          {type !== 2 ? (
            <Icon2
              name="chevron-right"
              type="FontAwesome"
              style={styles.iconRight}
            />
          ) : null}
        </View>
      </TouchableOpacity>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.whiteFour,
  },
  eventContainer: {
    backgroundColor: Colors.eventHistoryBackground,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: L.h(15),
    marginVertical: L.h(4),
    paddingTop: L.h(16),
    paddingBottom: L.h(15),
    // elevation: 2,
    // shadowColor: "#000",
    // shadowOffset: {
    //   width: 0,
    //   height: 2
    // },
    // shadowOpacity: 0.25,
    // shadowRadius: 3.84,
    flex: 1,
    borderTopWidth: 1,
    borderColor: Colors.historyBorderCardEvent,
  },
  eventDate: {
    flexDirection: 'column',
    justifyContent: 'center',
    //paddingRight: L.w(17),
    borderRightWidth: L.h(1),
    borderColor: Colors.historyBorderDate,
    flex: 2.2,
  },
  iconRight: {
    fontSize: L.h(20),
    color: Colors.historyArrow,
    marginRight: L.w(8),
  },
  eventYear: {
    fontSize: L.h(14),
    color: Colors.historyDateColor,
    fontFamily: Fonts.DLIGHT,
    textAlign: 'center',
  },
  eventDay: {
    fontSize: L.h(30),
    color: Colors.historyDateColor,
    fontFamily: Fonts.DBOLD,
    textAlign: 'center',
  },
  eventMonth: {
    fontSize: L.h(16),
    color: Colors.historyDateColor,
    fontFamily: Fonts.DSEMIBOLD,
    textAlign: 'center',
  },
  eventHour: {
    fontSize: L.h(14),
    marginTop: L.h(0),
    color: Colors.historyHour,
    fontFamily: Fonts.DBOLD,
  },
  eventDescription: {
    paddingLeft: L.w(8),
    flexDirection: 'row',
    flex: 7,
    alignItems: 'center',
  },
  eventImageContainer: {
    borderRadius: L.wProm(64) / 2,
    overflow: 'hidden',
    width: L.wProm(64),
    height: L.hProm(64),
    borderColor: Colors.historyBorderImage,
    borderWidth: 2,
  },
  eventImage: {
    width: L.wProm(64),
    height: L.hProm(64),
  },
  eventText: {
    paddingLeft: L.w(12),
    flex: 1,
  },
  eventName: {
    fontSize: L.h(17),
    marginTop: L.h(5),
    color: Colors.historyEventName,
    fontFamily: Fonts.DSEMIBOLD,
  },

  eventTicket: {
    fontSize: L.h(14),
    marginTop: 0,
    color: Colors.blueberry,
    fontFamily: Fonts.DLIGHT,
  },
});
