//var Dimensions = require("Dimensions"); Fix WEB
import { 
  Dimensions, 
  Platform
} from "react-native";
 
import L from "../../common/Layout";
import Colors from "../../common/Colors";
import Fonts from "../../common/Fonts";
const screen = Dimensions.get("window");

import commonColor from "../../common/ThemeColors";

export default {
  tabs: {
    backgroundColor: "#FFF",
    height: Platform.OS === "android" ? 55 : 70,
    flexDirection: "row",
    paddingTop: Platform.OS === "android" ? 15 : 30,
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: "rgba(0,0,0,0.1)",
    shadowColor: "#000",
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 1.5,
    elevation: 3
  },
  linearGradient: {
    flex: 1,
    position: "absolute",
    top: 0,
    left: 0,
    height: screen.height,
    width: screen.width
  },
  tab: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: 10
  },
  iconBtn: {
    backgroundColor: "#D9E0E4",
    width: 35,
    height: 35,
    borderRadius: 18,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 7
  },
  activeIcon: {
    color: "#222", //commonColor.brandPrimary,
    // fontSize: commonColor.titleFontSize,
    // fontFamily: commonColor.titleFontfamily,
    // lineHeight: commonColor.lineHeightH1,
    fontFamily: Fonts.DBOLD,
  },
  inactiveIcon: {
    //color: "#D9E0E4"
    color: "#fff", //commonColor.brandPrimary,
    // fontSize: commonColor.titleFontSize,
    // fontFamily: commonColor.titleFontfamily,
    // lineHeight: commonColor.lineHeightH1,
    fontFamily: Fonts.DBOLD,
  },
  complete: {
    // backgroundColor: commonColor.brandPrimary,
    width: 35,
    height: 35,
    borderRadius: 18,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 7
  },
  incomplete: {
    backgroundColor: "#D9E0E4",
    width: 35,
    height: 35,
    borderRadius: 18,
    alignItems: "center",
    justifyContent: "center",
    marginTop: 7
  },

  container: {
    backgroundColor: "#F5F5F5"
    //margin:30,
  },
  content: {
    //padding:20,
    //borderWidth:2,
    //borderRadius:8,
    flex: 1,
    paddingLeft: 10,
    paddingRight: 10
  },
  headerCol1: {
    borderRightWidth: 2,
    borderRightColor: "#222", //'#FFBEBC',
    marginRight: 20
  },
  headerRow: {
    // backgroundColor: commonColor.brandPrimary,
    paddingLeft: 20
  },
  headerRowText: {
    fontSize: L.h(24),
    letterSpacing: -0.6,
    color: Colors.rouge,
    fontFamily: Fonts.DREGULAR
  },
  contentRow: {
    backgroundColor: "#fff", //commonColor.lightTextColor
    paddingLeft: 20
  },
  contentCol1: {
    borderRightWidth: 2,
    borderRightColor: "#222",
    marginRight: 20
  },
  barButton: {
    color: Colors.rouge, //commonColor.brandPrimary,
    fontSize: L.h(14),
    letterSpacing: -0.3
  },
  profileImage: {
    height: L.hProm(140),
    width: L.wProm(140),
    borderRadius: L.wProm(140) / 2
  },

  validationRow: {
    marginTop: 2,
    marginBottom: 5,
    paddingLeft: 10,
    flex: 1,
    alignItems: "center",
    backgroundColor: "#fff",
    // borderColor: commonColor.brandDanger,
    borderWidth: 2
  },
  validationText: {
    margin: 5,
    // fontSize: commonColor.noteFontSize,
    fontFamily: Fonts.DBOLD,
    // color: commonColor.brandDanger
  },
  validationIcon: {
    // fontSize: commonColor.noteFontSize,
    // color: commonColor.brandDanger
  },

  //DefuseAccountB
  topGrid: {
    marginTop: L.h(25),
    alignItems: "flex-start"
  },
  firstCol: {
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "flex-start"
  },
  closeIcon: {
    color: Colors.greyishBrown,
    fontSize: L.h(20),
    margin: 0
  },
  checkIcon: {
    fontSize: L.h(12),
    color: "white",
    fontFamily: Fonts.DBOLD,
    marginRight: L.w(6)
  },
  paragraph: {
    alignItems: "center",
    marginTop: L.h(29)
  },
  imgLogo: {
    width: L.h(200),
    marginRight: L.w(10)
  },
  textLogo: {
    fontSize: L.h(30),
    color: "white",
    fontFamily: Fonts.DBOLD,
    lineHeight: L.h(30)
  },
  paragraphText: {
    fontSize: L.h(16),
    color: "white",
    fontFamily: Fonts.DLIGHT
  },
  button: {
    width: screen.width - L.wProm(62),
    height: L.hProm(37),
    backgroundColor: "white",
    borderRadius: 20,
    alignItems: "center",
    justifyContent: "center",
    marginTop: L.h(15),
    elevation: 2
  },
  activate: {
    backgroundColor: "white",
    borderWidth: 1,
    borderColor: "white"
  },
  activateTxt: {
    fontSize: L.h(13),
    letterSpacing: -0.3,
    color: Colors.primary_color,
    fontFamily: Fonts.MEDIUM,
    textAlign: "center"
  },
  listTextPayment: {
    fontSize: L.h(13),
    color: "rgba (0, 0, 0, 0.55)"
  },
  paragraphTextInfo: {
    fontSize: L.h(13),
    color: "white",
    fontFamily: Fonts.DLIGHT,
  }
};
