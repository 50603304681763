import React, { Component } from "react";
import {
  Container,
  Content,
  Item,
  Form,
  Label,
  Input,
  Button,
  Picker,
} from "native-base";
import {
  ImageBackground,
  View,
  Dimensions,
  Text,
  Image,
  ScrollView,
  StyleSheet,
  Keyboard,
  TouchableOpacity,
} from "react-native";
import { Actions } from "react-native-router-flux";
import { actRegister } from "../../actions";
import { bindActionCreators } from "redux";
import Icon from "react-native-vector-icons/Ionicons";
import { connect } from "react-redux";
import L from "../../common/Layout";
import Colors from "../../common/Colors";
import Fonts from "../../common/Fonts";
import I18n from "i18next"
import logo from "../../common/assets/logo.png";
import VTHeader from "../../components/VTHeader";
import RegisterConstants from "./RegisterConstants";
const BG_IMG = require("../../common/assets/background_login.jpg");

import { reduxForm } from "redux-form";

class RegisterA extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}
  // En Web estamos salteando el RegisterB
  render() {
    return (
      <ImageBackground source={BG_IMG} style={{ flex: 1 }} resizeMode="cover">
        <View style={localStyle.container}> 
          <View style={localStyle.mainContent}>
            <ScrollView>
              <Text style={localStyle.textInfo}>
                {I18n.t("register.signInSteps.textBePart")}
              </Text>
              <Image
                source={logo}
                style={localStyle.logo}
                resizeMode="contain"
              />
              <Text
                style={[
                  localStyle.textInfo,
                  {
                    marginTop: L.h(40),
                  },
                ]}
              >
                {I18n.t("register.signInSteps.textIntro")}
              </Text>
              <TouchableOpacity
                style={localStyle.buttonAction}
                onPress={Actions.registerC}
              >
                <Icon
                  name="md-arrow-round-forward"
                  style={localStyle.buttonActionIcon}
                />
                <Text style={localStyle.buttonActionText}>
                  {I18n.t("global.after")}
                </Text>
              </TouchableOpacity>
            </ScrollView>
          </View>
        </View>
      </ImageBackground>
    );
  }
}
const localStyle = StyleSheet.create({
  closeButton: {
    padding: 10,
    position: "absolute",
    top: L.h(25),
    left: L.w(15),
  },
  closeIcon: {
    fontSize: L.h(30),
    color: "white",
  },
  container: {
    flex: 1,
    //backgroundColor: Colors.blueberry_85
  },
  mainContent: {
    padding: L.h(45),
    paddingHorizontal: L.w(44),
  },
  logo: {
    width: L.w(141),
    marginTop: L.h(40),
    alignSelf: "center",
  },
  titleText: {
    fontSize: L.h(17),
    padding: 10,
    top: L.h(30),
    textAlign: "center",
    color: "white",
  },
  textInfo: {
    fontSize: L.h(25),
    fontFamily: Fonts.DBOLD,
    color: Colors.textRegisterA,
  },
  buttonAction: {
    padding: L.h(10),
    marginTop: L.h(15),
    alignItems: "center",
    justifyContent: "center",
  },
  buttonActionIcon: {
    fontSize: L.h(95),
    color: Colors.textIconRegisterA,
    marginBottom: L.h(-20),
  },
  buttonActionText: {
    fontSize: L.h(21),
    fontFamily: Fonts.DBOLD,
    color: Colors.textButtonRegisterA,
  },
});

function mapStateToProps(store) {
  //console.log("storereduxofrm", store.form);
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actRegister,
    },
    dispatch
  );
}
export default reduxForm({
  form: "register",
  destroyOnUnmount: false,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RegisterA)
);
