import React, { Component } from 'react';

const INLINE_HTML = `<div><div style={{"display":"none"}} class="amount-container">
    <label class="amount-text">Monto a pagar: </label>
    <label id="lblAmount" class="amount"></label>
    </div>
    <br>
    <div class="card-wrapper" style={{"margin-top":"20px"}}></div>
    <div class="form-container active">
    
    <form action="" method="post" id="formulario" class="form-class">
    
      <div class="row-single-container" style={{"margin-top":"20px"}}>
        <div class="form__group field">
          <input id="card" placeholder="N&uacute;mero de tarjeta" class="form__field floating-input" type="tel"
            name="number" onblur="validateCardNumber();"/>
          <label for="name" class="form__label">N&uacute;mero de tarjeta</label>
        </div>
      </div>
      <input type="hidden" id="card_number" class="floating-input" type="number" data-pagos360="card_number"
        data-title="Credit Card" placeholder="Numero de tarjeta " />
      <div class="row-container" style={{"margin-top":"20px"}}>
        <div class="form__group field">
          <input id="expiry" class="form__field floating-input" placeholder="MM/AA" type="tel" name="expiry"
            onblur="validateCardExpirationMonthAndYear();" maxlength="7"/>
          <label for="expiry" class="form__label">MM/AA</label>
        </div>
        <div class="form__group field">
          <input class="form__field floating-input" type="tel" data-pagos360="security_code" name="cvc"
            placeholder="CVC" maxlength="4" onblur="validateSecurityCode();"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" />
          <label for="cvc" class="form__label">CVC</label>
        </div>
        <input type="hidden" id="card_expiration_month" class="floating-input floating-place" type="tel"
          data-pagos360="card_expiration_month" placeholder="01" maxlength="2"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" />
        <input type="hidden" id="card_expiration_year" class="floating-input" type="number"
          data-pagos360="card_expiration_year" placeholder="24" maxlength="2"
          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" />
      </div>
      <div class="row-single-container">
        <div class="form__text field">
          <span>Ingres&aacute; el n&uacute;mero de documento del titular de la
            tarjeta</span>
        </div>
        <div class="form__group field" style={{"margin-top":"10px"}}>
          <input class="form__field floating-input" type="tel" data-pagos360="card_holder_doc_number" placeholder=" "
            maxlength="9" onblur="validateCardHolderDocNumber();"
            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" /> 
          <label for="card_holder_doc_number" class="form__label" id="card-holder-doc-number-label">N&uacute;mero de
            documento</label>
        </div>
      </div>
      <div class="row-single-container">
        <div class="form__text field">
          <span>Ingres&aacute; el nombre del titular tal cual figura en la
            tarjeta</span>
        </div>
        <div class="form__group field" style={{"margin-top":"10px"}}>
          <input class="form__field floating-input" type="text" data-pagos360="card_holder_name" name="name"
            placeholder="Nombre" onblur="validateCardHolderName();" />
          <label for="name" class="form__label">Nombre</label>
        </div>
      </div>
      <div class="row-single-container">
        <div class="form__text field">
          <span>E-mail donde recibir&aacute;s la confirmaci&oacute;n de compra</span>
        </div>
        <div class="form__group field">
          <input type="text" class="form__field floating-input" type="text" name="e-mail" data-pagos360="email"
            placeholder="E-mail" onblur="validateEmail();" />
          <label for="e-mail" class="form__label">E-mail</label>
        </div>
      </div>
      <input type="hidden" data-pagos360="installments" value="1" />
    </form> 
    </div>
    
    <div class="button-container">
    <input class="button-confirm" type="button" value="Confirmar pago" onclick="validate();" />
    </div>
    <div id="loading" class="loading-container">
    <div class="spinner"></div>
    </div> 
    </div>`;

export default class Pagos360Html extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    //console.log('dataPagos360', this.props.dataPagos360);

    //TODOWEB WEB99, ver si hay una forma mejor que el setTimeout
    setTimeout(() => {
      window['onMessageRN'](this.props.dataPagos360);
    }, 2500);
  }

  render() {
    return <div dangerouslySetInnerHTML={{ __html: INLINE_HTML }} />;
  }

  render1() {
    return (
      <div>
        <div style={{ display: 'none' }} className="amount-container">
          <label className="amount-text">
            Monto a pagar: (No quitar falla el set){' '}
          </label>
          <label id="lblAmount" className="amount" />
        </div>

        <div className="card-wrapper" style={{ 'margin-top': '20px' }} />
        <div className="form-container active">
          <form
            action=""
            method="post"
            id="formulario"
            className="form-className"
          >
            <div
              className="row-single-container"
              style={{ 'margin-top': '20px' }}
            >
              <div className="form__group field">
                <input
                  id="card"
                  placeholder="N&uacute;mero de tarjeta"
                  className="form__field floating-input"
                  type="tel"
                  name="number"
                  onblur="validateCardNumber();"
                />
                <label for="name" className="form__label">
                  N&uacute;mero de tarjeta
                </label>
              </div>
            </div>
            <input
              type="hidden"
              id="card_number"
              className="floating-input"
              type="number"
              data-pagos360="card_number"
              data-title="Credit Card"
              placeholder="Numero de tarjeta "
            />
            <div className="row-container" style={{ 'margin-top': '20px' }}>
              <div className="form__group field">
                <input
                  id="expiry"
                  className="form__field floating-input"
                  placeholder="MM/AA"
                  type="tel"
                  name="expiry"
                  onblur="validateCardExpirationMonthAndYear();"
                  maxlength="7"
                />
                <label for="expiry" className="form__label">
                  MM/AA
                </label>
              </div>
              <div className="form__group field">
                <input
                  className="form__field floating-input"
                  type="number"
                  data-pagos360="security_code"
                  name="cvc"
                  placeholder="CVC"
                  maxlength="4"
                  onblur="validateSecurityCode();"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                />
                <label for="cvc" className="form__label">
                  CVC
                </label>
              </div>
              <input
                type="hidden"
                id="card_expiration_month"
                className="floating-input floating-place"
                type="number"
                data-pagos360="card_expiration_month"
                placeholder="01"
                maxlength="2"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              />
              <input
                type="hidden"
                id="card_expiration_year"
                className="floating-input"
                type="number"
                data-pagos360="card_expiration_year"
                placeholder="24"
                maxlength="2"
                oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
              />
            </div>
            <div className="row-single-container">
              <div className="form__text field">
                <span>
                  Ingres&aacute; el n&uacute;mero de documento del titular de la
                  tarjeta
                </span>
              </div>
              <div
                className="form__group field"
                style={{ 'margin-top': '10px' }}
              >
                <input
                  className="form__field floating-input"
                  type="number"
                  data-pagos360="card_holder_doc_number"
                  placeholder=" "
                  maxlength="9"
                  onblur="validateCardHolderDocNumber();"
                  oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                />
                <label
                  for="card_holder_doc_number"
                  className="form__label"
                  id="card-holder-doc-number-label"
                >
                  N&uacute;mero de documento
                </label>
              </div>
            </div>
            <div className="row-single-container">
              <div className="form__text field">
                <span>
                  Ingres&aacute; el nombre del titular tal cual figura en la
                  tarjeta
                </span>
              </div>
              <div
                className="form__group field"
                style={{ 'margin-top': '10px' }}
              >
                <input
                  className="form__field floating-input"
                  type="text"
                  data-pagos360="card_holder_name"
                  name="name"
                  placeholder="Nombre"
                  onblur="validateCardHolderName();"
                />
                <label for="name" className="form__label">
                  Nombre
                </label>
              </div>
            </div>
            <div className="row-single-container">
              <div className="form__text field">
                <span>
                  E-mail donde recibir&aacute;s la confirmaci&oacute;n de compra
                </span>
              </div>
              <div className="form__group field">
                <input
                  type="text"
                  className="form__field floating-input"
                  type="text"
                  name="e-mail"
                  data-pagos360="email"
                  placeholder="E-mail"
                  onblur="validateEmail();"
                />
                <label for="e-mail" className="form__label">
                  E-mail
                </label>
              </div>
            </div>
            <input type="hidden" data-pagos360="installments" value="1" />
          </form>
        </div>

        <div className="button-container">
          <input
            className="button-confirm"
            type="button"
            value="Confirmar pago"
            onclick="validate();"
          />
        </div>
        <div id="loading" className="loading-container">
          <div className="spinner" />
        </div>
      </div>
    );
  }
}
