import React from 'react';
import { View, ActivityIndicator, Text } from 'react-native';
import Colors from '../common/Colors'; // Ajusta la ruta según la ubicación real de tus colores

const Loader = ({ text }) => {
  console.log(' >>>>>>>>>>aaaaaaa');
  return (
    <View style={styles.loaderContainer}>
      <ActivityIndicator size="large" color={Colors.loginErrorColor} />
      {text && <Text style={styles.text}>{text}</Text>}
    </View>
  );
};

const styles = {
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    backgroundColor: Colors.black_82,
  },
  text: {
    color: 'white',
    marginTop: 10,
    fontSize: 16,
  },
};

export default Loader;
