import React, { PureComponent } from "react";
import {
  View,
  Image,
  Platform,
  TouchableOpacity,
  Dimensions, 
  StyleSheet,
} from "react-native";
//WEB99  ActionSheetIOS
import {Icon, Text } from "native-base"; //WEB99 Header, Left, Right, Body
import Icon2 from "react-native-vector-icons/FontAwesome";
import StatusBarView from "../components/StatusBarView";
import Menu, { MenuOptions, MenuOption, MenuTrigger } from "react-native-menu";
import AppMem from "../common/AppMem";
import Util from "../common/Util";
import LinearGradient from "react-native-linear-gradient";
import L from "../common/Layout";
import Colors from "../common/Colors";
import Fonts from "../common/Fonts";
import I18n from "i18next"
const screen = Dimensions.get("window");

//WEB99
const Header = {};
const Left = {};
const Right = {};

const ActionSheetIOS = {};
 

export default class VTHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.onPressValue = this.onPressValue.bind(this);
    this.openDrawer = this.openDrawer.bind(this);
    this.onPressItem = this.onPressItem.bind(this);
    this.openActionSheet = this.openActionSheet.bind(this);
  }

  openDrawer() {
    console.log("opendraewen");
    AppMem.openDrawer();
  }

  closeDrawer() {
    AppMem.closeDrawer();
  }

  renderLeftButtons() {
    let leftButtonsList = [];
    if (this.props.leftButtons) {
      this.props.leftButtons.forEach((item, i) => {
        leftButtonsList.push(this.Buttons(item, i));
      });
    }
    if (leftButtonsList.length > 0) {
      return leftButtonsList;
    } else {
      return (
        <Image
          source={require("../common/assets/logoHeaderLeft.png")}
          resizeMode="contain"
          style={{
            //width: L.wProm(26),
            height: L.hProm(29),
            marginLeft: Util.isIos() ? L.w(12) : L.w(5),
          }}
        />
      );
    }
  }

  renderButtonContent(item, itemIcon, site) {
    let isMore = site === "menu";
    return (
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          //marginLeft: L.h(-10),
        }}
      >
        <Icon2
          name={itemIcon.name}
          style={[
            itemIcon.style,
            isMore
              ? { top: L.h(1), fontSize: L.h(22) }
              : { top: L.h(Util.isIos() ? 2 : 1) },
          ]}
          type={itemIcon.type}
        />
        {itemIcon.text ? (
          <Text
            style={[
              itemIcon.textStyle,
              isMore
                ? { fontSize: L.h(16), marginHorizontal: L.w(16) }
                : { marginLeft: L.w(3) },
            ]}
            numberOfLines={1}
          >
            {itemIcon.text}
          </Text>
        ) : null}
        {this.props.no_reads && this.props.home && item && item.noti ? (
          <View style={styles.noti}>
            <Text
              style={{
                color: "white",
                fontSize: L.h(10),
                fontFamily: Fonts.DBOLD,
                textAlign: "center",
              }}
            >
              {this.props.no_reads}
            </Text>
          </View>
        ) : null}
      </View>
    );
  }

  onPressItem(onPress) {
    onPress();
  }

  Buttons(item, index) {
    let itemIcon = item.icon;
    return (
      <TouchableOpacity
        activeOpacity={0.9}
        key={index}
        transparent
        onPress={item.onPress}
        style={{
          paddingHorizontal: L.w(8),
          height: L.hProm(40),
          alignItems: "center",
          justifyContent: "center",
          // marginHorizontal: Util.isIos() ? 0 : L.w(8)
        }}
      >
        {item.image ? (
          <Image
            source={
              item.image == 1
                ? require("../common/assets/filterDown.png")
                : require("../common/assets/filterUp.png")
            }
            resizeMode="contain"
            style={{
              width: L.wProm(40),
              height: L.hProm(40),
            }}
          />
        ) : (
          this.renderButtonContent(item, itemIcon)
        )}
      </TouchableOpacity>
    );
  }

  onPressValue(value) {
    this.props.rightButtons[value].onPress();
  }

  openActionSheet() {
    let options = [];
    options.push("Cancel");
    this.props.rightButtons.forEach((item, i) => {
      let index = i + 1;
      let text = item.icon.text
        ? item.icon.text
        : `${I18n.t("vtHeader.addOption")} ${index}`;
      options.push(text);
    });
    ActionSheetIOS.showActionSheetWithOptions(
      {
        options,
        // destructiveButtonIndex: 1,
        cancelButtonIndex: 0,
      },
      (buttonIndex) => {
        if (buttonIndex === 0) {
          null;
        } else {
          this.props.rightButtons[buttonIndex - 1].onPress();
        }
      }
    );
  }

  renderMenu() {
    if (Util.isIos()) {
      return (
        <TouchableOpacity
          style={{ padding: L.wProm(8) }}
          onPress={this.openActionSheet}
        >
          <Icon
            name="more"
            style={{
              fontSize: L.h(28),
              color: this.props.moreButtonColor,
              top: L.h(3),
            }}
          />
        </TouchableOpacity>
      );
    }
    return (
      <Menu onSelect={(value) => this.onPressValue(value)}>
        <MenuTrigger>
          <View
            style={{
              paddingLeft: L.w(16),
              paddingRight: L.w(Util.isIos() ? 8 : 16),
              top: L.h(Util.isIos() ? 2 : 1),
            }}
          >
            <Icon
              name="more"
              style={{ fontSize: L.h(28), color: this.props.moreButtonColor }}
            />
          </View>
        </MenuTrigger>
        <MenuOptions>{this.renderMenuOptions()}</MenuOptions>
      </Menu>
    );
  }

  renderMenuOptions() {
    let buttonOptions = [];
    this.props.rightButtons.forEach((item, i) => {
      buttonOptions.push(
        <MenuOption value={i} key={i}>
          {this.renderButtonContent(item, item.icon, "menu")}
        </MenuOption>
      );
    });
    return buttonOptions;
  }

  renderRightButtons() {
    let rightButtonsList = [];
    if (this.props.rightButtons) {
      if (this.props.rightButtons.length > 2 || this.props.moreButton) {
        return this.renderMenu();
      }
      this.props.rightButtons.forEach((item, i) => {
        rightButtonsList.push(this.Buttons(item, i));
      });
    }
    return rightButtonsList;
  }

  renderBody() {
    let { title, titleStyle } = this.props;
    return (
      <View style={{ alignItems: "center" }}>
        {title ? (
          <Text style={[titleStyle]} numberOfLines={1}>
            {title}
          </Text>
        ) : (
          <Image
            resizeMode="contain"
            style={{ height: L.hProm(40), width: L.wProm(200) }}
            source={require("../common/assets/logo.png")}
          />
        )}
      </View>
    );
  }

  render() {


    if (Platform.OS == 'web')
        return null;

    // let statusBarHeight =
    //   Platform.OS === "ios" ? 20 : Platform.Version < 21 ? 0 : 25;

    // let setHeaderStyle = Util.isIos()
    //   ? screen.height > 800
    //     ? {
    //         backgroundColor: this.props.backgroundColor
    //           ? this.props.backgroundColor
    //           : "transparent",
    //         paddingLeft: Util.isIos() ? 0 : L.w(8),
    //         paddingRight: Util.isIos() ? 0 : L.w(8),
    //         borderBottomWidth:
    //           this.props.backgroundColor === "transparent" ? 0 : 1,
    //       }
    //     : {
    //         backgroundColor: this.props.backgroundColor
    //           ? this.props.backgroundColor
    //           : "transparent",
    //         height: L.hProm(50) + statusBarHeight,
    //         paddingTop: statusBarHeight,
    //         paddingLeft: L.w(8),
    //         paddingRight: L.w(8),
    //         borderBottomWidth:
    //           this.props.backgroundColor === "transparent" ? 0 : 1,
    //       }
    //   : {
    //       backgroundColor: this.props.backgroundColor
    //         ? this.props.backgroundColor
    //         : "transparent",
    //       height: L.hProm(56) + statusBarHeight,
    //       paddingTop: statusBarHeight,
    //       paddingLeft: L.w(8),
    //       paddingRight: L.w(8),
    //     };
    return (
      <LinearGradient
        colors={
          this.props.secondary
            ? [Colors.white, Colors.white]
            : [
                Colors.gradientColorTwo,
                Colors.gradientColorThree,
                Colors.gradientColorFour,
                Colors.gradientColorFive,
                Colors.gradientColorSix,
                Colors.gradientColorSeven,
              ]
        }
        start={{ x: 0.0001, y: 0 }}
        end={{ x: 1.05, y: 0 }}
        style={{}}
      >
        <StatusBarView
          style={{ backgroundColor: this.props.backgroundColor }}
        />
        <Header
          androidStatusBarColor={this.props.backgroundColor}
          translucent={true}
          barStyle="light-content"
          iosBarStyle="light-content"
          //span
          style={{
            backgroundColor: this.props.backgroundColor,
            borderBottomWidth: 0,
            paddingTop: 0,
            marginTop: 0,
            height: 56,
            borderBottomWidth: 1,
            borderBottomColor: Colors.borderBottomHeader,
          }}
          noShadow={this.props.noShadow}
        >
          <Left
            style={{
              flex: Util.isIos() || this.props.home ? 0.6 : 0.3,
            }}
          >
            {this.renderLeftButtons()}
          </Left>
          <View
            style={{
              flex: 1,
              alignItems:
                Util.isIos() || this.props.home ? "center" : "flex-start",
            }}
          >
            {this.renderBody()}
          </View>
          <Right
            style={[
              this.props.rightButtonStyle,
              {
                flex: Util.isIos() || this.props.home ? 0.6 : 0.6,
              },
            ]}
          >
            {this.renderRightButtons()}
          </Right>
        </Header>
      </LinearGradient>
    );
  }
}

const styles = StyleSheet.create({
  noti: {
    width: L.wProm(13),
    height: L.hProm(13),
    borderRadius: L.hProm(13) / 2,
    backgroundColor: "red",
    position: "absolute",
    right: 0,
    top: 0,
    borderWidth: 1,
    borderColor: "white",
    alignItems: "center",
    justifyContent: "center",
  },
});

VTHeader.defaultProps = {
  moreButtonColor: "black",
  titleStyle: { fontSize: L.h(20), fontFamily: Fonts.DBOLD },
};
