import React, { Component } from 'react';
import {
  Dimensions,
  StyleSheet,
} from 'react-native';
//import { Calendar } from 'react-native-calendars';

import Modal from 'react-native-modalbox';
import L from '../common/Layout';

const screen = Dimensions.get( 'window' );
class VTCalendar extends Component {
  constructor( props ) {
    super( props );
    this.state = { selected: null };
    this.onDayPress = this.onDayPress.bind( this );
  }

  onDayPress( day ) {
    console.log( 'daylog', day );
    this.setState( {
      selected: day.dateString
    } );
  }

  open() {
    this.refs.modal && this.refs.modal.open();
  }

  close() {
    this.refs.modal && this.refs.modal.close();
  }

  render() {
    let modalProps = {
      style: {
        width: screen.width - L.wProm( 32 ),
        height: L.hProm( 360 ),
        backgroundColor: 'transparent'
      },
      backdropOpacity: 0.8
    };

if (true) return null;
/*
<Calendar
			onDayPress={this.onDayPress}
			style={localStyle.calendar}
			hideExtraDays
			markedDates={{
	  [ this.state.selected ]: {
	    selected: true,
	    disableTouchEvent: true,
	    selectedDotColor: 'orange'
	  }
			}}
		/>
*/

    return (
	<Modal ref="modal" {...modalProps}>
		 <Calendar
			onDayPress={this.onDayPress}
			style={localStyle.calendar}
			hideExtraDays
			markedDates={{
	  [ this.state.selected ]: {
	    selected: true,
	    disableTouchEvent: true,
	    selectedDotColor: 'orange'
	  }
			}}
		/>
	</Modal>
    );
  }
}

const localStyle = StyleSheet.create( {
  container: {
    flex: 1,
    backgroundColor: 'gray'
  },
  calendar: {
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: '#eee',
    height: L.hProm( 350 ),
    width: screen.width - L.wProm( 32 )
  }
} );

export default VTCalendar;