import React, { Component } from 'react';
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Dimensions,
  ScrollView,
  Platform,
} from 'react-native';

//Redux(+)
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
//Redux(-)
import moment from 'moment';
import Config from 'react-native-config';
import QRCode from 'react-native-qrcode-svg';
import _ from 'lodash';
import { Actions } from 'react-native-router-flux';
import Util from '../../common/Util';
import Header from '../../components/VTHeader';
import L from '../../common/Layout';
import IconFA from 'react-native-vector-icons/FontAwesome';
import { HStack as Row, VStack as Col } from 'native-base'; // Compatibilidad con Mobile
import toast from 'react-hot-toast';
import Fonts from '../../common/Fonts';
import Colors from '../../common/Colors';
import Carousel, { Pagination } from 'react-native-snap-carousel';
import TicketInfo from '../../components/TicketInfo';
import { TOP_PADDING_IOS_FONT } from '../../common/Constants';
const screen = Dimensions.get('window');
import I18n from 'i18next';
class ShopDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      slider1ActiveSlide: 0,
    };
    //this.onPressTransfer = this.onPressTransfer.bind(this);
    this._renderItem = this._renderItem.bind(this);
    this.renderList = this.renderList.bind(this);
  }

  renderLabelButton(
    ticketState,
    buyerUserIsOwner,
    canTransferTicket,
    activeEvent,
    overdueTicket,
    action
  ) {
    let component = null;
    if (overdueTicket) {
      component = null;
    } else if (ticketState == 'already_used') {
      component = (
        <Text style={{ color: 'orange', fontSize: L.h(16) }}>
          {I18n.t('shopDetail.redime')}
        </Text>
      );
    } else if (!buyerUserIsOwner) {
      component = (
        <Text style={{ color: 'orange', fontSize: L.h(16) }}>
          {I18n.t('shopDetail.transfered')}
        </Text>
      );
    } else if (activeEvent) {
      if (canTransferTicket && Config.HAS_TICKET_TRANSFER === 'active') {
        component = (
          <TouchableOpacity style={styles.buttonContainer} onPress={action}>
            <Text style={styles.buttonText}>{`TRANSFERIR`}</Text>
          </TouchableOpacity>
        );
      }
      // ELSE No tiene que mostrar nada, por que el evento esta activo, pero no puede transferir tickets
    } else {
      component = (
        <Text style={{ color: 'red', fontSize: L.h(16) }}>
          {I18n.t('shopDetail.overdueEvent')}
        </Text>
      );
    }
    return component;
  }

  renderQRLabel(
    qrCode,
    ticketState,
    ticketTransfered,
    activeEvent,
    overdueTicket,
    operationId
  ) {
    let label = null;
    let labelText = '';
    let isQR = false;

    if (overdueTicket) {
      labelText = `${I18n.t('shopDetail.orverdueTicket')}`;
    } else if (ticketState == 'already_used') {
      labelText = `${I18n.t('shopDetail.ticket')}\n${I18n.t(
        'shopDetail.redime'
      )}`;
    } else if (ticketTransfered) {
      labelText = `${I18n.t('shopDetail.ticket')}\n${I18n.t(
        'shopDetail.transfered'
      )}`;
    } else if (activeEvent) {
      isQR = true;
    } else {
      labelText = I18n.t('shopDetail.overdueEvent');
    }
    label = (
      <View
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: 'white',
          height: '100%',
          width: '100%',
          borderRadius: 15,
          paddingTop:20,
          paddingBottom: 20
        }}
      >
        <Text
          style={{
            color: activeEvent ? Colors.darkIndigo : 'red',
            fontSize: L.h(20),
            textAlign: 'center',
            fontFamily: Fonts.MEDIUM,
          }}
        >
          {labelText}
        </Text>
      </View>
    );
    return isQR ? (
      <View
        style={{
          backgroundColor: 'white',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          borderRadius: 15,
          paddingTop:20,
          paddingBottom: 20
        }}
      >
        <QRCode
          value={qrCode}
          size={L.hProm(140)}
          bgColor="black"
          fgColor="white"
          overflow="hidden"
        />
        <Text
          style={[styles.subCodeText, { alignSelf: 'center', marginTop: 8 }]}
        >
          {I18n.t('shopDetail.operationCode')}
        </Text>
        <Text
          style={[
            styles.subCodeText,
            {
              textAlign: 'center',
              color: Colors.subcodeTicketFinal,
              alignSelf: 'center',
            },
          ]}
        >
          {operationId}
        </Text>
        <Text style={styles.footerEndText}>
          {I18n.t('shopDetail.presentDNI')}
        </Text>
      </View>
    ) : (
      label
    );
  }

  renderList(item) {
    let { eventData } = this.props;
    let today = new Date();
    let activeEvent =
      moment(today).isAfter(eventData.evtInitDate) &&
      moment(today).isBefore(eventData.evtEndDate);
    let overdueTicket = item.tktOutdateDate
      ? moment(today).isAfter(moment.unix(item.tktOutdateDate.seconds).toDate())
      : false;
    const activeUserIsBuyer = this.props.user.proDNI == item.tktBuyerDU;
    const buyerUserIsOwner = item.tktBuyerDU == item.tktOwnerDU;
    let ticketState = item.tktState;
    let canTransferTicket =
      activeUserIsBuyer && buyerUserIsOwner && ticketState;
    let ticketTransfered =
      this.props.user.proDNI != item.tktOwnerDU && ticketState;
    let formatedDate = moment(eventData.evtDate).format('dddd DD [de] MMMM');
    let formatedHour = moment(eventData.evtDate).format('HH:mm');
    return (
      <div class="movie-list">
        <div class="movie-thumb c-thumb">
          <a
            //  href="movie-details.html"
            class="w-100 bg_img h-100"
            data-background="./assets/images/movie/movie12.jpg"
            //  onClick={{ cursor: 'pointer' }}
          >
            {this.renderQRLabel(
              item.id,
              ticketState,
              ticketTransfered,
              activeEvent,
              overdueTicket,
              item.tktBuyOperationId
            )}
          </a>
        </div>
        <div class="movie-content bg-one">
          <h5 class="title">
            <a onClick={{ cursor: 'pointer' }}>
              {' '}
              {eventData.evtTitle.toUpperCase()}
            </a>
          </h5>
          <p class="duration">
            {item.tktDisplayName
              ? item.tktDisplayName.toUpperCase()
              : item.tktType.toUpperCase()}
          </p>
          <div class="movie-tags">
            <a>{eventData?.evtPlace}</a>
            <a>{eventData?.evtPlaceAddress}</a>
          </div>
          <div class="release">
            <span>Fecha: </span>
            <a>
              {formatedDate} - {formatedHour}hs
            </a>
          </div>
          <div class="book-area">
            <div class="book-ticket">
              <div class="react-item mr-auto">
                <a style={{ marginTop: 6 }}>
                  <div class="thumb">
                    <img src="./assets/images/icons/book.png" alt="icons" />
                  </div>
                  <span>
                    {this.props.user.proDNI == item.tktOwnerDU
                      ? this.props.user.proName +
                        ' ' +
                        this.props.user.proLastName
                      : item.tktOwnerName}{' '}
                    - DNI {item.tktOwnerDU}
                  </span>
                </a>
              </div>
              <div class="react-item">
                {this.renderLabelButton(
                  ticketState,
                  buyerUserIsOwner,
                  canTransferTicket,
                  activeEvent,
                  overdueTicket,
                  () => {
                    toast(
                      'Para transferir tu entrada descarga nuestra app desde la tienda de tu dispositivo!',
                      { icon: '📲' }
                    );
                  }
                )}
                {/* <a  class="popup-video" style={{}}>
                  <div class="thumb">
                    <img
                      src="./assets/images/icons/play-button.png"
                      alt="icons"
                    />
                  </div>
                  <span>Transferir ticket</span>
                </a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  _renderItem({ item, index }) {
    let { eventData } = this.props;
    let today = new Date();
    let activeEvent =
      moment(today).isAfter(eventData.evtInitDate) &&
      moment(today).isBefore(eventData.evtEndDate);
    let overdueTicket = item.tktOutdateDate
      ? moment(today).isAfter(moment.unix(item.tktOutdateDate.seconds).toDate())
      : false;
    const activeUserIsBuyer = this.props.user.proDNI == item.tktBuyerDU;
    const buyerUserIsOwner = item.tktBuyerDU == item.tktOwnerDU;
    let ticketState = item.tktState;
    let canTransferTicket =
      activeUserIsBuyer && buyerUserIsOwner && ticketState;
    let ticketTransfered =
      this.props.user.proDNI != item.tktOwnerDU && ticketState;

    return (
      <View style={styles.ticketContainer}>
        <View style={{ flex: 0.3 }}>
          <Text style={styles.ticketTitle}>
            {eventData.evtTitle.toUpperCase()}
          </Text>
          <View
            style={[
              styles.ticketSubTitle,
              {
                backgroundColor: item.tktColor
                  ? item.tktColor
                  : Colors.primary_color,
              },
            ]}
          >
            {item.tktIcon ? (
              <View style={styles.iconContainer}>
                <IconFA
                  name={item && item.tktIcon ? item.tktIcon : 'ticket'}
                  type={
                    item && item.tktIconFamily
                      ? item.tktIconFamily
                      : 'FontAwesome'
                  }
                  style={{
                    color: 'white',
                    fontSize: L.h(20),
                  }}
                />
              </View>
            ) : null}
            <Text style={styles.subTitleText}>
              {item.tktDisplayName
                ? item.tktDisplayName.toUpperCase()
                : item.tktType.toUpperCase()}
            </Text>
          </View>
          <TicketInfo
            evtDateTime={eventData.evtDate}
            evtCoordinates={eventData.evtCoordinates}
            evtPlace={eventData.evtPlace}
            textColor="black"
          />
          <View
            style={{
              borderBottomWidth: 1,
              borderColor: Colors.separatorTicketFinal,
            }}
          />
        </View>
        <View
          style={[
            {
              flex: 0.5,
            },
          ]}
        >
          <View style={[styles.QRContainer]}>
            {this.renderQRLabel(
              item.id,
              ticketState,
              ticketTransfered,
              activeEvent,
              overdueTicket,
              item.tktBuyOperationId
            )}
            <View
              style={{
                flex: 1,
                alignItems: 'center',
                marginTop: L.h(8),
                position: 'absolute',
                bottom: L.h(8),
                left: 0,
                width: '100%',
              }}
            >
              <Text style={[styles.subCodeText, { alignSelf: 'center' }]}>
                {I18n.t('shopDetail.operationCode')}
              </Text>
              <Text
                style={[
                  styles.subCodeText,
                  {
                    marginHorizontal: L.w(16),
                    textAlign: 'center',
                    color: Colors.subcodeTicketFinal,
                    alignSelf: 'center',
                  },
                ]}
              >
                {item.tktBuyOperationId}
              </Text>
            </View>
          </View>
        </View>
        <View
          style={{ borderBottomWidth: 1, borderColor: Colors.veryLightPink }}
        />
        <View style={[styles.footerContainer, { flex: 0.22 }]}>
          <Text style={styles.validateTicket}>
            {I18n.t('shopDetail.ticketValid')}
          </Text>
          <Row>
            <Col>
              <Text style={styles.ticketName} numberOfLines={1}>
                {this.props.user.proDNI == item.tktOwnerDU
                  ? this.props.user.proName + ' ' + this.props.user.proLastName
                  : item.tktOwnerName}
              </Text>
              <Text style={styles.dniName}>DNI {item.tktOwnerDU}</Text>
            </Col>
            {this.renderLabelButton(
              ticketState,
              buyerUserIsOwner,
              canTransferTicket,
              activeEvent,
              overdueTicket,
              () => {
                Actions.push('transferTicket', {
                  ticket: item,
                  event: eventData,
                });
              }
            )}
          </Row>
          <Text style={styles.footerEndText}>
            {I18n.t('shopDetail.presentDNI')}
          </Text>
        </View>
      </View>
    );
  }

  render() {
    let { eventId, eventData } = this.props;
    let formatedDate = moment(eventData.evtDate).format('DD [de] MMMM');

    return (
      <View style={styles.container}>
        <section class="page-title bg-one">
          <div class="container">
            <div class="page-title-area">
              <div
                class="item md-order-1"
                onClick={() => Actions.pop()}
                style={{ cursor: 'pointer' }}
              >
                <a class="custom-button back-button">
                  <i class="flaticon-double-right-arrows-angles" />
                  Volver
                </a>
              </div>
              <div class="item date-item">
                <h4>
                  {/* {moment(eventData.evtDate).format('DD MMMM YYYY')} */}
                  {eventData.evtTitle}
                </h4>
              </div>
              <div class="item" />
            </div>
          </div>
        </section>
        {/* <!-- ==========Movie-Section========== --> */}
        <section
          class="movie-section padding-bottom"
          style={{ marginTop: '3%' }}
        >
          <div class="container">
            <div class="row flex-wrap-reverse justify-content-center">
              <div class="col-sm-10 col-md-8 col-lg-3">
                <div class="widget-1 widget-banner">
                  <div class="widget-1-body">
                    <a>
                      <img src={eventData.evtImg} alt="banner" />
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-9 mb-50 mb-lg-0">
                <div class="filter-tab tab">
                  <div class="tab-area">
                    <div class="tab-item">
                      <div class="row mb-10 justify-content-center">
                        <div class="col-sm-6 col-lg-4">
                          <div class="movie-grid">
                            <div class="movie-thumb c-thumb">
                              <a>
                                <img
                                  src="./assets/images/movie/movie12.jpg"
                                  alt="movie"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-item active">
                      <div class="movie-area mb-10">
                        {_.map(eventData?.tickets, ev => this.renderList(ev))}
                      </div>
                    </div>
                  </div>
                  {/* <div class="pagination-area text-center">
                    <a >
                      <i class="fas fa-angle-double-left" />
                      <span>Prev</span>
                    </a>
                    <a >1</a>
                    <a >2</a>
                    <a  class="active">
                      3
                    </a>
                    <a >4</a>
                    <a >5</a>
                    <a >
                      <span>Next</span>
                      <i class="fas fa-angle-double-right" />
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>

          {/* <Carousel
            ref={c => {
              this._carousel = c;
            }}
            data={eventData.tickets}
            renderItem={this._renderItem}
            sliderWidth={screen.width}
            layout={'default'}
            itemWidth={screen.width}
            inactiveSlideScale={0.97}
            inactiveSlideOpacity={1}
            hasParallaxImages={true}
            onSnapToItem={index => this.setState({ slider1ActiveSlide: index })}
            firstItem={0}
          /> */}
        </section>
        {/* <!-- ==========Movie-Section========== --> */}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  ticketContainer: {
    flex: 1,
    borderRadius: 9,
    elevation: 2,
    paddingTop: L.h(19),
    paddingBottom: L.h(12),
    margin: L.hProm(4),
    backgroundColor: Colors.backgroundTicketFinal,
    height: screen.height,
  },
  container: {
    flex: 1,
    backgroundColor: Colors.mainBackgroundTicketFinal,
    paddingTop: 110,
  },
  ticketTitle: {
    color: Colors.titleTicketFinal,
    paddingHorizontal: L.w(50),
    textAlign: 'center',
    marginBottom: L.h(11),
    fontFamily: Fonts.DBOLD,
    fontSize: Util.isIos() ? L.h(14) : L.h(13),
  },
  ticketSubTitle: {
    backgroundColor: Colors.blueberry,
    paddingVertical: L.h(8),
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: L.w(20),
  },
  subTitleText: {
    paddingHorizontal: L.w(8),
    textAlign: 'center',
    fontSize: Util.isIos() ? L.h(13) : L.h(12),
    color: Colors.subtitleTicketFinal,
    fontFamily: Fonts.MEDIUM,
  },
  ticketTopInfoContainer: {
    borderBottomWidth: 1,
    borderColor: Colors.veryLightPink,
  },

  QRContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    //marginTop: L.h(12),
    paddingBottom: L.h(20),
    backgroundColor: Colors.ticketQRBackgroundColor,
    flex: 1,
  },
  codeText: {
    fontFamily: Fonts.MEDIUM,
    fontSize: L.h(25),
    color: Colors.commonDetailText,
  },
  subCodeText: {
    fontSize: L.h(13),
    fontFamily: Fonts.MEDIUM,
    color: Colors.subcodeIntroTicketFinal,
  },
  footerContainer: {
    flex: 1,
    paddingTop: L.h(13),
    paddingHorizontal: L.w(16),
  },
  validateTicket: {
    fontSize: Util.isIos() ? L.h(13) : L.h(12),
    fontFamily: Fonts.DREGULAR,
    color: Colors.validateTicketFinal,
    marginBottom: L.h(8),
  },
  iconContainer: {
    borderWidth: 1,
    borderColor: 'white',
    borderRadius: L.hProm(25) / 2,
    height: L.hProm(25),
    width: L.wProm(25),
    alignItems: 'center',
    justifyContent: 'center',
  },
  ticketName: {
    fontSize: Util.isIos() ? L.h(16) : L.h(14),
    color: Colors.ticketNameTicketFinal,
    fontFamily: Fonts.DBOLD,
    lineHeight: L.h(20),
  },
  dniName: {
    fontSize: Util.isIos() ? L.h(16) : L.h(14),
    color: Colors.dniTicketFinal,
    fontFamily: Fonts.MEDIUM,
  },
  buttonContainer: {
    backgroundColor: Colors.buttonTransferTicketFinal,
    borderRadius: 5,
    height: Util.isIos() ? L.hProm(45) : L.hProm(40),
    justifyContent: 'center',
    alignItems: 'center',
  },
  buttonText: {
    fontSize: 12,
    fontFamily: Fonts.DSEMIBOLD,
    color: 'white',
    width: L.wProm(90),
    textAlign: 'center',
    lineHeight: 15,
  },
  footerEndText: {
    fontSize: Util.isIos() ? L.h(13) : L.h(12),
    fontFamily: Fonts.MEDIUM,
    color: Colors.alertmessageTicketFinal,
    textAlign: 'center',
    //marginTop: L.h(16),
  },
  paginatorContainer: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    bottom: Util.isIosXGen() ? L.h(20) : -L.h(20),
    left: 0,
    right: 0,
  },
  dotStyle: {
    height: L.hProm(11),
    width: L.wProm(11),
    borderRadius: L.hProm(11) / 2,
  },
  centerEnd: { alignItems: 'center', justifyContent: 'flex-end' },
  placeRow: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: L.h(8),
  },
  iconText: {
    color: Colors.commonDetailText,
    fontSize: Util.isIos() ? L.h(14) : L.h(12),
    marginLeft: L.w(8),
  },
  iconStyle: {
    fontSize: Util.isIosXGen() ? L.h(20) : L.h(14),
    color: Colors.blueberry,
  },
  alignCenter: { alignItems: 'center', flexDirection: 'row' },
  spaceBetween: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  ticketTopWrapper: {
    marginHorizontal: L.w(16),
    paddingVertical: L.h(8),
  },
  iconTextDecoration: {
    color: Colors.purpley,
    textDecorationLine: 'underline',
  },
});

function mapStateToProps(store) {
  return {
    user: store.authReducer.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopDetail);
