import { Connector } from "../base";
import Settings from "../base/Settings";
import FirebaseConnector from "../base/FirebaseConnector";

const connector = new Connector({ timeout: 30000 });

const firebaseConnector = new FirebaseConnector({ timeout: 30000 });

export default class AppConfig {
  static get base() {
    return Settings.CloudFunctions() + "/app";
  }

  static get endpoint() {
    return Settings.CloudFunctions();
  }
  static get checkVersion_endpoint() {
    return `${AppConfig.base}/check_appVersion`;
  }

  static get transfer_endpoint() {
    return `${AppConfig.endpoint}/payment/mpago/transferTicket2`;
  }
  static get loyaly_user_program() {
    return `${AppConfig.endpoint}/loyaly/user`;
  }
  static get loyaly_user_methods() {
    return `${AppConfig.endpoint}/loyaly/use-methods`;
  }

  static get personData_endpoint() {
    return Settings.CloudFunctions() + `/profile/personData`;
  }

  static get initNotification() {
    return `${AppConfig.endpoint}/app_notifications/initNotification`;
  }

  static get getNotification() {
    return `${AppConfig.endpoint}/app_notifications/getNotification`;
  }

  static get lastRead() {
    return `${AppConfig.endpoint}/app_notifications/lastRead`;
  }

  static get APIConnector() {
    return connector;
  }

  static get FirebaseConnector() {
    return firebaseConnector;
  }
}
