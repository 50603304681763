import AppConfig from "./AppConfig";
import AuthConfig from "../auth/AuthConfig";
import firebase from "react-native-firebase";
import Util from "../../common/Util";
const axios = require("axios");

const pathTickets = AuthConfig.pathTickets;
const pathUserTransactions = AuthConfig.pathUserTransactions;

export default class AppService {
  static getPersonData(document) {
    let token = null;
    return new Promise(async (resolve, reject) => {
      try {
        if (firebase.auth() && firebase.auth().currentUser) {
          await firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((data) => {
              token = data;
            });
        }
        let endpoint = `${AppConfig.personData_endpoint}?document=${document}`;
        let response = await AppConfig.APIConnector.get(endpoint, {
          headers: { Authorization: "Bearer " + token },
        });
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  static checkUpdate(logicId, nativeId, platform) {
    return new Promise(async (resolve, reject) => {
      try {
        let endpoint = `${
          AppConfig.checkVersion_endpoint
        }?logicVersion=${logicId}&nativeVersion=${nativeId}&platform=${platform}`;
        console.log(" >>>>>>>>>>checkUpdate endpoint", endpoint);
        let response = await AppConfig.APIConnector.get(endpoint);
        console.log(" >>>>>>>>>>checkUpdate", response);
        resolve(response);
      } catch (e) {
        console.log(" >>>>>>>>>>checkUpdate error", e);
        reject(e);
      }
    });
  }

  static transferTicket(friendData, ticketData, eventId, eventData) {
    let token = null;
    return new Promise(async (resolve, reject) => {
      try {
        if (firebase.auth() && firebase.auth().currentUser) {
          await firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((data) => {
              token = data;
            });
        }
        let endpoint = AppConfig.transfer_endpoint;

        let body = JSON.stringify({
          userSenderId: firebase.auth().currentUser._user.uid,
          ticketId: ticketData.id,
          eventId: eventId,
          friendId: friendData.id,
        });

        let response = await AppConfig.APIConnector.post(endpoint, {
          headers: { Authorization: "Bearer " + token },
          body: body,
        });
        let { response: responseReq = "" } = response;
        if (response.error || response === "ERROR" || responseReq === "ERROR") {
          reject("ERROR");
        } else {
          resolve("OK");
        }
      } catch (e) {
        reject("ERROR");
      }
    });
  }

  static getRevendedores() {
    //.orderBy('name')
    return new Promise(async (resolve, reject) => {
      await AppConfig.FirebaseConnector.getSubCollectionOrder(
        "productoras",
        "1", //TODO Id en duro de Productora Meed
        "rrpp",
        "lastname" // Order
      ).then((rrppList) => {
        resolve(rrppList);
      });
    });
  }
  static getLoyalyProgram() {
    let token = null;
    return new Promise(async (resolve, reject) => {
      try {
        if (firebase.auth() && firebase.auth().currentUser) {
          await firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((data) => {
              token = data;
            });
        }
        let endpoint = AppConfig.loyaly_user_program;

        let response = await AppConfig.APIConnector.get(endpoint, {
          headers: { Authorization: "Bearer " + token },
        });
        if (response.error) {
          reject("ERROR");
        } else {
          resolve(response.data);
        }
      } catch (e) {
        reject("ERROR");
      }
    });
  }
  static getLoyalyUseMethods() {
    let token = null;
    return new Promise(async (resolve, reject) => {
      try {
        if (firebase.auth() && firebase.auth().currentUser) {
          await firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((data) => {
              token = data;
            });
        }
        let endpoint = AppConfig.loyaly_user_methods;

        let response = await AppConfig.APIConnector.get(endpoint, {
          headers: { Authorization: "Bearer " + token },
        });
        if (response.error) {
          reject(response.error);
        } else {
          resolve(response.data);
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  static getErrorMsgExample(type) {
    // solo devuelve errores para ver como manejarlos con el manejador de errores o capturar errores particulares
    let error = {};
    switch (type) {
      case 1:
        error.status = 500;
        error.code = 500;
        error.msg = "Service Error";
        break;
      case 2:
        error.status = 500;
        error.code = 1;
        error.msg = "Data error";
        break;
      case 3:
        error.status = 404;
        error.code = 80;
        error.msg = "Not found";
        break;

      default:
        error.code = 500;
        error.msg = "Service Error";
        break;
    }
    return new Promise(async (resolve, reject) => {
      try {
        // let endpoint = `${AppConfig.checkVersion_endpoint}?logicVersion=${logicId}&nativeVersion=${nativeId}&platform=${platform}`;
        // let response = await AppConfig.APIConnector.get(endpoint);
        // if (response && response.code)
        // 	reject(response);
        // resolve(response);
        setTimeout(() => {
          resolve(error);
        }, 3000);
      } catch (e) {
        reject(e);
      }
    });
  }

  static getNotifications(tipo) {
    return new Promise(async (resolve, reject) => {
      try {
        let token = await Util.getCurrentToken();
        let endpoint = AppConfig.getNotification;
        let response = await AppConfig.APIConnector.post(endpoint, {
          body: JSON.stringify({ topic: "user" }),
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        });
        resolve(response);
      } catch (error) {
        //console.log(" >>>>>>>>>>error ", error);
        console.log(" >>>>>>>>>> error", error);
        reject(error);
      }
    });
  }

  static initNotifications(body) {
    return new Promise(async (resolve, reject) => {
      try {
        let endpoint = AppConfig.initNotification;
        let response = await AppConfig.APIConnector.post(endpoint, {
          body: JSON.stringify(body),
          headers: {
            //"mco-api-key": Settings.BSF_API_KEY(),
            "Content-Type": "application/json",
          },
        });
        resolve(response);
      } catch (error) {
        //console.log((" >>>>>>>>>>error ", error);
        reject(error);
      }
    });
  }

  static settingListener(callback) {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await AppConfig.FirebaseConnector.listenerCollection(
          "settings",
          callback
        );
        resolve(response);
      } catch (error) {
        console.log(" >>>>>>>>>> eeeeeeee", error);
        reject(error);
      }
    });
  }

  static lastRead() {
    return new Promise(async (resolve, reject) => {
      try {
        let endpoint = AppConfig.lastRead;
        let response = await AppConfig.APIConnector.post(endpoint, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        resolve(response);
      } catch (error) {
        //console.log((" >>>>>>>>>>error ", error);
        reject(error);
      }
    });
  }
}
