import React, { Component, PureComponent } from "react";
import { StyleSheet, Animated, Text, Platform } from "react-native";
import Fonts from "../common/Fonts";
import I18n from "i18next"

let styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  noConnectContainer: {
    width: "100%",
    backgroundColor: "red",
    justifyContent: "flex-end",
  },
  noConnectText: {
    fontSize: 12,
    color: "white",
    marginBottom: 4,
    fontFamily: Fonts.MEDIUM,
    alignSelf: "center",
  },
});

class VTOfflineBar extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      height: new Animated.Value(0),
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isConnected != this.props.isConnected) {
      // Cambio el estado de conexion
      this.startAnimation(this.props.isConnected);
    }
  }

  startAnimation(isConnected) {
    const version = Platform.Version < 21 ? 0 : 25;
    const STATUS_BAR_HEIGHT = Platform.OS === "ios" ? 20 : version;
    if (!isConnected) {
      Animated.timing(this.state.height, {
        toValue: 20 + STATUS_BAR_HEIGHT,
        duration: 1000,
      }).start();
    } else {
      Animated.timing(this.state.height, {
        toValue: 0,
        duration: 1000,
      }).start();
    }
  }

  render() {
    return (
      <Animated.View
        style={[styles.noConnectContainer, { height: this.state.height }]}
      >
        <Text style={styles.noConnectText}>
          {I18n.t("vtOfflineBar.noNetwork")}
        </Text>
      </Animated.View>
    );
  }
}

export default VTOfflineBar;
