import React, { Component, PureComponent } from 'react';
import VTOfflineBar from "../../components/VTOfflineBar";

//Redux(+)
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
//Redux(-) 
 
class OfflineBarWrapper extends PureComponent { 
  render() {
    return (
	    <VTOfflineBar isConnected={this.props.isConnected}  />
    );
  }
}


function mapStateToProps(store) {
    return {
      isConnected: store.appReducer.isConnected
    };
  }
  
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch);
}

export default connect(
mapStateToProps,
mapDispatchToProps
)(OfflineBarWrapper); 