import React, { Component } from "react";
import { StyleSheet, View, Text } from "react-native";
import { Spinner } from "native-base";

let styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

class VTSpinner extends Component {
  render() {
    return (
      <View style={styles.container}>
        <Spinner color="black" />
        <Text>{I18n.t("vtSpinner.loading")}</Text>
      </View>
    );
  }
}

export default VTSpinner;
