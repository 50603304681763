import _ from "lodash";
import React from "react";
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  RefreshControl,
  ActivityIndicator,
} from "react-native";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Actions } from "react-native-router-flux";
import L from "../../common/Layout";
import Fonts from "../../common/Fonts";
import Colors from "../../common/Colors";
import EventCard from "../../components/EventCard";
import AppMem from "../../common/AppMem";
import SegmentControl from "../../components/SegmentControl";
import { getEventsCollection } from "../../actions/AppActions";
import I18n from "i18next"

class StatisticsMain extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      refreshing: false,
      tabIndex: 0,
    };
    this.eventsCallback = this.eventsCallback.bind(this);
    this.onPressCard = this.onPressCard.bind(this);
    this._onRefresh = this._onRefresh.bind(this);
    this.onPressSegment = this.onPressSegment.bind(this);
  }

  componentWillMount() {
    let { getEventsCollection, producerId } = this.props;
    this.setState({ loading: true });
    if (AppMem.appLoader) {
      setTimeout(() => {
        AppMem.closeAppLoader();
      }, 1000);
    }
    getEventsCollection(producerId, this.eventsCallback);
  }

  onPressSegment(index) {
    this.setState({ tabIndex: index });
  }

  eventsCallback() {
    this.setState({ loading: false, refreshing: false });
  }

  _onRefresh() {
    let { getEventsCollection, producerId } = this.props;
    this.setState({ refreshing: true });
    getEventsCollection(producerId, this.eventsCallback);
  }

  renderEvents() {
    let { events } = this.props;
    return (
      <View
        style={{
          width: "100%",
          alignSelf: "center",
        }}
      >
        <SegmentControl
          values={[
            I18n.t("statisticsMain.actives"),
            I18n.t("statisticsMain.unactives"),
          ]}
          selectedIndex={this.state.tabIndex}
          height={30}
          onTabPress={this.onPressSegment}
          borderRadius={0}
          //tabsContainerStyle={{ height: L.hProm(40) }}
        />
        {events &&
          events.map((ev) => {
            if (this.state.tabIndex === 0 && ev.evtState == "active") {
              return <EventCard key={ev.id} eventData={ev} />;
            } else if (this.state.tabIndex === 1 && ev.evtState == "inactive") {
              return <EventCard key={ev.id} eventData={ev} />;
            }
          })}
      </View>
    );
  }

  onPressCard(ev) {
    Actions.statisticsDetail({ event: ev });
  }

  render() {
    let { user } = this.props;
    let { loading, refreshing } = this.state;
    return (
      <View style={styles.container}>
        <View style={styles.divider}>
          <Text style={styles.dividerText}>
            {I18n.t("statisticsMain.welcome")}
            <Text style={{ fontFamily: Fonts.MEDIUM }}>{user.proName}</Text>!
            {I18n.t("statisticsMain.yourEvents")}
          </Text>
        </View>
        <ScrollView
          style={styles.container}
          keyboardShouldPersistTaps="always"
          refreshControl={
            <RefreshControl
              refreshing={refreshing}
              onRefresh={this._onRefresh}
            />
          }
          keyboardDismissMode="on-drag"
        >
          {loading ? (
            <View style={{ marginTop: L.h(16), alignSelf: "center" }}>
              <ActivityIndicator size="large" color={Colors.darkIndigo} />
            </View>
          ) : (
            this.renderEvents()
          )}
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.producerHomeBackground,
  },
  divider: {
    paddingVertical: L.h(7),
    paddingHorizontal: L.w(18),
    backgroundColor: Colors.black_87,
  },
  dividerText: {
    color: "white",
    fontSize: L.h(14),
    fontFamily: Fonts.DREGULAR,
  },
  eventContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: L.h(16),
    paddingLeft: L.w(25),
    paddingRight: L.w(35),
    paddingBottom: L.h(15),
    borderBottomWidth: 1,
    borderColor: Colors.black_14,
  },
  eventDescription: { paddingRight: L.w(10) },
  eventDate: {
    flexDirection: "row",
    alignItems: "center",
  },
  iconDate: {
    fontSize: L.h(13),
    marginRight: L.w(5),
  },
  eventText: {
    fontSize: L.h(14),
    color: Colors.greyishBrown,
    fontFamily: Fonts.DLIGHT,
  },
  eventName: {
    fontSize: L.h(17),
    marginTop: L.h(5),
    color: Colors.greyishBrown,
    fontFamily: Fonts.DSEMIBOLD,
  },
  eventTicket: {
    fontSize: L.h(14),
    marginTop: L.h(5),
    color: Colors.blueberry,
    fontFamily: Fonts.DLIGHT,
  },
  iconRight: {
    fontSize: L.h(20),
    color: Colors.greyishBrown,
  },
});

function mapStateToProps(store) {
  return {
    tickets: {},
    nextEvents: store.appReducer.nextEvents, // store.ticketReducer.tickets,
    events: store.appReducer.events,
    user: store.authReducer.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEventsCollection,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticsMain);
