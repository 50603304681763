//For documentation visit
//https://www.npmjs.com/package/react-native-modalbox
import React, { Component } from "react";
import { StyleSheet, Dimensions, Keyboard } from "react-native";
import Modal from "react-native-modalbox";
import VTSpinner from "./VTSpinner";
import { VTConfirmationModal, VTAlertModal, VTInputModal } from "./modals";
import L from "../common/Layout";
import LinearGradient from "react-native-linear-gradient";
import Colors from "../common/Colors";
import Util from "../common/Util";

var styles = StyleSheet.create({
  containerSmall: {
    // paddingBottom: 10,
    // paddingTop: 10,
    height: L.hProm(300),
    width: "70%",
    borderRadius: 5,
    alignItems: "center",
  },
  updateModal: {
    height: L.hProm(500),
  },
  spinner: {
    height: L.hProm(150),
    width: L.wProm(150),
    borderRadius: 5,
  },
  inputModal: {
    height: L.hProm(250),
    borderWidth: 1,
  },
  alert: {
    height: L.hProm(202),
    borderWidth: 1,
  },
  error: {
    borderColor: "rgb(174, 24, 30)",
  },
  success: {
    borderColor: "rgb(12, 113, 48)",
  },
  warning: {
    borderColor: "rgb(204, 140, 0)",
  },
  primary: {
    borderColor: Colors.darkIndigo,
  },
});

class VTModal extends React.PureComponent {
  constructor(props) {
    super(props);
    //for get keyboard height
    this.state = {
      children: null,
      style: null,
      autoHeight: false,
      onClosed: null,
      modalProps: null,
      margin: true,
      scrollEnabled: true,
      title: this.props.title,
      update: null,
      keyboardSpace: 0,
      nicknameModalVisible: true,
    };
    Keyboard.addListener("keyboardDidShow", (frames) => {
      if (!frames.endCoordinates) return;
      this.setState({ keyboardSpace: frames.endCoordinates.height });
    });
    Keyboard.addListener("keyboardDidHide", (frames) => {
      this.setState({ keyboardSpace: 0 });
    });
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    // this.resizeModal = this.resizeModal.bind(this);
    this._isOpen = false;
    this._mustBeOpen = false;
    this.closeEvent = null;
  }

  get isOpen() {
    return this._isOpen;
  }

  get mustBeOpen() {
    return this._mustBeOpen;
  }

  close(closeEvent = null) {
    this._isOpen = false;
    this._mustBeOpen = false;
    this.closeEvent = closeEvent;
    this.refs.modal.close();
  }
  open(modal, props, child) {
    this._isOpen = true;
    this._mustBeOpen = props.mustBeOpen;
    let style = {};
    let children = null;
    if (typeof modal === "string") {
      switch (modal) {
        case "spinner":
          children = <VTSpinner />;
          style = [styles.spinner];
          break;
        case "confirmation":
          children = <VTConfirmationModal {...props} close={this.close} />;
          style = [styles.confirmation];
          break;
        case "alert":
          children = <VTAlertModal {...props} close={this.close} />;
          style = [styles[props.type], styles.alert, props.style];
          break;
        case "input":
          children = <VTInputModal {...props} close={this.close} />;
          style = [styles[props.type], styles.inputModal, props.style];
          break;
        case "content":
          children = child;
          style = props.style;
          break;
        default:
          children = modal;
          break;
      }
    } else {
      children = modal;
      style = props.style;
      //WIP: when modal is a component with and without scroll
    }
    this.setState({ children: children, style: style, modalProps: props });
    this.closeEvent = null;
    this.refs.modal.open();
  }

  render() {
    let keyboardSize = Util.isIosXGen() ? 370 : 180;
    let properties = {
      ...this.state.modalProps,
      ref: "modal",
      style: [
        styles.containerSmall,
        {
          position: "absolute",
          bottom: 0,
          //change modal position by keyboardspace
          top: this.state.keyboardSpace
            ? keyboardSize - this.state.keyboardSpace
            : 0,
        },
        this.state.style,
      ],
      swipeToClose: false,
      onClosed: () =>
        this.state.onClosed && this.state.onClosed(this.closeEvent),
    };

    return <Modal {...properties}>{this.state.children}</Modal>;
  }
}

export default VTModal;
