import _ from 'lodash';
import React from 'react';
import AppMem from '../../common/AppMem';
import {
  ActivityIndicator,
  Dimensions,
  Platform,
  KeyboardAvoidingView,
  View,
} from 'react-native';
import { Actions } from 'react-native-router-flux';
import { connect } from 'react-redux';

import { bindActionCreators } from 'redux';
import Fonts from '../../common/Fonts';
import L from '../../common/Layout';
import VTHeader from '../../components/VTHeader';
import LinearGradient from 'react-native-linear-gradient';
import Colors from '../../common/Colors';
import { TOP_PADDING_IOS_FONT } from '../../common/Constants';
import Util from '../../common/Util';
import I18n from 'i18next';
import Pagos360Web from './pagos360/Pagos360Web.web';
const screen = Dimensions.get('window');
import toast from 'react-hot-toast';
import Loader from '../../components/Loader';

class Recharge extends React.PureComponent {
  constructor(props) {
    super(props);
    this.dataToPagos360 = this.dataToPagos360.bind(this);
    this.onMessage = this.onMessage.bind(this);
    this.state = { loading: false, processing: false };
  }

  componentWillMount() {
    window.reactOnMessage = msg => {
      this.onMessage(msg);
    };
  }

  //onLoadEnd() {
  dataToPagos360() {
    const { preferenceData, card_type, user } = this.props;

    let data = {
      importe: preferenceData.first_total,
      datos_pago: {
        id_preferencia_pago: `${preferenceData.id}`,
        url_checkout: preferenceData.checkout_url,
        pdf_url: preferenceData.pdf_url,
        card_type: card_type,
        importeFormat: `$ ${preferenceData.first_total},00`,
        dni: user.proDNI,
        email: user.proEmail,
      },
    };

    /*try {
      setTimeout(() => {
        this.web.postMessage(JSON.stringify(data));
      }, 200);
    } catch (e) {
      console.log("*** e ", e);
    }*/

    return data;
  }

  onMessage(dataHTML) {
    // Llamado desde el formulario WEB de Pagos360
    const { onSuccesBuy, onErrorBuy } = this.props;

    //WEB99
    // const data = JSON.parse(_.get(e, "nativeEvent.data"));
    // En web el JSON no viene wrappeado
    const data = JSON.parse(dataHTML);
    if (_.get(data, 'valid', false)) {
      toast.error('Verifique los campos ');
    } else {
      console.log('loading');
      if (_.get(data, 'loading', false)) {
        this.setState({ loading: true });
        toast.loading('Procesando pago...', { duration: 6000 });
      } else {
        console.log('onMessage - PASO 1 ');
        this.setState({ loading: false });
        if (
          _.get(data, 'statusCode') === 200 ||
          _.get(data, 'statusCode') === 201
        ) {
          console.log('onSuccesBuy - PASO 1 ');
          toast.success('Tu compra se realizó correctamente!', {
            duration: 8000,
          });
          onSuccesBuy(data);
        } else {
          console.log('onErrorBuy - PASO 1 ');
          toast.error(data.message, { duration: 8000 });
          onErrorBuy();
        }
      }
    }
  }

  render() {
    let { loading } = this.state;
    return (
      <View style={{ backgroundColor: Colors.backgroundHistory, paddingTop: 100 }}>
        <div class="padding-top">
          <section class="page-title bg-one">
            <div class="container">
              <div class="page-title-area">
                <div
                  class="item"
                  onClick={() => Actions.pop()}
                  style={{ cursor: 'pointer' }}
                >
                  <a class="custom-button back-button">
                    <i class="flaticon-double-right-arrows-angles" />
                    Volver
                  </a>
                </div>
                <div class="item date-item">
                  {/* <h4>Finalizar Compra</h4> */}
                </div>
                <div class="item" />
              </div>
            </div>
          </section>
          <section class="account-section">
              <div class="container">
                <div class="padding-top padding-bottom">
                  <div class="account-area" style={{padding: 0}}>
                    <Pagos360Web
                      dataPagos360={this.dataToPagos360()}
                      onMessage={this.onMessage}
                    />
                  </div>
                </div>
              </div>
            </section>
        </div>
        {loading ? <Loader text={'Cargando...'} /> : null}
      </View>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({}, dispatch);
}

function mapStateToProps(store) {
  return {
    user: store.authReducer.user,
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Recharge);
