import React from "react";
import { View } from "react-native";
import { getStatusBarHeight } from "react-native-status-bar-height";

const StatusBarView = ({ style }) => {
  return (
    <View style={[{ height: getStatusBarHeight(), width: "100%" }, style]} />
  );
};

export default StatusBarView;
