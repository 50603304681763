import React, { Component } from "react";
import {
  View,
  Text,
  ScrollView,
  TouchableOpacity,
  Dimensions,
} from "react-native";
import Fonts from "../common/Fonts";
import Colors from "../common/Colors";
import L from "../common/Layout";
import AppMem from "../common/AppMem";
const screen = Dimensions.get("window");
import Config from "react-native-config";
import I18n from "i18next"

export default class UserExistPopUp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { email } = this.props;
    return (
      <View style={{ flex: 1, width: screen.width - L.wProm(90) }}>
        <Text
          style={{
            color: "black",
            marginTop: L.h(16),
            fontSize: L.h(26),
            textAlign: "left",
            marginBottom: L.h(8),
          }}
        >
          {I18n.t("userExistPopUp.ups")}
        </Text>
        <View style={{ height: L.hProm(270), flex: 1 }}>
          <Text
            style={{
              color: "black",
              textAlign: "left",
              flex: 1,
              fontSize: L.h(16),
              marginTop: L.h(8),
              lineHeight: 20,
            }}
          >
            {I18n.t("userExistPopUp.mainMessage", {
              message: email ? "E-mail" : "DNI",
            })}
            {"\n" + "\n"}
            {I18n.t("userExistPopUp.mainMessage2")} {Config.EMAIL_INFO_APP}
          </Text>
        </View>
        <TouchableOpacity
          style={{
            height: L.hProm(40),
            alignCenter: "center",
            justifyContent: "center",
          }}
          onPress={() => {
            AppMem.closeModal();
            this.props.onPressClose && this.props.onPressClose();
          }}
        >
          <Text
            style={{
              color: Colors.darkIndigo,
              textAlign: "center",
              fontFamily: Fonts.MEDIUM,
              fontSize: L.h(17),
            }}
          >
            CERRAR
          </Text>
        </TouchableOpacity>
      </View>
    );
  }
}
