import FirebaseConnector from "../base/FirebaseConnector";
const PATH = "payment-history";
import APIConnector from '../base/Connector'
import Settings from "../base/Settings"
const connector = new FirebaseConnector({ timeout: 30000 });
const apiConnector = new APIConnector({ timeout: 30000 })

export default class PaymentHistory {
  static get pathPaymentHistory() {
    return PATH;
  }
  static get endpoint() {
    return Settings.CloudFunctions();
  }

  static get endpointCreatePreference() {
    return `${PaymentHistory.endpoint}/payment/pagos360/create_preference`;
  }

  static get endpointCreateStripePreference() {
    return `${PaymentHistory.endpoint}/payment/stripe/payment-sheet`;
  }

  static get loyalyExchangeBenefit() {
    return `${PaymentHistory.endpoint}/loyaly/exchange`;
  }
  static get loyalyExchangeBenefitByWallet() {
    return `${PaymentHistory.endpoint}/loyaly/wallet`;
  }

  static get endpointGetPreference() {
    return `${PaymentHistory.endpoint}/payment/pagos360/get_preference`;
  }

  static get endpointMailSender() {
    return `${PaymentHistory.endpoint}/payment/mpago/emailSender`;
  }

  static get endpointPay() {
    return `${PaymentHistory.endpoint}/payment/mpago/pay`;
  }

  static get endpointCreateFreeTickets() {
    return `${PaymentHistory.endpoint}/payment/global/create_free_tickets`;
  }

  static get APIConnector() {
    return apiConnector
  }
  static get FirebaseConnector() {
    return connector;
  }
}
