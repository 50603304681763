import React from "react";
import { View, StyleSheet, Dimensions, Alert, Platform } from "react-native";
import { Actions } from "react-native-router-flux";
import { connect } from "react-redux";
import I18n from "i18next";
import { Field, reduxForm, formValueSelector } from "redux-form";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import { Icon, Fab, Toast } from "native-base";
import L from "../../common/Layout";
import style from "./style";
import {
  saveDataToUser,
  changeEmail,
  actLogout,
  saveEmailDataToUser,
} from "../../actions/AuthActions";
import VTHeader from "../../components/VTHeader";
import ThemeColors from "../../common/ThemeColors";
import renderInput from "../../components/LabelCustomInput";
import Colors from "../../common/Colors";
import AppMem from "../../common/AppMem";
import Util from "../../common/Util";
import { required, validateEmail } from "../../common/Validations";
import Fonts from "../../common/Fonts";
import firebase from "react-native-firebase";
import UserExistPopUp from "../../components/UserExistPopUp";
let screen = Dimensions.get("window");
class EditEmail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { editable: true, active: false };
    this.inputs = {};
  }

  componentWillMount() {
    let { user } = this.props;
    let intialData = {
      proEmail: user.proEmail,
    };
    this.props.initialize(intialData);
  }

  getScrollViewProps() {
    return {
      alwaysBounceVertical: false,
      bounces: false,
      keyboardShouldPersistTaps: "always",
    };
  }

  getInputProps() {
    let props = {
      editable: this.state.editable,
      maxWidth: "89%",
      borderColor: Colors.secondary_color,
      placeholderStyle: { color: Colors.profileFieldColorEdit },
      labelStyle: { color: Colors.profileFieldColorEdit },
      inputStyle: { color: Colors.profileFieldColorEdit },
      withRef: true,
    };
    return props;
  }

  onPressFAB = (formValues, isSocialMedia) => {
    let { user } = this.props;
    if (formValues.proEmail == user.proEmail) {
      Toast.show({
        text: I18n.t("editEmail.fabError"),
        buttonText: I18n.t("global.accept"),
        type: "danger",
        position: "bottom",
      });
    } else {
      AppMem.openAppLoader();
      if (formValues) {
        if (isSocialMedia) {
          this.props.saveEmailDataToUser(
            formValues,
            () => {
              AppMem.closeAppLoader();
              Toast.show({
                text: I18n.t("register.passwordSuccesfullyUpdated"),
                buttonText: I18n.t("global.accept"),
                type: "success",
                position: "bottom",
                onClose: () => null,
              });
            },
            (e) => {
              AppMem.closeAppLoader();
              this.errorCallback(e);
            }
          );
        } else {
          this.props.changeEmail(
            formValues.proEmail,
            formValues.password,
            (callbackData) => {
              AppMem.closeAppLoader();
              if (callbackData == "OK") {
                this.props.saveDataToUser(
                  formValues,
                  () => {
                    AppMem.closeAppLoader();
                  },
                  (e) => {
                    AppMem.closeAppLoader();
                    Alert.alert("Ocurrio un Error");
                  }
                );
                Toast.show({
                  text: I18n.t("register.passwordSuccesfullyUpdated"),
                  buttonText: I18n.t("global.accept"),
                  type: "success",
                  position: "bottom",
                  onClose: () =>
                    this.props.actLogout(() => {
                      Actions.reset("login");
                    }),
                });
              } else {
                Toast.show({
                  text: I18n.t("register.errorOcurred"),
                  buttonText: I18n.t("global.accept"),
                  type: "danger",
                  position: "bottom",
                });
              }
            }
          );
        }
      }
    }
  };

  errorCallback(error) {
    AppMem.closeAppLoader();
    if (error) {
      AppMem.openModal(<UserExistPopUp onPressClose={() => null} email />, {
        style: {
          height: L.hProm(310),
          borderRadius: 12,
          width: screen.width - L.wProm(60),
        },
        swipeToClose: false,
      });
    }
  }

  renderToast() {
    Toast.show({
      text: I18n.t("editEmail.requiredFields"),
      buttonText: I18n.t("editEmail.ok"),
      position: "bottom",
      type: "danger",
    });
  }

  renderInputEmail() {
    return (
      <View style={[localStyle.inputRow, { marginTop: L.h(16) }]}>
        <Icon
          name="mail"
          type="MaterialIcons"
          style={[
            localStyle.iconSt,
            { marginRight: L.w(Util.isIos() ? 14 : 10) },
          ]}
        />
        <Field
          name="proEmail"
          label="E-mail"
          ref={(input) => {
            this.inputs.email = input;
          }}
          emailCorrection
          component={renderInput}
          validate={[required, validateEmail]}
          autoCapitalize="none"
          {...this.getInputProps()}
        />
      </View>
    );
  }

  renderPassword() {
    return (
      <View style={localStyle.inputRow}>
        <Icon
          name="lock"
          type="MaterialIcons"
          style={[
            localStyle.iconSt,
            { marginRight: L.w(Util.isIos() ? 14 : 10) },
          ]}
        />
        <Field
          name="password"
          label={I18n.t("editEmail.password")}
          ref={(input) => {
            this.inputs.email = input;
          }}
          trimWhiteSpace
          component={renderInput}
          validate={required}
          secureTextEntry
          autoCapitalize="none"
          {...this.getInputProps()}
        />
      </View>
    );
  }

  renderRightButtons(isSocialMedia) {
    let { handleSubmit } = this.props;

    return [
      {
        onPress: handleSubmit((values) =>
          this.onPressFAB(values, isSocialMedia)
        ),
        icon: {
          text: Util.isIos()
            ? I18n.t("editEmail.save")
            : I18n.t("editEmail.save").toUpperCase(),
          textStyle: {
            fontSize: L.h(17),
            color: ThemeColors.primary1Color,
            fontFamily: Fonts.MEDIUM,
            top: L.h(2),
          },
        },
      },
    ];
  }

  render() {
    AppMem.closeAppLoader();
    let userAuth = firebase.auth().currentUser;
    let { providerData } = userAuth._user;
    let isSocialMedia =
      providerData[0].providerId === "facebook.com" ||
      providerData[0].providerId === "google.com" ||
      providerData[0].providerId === "apple.com";
    return (
      <View
        style={[
          style.container,
          { backgroundColor: Colors.profileBackgroundEdit },
        ]}
      >
        <VTHeader
          title={I18n.t("editEmail.updateEmail")}
          leftButtons={[
            {
              onPress: Actions.pop,
              icon: {
                name: "chevron-left",
                type: "MaterialIcons",
                style: {
                  color: "white",
                  fontSize: L.h(20),
                  marginLeft: L.w(8),
                },
                text: Util.isIos() ? "" : "",
                textStyle: {
                  fontSize: L.h(18),
                  color: "white",
                },
              },
            },
          ]}
          titleStyle={{
            fontFamily: Fonts.MEDIUM,
            fontSize: L.h(18),
            color: "white",
            top: Platform.OS === "ios" ? 2 : 0,
          }}
          rightButtons={this.renderRightButtons(isSocialMedia)}
        />
        <KeyboardAwareScrollView
          extraHeight={L.h(80)}
          resetScrollToCoords={{ x: 0, y: 0 }}
          contentContainerStyle={{ flexGrow: 1 }}
          enableOnAndroid
          keyboardShouldPersistTaps="always"
          scrollEnabled
        >
          {this.renderInputEmail()}
          {isSocialMedia ? null : this.renderPassword()}
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

const localStyle = StyleSheet.create({
  inputRow: {
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: L.w(16),
  },
  iconSt: {
    fontSize: L.h(30),
    color: Colors.greyishBrownThree,
    marginRight: L.w(8),
  },
});

function mapStateToProps(store) {
  const selector = formValueSelector("editProfile");
  return {
    editProfile: selector(
      store,
      "proName",
      "proLastName",
      "proBirthDate",
      "proGender",
      "proEmail"
    ),
    user: store.authReducer.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    saveDataToUser(userData, callbackSuccess, callbackError) {
      dispatch(saveDataToUser(userData, callbackSuccess, callbackError));
    },
    changeEmail(newEmail, password, callback) {
      dispatch(changeEmail(newEmail, password, callback));
    },
    actLogout(onSuccess) {
      dispatch(actLogout(onSuccess));
    },
    saveEmailDataToUser(data, okCallback, errorCallBack) {
      dispatch(saveEmailDataToUser(data, okCallback, errorCallBack));
    },
  };
}
export default reduxForm({
  form: "editEmail",
  destroyOnUnmount: false,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditEmail)
);
