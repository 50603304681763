import React, { Component } from "react";
import {
  View,
  Text,
  TouchableOpacity,
  ImageBackground,
  StyleSheet,
  Dimensions,
  Image,
} from "react-native";
import Colors from "../common/Colors";
import L from "../common/Layout";
import LinearGradient from "react-native-linear-gradient";
import Util from "../common/Util";
import Fonts from "../common/Fonts";
import { TOP_PADDING_IOS_FONT } from "../common/Constants";

class MainButton extends Component {
  render() {
    return (
      <LinearGradient
        colors={
          this.props.secondary
            ? [Colors.white, Colors.white]
            : [Colors.primaryGradientButton, Colors.secondaryGradientButton]
        }
        start={{ x: 0.2, y: 0 }}
        end={{ x: 1, y: 0 }}
        style={[
          this.props.secondary ? styles.containerSecondary : styles.container,
          this.props.style,
        ]}
      >
        <TouchableOpacity
          disabled={this.props.disabled}
          onPress={this.props.onPress}
          style={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <Text
            style={[
              this.props.secondary
                ? styles.textButtonSecondary
                : styles.textButton,
              this.props.textStyle,
            ]}
          >
            {this.props.text}
          </Text>
        </TouchableOpacity>
      </LinearGradient>
    );
  }
}

MainButton.defaultProps = {
  disabled: false,
};

const styles = StyleSheet.create({
  container: {
    //backgroundColor: Colors.primary_color,
    borderRadius: L.w(25),
    height: L.hProm(30),
    alignItems: "center",
    justifyContent: "center",
  },
  containerSecondary: {
    //backgroundColor: "white",
    borderRadius: L.w(25),
    height: L.hProm(22),
    alignItems: "center",
    justifyContent: "center",
  },
  textButton: {
    color: Colors.primaryButtonText,
    textAlign: "center",
    fontSize: 16,
    fontFamily: Fonts.DBOLD,
    //top: Platform.OS === "ios" ? TOP_PADDING_IOS_FONT : 0,
    //textTransform: "uppercase"
  },
  textButtonSecondary: {
    color: Colors.secondaryButtonText,
    textAlign: "center",
    fontSize: 16,
    fontFamily: Fonts.DBOLD,
    textTransform: "uppercase",
    //top: Platform.OS === "ios" ? TOP_PADDING_IOS_FONT : 0,
  },
});

export default MainButton;
