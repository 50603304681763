import React, { Component } from "react";
import { FormControl, Content } from "native-base"; //WEB99 Form
import Icon from "react-native-vector-icons/Ionicons";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Keyboard,
  ActivityIndicator,
  ImageBackground,
} from "react-native";
import { Actions } from "react-native-router-flux";
import LabelCustomInput from "../../components/LabelCustomInput";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import L from "../../common/Layout";
import Colors from "../../common/Colors";
import Fonts from "../../common/Fonts";
import I18n from "i18next"
//import { verifyUsername } from "../../actions/UserActions";
import AppMem from "../../common/AppMem";
import {
  required,
  validateEmail,
  confirmPassword,
  password,
  username,
} from "../../common/Validations";
import VTHeader from "../../components/VTHeader";
import RegisterConstants from "./RegisterConstants";
const BG_IMG = require("../../common/assets/background_login.jpg");
import { Field, reduxForm, formValueSelector, change } from "redux-form";
import firebase from "react-native-firebase";
import Util from "../../common/Util";

class RegisterC extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      name: "",
      lastname: "",
    };
    this.inputs = {};
    //this.verifyUsername = this.verifyUsername.bind(this);
    this.verificationCallBack = this.verificationCallBack.bind(this);
  }

  UNSAFE_componentWillMount() {
    let { dispatch, socialMedia, initialize } = this.props;
    dispatch(change("register", "proInactiveAccount", false));
    let auth = firebase.auth();
    let user = auth.currentUser && auth.currentUser._user;
    //console.log(" >>>>>>>>>> user", user);
    if (socialMedia && auth.currentUser && auth.currentUser._user) {
      let splitName = user.displayName
        ? Util.splitFullName(user.displayName)
        : { name: "", lastname: "" };
      this.setState({ name: splitName.name, lastname: splitName.lastname });
      const name = splitName.name;
      const lastname = splitName.lastname;
      dispatch(change("register", "proName", Util.autoCapitalize(name)));
      dispatch(
        change("register", "proLastName", Util.autoCapitalize(lastname))
      );
      if (user.photoURL)
        dispatch(change("register", "proProfileImage", user.photoURL));
      if (user.email) dispatch(change("register", "proEmail", user.email));
      if (user.phoneNumber)
        dispatch(change("register", "proPhone", user.phoneNumber));
      initialize({
        proName: name ? Util.autoCapitalize(name) : "",
        proLastName: name ? Util.autoCapitalize(lastname) : "",
        proProfileImage: user.photoURL,
        proEmail: user.email,
        proPhone: user.phoneNumber,
      });
    }
  }

  getInputProps() {
    let props = {
      borderColor: Colors.registerFieldBorderColor,
      labelStyle: { color: Colors.registerFieldColor },
      inputStyle: { color: Colors.registerTextColor},
      errorIconStyle: { color: Colors.registerErrorColor },
      borderBottomColor: Colors.registerBorderColor,
      blurOnSubmit: false,
      withRef: true,
      autoCapitalize: "none",
      topMargin: L.h(20),
    };
    return props;
  }

  // verifyUsername(formValues) {
  //   let { verifyUsername } = this.props;
  //   Keyboard.dismiss();
  //   //AppMem.openAppLoader();
  //   this.setState({ isLoading: true });
  //   verifyUsername(formValues.username, this.verificationCallBack);
  // }

  verificationCallBack(e) {
    this.setState({ isLoading: false });
    if (e) {
      return null;
      //this.setState({ isLoading: false });
    } else {
      Actions.registerD();
    }
  }

  focusNext(key) {
    if (!this.props.registerValues[key]) {
      // verifica que si el siguiente input tiene un dato
      // si no tiene dato hace el next
      this.inputs && this.inputs[key].getRenderedComponent().executeFocus();
    } else {
      // si lo tiene cierra el teclado y no hace el next
      Keyboard && Keyboard.dismiss();
    }
  }

  render() {
    const { handleSubmit, socialMedia, isAppleAuth } = this.props;
    const { isLoading, name, lastname } = this.state;
    return (
      <ImageBackground source={BG_IMG} style={{ flex: 1 }} resizeMode="cover">
        <View style={localStyle.container}>
           
         
            <View style={localStyle.mainContent}>
              <Text style={localStyle.textInfo}>
                {I18n.t("register.signInSteps.textCompleteFields")}
              </Text>
              <FormControl style={localStyle.form}>
                {socialMedia && name ? null : (
                  <Field
                    name="proName"
                    label={"Nombre"}
                    ref={(input) => {
                      this.inputs.name = input;
                    }}
                    autoCapitalize="words"
                    component={LabelCustomInput}
                    validate={[required]}
                    returnKeyType={"next"}
                    onSubmitEditing={() => this.focusNext("lastname")}
                    {...this.getInputProps()}
                  />
                )}
                {socialMedia && lastname ? null : (
                  <Field
                    name="proLastName"
                    label={"Apellido"}
                    ref={(input) => {
                      this.inputs.lastname = input;
                    }}
                    autoCapitalize="words"
                    component={LabelCustomInput}
                    validate={[required]}
                    returnKeyType={"next"}
                    onSubmitEditing={Keyboard.dismiss}
                    {...this.getInputProps()}
                  />
                )}
                <Field
                  name="proGender"
                  itemType="ItemList"
                  type="picker"
                  placeholderStyle={{ color: "white" }}
                  ref={(input) => {
                    this.inputs.gender = input;
                  }}
                  label="Género (opcional)"
                  borderColor={Colors.rouge}
                  //validate={required}
                  component={LabelCustomInput}
                  items={[
                    { label: "Masculino", value: "male" },
                    { label: "Femenino", value: "female" },
                  ]}
                  {...this.getInputProps()}
                />
              </FormControl>
              {isLoading ? (
                <View
                  style={[localStyle.buttonAction, { height: L.hProm(100) }]}
                >
                  <ActivityIndicator size="large" color="white" />
                </View>
              ) : (
                <TouchableOpacity
                  style={localStyle.buttonAction}
                  onPress={handleSubmit(() =>
                    Actions.push("registerD", { socialMedia, isAppleAuth })
                  )}
                >
                  <Icon
                    name="md-arrow-round-forward"
                    style={localStyle.buttonActionIcon}
                  />
                  <Text style={localStyle.buttonActionText}>
                    {I18n.t("global.after")}
                  </Text>
                </TouchableOpacity>
              )}
            </View> 
        </View>
      </ImageBackground>
    );
  }
}
const localStyle = StyleSheet.create({
  closeButton: {
    padding: 10,
    position: "absolute",
    top: L.h(25),
    left: L.w(15),
  },
  closeIcon: {
    fontSize: L.h(30),
    color: "white",
  },
  container: {
    flex: 1,
  },
  mainContent: {
    paddingTop: L.h(20),
    paddingHorizontal: L.w(44),
  },
  form: {
    marginTop: L.h(0),
  },
  textInfo: {
    fontSize: L.h(25),
    fontFamily: Fonts.DBOLD,
    color: Colors.textButtonRegisterC,
  },
  buttonAction: {
    padding: L.h(10),
    top: L.h(15),
    alignItems: "center",
    justifyContent: "center",
    marginBottom: L.h(80),
  },
  buttonActionIcon: {
    fontSize: L.h(100),
    color: Colors.textIconRegisterC,
    marginBottom: L.h(-20),
  },
  buttonActionText: {
    fontSize: L.h(21),
    fontFamily: Fonts.DBOLD,
    color: Colors.textButtonRegisterC,
  },
});

function mapStateToProps(store) {
  const selector = formValueSelector("register");
  return {
    registerValues: selector(store, "name", "lastName", "email", "username"),
    //genderList: store.parametersReducer.genderList
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      //verifyUsername
    },
    dispatch
  );
}
export default reduxForm({
  form: "register",
  destroyOnUnmount: false,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RegisterC)
);
