import React, { Component } from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  Keyboard,
} from "react-native";
import { Actions } from "react-native-router-flux";
import { bindActionCreators } from "redux";
import {
  searchPersonByDocument,
  onTransferTicket,
  getMyNextEvents,
} from "../../actions/AppActions";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import _ from "lodash";
import AppMem from "../../common/AppMem";
//import { Row, Col, Toast } from "native-base";//WEB99
import { Toast } from "native-base";
import { Field, reduxForm } from "redux-form";
import { connect } from "react-redux";
import moment from "moment";
import Util from "../../common/Util";
import Header from "../../components/VTHeader";
import L from "../../common/Layout";
import Fonts from "../../common/Fonts";
import Colors from "../../common/Colors";
import LabelCustomInput from "../../components/LabelCustomInput";
import { required } from "../../common/Validations";
import NumericInput from "react-native-numeric-input";
import { transferRRPPTickets } from "../../actions/RRPPActions";
import Config from "react-native-config";
import Icon from "react-native-vector-icons/MaterialIcons";
import { getEventsCollection } from "../../actions/AppActions";
import I18n from "i18next";

const Col = {}; //WEB99
const Row = {};

const screen = Dimensions.get("window");

class TransferTicketRRPP extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      select: false,
      isGift: false,
      searchOk: false,
      qnty: 0,
      ticketTypeSelected: null,
    };
    this.onPressTransfer = this.onPressTransfer.bind(this);
    this.onChangeText = this.onChangeText.bind(this);
  }

  getInputProps() {
    return {
      borderColor: Colors.fieldBuyerSellRRPP,
      labelStyle: { color: Colors.fieldBuyerSellRRPP },
      inputStyle: { color: Colors.fieldBuyerSellRRPP },
      maxWidth: "75%",
      errorIconStyle: { color: "black" },
      borderBottomColor: Colors.fieldBuyerSellRRPP,
      blurOnSubmit: false,
      withRef: true,
      autoCapitalize: "none",
    };
  }

  onSearchPress(formValues) {
    Keyboard.dismiss();
    if (formValues.document == this.props.user.proDNI) {
      setTimeout(() => {
        Toast.show({
          text: I18n.t("transferTicketRRPP.errorDNISearch"),
          position: "bottom",
          type: "danger",
          buttonText: I18n.t("transferTicketRRPP.accept"),
        });
      }, 200);
    } else {
      AppMem.openAppLoader("bars", {
        text: I18n.t("transferTicketRRPP.loaderText"),
        color: Colors.secondary_color,
      });
      this.props.searchPersonByDocument(formValues.document, (data) => {
        if (data && data.proDNI) {
          this.setState({ friendData: data });
        } else {
          Toast.show({
            text: I18n.t("transferTicketRRPP.errorUserSearch"),
            position: "bottom",
            type: "danger",
            buttonText: I18n.t("transferTicketRRPP.accept"),
          });
        }
        AppMem.closeAppLoader();
      });
    }
  }

  onPressTransfer(eventId, userDni) {
    const { ticketTypeSelected, selectedTicket, qnty, friendData } = this.state;
    const { getEventsCollection } = this.props;
    const { proName, proLastName } = friendData;
    const { isGift } = this.state;
    const price = isGift ? 0 : selectedTicket.tktPriceProducer * qnty;
    let modalProps = {
      title: I18n.t("transferTicketRRPP.confirmTicketSell", {
        qnty,
        price,
        proName,
        proLastName,
      }),
      type: "warning",
      buttonOk: {
        text: I18n.t("transferTicketRRPP.sell"),
        action: () => {
          AppMem.openAppLoader("bars", {
            text: I18n.t("transferTicketRRPP.loaderText"),
            color: Colors.secondary_color,
          });
          if (ticketTypeSelected && qnty && userDni) {
            this.props.transferRRPPTickets(
              eventId,
              userDni,
              ticketTypeSelected,
              qnty,
              this.state.isGift,
              (response) => {
                AppMem.closeAppLoader();
                if (response.status == "ERROR") {
                  Toast.show({
                    text: I18n.t("transferTicketRRPP.errorSell"),
                    position: "bottom",
                    type: "danger",
                    buttonText: I18n.t("transferTicketRRPP.accept"),
                  });
                } else {
                  getEventsCollection(null, () =>
                    Toast.show({
                      text: I18n.t("transferTicketRRPP.successSell"),
                      position: "bottom",
                      onClose: () => Actions.pop(),
                      type: "success",
                      buttonText: I18n.t("transferTicketRRPP.accept"),
                    })
                  );
                }
              }
            );
          } else {
            Toast.show({
              text: I18n.t("transferTicketRRPP.errorRequiredFields"),
              position: "bottom",
              type: "danger",
              buttonText: I18n.t("transferTicketRRPP.accept"),
            });
          }
        },
      },
      buttonCancel: {
        text: I18n.t("transferTicketRRPP.cancel"),
        action: () => null,
      },
    };
    AppMem.openModal("alert", modalProps);
  }

  onChangeText(a) {
    let { ticket } = this.props;
    let selectedTicketData = ticket.filter((et) => et.id == a)[0]; 

    if (selectedTicketData && selectedTicketData.data){
      this.setState({
        ticketTypeSelected: a,
        qnty: 0,
        selectedTicket: selectedTicketData.data,
      });
    }
  }

  render() {
    let {
      text14Med,
      titleCardContainer,
      container,
      warningText,
      infoText,
      buttonStyle,
      footerContainer,
      buttonText,
      nameText,
    } = styles;
    let { ticket, eventId, eventName, handleSubmit } = this.props;
    let { select, friendData, selectedTicket, isGift } = this.state;
 
    let getTicket = null;
    this.state.ticketTypeSelected
      ? ticket.map((e) => {
          if (e.id == this.state.ticketTypeSelected) {
            getTicket = e;
          }
        })
      : null;
    
    let ticketList = []; 
    ticket.map((each) => { 
      let isActive = moment(new Date()).isBefore(
        Util.timestampToDate(each.data.tktEndDate.seconds)
      );
      if (each.data.tktSellRrppEnable && isActive) {
        ticketList.push({
          label: `${each.data.tktDisplayName} (${I18n.t(
            "transferTicketRRPP.pay"
          )}${isGift ? 0 : each.data.tktPriceProducer})`,
          value: each.id,
        });
      }
    });

    if (selectedTicket) {
      var { tktSoldOut } = selectedTicket;
    } 

    let maxQtBuy = 0;
    if (getTicket && getTicket.data && getTicket.data.tktMaxQtBuy){
        maxQtBuy = getTicket.data.tktMaxQtBuy;
    } 

    return (
      <View style={container}>
        <Header
          title={I18n.t("transferTicketRRPP.sellTickets")}
          leftButtons={[
            {
              onPress: Actions.pop,
              icon: {
                name: "chevron-left",
                type: "MaterialIcons",
                style: {
                  color: "white",
                  fontSize: L.h(23),
                  marginLeft: L.w(8),
                },
                text: Util.isIos() ? "" : "",
                textStyle: {
                  fontSize: L.h(18),
                  color: "white",
                },
              },
            },
          ]}
          titleStyle={{
            fontFamily: Fonts.MEDIUM,
            fontSize: L.h(18),
            color: "white",
            top: Platform.OS === "ios" ? 2 : 0,
          }}
        />
        <KeyboardAwareScrollView
          extraHeight={L.h(80)}
          resetScrollToCoords={{ x: 0, y: 0 }}
          contentContainerStyle={{ flexGrow: 1 }}
          enableOnAndroid
          //keyboardShouldPersistTaps="always"
          scrollEnabled
        >
          <View
            style={{
              flex: 1,
              alignItems: "center",
              marginHorizontal: L.w(22),
            }}
          >
            <Text style={[text14Med, { marginVertical: L.h(16) }]}>
              {I18n.t("transferTicketRRPP.sellTickets2")}
            </Text>
            <View style={titleCardContainer}>
              <Text style={styles.ticketTitle}>
                {/*event.evtTitle.toUpperCase()*/}
                {eventName}
              </Text>
              <View style={[styles.ticketSubTitle]}>
                <Text style={styles.subTitleText}>
                  {I18n.t("transferTicketRRPP.selectTicketTypeToSell")}
                </Text>
                <Field
                  name="rrppSel"
                  itemType="ItemList"
                  type="picker"
                  component={LabelCustomInput}
                  defaultLabel={I18n.t("transferTicketRRPP.selectRrpp")}
                  onChange={this.onChangeText}
                  items={ticketList}
                  labelStyle={{ color: Colors.black_54 }}
                  inputStyle={{ color: Colors.black_54 }}
                  placeholderStyle={{ color: "black" }}
                  withRef={true}
                  maxWidth={"100%"}
                  placeholder={I18n.t("transferTicketRRPP.selectEvent")}
                  borderColor={Colors.primary_color}
                />
              </View>
              {selectedTicket && selectedTicket.tktQt > 0 && !tktSoldOut ? (
                <View>
                  <Text
                    style={[
                      styles.ticketTitle,
                      { color: "black", fontSize: L.h(20) },
                    ]}
                  >
                    {I18n.t("transferTicketRRPP.availables")}{" "}
                    <Text style={{ color: Colors.blueberry }}>
                      {selectedTicket?.tktQt}
                    </Text>
                  </Text>
                  <View
                    style={{
                      paddingVertical: L.h(12),
                      paddingHorizontal: L.h(5),
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: "row",
                      marginTop: L.h(14),
                    }}
                  >
                    <Text style={[nameText, { color: "black" }]}>
                      {" "}
                      {I18n.t("transferTicketRRPP.sellTickets0")}
                    </Text>
                    <TouchableOpacity
                      style={{ padding: L.h(10) }}
                      onPress={() =>
                        this.setState({ isGift: !this.state.isGift })
                      }
                    >
                      <Icon
                        name={isGift ? "check-box" : "check-box-outline-blank"}
                        style={{ color: Colors.trueGreen, fontSize: L.h(35) }}
                      />
                    </TouchableOpacity>
                  </View>
                </View>
              ) : null}
              {tktSoldOut || (selectedTicket && selectedTicket.tktQt <= 0) ? (
                <View style={styles.quantityTicket}>
                  <Text
                    style={[
                      styles.subTitleText,
                      { marginBottom: 5, color: "red" },
                    ]}
                  >
                    {I18n.t("transferTicketRRPP.soldOut")}
                  </Text>
                </View>
              ) : (
                <View style={styles.quantityTicket}>
                  <Text style={[styles.subTitleText, { marginBottom: 5 }]}>
                    {I18n.t("transferTicketRRPP.selectQtity")}
                  </Text>
                  <NumericInput
                    value={this.state.qnty}
                    onChange={(value) => {
                      if (this.state.ticketTypeSelected) {
                        this.setState({ qnty: value });
                      } else {
                        Toast.show({
                          text: I18n.t("transferTicketRRPP.selectTicketType"),
                          position: "bottom",
                          type: "danger",
                          buttonText: I18n.t("transferTicketRRPP.accept"),
                        });
                      }
                    }}
                    valueType="real"
                    rounded
                    minValue={0}
                    initValue={0}
                    type="plus-minus"
                    textColor="#424242"
                    maxValue={maxQtBuy}
                    totalWidth={80}
                    iconStyle={{ color: "white" }}
                    rightButtonBackgroundColor={Colors.primary_color}
                    leftButtonBackgroundColor={Colors.primary_color}
                    borderColor="white"
                  />
                  <View>
                    {this.state.qnty ? (
                      <Text
                        style={[
                          styles.ticketSubTitle,
                          { fontSize: 20, marginTop: 8 },
                        ]}
                      >
                        {I18n.t("transferTicketRRPP.total")}
                        {this.state.isGift
                          ? 0
                          : this.state.selectedTicket.tktPriceProducer *
                            this.state.qnty}
                      </Text>
                    ) : null}
                  </View>
                </View>
              )}
            </View>
            <Text style={warningText}>
              {I18n.t("transferTicketRRPP.attention")}{" "}
              <Text style={{ fontFamily: Fonts.MEDIUM }}>
                {Config.PRODUCER_NAME} {I18n.t("transferTicketRRPP.attention2")}
              </Text>
            </Text>

            <View
              style={{
                width: "100%",
                flexDirection: "row",
                alignItems: "center",
                marginTop: L.h(16),
                justifyContent: "center",
              }}
            >
              <Field
                name="document"
                label={I18n.t("transferTicketRRPP.registeredBuyer")}
                ref="document"
                component={LabelCustomInput}
                validate={[required]}
                onSubmitEditing={handleSubmit(this.onSearchPress.bind(this))}
                returnKeyType="send"
                keyboardType={"numeric"}
                floatingLabelFixed
                {...this.getInputProps()}
              />
              <TouchableOpacity
                style={{
                  padding: L.h(8),
                  borderWidth: 1,
                  borderRadius: 24,
                  borderColor: Colors.secondary_color,
                  width: L.wProm(70),
                  alignItems: "center",
                  justifyContent: "center",
                  marginTop: L.h(16),
                  elevation: 2,
                  backgroundColor: Colors.findButtonSellRRPP,
                  marginLeft: L.w(16),
                }}
                onPress={handleSubmit(this.onSearchPress.bind(this))}
              >
                <Text
                  style={{
                    color: Colors.findButtonSellRRPPColor,
                    fontFamily: Fonts.DREGULAR,
                  }}
                >
                  {I18n.t("transferTicketRRPP.search")}
                </Text>
              </TouchableOpacity>
            </View>

            {friendData ? (
              <Row
                style={{
                  borderTopWidth: 1,
                  borderBottomWidth: 1,
                  alignItems: "center",
                  paddingVertical: L.h(12),
                  paddingHorizontal: L.h(5),
                  borderColor: Colors.borderSellRRPP,
                  backgroundColor: Colors.personBackgroundSellRRPP,
                  marginTop: L.h(14),
                }}
              >
                <Row style={{ alignItems: "center" }}>
                  <Icon
                    name="account-circle"
                    style={{
                      fontSize: L.h(46),
                      color: "rgb(216, 216, 216)",
                      marginRight: L.w(16),
                    }}
                  />
                  <Col>
                    <Text style={nameText}>
                      {friendData.proName} {friendData.proLastName}
                    </Text>
                    <Text style={nameText}>
                      {I18n.t("transferTicketRRPP.dni")} {friendData.proDNI}
                    </Text>
                  </Col>
                </Row>
                <TouchableOpacity
                  style={{ padding: L.h(8) }}
                  onPress={() => this.setState({ select: !select })}
                >
                  <Icon
                    name={select ? "check-box" : "check-box-outline-blank"}
                    style={{ color: Colors.trueGreen, fontSize: L.h(35) }}
                  />
                </TouchableOpacity>
              </Row>
            ) : null}
          </View>
          <View style={footerContainer}>
            <Text
              style={[
                infoText,
                { marginHorizontal: L.w(20), color: Colors.tangerine },
              ]}
            >
              {I18n.t("transferTicketRRPP.ticketSellLabel")}
            </Text>
            <TouchableOpacity
              disabled={!select || !this.state.qnty}
              onPress={() => {
                if (getTicket && getTicket.data && getTicket.data.tktQt < this.state.qnty) {
                  Toast.show({
                    text: I18n.t("transferTicketRRPP.noMoreTickets"),
                    position: "bottom",
                    type: "danger",
                    buttonText: I18n.t("transferTicketRRPP.accept"),
                  });
                } else {
                  this.onPressTransfer(eventId, friendData.proDNI);
                }
              }}
              style={[
                buttonStyle,
                {
                  backgroundColor:
                    select && this.state.qnty
                      ? Colors.trueGreen
                      : Colors.pinkishGrey,
                },
              ]}
            >
              <Text style={buttonText}>
                {I18n.t("transferTicketRRPP.sellTicket").toUpperCase()}
              </Text>
            </TouchableOpacity>
          </View>
        </KeyboardAwareScrollView>
      </View>
    );
  }
}

TransferTicketRRPP.defaultProps = {};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    color: Colors.whiteFour,
    backgroundColor: Colors.backgroundSellRRPP,
  },
  text14Med: {
    color: Colors.introSellRRPP,
    fontSize: L.h(14),
    fontFamily: Fonts.MEDIUM,
  },
  text13: {
    color: Colors.commonDetailText,
    fontSize: L.h(14),
    fontFamily: Fonts.MEDIUM,
  },
  titleCardContainer: {
    borderRadius: 4,
    elevation: 2,
    paddingTop: L.h(19),
    margin: L.hProm(4),
    backgroundColor: "white",
  },
  ticketTitle: {
    color: Colors.eventSellRRPP,
    marginHorizontal: L.w(50),
    textAlign: "center",
    marginBottom: L.h(11),
    fontFamily: Fonts.DBOLD,
    fontSize: L.h(14),
  },
  ticketSubTitle: {
    //backgroundColor: Colors.blueberry,
    paddingVertical: L.h(8),
    paddingHorizontal: L.h(8),
    color: Colors.blueberry,
  },
  subTitleText: {
    marginHorizontal: L.w(20),
    textAlign: "center",
    fontSize: L.h(13),
    color: Colors.steel,
    fontFamily: Fonts.DREGULAR,
  },
  quantityTicket: {
    paddingVertical: L.h(8),
    paddingHorizontal: L.h(8),
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
    alignItems: "center",
  },
  warningText: {
    fontSize: L.h(13),
    color: Colors.warningSellRRPP,
    marginHorizontal: L.w(30),
    textAlign: "center",
    marginTop: L.h(16),
  },
  infoText: {
    fontSize: L.h(13),
    fontFamily: Fonts.MEDIUM,
    color: Colors.redTwo,
    textAlign: "center",
    marginTop: L.h(15),
  },
  buttonStyle: {
    width: screen.width - L.wProm(40),
    height: L.hProm(50),
    elevation: 2,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.pinkishGrey,
    borderRadius: L.wProm(40) / 2,
    marginTop: L.h(20),
    alignSelf: "center",
  },
  footerContainer: {
    //position: "absolute",
    marginBottom: 30,
    left: 0,
    width: "100%",
  },
  buttonText: {
    fontFamily: Fonts.MEDIUM,
    color: Colors.textSellRRPPDetail,
    fontSize: L.h(16),
  },
  nameText: {
    fontSize: L.h(14),
    color: Colors.nameSellRRPP,
    fontFamily: Fonts.MEDIUM,
  },
});

function mapStateToProps(store) {
  return {
    user: store.authReducer.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      searchPersonByDocument,
      onTransferTicket,
      getMyNextEvents,
      transferRRPPTickets,
      getEventsCollection,
    },
    dispatch
  );
}

export default reduxForm({
  form: "transferTicketRRPP",
  destroyOnUnmount: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TransferTicketRRPP)
);
