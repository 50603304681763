import React, { PureComponent } from "react";
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Linking,
} from "react-native";
import { Row } from "native-base";
import Icon from "react-native-vector-icons/MaterialIcons";
import Fonts from "../common/Fonts";
import Colors from "../common/Colors";
import L from "../common/Layout";
import S from "string";
import moment from "moment";
import Util from "../common/Util";
import { showLocation } from "react-native-map-link";
import I18n from "i18next";

export default class TicketInfo extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.onPressLocation = this.onPressLocation.bind(this);
  }

  onPressLocation() {
    let { evtCoordinates } = this.props;
    if (evtCoordinates && evtCoordinates._latitude) {
      if (Util.isIos()) {
        showLocation({
          latitude: evtCoordinates._latitude, //-31.317955022680966,
          longitude: evtCoordinates._longitude, //-64.3267458263385,
          dialogTitle: I18n.t("ticketInfo.dialogTitle"), // optional (default: 'Open in Maps')
          dialogMessage: I18n.t("ticketInfo.dialogMessage"), // optional (default: 'What app would you like to use?')
          cancelText: I18n.t("ticketInfo.cancelText"),
        });
      } else {
        Linking.openURL(
          "google.navigation:q=" +
            evtCoordinates._latitude +
            "," +
            evtCoordinates._longitude
        ).catch((error) => {
          console.log("ERROR  Linking android ### ", error);
        });
      }
    }
  }

  render() {
    let { evtDateTime, evtPlace, textColor, style } = this.props;

    let formatedDate = moment(evtDateTime).format("dddd DD [de] MMMM");
    let formatedHour = moment(evtDateTime).format("HH:mm");
    return (
      <View style={[styles.ticketTopWrapper, style]}>
        <View style={styles.spaceBetween}>
          <View style={styles.alignCenter}>
            <View style={styles.iconDateContainer}>
              <Icon name="today" style={styles.iconStyle} />
            </View>
            <View>
              <Text
                style={[
                  styles.iconTextDay,
                  { color: textColor ? textColor : Colors.textTicketDetail },
                ]}
              >
                {S(formatedDate).capitalize().s}
              </Text>
              <Text
              style={[
                styles.iconText,
                { color: textColor ? textColor : Colors.textTicketDetail },
              ]}
            >
              {formatedHour}
              {I18n.t("ticketInfo.hours")}
            </Text>
            </View>
          </View>
          <TouchableOpacity onPress={this.onPressLocation} style={styles.btnLocation}>
            <Row style={styles.centerEnd}>
                <Icon name="place" style={styles.iconCalendar} />
                <Text style={[styles.iconText, styles.iconTextDecoration]} ellipsizeMode="tail" numberOfLines={1}>
                {evtPlace}
                </Text>
              </Row>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  btnLocation: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    borderRadius: L.h(9),
    paddingLeft: L.h(6),
    paddingRight: L.h(20),
    maxWidth: 120
  },
  centerEnd: { 
    alignItems: "center", 
    justifyContent: "flex-start"
},
  placeRow: {
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: L.h(8),
  },
  iconText: {
    color: Colors.textTicketDetail,
    fontSize: Util.isIos() || Util.isRegular() ? L.h(12) : L.h(12),
    marginLeft: L.w(8),
    fontFamily: Fonts.DREGULAR,
  },
  iconTextDay:{
    color: Colors.textTicketDetail,
    fontSize: Util.isIos() || Util.isRegular() ? L.h(12) : L.h(12),
    marginLeft: L.w(8),
    fontFamily: Fonts.DBOLD
  },
  iconDateContainer:{
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    width: L.h(40),
    height: L.h(40),
    alignItems: "center",
    justifyContent: "center",
    borderRadius: L.h(9)
  },
  iconStyle: {
    fontSize: Util.isIosXGen() ? L.h(20) : L.h(20),
    color: Colors.iconLocationTicketDetail,
  },
  iconLocationContainer:{
    alignItems: "center",
    justifyContent: "center",
  },
  iconCalendar: {
    fontSize: Util.isIosXGen() ? L.h(18) : L.h(18),
    color: Colors.iconTextLocationTextDetail,
  },
  alignCenter: { 
    alignItems: "center", 
    flexDirection: "row"
  },
  spaceBetween: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  ticketTopWrapper: {
    marginHorizontal: L.w(16),
    paddingVertical: L.h(8),
  },
  iconTextDecoration: {
    color: Colors.iconTextLocationTextDetail,
    textDecorationLine: "underline",
    fontSize: Util.isIos() || Util.isRegular() ? L.h(12) : L.h(12),
    marginLeft: L.w(8),
    fontFamily: Fonts.DREGULAR,
  },
});
