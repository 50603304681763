import firebase from "react-native-firebase";
import PaymentHistory from "./PaymentConfig";
import authConfig from "../auth/AuthConfig";
import { NetworkInfo } from "react-native-network-info";
import { Platform } from "react-native";

const pathUserTransactions = authConfig.pathUserTransactions;

export default class PaymentService {
  static listenerEvents(buyData, callback) {
    return new Promise(async (resolve, reject) => {
      try {
        //path, id, subCollection, callback
        let response = await authConfig.FirebaseConnector.listenerWithGrandChildCollection(
          pathUserTransactions,
          firebase.auth().currentUser._user.uid,
          "events",
          buyData.event.evtId,
          "tickets",
          callback
        );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  static getPaymentInformation(paymentId) {
    let token = null;
    return new Promise(async (resolve, reject) => {
      try {
        if (firebase.auth() && firebase.auth().currentUser) {
          await firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((data) => {
              token = data;
            });

          let endpoint = PaymentHistory.endpointGetPreference;
          let body = JSON.stringify({
            paymentId: paymentId,
          });
          console.log("BODY BODY", body);
          console.log("BODY BODY", token);

          let response = await PaymentHistory.APIConnector.post(endpoint, {
            headers: { Authorization: "Bearer " + token },
            body: body,
          });
          if (response.error) {
            reject(response);
          } else {
            resolve(response);
          }
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  static createPreference(total, email, product, rrppId) {
    let token = null;
    const user = firebase.auth().currentUser;
    return new Promise(async (resolve, reject) => {
      let ip = "127.0.0.1";
      if (Platform.OS == "web") ip = "127.0.0.1";
      //WEB99 TODOWEB ver alternativa para web
      else ip = await NetworkInfo.getIPV4Address();
      try {
        if (firebase.auth() && firebase.auth().currentUser) {
          await firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((data) => {
              token = data;
            });

          let endpoint = PaymentHistory.endpointCreatePreference;
          let body = JSON.stringify({
            ip,
            total: total,
            email: email,
            product: product,
            rrppId: rrppId,
            userId: user.uid,
          });
          let response = await PaymentHistory.APIConnector.post(endpoint, {
            headers: { Authorization: "Bearer " + token },
            body: body,
          });

          if (response.error) {
            reject(response);
          } else {
            resolve(response);
          }
        }
      } catch (err) {
        reject(err);
      }
    });
  }
  static createStripePreference(total, email, product, rrppId) {
    let token = null;
    return new Promise(async (resolve, reject) => {
      try {
        if (firebase.auth() && firebase.auth().currentUser) {
          await firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((data) => {
              token = data;
            });

          let endpoint = PaymentHistory.endpointCreateStripePreference;
          let body = JSON.stringify({
            total: total,
            email: email,
            product: product,
            rrppId: rrppId,
            userId: firebase.auth().currentUser._user.uid,
          });

          console.log("body", body);
          let response = await PaymentHistory.APIConnector.post(endpoint, {
            headers: { Authorization: "Bearer " + token },
            body: body,
          });

          if (response.error) {
            reject(response);
          } else {
            resolve(response);
          }
        }
      } catch (err) {
        reject(err);
      }
    });
  }
  static loyalyExchangeBenefit(benefit) {
    let token = null;
    const { pr_client_metadata } = benefit.product[0];
    benefit.product[0].pr_client_metadata = JSON.parse(pr_client_metadata);
    return new Promise(async (resolve, reject) => {
      try {
        if (firebase.auth() && firebase.auth().currentUser) {
          await firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((data) => {
              token = data;
            });

          let endpoint = PaymentHistory.loyalyExchangeBenefit;
          let body = JSON.stringify(benefit);
          let response = await PaymentHistory.APIConnector.post(endpoint, {
            headers: { Authorization: "Bearer " + token },
            body: body,
          });

          if (response.error) {
            reject(response);
          } else {
            resolve(response);
          }
        }
      } catch (err) {
        reject(err);
      }
    });
  }
  static buyTicketWithPoints(ticketData) {
    return new Promise(async (resolve, reject) => {
      try {
        if (firebase.auth() && firebase.auth().currentUser) {
          await firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((data) => {
              token = data;
            });

          let endpoint = PaymentHistory.loyalyExchangeBenefitByWallet;
          let body = JSON.stringify(ticketData);
          console.log("bodybodybody", body);
          let response = await PaymentHistory.APIConnector.post(endpoint, {
            headers: { Authorization: "Bearer " + token },
            body,
          });

          if (response.error) {
            reject(response);
          } else {
            resolve(response);
          }
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  static pay(buyData) {
    let token = null;
    return new Promise(async (resolve, reject) => {
      try {
        if (firebase.auth() && firebase.auth().currentUser) {
          await firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((data) => {
              token = data;
            });

          let endpoint = PaymentHistory.endpointPay;
          let body = JSON.stringify({
            ...buyData,
          });
          let response = await PaymentHistory.APIConnector.post(endpoint, {
            headers: { Authorization: "Bearer " + token },
            body: body,
          });
          if (response.error) {
            reject(response);
          } else {
            resolve(response);
          }
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  static createFreeTickets(total, email, product, rrppId) {
    let token = null;
    const user = firebase.auth().currentUser;
    return new Promise(async (resolve, reject) => {
      let ip = "127.0.0.1";
      if (Platform.OS == "web") ip = "127.0.0.1";
      //WEB99 TODOWEB ver alternativa para web
      else ip = await NetworkInfo.getIPV4Address();
      try {
        if (firebase.auth() && firebase.auth().currentUser) {
          await firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((data) => {
              token = data;
            });

          let endpoint = PaymentHistory.endpointCreateFreeTickets;
          let body = JSON.stringify({
            ip,
            total: total,
            email: email,
            product: product,
            rrppId: rrppId,
            userId: user.uid,
          });

          let response = await PaymentHistory.APIConnector.post(endpoint, {
            headers: { Authorization: "Bearer " + token },
            body: body,
          });

          if (response.error) {
            reject(response);
          } else {
            resolve(response);
          }
        }
      } catch (err) {
        reject(err);
      }
    });
  }
}
