import React from "react";
import Footer from "./Footer";
import Header from "./Header";

const WebAppNav = ({ children, user }) => (
  <>
    <Header user={user} />
    {children}
  </>
);

export default WebAppNav;
