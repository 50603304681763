import React from 'react';
import { Scene, Router, Actions } from 'react-native-router-flux';
import { StatusBar, View, Animated, Platform } from 'react-native';
// Screens
import Home from '../screens/Home';
import ForgotPassword from '../screens/auth/ForgotPassword';
import Login from '../screens/auth/LoginMain';
import Register from '../screens/auth/Register';
import RegisterA from '../screens/auth/RegisterA';
import RegisterB from '../screens/auth/RegisterB';
import RegisterC from '../screens/auth/RegisterC';
import RegisterD from '../screens/auth/RegisterD';
import RegisterE from '../screens/auth/RegisterE';
import RegisterF from '../screens/auth/RegisterF';
import Ticket from '../screens/ticket/ticketDetail';
import TicketResume from '../screens/ticket/ticketResume';
import TicketFinal from '../screens/ticket/ticketFinal';
import ScanDataRegister from '../screens/auth/ScanDataRegister';
import Scanner from '../screens/auth/Scanner';
import ShopMain from '../screens/shop/shopMain';
import StaticsMain from '../screens/producer/StatisticsMain';
import StatisticsDetail from '../screens/producer/StatisticsDetail';
import StatisticsRRPP from '../screens/producer/StatisticsRRPP';
import StatisticsProducer from '../screens/producer/StatisticsProducer';
import QRBarcodeScanner from '../screens/QRBarCodeScanner';
import Camera from '../screens/camera/camera';
import CardTypeSelect from '../screens/ticket/cardTypeSelect';
import Profile from '../screens/profile/ProfileMain';
import EditProfile from '../screens/profile/EditProfile';
import EditPassword from '../screens/profile/EditPassword';
import EditEmail from '../screens/profile/EditEmail';
import MainView from '../screens/MainView';
import ActivateAccount from '../screens/auth/ActivateAccount';
import RNExitApp from 'react-native-exit-app';
import AppMem from '../common/AppMem';
import Pagos360Screen from '../screens/ticket/pagos360Screen';
import StripeScreen from '../screens/ticket/stripeScreen';
import ShopDetail from '../screens/shop/ShopDetail';
import TransferTicket from '../screens/shop/TransferTicket';
import OfflineBarWrapper from '../screens/global/OfflineBarWrapper';
import MainRRPP from '../screens/rrpp/MainRRPP';
import RRPPDetail from '../screens/rrpp/RRPPDetail';
import TransferTicketRRPP from '../screens/rrpp/TransferTicketRRPP';
import Notifications from '../screens/notifications/Notifications';
import ValidatePhone from '../screens/auth/ValidatePhone';
import ValidateCode from '../screens/auth/ValidateCode';
import DetailBenefits from '../screens/benefits/DetailBenefits';
import Redirect from '../screens/web/Redirect';

class RouterComponent extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onBackAndroid = this.onBackAndroid.bind(this);
  }

  onBackAndroid() {
    if (
      Actions.currentScene == '' ||
      Actions.currentScene == 'mainView' ||
      Actions.currentScene == 'login'
    ) {
      if (AppMem.modalRef.isOpen) {
        if (AppMem.modalRef.mustBeOpen) {
          return true;
        }
        AppMem.closeModal();
        return true;
      }
      RNExitApp.exitApp();
      return false;
    }
    if (AppMem.modalRef.isOpen) {
      if (this.props.confirmModalIsOpen) {
        return true;
      }
      if (AppMem.modalRef.mustBeOpen) {
        return true;
      }
      AppMem.closeModal();
      return true;
    }
    if (AppMem.appLoader.isOpen) {
      return true;
    }
    if (Actions.currentScene == 'shopDetail') {
      Actions.reset('mainView', { screenChoose: true });
      return true;
    }
    Actions.pop();
    return true;
  }

  render() {
    console.log(' >>>>>>>>>> rerender en el ');
    return (
      <View style={{ flex: 1 }}>
        {Platform.OS == 'web' ? null : (
          <StatusBar
            translucent
            backgroundColor="rgba(0, 0, 0, 0.2)"
            barStyle="light-content"
          />
        )}
        {Platform.OS == 'web' ? null : <OfflineBarWrapper />}

        <Router backAndroidHandler={this.onBackAndroid}>
          <Scene hideNavBar>
            <Scene key="redirect" component={Redirect} initial />
            <Scene key="qRBarcodeScanner" component={QRBarcodeScanner} />
            <Scene key="login" component={Login} />
            <Scene key="register" component={Register} />
            <Scene key="registerA" component={RegisterA} />
            <Scene key="registerB" component={RegisterB} />
            <Scene key="registerC" component={RegisterC} />
            <Scene key="registerD" component={RegisterD} />
            <Scene key="registerE" component={RegisterE} />
            <Scene key="registerF" component={RegisterF} />
            <Scene key="mainView" component={MainView} />
            <Scene key="cardTypeSelect" component={CardTypeSelect} />
            <Scene key="forgotPassword" component={ForgotPassword} />
            <Scene key="editPassword" component={EditPassword} />
            <Scene key="editEmail" component={EditEmail} />
            <Scene key="pagos360Screen" component={Pagos360Screen} />
            <Scene key="stripeScreen" component={StripeScreen} />
            <Scene key="statisticsDetail" component={StatisticsDetail} />
            <Scene key="staticsMain" component={StaticsMain} />
            <Scene key="statisticsRRPP" component={StatisticsRRPP} />
            <Scene key="statisticsProducer" component={StatisticsProducer} />
            <Scene key="home" component={Home} />
            <Scene key="scanData" component={ScanDataRegister} />
            <Scene key="scanner" component={Scanner} />
            <Scene
              key="shopDetail"
              component={ShopDetail}
              gesturesEnabled={false}
              panHandlers={null}
            />
            <Scene key="validatePhone" component={ValidatePhone} />
            <Scene key="validateCode" component={ValidateCode} />
            <Scene key="notifications" component={Notifications} />
            <Scene key="transferTicket" component={TransferTicket} />
            <Scene key="camera" component={Camera} />
            <Scene key="editProfile" component={EditProfile} />
            <Scene key="profile" component={Profile} />
            <Scene key="ticketDetail" component={Ticket} />
            <Scene key="activateAccount" component={ActivateAccount} />
            <Scene key="ticketResume" component={TicketResume} />
            <Scene key="ticketFinal" component={TicketFinal} />
            <Scene key="shopMain" component={ShopMain} />
            <Scene key="mainRRPP" component={MainRRPP} />
            <Scene key="rrppDetail" component={RRPPDetail} />
            <Scene key="transferTicketRRPP" component={TransferTicketRRPP} />
            <Scene key="detailBenefits" component={DetailBenefits} />
          </Scene>
        </Router>
      </View>
    );
  }
}

export default RouterComponent;
