import PaymentHistory from "../payment/PaymentConfig";
import ProducerConfig from "../producer/ProducerConfig";
import firebase from "react-native-firebase";

export default class ProducerService {
  static eventStats(eventId) {
    let token = null;
    return new Promise(async (resolve, reject) => {
      try {
        if (firebase.auth() && firebase.auth().currentUser) {
          await firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((data) => {
              token = data;
            });
        }
        let endpoint = `${ProducerConfig.endpointGetEventStats}`;
        let response = await PaymentHistory.APIConnector.post(endpoint, {
          headers: { Authorization: "Bearer " + token },
          body: JSON.stringify({ eventId }),
        });
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }
  static directDetailRrpp(eventId) {
    let token = null;
    return new Promise(async (resolve, reject) => {
      try {
        if (firebase.auth() && firebase.auth().currentUser) {
          await firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((data) => {
              token = data;
            });
        }
        let endpoint = `${ProducerConfig.endpointGetDirectDetailRrpp}`;
        let response = await PaymentHistory.APIConnector.post(endpoint, {
          headers: { Authorization: "Bearer " + token },
          body: JSON.stringify({ eventId }),
        });
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }
  static referencedDetailRrpp(eventId) {
    let token = null;
    return new Promise(async (resolve, reject) => {
      try {
        if (firebase.auth() && firebase.auth().currentUser) {
          await firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((data) => {
              token = data;
            });
        }
        let endpoint = `${ProducerConfig.endpointGetReferencedDetailRrpp}`;
        let response = await PaymentHistory.APIConnector.post(endpoint, {
          headers: { Authorization: "Bearer " + token },
          body: JSON.stringify({ eventId }),
        });
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }
  static getProducerDetail(eventId) {
    let token = null;
    return new Promise(async (resolve, reject) => {
      try {
        if (firebase.auth() && firebase.auth().currentUser) {
          await firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((data) => {
              token = data;
            });
        }
        let endpoint = `${ProducerConfig.endpointGetDetailProducer}`;
        let response = await PaymentHistory.APIConnector.post(endpoint, {
          headers: { Authorization: "Bearer " + token },
          body: JSON.stringify({ eventId }),
        });
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  static ticketInfo(eventId, callback) {
    return PaymentHistory.FirebaseConnector.listenerWithSubCollection(
      "0events",
      eventId,
      "evtTicketsType",
      callback
    );
  }

  static rrppTicketInfo(eventId) {
    let token = null;
    return new Promise(async (resolve, reject) => {
      try {
        if (firebase.auth() && firebase.auth().currentUser) {
          await firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((data) => {
              token = data;
            });
        }
        let endpoint = `${
          ProducerConfig.rrppTicketInfoEndpoint
        }?eventId=${eventId}`;
        let response = await PaymentHistory.APIConnector.get(endpoint, {
          headers: { Authorization: "Bearer " + token },
        });
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }
}
