import firebase from "react-native-firebase";
import {Platform} from "react-native"; 
import Config from "react-native-config";
  

if (Platform.OS == 'web'){
    const app = firebase.initializeApp({
      "apiKey": Config.FB_API_KEY,
      "authDomain": Config.FB_AUTH_DOMAIN,
      "databaseURL": Config.FB_DATABASE_URL,
      "projectId": Config.FB_PROJECT_ID,
      "storageBucket": Config.FB_STORAGE_BUCKET,
      "messagingSenderId": Config.FB_MESSAGING_SENDER_ID,
      "appId": Config.FB_APP_ID,
      "measurementId": Config.FB_MEASUREMENT_ID
    });
}
  
// apiKey: "AIzaSyCZm5NA_a6pGWcesU-g2krbsWOCB_x_r3U",
// authDomain: "keops-dev.firebaseapp.com",
// projectId: "keops-dev",
// storageBucket: "keops-dev.appspot.com",
// messagingSenderId: "951815721356",
// appId: "1:951815721356:web:83157c8d37fd4bc0df46a9",
// measurementId: "G-PS8W1EMX37"

// "apiKey": "AIzaSyA0xCcughU8qLxorZFaLiOKXY35YwLU_6A",
//       "authDomain": "leparc-dev.firebaseapp.com",
//       "databaseURL": "https://leparc-dev.firebaseio.com",
//       "projectId": "leparc-dev",
//       "storageBucket": "leparc-dev.appspot.com",
//       "messagingSenderId": "784725468102",
//       "appId": "1:784725468102:web:c6234018b0db5a8b8408c7",
//       "measurementId": "G-K0VLFWKX81"

let firestoreVar = firebase.firestore();
const settings = {timestampsInSnapshots: true};
firestoreVar.settings(settings);

export const firestoreApp = firestoreVar;
export const firebaseValues = firebase;