import React, { Component } from 'react';
import { FormControl, Button } from 'native-base';
import {
  View,
  Dimensions,
  Text,
  Image,
  ImageBackground,
  StyleSheet,
  Keyboard,
  TouchableOpacity,
  Platform,
  ActivityIndicator,
} from 'react-native';
import _ from 'lodash';
import { Actions } from 'react-native-router-flux';
import { actLogout } from '../../actions/AuthActions';
import firebase from 'react-native-firebase';
import IconMA from 'react-native-vector-icons/MaterialIcons';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm, reset } from 'redux-form';
import * as Animatable from 'react-native-animatable';
import I18n from 'i18next';
import { AccessToken, LoginManager } from 'react-native-fbsdk';
import appleAuth, {
  AppleButton,
  AppleAuthRequestOperation,
  AppleAuthRequestScope,
} from '@invertase/react-native-apple-authentication';
import { GoogleSignin } from '@react-native-google-signin/google-signin';
import Connector from '../../provider/base/Connector';
import ErrorManager from '../../common/ErrorManager';
import {
  actLogin,
  splash,
  actValidateToken,
  listenerToMyProfile,
  getUserData,
  setAnonymousMode,
  actSetClaims,
} from '../../actions';
import Util from '../../common/Util';
import AppMem from '../../common/AppMem';
import L from '../../common/Layout';
import Colors from '../../common/Colors';
import LabelCustomInput from '../../components/LabelCustomInput';
import { required, validateEmail, password } from '../../common/Validations';
import MainButton from '../../components/MainButton';
import Fonts from '../../common/Fonts';
import AppAsyncStorage from '../../common/AppAsyncStorage';
import Config from 'react-native-config';
import Footer from '../../components/web/Footer';
import toast from 'react-hot-toast';

let screen = Dimensions.get('window');

const slideInUp = {
  from: {
    translateY: L.h(0),
  },
  to: {
    translateY: Util.isIosXGen() ? -240 : L.h - 260,
  },
};
GoogleSignin.configure({
  webClientId: Config.GOOGLE_WEB_CLIENT,
});
class LoginIndex extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
    console.log('INICIALIZA EL PRIMERO');
    this.focusNext = this.focusNext.bind(this);
    this.onSplashEnds = this.onSplashEnds.bind(this);
    this.onAppleButtonPress = this.onAppleButtonPress.bind(this);
    this.onPressLoginWFacebook = this.onPressLoginWFacebook.bind(this);
    this.onPressAnonymousMode = this.onPressAnonymousMode.bind(this);
    this.successCallback = this.successCallback.bind(this);
    this.successCallbackAppleID = this.successCallbackAppleID.bind(this);
    this.googleLogin = this.googleLogin.bind(this);
    this.socialSuccessCallback = this.socialSuccessCallback.bind(this);
  }

  componentWillMount() {
    //firebase.auth().signOut();
    // AppAsyncStorage.removeItem("phoneValidate");
    //  this.onSplashEnds();
  }

  state = {
    showingSplash: false,
  };

  onPressAnonymousMode() {
    this.props.setAnonymousMode(true);
    Actions.reset('home');
  }

  async googleLogin() {
    AppMem.openAppLoader('bars', {
      text: I18n.t('loginMain.loaderText'),
      color: Colors.secondary_color,
    });
    // Get the users ID token
    try {
      const { idToken } = await GoogleSignin.signIn();
      // Create a Google credential with the token
      const googleCredential = firebase.auth.GoogleAuthProvider.credential(
        idToken
      );

      // Sign-in the user with the credential
      return firebase.auth().signInWithCredential(googleCredential);
    } catch (e) {
      console.log(' >>>>>>>>>> ERROR INGRESAR CON GOOGLE', e);
      AppMem.closeAppLoader();
    }
  }

  async onPressLoginWFacebook() {
    try {
      const result = await LoginManager.logInWithPermissions([
        'public_profile',
        'email',
      ]);
      if (result.isCancelled) {
        // handle this however suites the flow of your app
        throw new Error(I18n.t('loginMain.userCancelledErrorText'));
      }

      console.log(
        `Login success with permissions: ${result.grantedPermissions.toString()}`
      );

      // get the access token
      const data = await AccessToken.getCurrentAccessToken();

      if (!data) {
        // handle this however suites the flow of your app
        throw new Error(I18n.t('loginMain.userCancelledWrongAccessText'));
      }
      // create a new firebase credential with the token
      const credential = firebase.auth.FacebookAuthProvider.credential(
        data.accessToken
      );
      // // login with credential
      AppMem.openAppLoader('bars', {
        text: I18n.t('loginMain.loaderText'),
        color: Colors.secondary_color,
      });
      const firebaseUserCredential = await firebase
        .auth()
        .signInWithCredential(credential);
      console.warn(JSON.stringify(firebaseUserCredential.user.toJSON()));
    } catch (e) {
      console.log(' >>>>>>>>>> ERROR INGRESAR CON FACEBOOK', e);
      // let objError = Object.assign({}, e);
      // objError.code = "448";
      //firebase.auth().signOut();
      AppMem.closeAppLoader();
      LoginManager.logOut();
      if (e.code === 'auth/account-exists-with-different-credential') {
        ErrorManager.message(e);
      }
    }
  }

  onLoginPress(formValues) {
    //WEB Keyboard.dismiss();
    this.setState({ loading: true });
    if (formValues.user && formValues.user.email && formValues.user.password) {
      let trimEmail = formValues.user.email.trim();
      let form = {
        email: trimEmail,
        password: formValues.user.password,
      };
      // AppMem.openAppLoader("bars", {
      //   text: I18n.t("loginMain.loaderText"),
      //   color: Colors.secondary_color,
      // });

      this.props.actLogin(
        form,
        this.onLoginSuccess.bind(this),
        this.onLoginError.bind(this)
      );
    }
  }

  onLoginSuccess(user) {
    // if (user._user.emailVerified) {
    //   // Actions.reset("home");
    //   // this.props.listenerToMyProfile(user._user.uid);
    // } else {
    //   Actions.replace("activateAccount");
    // }
    this.setState({ loading: false });
    // Actions.reset('home');
    //  this.props.dispatch(reset("login"));
  }

  focusNext() {
    this.refs.password &&
      this.refs.password.getRenderedComponent().executeFocus();
  }

  onLoginError(error) {
    let message = '';
    this.setState({ loading: false });
    AppMem.closeAppLoader();
    if (error) {
      switch (error.code) {
        case 'auth/wrong-password':
          message = I18n.t('login.wrongPassword');
          break;
        case 'auth/user-not-found':
          message = I18n.t('login.userNotRegistered');
          break;
        case 'auth/invalid-email':
          message = I18n.t('login.invalidEmail');
          break;
        case 'auth/weak-password':
          message = I18n.t('login.weakPassword');
          break;

        case 'auth/email-already-in-use':
          message = I18n.t('login.emailInUse');
          break;
        case 'auth/network-request-failed':
          message = I18n.t('login.verifyYourConection');
          break;
        default:
          message = I18n.t('login.verifyYourConection');
          break;
      }
      toast.error(message, { duration: 10000 });
    } else {
      toast.error('Error al iniciar sesión', { duration: 10000 });
    }
    // let modalProps = {
    //   title: message,
    //   type: 'error',
    //   buttonOk: { text: 'Ok', action: () => null },
    // };
    // AppMem.openModal('alert', modalProps);
    // toast.error(message, { duration: 10000 });
  }

  async onAppleButtonPress() {
    // 1). start a apple sign-in request
    const appleAuthRequestResponse = await appleAuth.performRequest({
      requestedOperation: AppleAuthRequestOperation.LOGIN,
      requestedScopes: [
        AppleAuthRequestScope.EMAIL,
        AppleAuthRequestScope.FULL_NAME,
      ],
    });

    this.setState({ appleAuthRequestResponse });

    // 2). if the request was successful, extract the token and nonce
    const { identityToken, nonce } = appleAuthRequestResponse;
    // console.log("************** 1", identityToken);
    // console.log("************** 2", nonce);

    // can be null in some scenarios
    if (identityToken) {
      // 3). create a Firebase `AppleAuthProvider` credential
      const appleCredential = firebase.auth.AppleAuthProvider.credential(
        identityToken,
        nonce
      );
      //console.log("************** 3", appleCredential);

      // 4). use the created `AppleAuthProvider` credential to start a Firebase auth request,
      //     in this example `signInWithCredential` is used, but you could also call `linkWithCredential`
      //     to link the account to an existing user
      const userCredential = await firebase
        .auth()
        .signInWithCredential(appleCredential);

      //console.log("************** 4", userCredential);
      // user is now signed in, any Firebase `onAuthStateChanged` listeners you have will trigger
      console.warn(
        `Firebase authenticated via Apple, UID: ${userCredential.user.uid}`
      );
    } else {
      // handle this - retry?
    }
  }

  onAnimationEnd() {
    debugger;
    this.setState({ showingSplash: false });
  }

  onSplashEnds() {
    //WEB99 if (true) return;
    firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        if (Platform.OS == 'web') user._user = user; // En Web el usuario tiene otra estructura
        console.log('EL USUARIO onSplashEnds', user);
        firebase
          .auth()
          .currentUser.getIdTokenResult()
          .then(idTokenResult => {
            let tokenHeader = 'Bearer ';
            tokenHeader += idTokenResult.token;
            Connector.defaultHeaders.Authorization = tokenHeader;
            this.setClaims();
            this.props.setAnonymousMode(false); // Si se logueo un usuario, no esta en modo anonimo
            if (
              user._user.providerData[0].providerId === 'facebook.com' ||
              user._user.providerData[0].providerId === 'google.com' ||
              user._user.providerData[0].providerId === 'apple.com'
            ) {
              this.props.getUserData(user._user.uid, () =>
                this.socialSuccessCallback(
                  user._user.providerData[0].providerId === 'apple.com'
                )
              );
              this.props.listenerToMyProfile(user._user.uid);
            } else if (user._user.emailVerified) {
              console.log(' >>>>>>>>>> email verified ', user._user);
              let emailVerified = true;
              let socialMedia = false;
              this.props.getUserData(user._user.uid, () =>
                this.validatePhone(socialMedia, emailVerified)
              );
              this.props.listenerToMyProfile(user._user.uid);

              //Actions.home();
            } else {
              let emailVerified = false;
              let socialMedia = false;
              this.validatePhone(socialMedia, emailVerified);
            }
          });
      } else {
        this.props.splash();
      }
    });
  }

  validatePhone(socialMedia, emailVerified) {
    AppAsyncStorage.getItem('phoneValidate').then(res => {
      console.log('phone validate', res);
      //console.log("PHONE VALIDATE ACTION", res, socialMedia);
      AppMem.closeAppLoader();
      if (!emailVerified) {
        Actions.replace('registerF', { socialMedia });
      } else if (res == 'ok') {
        this.successCallback();
      } else {
        Actions.replace('validatePhone', {
          socialMedia,
          emailVerified,
        });
      }
    });
  }

  setClaims() {
    this.props.actSetClaims();
  }

  successCallback() {
    if (Platform.OS == 'web') {
      Actions.reset('home');
    }
    //WEB99 if (this.props.isConnected) Actions.reset("home");
    // Se encuentra desconectado, va a mis compras.
    //WEB99 else Actions.reset("home", { screenChoose: true });
  }

  async socialSuccessCallback(isAppleAuth) {
    let { user } = this.props;
    setTimeout(() => {
      AppMem.closeAppLoader();
    }, 1000);
    if (isAppleAuth) {
      /*
      TODO Removi esta logica del onAppleAuthAutentication y la traje para aca por que cuando
      el provider de firebase crea las credenciales, automaticamente se triggerea este onAuthStateChange
      y no permite darnos tiempo de hacer el update del profile de usuario, lo que genera que en el step siguiente
      la primera vez que ingresamos el name y el lastname de firebase esten undefined.
      */
      const { appleAuthRequestResponse } = this.state;
      await firebase.auth().currentUser.updateProfile({
        displayName: `${_.get(
          appleAuthRequestResponse,
          'fullName.givenName',
          ''
        )} ${_.get(appleAuthRequestResponse, 'fullName.familyName', '')}`,
      });

      // console.log("************** 5", firebase.auth().currentUser);
    }
    AppAsyncStorage.getItem('phoneValidate').then(res => {
      // console.log("SOCIAL SUCCESS", res);
      if (this.props.isConnected) {
        if (user && res == 'ok') {
          //&& user.elDNI
          Actions.reset('home');
        } else if (!user) {
          //this.validatePhone(true);
          Actions.replace('registerC', { socialMedia: true, isAppleAuth });
        } else {
          Actions.replace('validatePhone', { socialMedia: true, isAppleAuth });
        }
      } else {
        // Se encuentra desconectado, va a mis compras.
        Actions.reset('home', { screenChoose: true });
      }
    });
  }

  successCallbackAppleID(iosFaceId, email) {
    if (this.props.isConnected) {
      let user = this.props.user && this.props.user.proName;
      if (iosFaceId && !user) {
        Actions.push('registerA', { iosFaceID: true, iosEmail: email });
      } else {
        Actions.reset('home');
      }
    }
    // Se encuentra desconectado, va a mis compras.
    else {
      Actions.reset('home', { screenChoose: true });
    }
  }

  renderSplash() {
    return (
      <View style={{ flex: 1 }}>
        <Animatable.Image
          ref="imgLogo"
          source={require('../../common/assets/logo.png')}
          resizeMode="contain"
          onAnimationEnd={this.onSplashEnds}
          delay={1000}
          animation={slideInUp}
          duration={1000}
          style={[localStyle.imgLogo]}
        />
        {this.props.showingSplash ? (
          <View style={localStyle.poweredLogo}>
            <Image
              source={require('../../common/assets/poweredQuickpass.png')}
            />
          </View>
        ) : null}
      </View>
    );
  }

  getInputProps() {
    let props = {
      borderColor: Colors.loginFieldBorderColor,
      labelStyle: { color: Colors.loginFieldLabelColor },
      inputStyle: { color: Colors.loginFieldTextColor },
      errorIconStyle: { color: Colors.loginErrorColor },
      borderBottomColor: Colors.loginFieldBorderColor,
      blurOnSubmit: false,
      withRef: true,
      autoCapitalize: 'none',
    };
    return props;
  }

  render() {
    const { handleSubmit, register } = this.props;
    const { loading } = this.state;
    //WEB lo llamamos en WEB para que ejecute la navegacion si es que se logueo
    // if (true) return <Loader />;
    return (
      <div style={{ backgroundColor: 'rgb(34, 34, 34)' }}>
        <section
          class="account-section bg_img"
          data-background="./assets/images/account/account-bg.jpg"
        >
          {/* <ImageBackground
          source={require("../../common/assets/account-bg.jpg")}
          style={{ flex: 1 }}
          resizeMode="cover"
        > */}
          <div class="container">
            <div class="padding-top padding-bottom">
              <div class="account-area">
                <div class="section-header-3">
                  <h2 class="title">Inicia sesión</h2>
                  <span class="cate">para adquirir tus tickets</span>
                </div>
                {/*
                    <form class="account-form">
                        <div class="form-group">
                            <label for="email2">Email<span>*</span></label>
                            <input type="text" placeholder="Enter Your Email" id="email2" required/>
                        </div>
                        <div class="form-group">
                            <label for="pass3">Password<span>*</span></label>
                            <input type="password" placeholder="Password" id="pass3" required/>
                        </div>
                        <div class="form-group checkgroup">
                            <input type="checkbox" id="bal2" required checked/>
                            <label for="bal2">remember password</label>
                            <a  class="forget-pass">Forget Password</a>
                        </div>
                        <div class="form-group text-center">
                            <input type="submit" value="log in"/>
                        </div>
                    </form>
                    <div class="option">
                        Don't have an account? <a href="sign-up.html">sign up now</a>
                    </div>
                    */}

                {loading ? (
                  <ActivityIndicator
                    size={'large'}
                    style={{ alignSelf: 'center' }}
                  />
                ) : (
                  <View style={localStyle.formContainer}>
                    <FormControl style={localStyle.form}>
                      <Field
                        name="user.email"
                        label="Email"
                        ref="email"
                        trimWhiteSpace
                        component={LabelCustomInput}
                        validate={[required, validateEmail]}
                        returnKeyType="next"
                        onSubmitEditing={this.focusNext}
                        {...this.getInputProps()}
                      />
                      <Field
                        name="user.password"
                        label="Password"
                        type="password"
                        ref="password"
                        validate={[required, password]}
                        component={LabelCustomInput}
                        secureTextEntry
                        trimWhiteSpace
                        onSubmitEditing={handleSubmit(
                          this.onLoginPress.bind(this)
                        )}
                        returnKeyType="send"
                        {...this.getInputProps()}
                      />
                    </FormControl>
                    <View style={localStyle.subButtons}>
                      {register ? null : <View />}
                      <TouchableOpacity
                        transparent
                        onPress={() => Actions.forgotPassword()}
                      >
                        <Text style={localStyle.forgotmypassText}>
                          {I18n.t('login.forgotMyPass')}
                        </Text>
                      </TouchableOpacity>
                    </View>
                    <View style={localStyle.buttonContainer}>
                      <input
                        type="submit"
                        onClick={handleSubmit(this.onLoginPress.bind(this))}
                        class="custom-button"
                        value={I18n.t('login.signIn')}
                      />

                      {/* <MainButton
                        style={localStyle.bottom10}
                        text={
                          Util.isIos()
                            ? I18n.t("login.signIn")
                            : I18n.t("login.signIn").toUpperCase()
                        }
                        onPress={handleSubmit(this.onLoginPress.bind(this))}
                      /> */}
                      {register ? (
                        <input
                          type="submit"
                          onClick={() => Actions.reset('register')}
                          class="custom-button transparent"
                          value={I18n.t('login.signUp')}
                          style={{ marginTop: 10 }}
                        />
                      ) : (
                        // <MainButton
                        //   secondary
                        //   style={localStyle.bottom10}
                        //   text={
                        //     Util.isIos()
                        //       ? I18n.t("login.signUp")
                        //       : I18n.t("login.signUp").toUpperCase()
                        //   }
                        //   onPress={() => Actions.reset("register")}
                        // />
                        <View />
                      )}
                    </View>
                  </View>
                )}
              </div>
            </div>
          </div>
          {/* </ImageBackground> */}
        </section>
        <Footer />
      </div>
    );
  }

  render2() {
    const { handleSubmit, register } = this.props;

    return (
      <View style={localStyle.mainContainer}>
        {/* {this.props.showingSplash ? (
            this.renderSplash()
          ) : ( */}
        <Image
          source={require('../../common/assets/background_login.jpg')}
          style={localStyle.linearGradient}
          resizeMode="cover"
        />
        <View style={localStyle.content}>
          <View style={localStyle.imgContainer}>{this.renderSplash()}</View>
          {/* <View style={localStyle.container} /> */}
          {this.props.showingSplash && Platform.OS != 'web' ? null : (
            <View style={localStyle.formContainer}>
              <FormControl style={localStyle.form}>
                <Field
                  name="user.email"
                  label="Email"
                  ref="email"
                  trimWhiteSpace
                  component={LabelCustomInput}
                  validate={[required, validateEmail]}
                  returnKeyType="next"
                  onSubmitEditing={this.focusNext}
                  {...this.getInputProps()}
                />
                <Field
                  name="user.password"
                  label="Password"
                  type="password"
                  ref="password"
                  validate={[required, password]}
                  component={LabelCustomInput}
                  secureTextEntry
                  trimWhiteSpace
                  onSubmitEditing={handleSubmit(this.onLoginPress.bind(this))}
                  returnKeyType="send"
                  {...this.getInputProps()}
                />
              </FormControl>
              <View style={localStyle.subButtons}>
                {register ? null : <View />}
                <Button transparent onPress={() => Actions.forgotPassword()}>
                  <Text style={localStyle.forgotmypassText}>
                    {I18n.t('login.forgotMyPass')}
                  </Text>
                </Button>
              </View>
              <View style={localStyle.buttonContainer}>
                <MainButton
                  style={localStyle.bottom10}
                  text={
                    Util.isIos()
                      ? I18n.t('login.signIn')
                      : I18n.t('login.signIn').toUpperCase()
                  }
                  onPress={handleSubmit(this.onLoginPress.bind(this))}
                />
                {register ? (
                  <MainButton
                    secondary
                    style={localStyle.bottom10}
                    text={
                      Util.isIos()
                        ? I18n.t('login.signUp')
                        : I18n.t('login.signUp').toUpperCase()
                    }
                    onPress={() => Actions.registerA()}
                  />
                ) : (
                  <View />
                )}
              </View>
              <Text style={localStyle.loginHint}>
                ---- {I18n.t('loginMain.enterWith')} ----
              </Text>
              <View style={localStyle.formContainerTwo}>
                <TouchableOpacity
                  style={localStyle.facebookLogin}
                  onPress={this.onPressLoginWFacebook}
                >
                  <Image
                    source={require('../../common/assets/facebookIcon.png')}
                    resizeMode="contain"
                    style={localStyle.networkIcon}
                  />
                  <Text style={localStyle.btnText}>
                    {I18n.t('loginMain.continueWithFacebook')}
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={localStyle.facebookLoginTwo}
                  onPress={this.googleLogin}
                >
                  <Image
                    source={require('../../common/assets/logoGoogleg48Dp.png')}
                    resizeMode="contain"
                    style={localStyle.networkIconTwo}
                  />
                  <Text style={localStyle.btnTextTwo}>
                    {I18n.t('loginMain.continueWithGoogle')}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
          )}
        </View>
        {this.props.showingSplash ? null : (
          <TouchableOpacity
            style={localStyle.omitContainer}
            onPress={this.onPressAnonymousMode}
          >
            <Text style={localStyle.omitText}>{I18n.t('loginMain.omit')}</Text>
            <IconMA
              type="MaterialIcons"
              name="chevron-right"
              style={localStyle.iconRight}
            />
          </TouchableOpacity>
        )}
      </View>
    );
  }
}

LoginIndex.defaultProps = {
  register: true,
};

const localStyle = StyleSheet.create({
  mainContainer: {
    flex: 1,
    backgroundColor: Colors.backgroundLoginMain,
  },
  animatableImg: {
    width: null,
    marginTop: L.h(220),
    height: L.hProm(50),
  },
  buttonContainer: {
    paddingHorizontal: 20,
    textAlign: 'center',
  },
  bottom10: {
    marginBottom: 10,
    height: 60,
  },
  buttonSignIn: {
    backgroundColor: 'white',
    width: '100%',
    alignSelf: 'center',
    height: L.hProm(25),
    marginTop: L.h(6),
    marginTop: L.h(15),
    marginBottom: L.h(15),
  },
  buttonSignIntext: {
    fontSize: L.h(14),
    color: Colors.black_87,
    //fontFamily: Fonts.MEDIUM
  },
  container: {
    // flex: screen.height > 700 ? 0.4 : 0.8,
  },
  initialView: {
    flex: 1,
    backgroundColor: 'transparent',
    flexDirection: 'row',
    // marginTop: L.h(20),
  },
  content: {
    // alignItems: "center",
    // justifyContent: "center",
    // flex: 1
    flexDirection: 'row',
    paddingTop: 90,
  },
  socialButtonLogin: {
    backgroundColor: Colors.exolganBlue,
    elevation: 0,
    height: L.hProm(48),
    width: L.wProm(48),
    borderWidth: 1,
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  networkIcon: {
    //marginLeft: L.w(8)
    // marginLeft: 32,
    width: 23,
    height: 23,
  },
  networkIconTwo: {
    width: 18,
    height: 18,
    // marginLeft: 34,
  },
  formContainer: {
    // width: "50%",
  },
  formContainerTwo: {
    width: '50%',
    flexDirection: 'row',
  },
  btnText: {
    fontFamily: Fonts.DREGULAR,
    fontSize: 16,
    letterSpacing: -0.36,
    color: '#ffffff',
    marginLeft: 16,
  },
  btnTextTwo: {
    fontSize: 16,
    letterSpacing: -0.36,
    color: 'rgba(0, 0, 0, 0.54)',
    fontFamily: Fonts.DREGULAR,
    marginLeft: 16,
  },
  form: {
    // marginHorizontal: L.w(24),
    borderColor: 'red',
  },
  forgotmypassText: {
    fontSize: 14,
    color: Colors.loginForgotPassText,
    fontWeight: "500",
    // fontFamily: Fonts.MEDIUM,
  },
  facebookLogin: {
    width: '100%',
    // height: 44,
    borderRadius: 22,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 13,
    backgroundColor: 'rgb(66, 103, 178)',
  },
  facebookLoginTwo: {
    width: '100%',
    // height: 44,
    borderRadius: 22,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: 13,
    backgroundColor: 'white',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#e6e6e6',
  },
  imgContainer: {
    // flex: 1,
    //alignItems: "flex-end",
    justifyContent: 'center',
    // position: "absolute",
    // left: 0,
    // top: L.h(340),
    // width: "100%",
  },
  imgLogo: {
    width: L.wProm(170),
    height: L.hProm(61),
    alignSelf: 'center',
  },
  linearGradient: {
    // flex: 1,
    // top: 0,
    // left: 0,
    // right: 0,
  },
  loginHint: {
    fontSize: 14,
    marginVertical: 16,
    fontFamily: Fonts.DREGULAR,
    color: Colors.loginHint,
  },
  subButtons: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    alignSelf: 'center',
    paddingBottom: 10,
    marginTop: L.h(15),
    marginBottom: L.h(15),
  },
  omitContainer: {
    position: 'absolute',
    top: Util.isIosXGen() ? 48 : 30,
    right: 0,
    padding: 10,
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'row',
  },
  omitText: {
    color: Colors.omitColor,
    fontSize: 20,
    fontFamily: Fonts.DREGULAR,
    top: Util.isIosXGen() ? 0 : -L.h(2),
  },
  iconRight: {
    fontSize: L.h(30),
    color: Colors.omitColor,
  },
  poweredLogo: {
    justifyContent: 'center',
    alignItems: 'center',
    height: L.h(300),
  },
});

function mapStateToProps(store) {
  return {
    showingSplash: store.authReducer.showingSplash,
    tokenOK: store.authReducer.tokenOK,
    isConnected: store.appReducer.isConnected,
    user: store.authReducer.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actLogin,
      splash,
      actValidateToken,
      getUserData,
      listenerToMyProfile,
      setAnonymousMode,
      actSetClaims,
      actLogout,
    },
    dispatch
  );
}

export default reduxForm({
  form: 'login',
  destroyOnUnmount: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(LoginIndex)
);
