import React, { Component } from 'react';
import { FormControl, Toast } from 'native-base'; //WEB99 Content, Form
import Icon from 'react-native-vector-icons/Ionicons';
import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Keyboard,
  ImageBackground,
  Dimensions,
} from 'react-native';
import { Actions } from 'react-native-router-flux';
import LabelCustomInput from '../../components/LabelCustomInput';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import L from '../../common/Layout';
import Colors from '../../common/Colors';
import Fonts from '../../common/Fonts';
import I18n from 'i18next';
import Util from '../../common/Util';
import AppMem from '../../common/AppMem';
import moment from 'moment';
import ErrorManager from '../../common/ErrorManager';
import {
  required,
  typeDisplayName,
  validateEmail,
} from '../../common/Validations';
import { actLogout, actVerifyDni } from '../../actions/AuthActions';
//import { actVerifyDni } from "../../actions";
import VTHeader from '../../components/VTHeader';
import RegisterConstants from './RegisterConstants';
import Constants from '../../common/Constants';
//import PressInput from "../../components/PressInput";
import firebase from 'react-native-firebase';
import { AccessToken } from 'react-native-fbsdk';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import { GoogleSignin } from '@react-native-google-signin/google-signin';
import _ from 'lodash';
import UserExistPopUp from '../../components/UserExistPopUp';
let screen = Dimensions.get('window');
//import VTTermsModal from "../../components/modals/VTTermsModal";
const BG_IMG = require('../../common/assets/background_login.jpg');
import Config from 'react-native-config';

let FORMAT_DATE = Config.TENANT_HAS_BIRTHDATE_MIGRATED
  ? 'DD-MM-YYYY'
  : 'DD [de] MMMM YYYY';

class RegisterD extends React.PureComponent {
  constructor(props) {
    super(props);
    this.inputs = {};
    //this.onPressForward = this.onPressForward.bind(this);
    this.createSocialCallback = this.createSocialCallback.bind(this);
    this.reAuth = this.reAuth.bind(this);
    this.onSelectCity = this.onSelectCity.bind(this);
    this.verifyDni = this.verifyDni.bind(this);
    this.callback = this.callback.bind(this);
  }

  // componentWillMount() {
  //   let {
  //     socialMedia,
  //     verifiedUser,
  //     dispatch,
  //     docsList,
  //     scanData
  //   } = this.props;
  //   let auth = firebase.auth();
  //   if (socialMedia && auth.currentUser && auth.currentUser._user) {
  //     let user = auth.currentUser && auth.currentUser._user;
  //     let splitName = Util.splitFullName(user.displayName);
  //     const name = verifiedUser ? scanData.name : splitName.name;
  //     const lastname = verifiedUser ? scanData.lastname : splitName.lastname;
  //     dispatch(change("register", "name", Util.autoCapitalize(name)));
  //     dispatch(change("register", "lastname", Util.autoCapitalize(lastname)));
  //     if (user.photoURL)
  //       dispatch(change("register", "photo_url", user.photoURL));
  //     if (user.email) dispatch(change("register", "email", user.email));

  //     if (verifiedUser) {
  //       dispatch(change("register", "gender", scanData.gender === "M" ? 2 : 1));
  //       dispatch(
  //         change(
  //           "register",
  //           "doc_type_id",
  //           _.find(docsList, doc => {
  //             return doc.value === 1;
  //           }).value
  //         )
  //       );
  //       dispatch(change("register", "doc_country_id", Constants.CC_ARGENTINA));
  //       dispatch(change("register", "doc_country_id_name", "Argentina"));
  //       dispatch(change("register", "doc_number", scanData.doc_number));
  //     }
  //   }
  // }

  renderSocialMediaForm() {
    let { socialMedia } = this.props;
    let auth = firebase.auth();
    let user = auth.currentUser && auth.currentUser._user;
    let mediaForm =
      auth.currentUser && auth.currentUser._user ? (
        <View>
          <Field
            name="username"
            label={I18n.t('global.username')}
            ref={input => {
              this.inputs.username = input;
            }}
            hint="Sin espacios ni caracteres especiales*"
            hintStyle={{ color: 'white' }}
            component={LabelCustomInput}
            validate={[required]}
            returnKeyType={'next'}
            autoCapitalize="none"
            onSubmitEditing={() =>
              this.focusNext(!user.email ? 'email' : 'doc_type_id')
            }
            //onSubmitEditing={Keyboard.dismiss()}
            {...this.getInputProps()}
          />
          {!user.email ? (
            <Field
              name="email"
              label={I18n.t('global.email')}
              ref={input => {
                this.inputs.email = input;
              }}
              trimWhiteSpace={true}
              component={LabelCustomInput}
              validate={[required, validateEmail]}
              returnKeyType={'next'}
              //onSubmitEditing={() => this.focusNext("password")}
              {...this.getInputProps()}
            />
          ) : null}
        </View>
      ) : null;
    return socialMedia ? mediaForm : null;
  }

  getInputProps() {
    let props = {
      borderColor: Colors.registerFieldBorderColor,
      labelStyle: { color: Colors.registerFieldColor },
      inputStyle: { color: Colors.registerTextColor },
      errorIconStyle: { color: Colors.registerErrorColor },
      borderBottomColor: Colors.registerBorderColor,
      blurOnSubmit: false,
      withRef: true,
      autoCapitalize: 'none',
      topMargin: L.h(20),
    };
    return props;
  }

  focusNext(key, type) {
    if (!this.props.registerValues[key]) {
      // verifica que si el siguiente input tiene un dato
      // si no tiene dato hace el next
      this.inputs && this.inputs[key].getRenderedComponent().executeFocus();
    } else {
      // si lo tiene cierra el teclado y no hace el next
      Keyboard && Keyboard.dismiss();
    }
  }

  // onPressForward(socialData) {
  //   let {
  //     socialMedia,
  //     actCreateSocialAccount,
  //     registerValues,
  //     verifiedUser
  //   } = this.props;

  //   if (registerValues && !registerValues.doc_country_id) {
  //     Toast.show({
  //       text: "Seleccione el País de su documento",
  //       buttonText: "OK",
  //       position: "bottom",
  //       type: "danger"
  //     });
  //     return null;
  //   }
  //   if (socialMedia) {
  //     this.refs.modalTerms.open({
  //       onPressAccept: () => {
  //         this.refs.modalTerms.close();
  //         AppMem.openAppLoader("customLoader", {
  //           text: "Creando Cuenta",
  //           color: Colors.cerulean
  //         });
  //         actCreateSocialAccount(
  //           { ...socialData, verifiedUser },
  //           this.createSocialCallback
  //         );
  //       },
  //       onPressCancel: () => {
  //         this.refs.modalTerms.close();
  //       },
  //       termsUrl: _.get(this.props, "termsAndConditions")
  //     });
  //   } else {
  //     Actions.push("registerE");
  //   }
  // }

  async createSocialCallback() {
    let user = await firebase.auth().currentUser;
    // create a new firebase credential with the token
    let userProviderGoogle =
      user && user._user.providerData[0].providerId === 'google.com';
    let userProviderFacebook =
      user && user._user.providerData[0].providerId === 'facebook.com';
    let userProviderApple =
      user && user._user.providerData[0].providerId === 'apple.com';
    if (userProviderGoogle) {
      GoogleSignin.getTokens().then(token => {
        let GGCredential = firebase.auth.GoogleAuthProvider.credential(
          token.idToken,
          token.accessToken
        );
        this.reAuth(GGCredential, user);
      });
    } else if (userProviderFacebook) {
      AccessToken.getCurrentAccessToken().then(token => {
        let FBCredential = firebase.auth.FacebookAuthProvider.credential(
          token.accessToken
        );
        this.reAuth(FBCredential, user);
      });
    } else if (userProviderApple) {
      Actions.replace('login');
    }
  }

  reAuth(credential, user) {
    // AppMem.openAppLoader("loading", {
    //   text: "Ingresando",
    //   color: Colors.cerulean
    // });
    user
      .reauthenticateWithCredential(credential)
      .then(() => {
        Actions.replace('login');
        // User re-authenticated.
      })
      .catch(error => {
        console.log(' >>>>>>>>>>error ', error);
        ErrorManager.message(error);
        // An error happened.
      });
  }

  onSelectCity(country) {
    let { dispatch } = this.props;
    dispatch(change('register', 'doc_country_id', country.id));
    dispatch(change('register', 'doc_country_id_name', country.name));
  }

  // renderDocCountry() {
  //   let { registerValues, socialMedia, verifiedUser } = this.props;
  //   return (
  //     <View style={{ width: "100%", alignSelf: "center", marginTop: L.h(16) }}>
  //       <PressInput
  //         onSelectCity={this.onSelectCity}
  //         colorLabel="white"
  //         //prov_id={registerValues.i}
  //         label="País de Documento"
  //         city_name={registerValues && registerValues.doc_country_id_name}
  //         disabled={socialMedia && verifiedUser}
  //       />
  //     </View>
  //   );
  // }

  verifyDni(values) {
    let { dispatch } = this.props;
    AppMem.openAppLoader('bars', {
      text: 'Verificando...',
      color: Colors.secondary_color,
    });
    let { actVerifyDni } = this.props;
    let formValues = values;

    //WEB99 //TODOWEB
    //if (values.proBirthDate) {
    if (values && values.proBirthDate) {
      formValues = Util.formatBirthDate(values, FORMAT_DATE);
      dispatch(change('register', 'proBirthDate', formValues.proBirthDate));
    }
    actVerifyDni(formValues, this.callback);
  }

  // callback() {
  //   AppMem.closeAppLoader();
  // }

  callback(error) {
    let { socialMedia, isAppleAuth } = this.props;
    AppMem.closeAppLoader();
    if (error != 'OK') {
      AppMem.openModal(<UserExistPopUp />, {
        style: {
          height: L.hProm(310),
          borderRadius: 12,
          width: screen.width - L.wProm(60),
        },
        swipeToClose: true,
      });
    } else {
      Actions.push('registerE', { socialMedia, isAppleAuth });
    }
  }

  render() {
    const { handleSubmit, socialMedia, actLogout, appSettings } = this.props;
    let headerType = 'D';
    let onPressRight = socialMedia ? actLogout : null;
    let headerProps = RegisterConstants.headerProps(headerType, onPressRight);
    // if (socialMedia) {
    //   headerProps.leftButtons = [
    //     {
    //       onPress: () => Actions.pop(),
    //       icon: {
    //         name: "arrow-back",
    //         style: {
    //           color: "white",
    //           fontSize: L.h(33),
    //           fontFamily: Fonts.MEDIUM
    //         }
    //       }
    //     }
    //   ];
    // }
    let mayorEdad = moment().subtract(18, 'years');
    return (
      <ImageBackground source={BG_IMG} style={{ flex: 1 }} resizeMode="cover">
        <View style={localStyle.container}>
          <VTHeader {...headerProps} />

          <View style={localStyle.mainContent}>
            <Text style={localStyle.textInfo}>
              {socialMedia
                ? 'Completa los campos'
                : I18n.t('register.signInSteps.textCompleteFields')}
            </Text>
            <FormControl style={localStyle.form}>
              <Field
                name="proDniType"
                itemType="ItemList"
                type="picker"
                ref={input => {
                  this.inputs['proDniType'] = input;
                }}
                label="Tipo de documento (opcional)"
                //validate={required}
                component={LabelCustomInput}
                placeholderStyle={{ color: 'white' }}
                items={appSettings?.documentTypes?.map((ed, i) => ({
                  label: ed,
                  value: i + 1,
                }))}
                {...this.getInputProps()}
                //disabled={socialMedia && verifiedUser}
              />
              {/* {this.renderDocCountry()} */}
              <Field
                name="proDNI"
                label={'Número de documento (opcional)'}
                ref={input => {
                  this.inputs.doc_number = input;
                }}
                trimWhiteSpace
                component={LabelCustomInput}
                //validate={[required]}
                returnKeyType={'next'}
                //onSubmitEditing={handleSubmit(this.onPressForward)}
                {...this.getInputProps()}
                //disabled={socialMedia && verifiedUser}
              />
              <Field
                name="proBirthDate"
                label={`${I18n.t('register.birthDate')} ${'(opcional)'}`}
                borderColor={Colors.rouge}
                ref={input => {
                  this.inputs.birthDate = input;
                }}
                // validate={[required]}
                component={LabelCustomInput}
                itemType="DatePicker"
                dateProps={{
                  maxDate: mayorEdad,
                  format: FORMAT_DATE,
                }}
                {...this.getInputProps()}
                // onChangeDate={() => this.focusNext("gender")}
              />
            </FormControl>
            <TouchableOpacity
              style={localStyle.buttonAction}
              onPress={handleSubmit(this.verifyDni)}
            >
              <Icon
                name="md-arrow-round-forward"
                style={localStyle.buttonActionIcon}
              />
              <Text style={localStyle.buttonActionText}>
                {I18n.t('global.after')}
              </Text>
            </TouchableOpacity>
          </View>

          {/* <VTTermsModal
          ref="modalTerms"
          logoStyle={{
            backgroundColor: Colors.cerulean,
            width: "90%",
            height: "90%"
          }}
        /> */}
        </View>
      </ImageBackground>
    );
  }
}
const localStyle = StyleSheet.create({
  closeButton: {
    padding: 10,
    position: 'absolute',
    top: L.h(25),
    left: L.w(15),
  },
  closeIcon: {
    fontSize: L.h(30),
    color: 'white',
  },
  container: {
    flex: 1,
  },
  mainContent: {
    paddingTop: L.h(20),
    paddingHorizontal: L.w(44),
  },
  form: {
    marginTop: L.h(20),
  },
  textInfo: {
    fontSize: L.h(25),
    fontFamily: Fonts.DBOLD,
    color: Colors.textRegisterD,
  },
  buttonAction: {
    padding: L.h(10),
    top: L.h(15),
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: L.h(80),
  },
  buttonActionIcon: {
    fontSize: L.h(100),
    color: Colors.textIconRegisterD,
    marginBottom: L.h(-20),
  },
  buttonActionText: {
    fontSize: L.h(21),
    fontFamily: Fonts.DBOLD,
    color: Colors.textButtonRegisterD,
  },
});

function mapStateToProps(store) {
  //console.log("storereduxofrm", store.form);
  const selector = formValueSelector('register');
  return {
    appSettings: store.appReducer.appSettings,
    registerValues: selector(
      store,
      'username',
      'doc_type_id',
      'doc_number',
      'doc_country_id',
      'doc_country_id_name'
    ),
    //countries: store.parametersReducer.countries,
    //docsList: store.parametersReducer.docsList,
    //verifiedUser: store.authReducer.verifiedUser,
    //termsAndConditions: store.parametersReducer.termsAndConditions
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actVerifyDni,
      //actCreateSocialAccount
    },
    dispatch
  );
}
export default reduxForm({
  form: 'register',
  destroyOnUnmount: false,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RegisterD)
);
