import { StyleSheet, Platform, Dimensions } from 'react-native';
import L from '../../common/Layout';
import Colors from '../../common/Colors';
import Fonts from '../../common/Fonts';

const screen = Dimensions.get('window');
const isIOS = Platform.OS === 'ios';

const style = StyleSheet.create({
  // Main container
  container: {
    flex: 1,
    backgroundColor: Colors.profileBackground,
  },

  // Extra container
  container1: {
    flex: 1,
    flexDirection: 'row',
  },

  // User styles
  usernameContainer: {
    height: L.hProm(100),
    paddingTop: L.h(55),
    backgroundColor: 'white',
    alignItems: 'center',
    borderBottomColor: '#CCCCCC8A',
    borderBottomWidth: 1,
  },
  usernameText: {
    fontSize: 24,
  },
  nameContainer: {
    marginTop: L.h(70),
    // height: L.h(35),
    width: screen.width,
    alignItems: 'center',
    justifyContent: 'center',
  },
  userText: {
    color: Colors.profileName,
    fontSize: L.h(21),
    fontFamily: Fonts.DBOLD,
  },
  dniText: {
    color: Colors.profileDNIText,
    fontSize: L.h(18),
    fontFamily: Fonts.MEDIUM,
    marginTop: L.h(4),
  },
  verifiedContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  verifiedUser: {
    fontFamily: Fonts.DREGULAR,
    fontSize: L.h(16),
    color: Colors.profileVerifiedText,
  },
  verifiedIcon: {
    color: Colors.profileVerifiedIcon,
    fontSize: L.h(16),
    marginLeft: L.w(5),
  },
  nonVerifiedUser: {
    fontFamily: Fonts.DREGULAR,
    fontSize: L.h(16),
    color: Colors.profileNonVerifiedText,
  },
  nonVerifiedIcon: {
    color: Colors.profileNonVerifiedIcon,
    fontSize: L.h(16),
    marginLeft: L.w(5),
  },
  //Tags
  tagContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: screen.width,
    marginTop: L.h(15),
  },
  //User points
  userPointsImage: {
    width: '100%',
    height: L.h(115),
    justifyContent: 'center',
  },
  userPointsContainer: {
    paddingLeft: L.w(25),
    justifyContent: 'center',
  },
  textTitleUserPoints: {
    fontSize: L.h(16),
    lineHeight: L.h(22),
    fontFamily: Fonts.DBOLD,
    color: 'white',
    textShadowColor: 'rgba(0, 0, 0, 0.8)',
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 10,
    top: L.h(10),
  },
  numberUserPoints: {
    fontSize: L.h(32),
    fontFamily: Fonts.DBOLD,
    color: 'white',
    marginLeft: L.w(5),
  },
  textDescUserPoints: {
    fontSize: L.h(13),
    lineHeight: L.h(15),
    fontFamily: Fonts.DREGULAR,
    color: 'white',
  },
  // Profile photo circle
  circle: {
    position: 'absolute',
    backgroundColor: 'white',
    borderRadius: L.hProm(150) / 2,
    height: L.hProm(150),
    width: L.wProm(150),
    top: L.h(100),
    left: L.w(129.5),
    elevation: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  circleView: {
    backgroundColor: 'white',
    borderRadius: L.hProm(150) / 2,
    height: L.hProm(150),
    width: L.wProm(150),
    top: L.h(60),
    left: (screen.width - L.wProm(150)) / 2,
    elevation: 2,
    justifyContent: 'center',
    alignItems: 'center',
  },
  profilePhoto: {
    borderRadius: L.hProm(145) / 2,
    height: L.hProm(145),
    width: L.wProm(145),
  },
  backgroundImage: {
    height: L.hProm(150),
  },
  iconCamera: {
    color: Colors.primary_color,
    fontSize: L.h(40),
  },

  // Header
  title: {
    alignSelf: 'center',
    color: '#FFFFFF',
    fontSize: 16,
    fontFamily: Fonts.DBOLD,
  },
  action: {
    paddingRight: 10,
    color: '#FFFFFF',
    fontSize: 16,
  },

  // Scroll View
  scrollView: {
    backgroundColor: Colors.profileBackground,
    marginTop: L.h(50),
    justifyContent: 'flex-end',
  },

  // Options
  optionContainer: {
    height: L.hProm(45),
    backgroundColor: Colors.profileBackground,
  },
  optionTouchable: {
    flex: 1,
    flexDirection: 'row',
  },
  optionContainerIcon: {
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
  optionContainerText: {
    flex: 8,
    flexDirection: 'row',
    justifyContent: 'center',
  },
  optionLabel: {
    flex: 8,
    justifyContent: 'center',
  },
  optionArrow: {
    flex: 2,
    alignItems: 'center',
    justifyContent: 'center',
  },

  // Log out
  logoutContainer: {
    justifyContent: 'center',
    backgroundColor: Colors.profileBackground,
    borderTopWidth: 1,
    borderTopColor: '#CCCCCC8A',
    borderBottomWidth: 1,
    borderBottomColor: '#CCCCCC8A',
    marginTop: L.h(25),
    height: L.hProm(48),
  },
  logoutText: {
    color: Colors.profileName,
    fontSize: 16,
    textAlign: 'center',
  },
  deleteText: {
    color: Colors.profileDeleteText,
    fontSize: 16,
    textAlign: 'center',
  },

  // Support
  supportContainer: {
    //width: "100%",
    marginVertical: L.h(20),
    // justifyContent: "center",
    // alignItems: "center",
    paddingBottom: L.h(35),
  },
  supportText: {
    color: Colors.profileSupportText,
    fontSize: L.h(15),
    textAlign: 'center',
    marginHorizontal: 16,
  },
});

export default style;
