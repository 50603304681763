import React from 'react';
import { Actions } from 'react-native-router-flux';
import Colors from '../../common/Colors';
import _ from 'lodash';
import firebase from 'firebase';
import { setMenuState } from '../../actions/AppActions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

const Header = ({ setMenuState, selectedMenu }) => {
  const isEmailVerified = firebase.auth()?.currentUser?.emailVerified;
  const user = firebase.auth()?.currentUser;
  const isLogged = !_.isEmpty(user);
  const styles = { cursor: { cursor: 'pointer' } };

  const setMenu = menu => {
    setMenuState(menu);
    Actions.reset(menu);
  };

  return (
    <header
      class="header-section"
      style={{ backgroundColor: Colors.backgroundHistory }}
    >
      <div class="container">
        <div class="header-wrapper">
          <div class="logo">
            <a style={styles.cursor} onClick={() => setMenu('home')}>
              <img
                src={require('../../common/assets/logo.png')}
                alt="logo"
                height="59"
              />
            </a>
          </div>
          <ul class="menu">
            <li onClick={() => setMenu('home')} style={styles.cursor}>
              <a>EVENTOS</a>
            </li>
            <li
              onClick={() =>
                setMenu(
                  isLogged
                    ? !isEmailVerified
                      ? 'activateAccount'
                      : 'profile'
                    : 'login',
                  2
                )
              }
              style={styles.cursor}
            >
              <a>{isLogged ? 'MI PERFIL' : 'INICIAR SESIÓN'}</a>
            </li>
            <li
              class="header-button pr-0"
              onClick={() =>
                setMenu(
                  isLogged
                    ? !isEmailVerified
                      ? 'activateAccount'
                      : 'shopMain'
                    : 'register',
                  2
                )
              }
              style={styles.cursor}
            >
              <a>{isLogged ? 'MIS COMPRAS' : 'REGISTRARSE'}</a>
            </li>
          </ul>
          {/* <div class="header-bar d-lg-none">
            <span />
            <span />
            <span />
          </div> */}
          <div
            className="mobile-menu d-lg-none"
            style={{
              flexDirection: 'flex',
              alignItems: 'center',
              display: 'flex',
              backgroundColor: 'red',
            }}
          >
            <select
              onChange={e => setMenu(e.target.value)}
              style={{
                paddingLeft: 10,
                paddingRight: 10,
              }}
              value={selectedMenu}
            >
              <option value="home">EVENTOS</option>
              <option
                value={
                  isLogged
                    ? isEmailVerified
                      ? 'profile'
                      : 'activateAccount'
                    : 'login'
                }
              >
                {isLogged ? 'MI PERFIL' : 'INICIAR SESIÓN'}
              </option>
              <option
                value={
                  isLogged
                    ? isEmailVerified
                      ? 'shopMain'
                      : 'activateAccount'
                    : 'register'
                }
              >
                {isLogged ? 'MIS COMPRAS' : 'REGISTRARSE'}
              </option>
            </select>
          </div>
        </div>
      </div>
    </header>
  );
};

function mapStateToProps(store) {
  return {
    selectedMenu: store.appReducer.menu,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setMenuState }, dispatch);
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
