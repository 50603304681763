import React, { Component } from "react";
import {
  View,
  Dimensions,
  Text,
  StyleSheet,
  Animated,
  Easing,
  ActivityIndicator,
  Platform,
} from "react-native";
import Modal from "react-native-modalbox";
import { Bubbles, DoubleBounce, Bars, Pulse } from "react-native-loader";
import L from "../common/Layout";
import Fonts from "../common/Fonts";
import I18n from "i18next"

const screen = Dimensions.get("window");
class VTLoaderLogo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "spinner",
      loaderProps: {
        text: I18n.t("vtLoaderLogo.loaderText"),
        textStyle: {},
        color: "red",
        image: "",
      },
    };
    this._isOpen = false;
    this.spinValue = new Animated.Value(0);

    this.refModal = null;
    if (Platform.OS == 'web'){
        this.refModal = React.createRef();
    }
  }

  close() {
    this._isOpen = false;
    if (Platform.OS == 'web')
        this.refModal && this.refModal.current && this.refModal.current.close();
    else
        this.refs.modal && this.refs.modal.close();
  }

  open(type, props) {
    let propsUp = props || {
      text: "Cargando",
      textStyle: {},
      color: "red",
      image: "",
    };
    this._isOpen = true;
    this.setState({ type, loaderProps: propsUp });
    if (Platform.OS == 'web')
        this.refModal && this.refModal.current && this.refModal.current.open();
    else
        this.refs.modal && this.refs.modal.open(); 
  }

  componentDidMount() {
    //WEB99this.spin();
  }

  spin() {
    this.spinValue.setValue(0);
    Animated.timing(this.spinValue, {
      toValue: 1,
      duration: 2000,
      easing: Easing.linear,
    }).start(() => this.spin());
  }

  get isOpen() {
    return this._isOpen;
  }



  //WEB99 PARA WEB, vamos a dejar solo el activity indicator por compatibilidad
  renderLoader() {

 
    let loader = null;
    /*WEB99 const spin = this.spinValue.interpolate({
      inputRange: [0, 1],
      outputRange: ["0deg", "360deg"],
    });*/


    console.log('this.state.type', this.state.type);

    switch (this.state.type) {
      case "spinner":
        loader = <ActivityIndicator size="large" {...this.state.loaderProps} />;
        break;
      case "whirl":
        loader = <ActivityIndicator size="large" {...this.state.loaderProps} />;
        break;
      case "bounce":
        loader = <ActivityIndicator size="large" {...this.state.loaderProps} />;
        break;
      case "loading":
        loader = <ActivityIndicator size="large" {...this.state.loaderProps} />;
        break;
      case "bars":
        loader = <ActivityIndicator size="large" {...this.state.loaderProps} />;
        break;
      case "pulse":
        loader = <ActivityIndicator size="large" {...this.state.loaderProps} />;
        break;
      default:
        loader = <ActivityIndicator size="large" color="red" />;
        break;
    }

    return loader;
  }

  render() {
    console.log('VA A RENDERIZAR LOADER LOGO');
    return (
      <Modal
        ref={Platform.OS == 'web' ? this.refModal :  "modal"}
        animationDuration={0}
        backdropPressToClose={false}
        swipeToClose={false}
        style={this.props.logoStyle}
        backdropOpacity={0.8}
      >
        {this.renderLoader()}
      </Modal>
    );
  }
}

VTLoaderLogo.defaultProps = {
  logoStyle: {
    flex:1,
    width: '100%',
    height: '1000px',
    backgroundColor: "transparent",
  },
};
const localStyle = StyleSheet.create({});

export default VTLoaderLogo;
