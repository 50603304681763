import React, { Component } from 'react';
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  Keyboard,
  Alert,
} from 'react-native';
import { Actions } from 'react-native-router-flux';
import { bindActionCreators } from 'redux';
import {
  searchPersonByDocument,
  onTransferTicket,
  getMyNextEvents,
} from '../../actions/AppActions';
import _ from 'lodash';
import AppMem from '../../common/AppMem';
import { Toast } from 'native-base';
//import { Row, Col, Toast } from "native-base";//WEB99
import { Field, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import Util from '../../common/Util';
import Header from '../../components/VTHeader';
import L from '../../common/Layout';
import Fonts from '../../common/Fonts';
import Colors from '../../common/Colors';
import LabelCustomInput from '../../components/LabelCustomInput';
import { required } from '../../common/Validations';
import { TOP_PADDING_IOS_FONT } from '../../common/Constants';
const screen = Dimensions.get('window');
import Icon from 'react-native-vector-icons/MaterialIcons';
import Config from 'react-native-config';
import I18n from 'i18next';

const Col = {}; //WEB99
const Row = {};

class TransferTicket extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { select: false, searchOk: false };
    this.onPressTransfer = this.onPressTransfer.bind(this);
  }

  getInputProps() {
    let props = {
      borderColor: Colors.fieldColorTransferTicket,
      labelStyle: { color: Colors.textColorTransferTicket },
      inputStyle: { color: Colors.textColorTransferTicket },
      maxWidth: '65%',
      errorIconStyle: { color: 'black' },
      borderBottomColor: Colors.fieldColorTransferTicket,
      blurOnSubmit: false,
      withRef: true,
      autoCapitalize: 'none',
    };
    return props;
  }

  onSearchPress(formValues) {
    Keyboard.dismiss();
    if (formValues.document == this.props.user.proDNI) {
      setTimeout(() => {
        Toast.show({
          text: I18n.t('transferTicket.errorSearchDNI'),
          position: 'bottom',
          type: 'danger',
          buttonText: I18n.t('transferTicket.accept'),
        });
      }, 200);
    } else {
      AppMem.openAppLoader('bars', {
        text: I18n.t('transferTicket.loaderText'),
        color: Colors.secondary_color,
      });
      this.props.searchPersonByDocument(formValues.document, data => {
        if (data && data.proDNI) {
          this.setState({ friendData: data });
        } else {
          Toast.show({
            text: I18n.t('transferTicket.errorSearchUser'),
            position: 'bottom',
            type: 'danger',
            buttonText: I18n.t('transferTicket.accept'),
          });
        }
        AppMem.closeAppLoader();
      });
    }
  }

  onPressTransfer() {
    let { ticket, event } = this.props;
    let { friendData } = this.state;
    if (event.evtSellVerify) {
      if (
        friendData &&
        friendData.proDNI != ticket.proDNI &&
        friendData.proVerified
      ) {
        AppMem.openAppLoader('bars', {
          text: I18n.t('transferTicket.loaderTextTransfer'),
          color: Colors.secondary_color,
        });
        this.props.onTransferTicket(friendData, ticket, event.id, event, () => {
          this.props.getMyNextEvents(eventData => {
            AppMem.closeAppLoader();
            _.map(eventData, (eachEvent, eventId) => {
              //  console.log(" >>>>>>>>>> eachEvent", eachEvent, eventId);
              if (eachEvent.id == event.id) {
                Actions.replace('shopDetail', {
                  eventId: eachEvent.id,
                  eventData: eachEvent,
                });
              }
            });
          });
        });
      } else {
        friendData.proVerify
          ? Alert.alert(I18n.t('transferTicket.errorTransfer'))
          : Alert.alert(I18n.t('transferTicket.errorTransfer2'));
      }
    } else {
      if (friendData && friendData.proDNI != ticket.proDNI) {
        AppMem.openAppLoader('bars', {
          text: I18n.t('transferTicket.loaderTextTransfer'),
          color: Colors.secondary_color,
        });
        this.props.onTransferTicket(friendData, ticket, event.id, event, () => {
          this.props.getMyNextEvents(eventData => {
            AppMem.closeAppLoader();
            _.map(eventData, (eachEvent, eventId) => {
              //  console.log(" >>>>>>>>>> eachEvent", eachEvent, eventId);
              if (eachEvent.id == event.id) {
                Actions.replace('shopDetail', {
                  eventId: eachEvent.id,
                  eventData: eachEvent,
                });
              }
            });
          });
        });
      } else {
        Alert.alert('No podes transferir el ticket a vos mismo.');
      }
    }
  }
  render() {
    let {
      text14Med,
      titleCardContainer,
      container,
      warningText,
      infoText,
      buttonStyle,
      footerContainer,
      buttonText,
      nameText,
      dniText,
    } = styles;
    let { ticket, event } = this.props;
    let { select } = this.state;
    const { handleSubmit } = this.props;
    let { friendData } = this.state;
    return (
      <View style={container}>
        <Header
          title={I18n.t('transferTicket.transferTicket')}
          leftButtons={[
            {
              onPress: Actions.pop,
              icon: {
                name: 'chevron-left',
                type: 'MaterialIcons',
                style: {
                  color: Colors.mainTitleColor,
                  fontSize: L.h(23),
                  marginLeft: L.w(8),
                },
                text: Util.isIos() ? '' : '',
                textStyle: {
                  fontSize: L.h(18),
                  color: Colors.mainTitleColor,
                },
              },
            },
          ]}
          titleStyle={{
            fontFamily: Fonts.MEDIUM,
            fontSize: L.h(18),
            color: Colors.mainTitleColor,
            //  top: Platform.OS === "ios" ? TOP_PADDING_IOS_FONT : 0,
          }}
        />
        <ScrollView keyboardShouldPersistTaps="always">
          <View
            style={{
              flex: 1,
              alignItems: 'center',
              marginHorizontal: L.w(22),
            }}
          >
            <Text style={[text14Med, { marginVertical: L.h(16) }]}>
              {I18n.t('transferTicket.transferingTicket')}
            </Text>
            <View style={titleCardContainer}>
              <Text style={styles.ticketTitle}>
                {event.evtTitle.toUpperCase()}
              </Text>
              <View
                style={[
                  styles.ticketSubTitle,
                  {
                    backgroundColor: ticket.tktColor
                      ? ticket.tktColor
                      : Colors.blueberry,
                  },
                ]}
              >
                <Text style={styles.subTitleText}>
                  {ticket.tktDisplayName
                    ? ticket.tktDisplayName.toUpperCase()
                    : ticket.tktType.toUpperCase()}
                </Text>
              </View>
            </View>
            <Text style={warningText}>
              {I18n.t('transferTicket.attention')}{' '}
              <Text style={{ fontFamily: Fonts.MEDIUM }}>
                {Config.PRODUCER_NAME} {I18n.t('transferTicket.attention2')}
              </Text>
            </Text>
            <View
              style={{
                width: '100%',
                flexDirection: 'row',
                alignItems: 'center',
                marginTop: L.h(16),
                justifyContent: 'center',
              }}
            >
              <Field
                name="document"
                label={I18n.t('transferTicket.dniToTransfer')}
                ref="document"
                component={LabelCustomInput}
                validate={[required]}
                onSubmitEditing={handleSubmit(this.onSearchPress.bind(this))}
                returnKeyType="send"
                keyboardType={'numeric'}
                {...this.getInputProps()}
              />
              <TouchableOpacity
                style={{
                  padding: L.h(8),
                  borderRadius: 40,
                  //borderColor: "green",
                  width: L.wProm(100),
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginTop: L.h(16),
                  elevation: 2,
                  backgroundColor: Colors.findButton,
                  marginLeft: L.w(16),
                }}
                onPress={handleSubmit(this.onSearchPress.bind(this))}
              >
                <Text style={{ color: 'white' }}>
                  {I18n.t('transferTicket.findDNI')}
                </Text>
              </TouchableOpacity>
            </View>

            {friendData ? (
              <Row
                style={{
                  borderTopWidth: 1,
                  borderBottomWidth: 1,
                  alignItems: 'center',
                  paddingVertical: L.h(12),
                  paddingHorizontal: L.w(5),
                  borderColor: Colors.borderTransferTicket,
                  marginTop: L.h(14),
                  backgroundColor: Colors.personBackgroundTransferTicket,
                }}
              >
                <Row style={{ alignItems: 'center' }}>
                  <Icon
                    name="account-circle"
                    style={{
                      fontSize: L.h(46),
                      color: 'rgb(216, 216, 216)',
                      marginRight: L.w(16),
                    }}
                  />
                  <Col>
                    <Text style={nameText}>
                      {friendData.proName} {friendData.proLastName}
                    </Text>
                    <Text style={dniText}>DNI {friendData.proDNI}</Text>
                  </Col>
                </Row>
                <TouchableOpacity
                  style={{ padding: L.h(8) }}
                  onPress={() => this.setState({ select: !select })}
                >
                  <Icon
                    name={select ? 'check-box' : 'check-box-outline-blank'}
                    style={{ color: Colors.trueGreen, fontSize: L.h(35) }}
                  />
                </TouchableOpacity>
              </Row>
            ) : null}
          </View>
        </ScrollView>
        <View style={footerContainer}>
          <Text
            style={[
              infoText,
              { marginHorizontal: L.w(20), color: Colors.tangerine },
            ]}
          >
            {I18n.t('transferTicket.transferLabel')}
          </Text>
          <TouchableOpacity
            disabled={!select}
            onPress={this.onPressTransfer}
            style={[
              buttonStyle,
              {
                backgroundColor: select
                  ? Colors.findButton
                  : Colors.pinkishGrey,
              },
            ]}
          >
            <Text style={buttonText}>
              {I18n.t('transferTicket.transferTicket').toUpperCase()}
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  }
}

TransferTicket.defaultProps = {};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    color: Colors.whiteFour,
    backgroundColor: Colors.backgroundTransferTicket,
  },
  text14Med: {
    color: Colors.introTransferTicket,
    fontSize: L.h(14),
    fontFamily: Fonts.MEDIUM,
  },
  titleCardContainer: {
    borderRadius: 4,
    elevation: 2,
    paddingTop: L.h(19),
    margin: L.hProm(4),
    backgroundColor: 'white',
  },
  ticketTitle: {
    color: Colors.ticketTitleTransferTicket,
    marginHorizontal: L.w(50),
    textAlign: 'center',
    marginBottom: L.h(11),
    fontFamily: Fonts.DBOLD,
    fontSize: L.h(14),
  },
  ticketSubTitle: {
    backgroundColor: Colors.blueberry,
    paddingVertical: L.h(8),
    borderBottomRightRadius: 4,
    borderBottomLeftRadius: 4,
  },
  subTitleText: {
    marginHorizontal: L.w(20),
    textAlign: 'center',
    fontSize: L.h(13),
    color: 'white',
    fontFamily: Fonts.MEDIUM,
  },
  warningText: {
    fontSize: L.h(13),
    fontFamily: Fonts.DLIGHT,
    color: Colors.warningTextTransferTicket,
    marginHorizontal: L.w(30),
    textAlign: 'center',
    marginTop: L.h(16),
    lineHeight: L.h(16),
  },
  infoText: {
    fontSize: L.h(13),
    fontFamily: Fonts.MEDIUM,
    color: Colors.redTwo,
    textAlign: 'center',
    marginTop: L.h(15),
  },
  buttonStyle: {
    width: screen.width - L.wProm(40),
    borderRadius: L.wProm(40),
    height: L.hProm(50),
    elevation: 2,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: Colors.pinkishGrey,
    marginTop: L.h(20),
    alignSelf: 'center',
  },
  footerContainer: {
    position: 'absolute',
    bottom: 30,
    left: 0,
    width: '100%',
  },
  buttonText: {
    fontFamily: Fonts.MEDIUM,
    color: 'white',
    fontSize: L.h(16),
  },
  nameText: {
    fontSize: L.h(14),
    color: Colors.nameTransferTicket,
    fontFamily: Fonts.DBOLD,
  },
  dniText: {
    fontSize: L.h(14),
    color: Colors.dniTransferTicket,
    fontFamily: Fonts.DREGULAR,
  },
});

function mapStateToProps(store) {
  return {
    user: store.authReducer.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      searchPersonByDocument,
      onTransferTicket,
      getMyNextEvents,
    },
    dispatch
  );
}

export default reduxForm({
  form: 'transferTicket',
  destroyOnUnmount: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TransferTicket)
);
