import React from "react";
import {  Button, Icon, HStack} from "native-base"; //WEB99 Footer, FooterTab,
import IconMA from "react-native-vector-icons/MaterialIcons";
import IconIO from "react-native-vector-icons/Ionicons";
import IconFA from "react-native-vector-icons/FontAwesome";
import {
  StyleSheet,
  Dimensions,
  View,
  TouchableOpacity,
  Text,
} from "react-native";
import Config from "react-native-config";
import L from "../common/Layout";
import Colors from "../common/Colors";
import Util from "../common/Util";
import Fonts from "../common/Fonts";
import LinearGradient from "react-native-linear-gradient";
import I18n from "i18next"

const screen = Dimensions.get("window");
const Footer = {} ; //WEB99
const FooterTab = {};//WEB99

export default class FooterTabsBadgeExample extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedIndex: 1,
    };
    this.setSelectedIndex = this.setSelectedIndex.bind(this);
  }

  componentWillMount() {
    this.setState({ selectedIndex: this.props.screenChoose });
  }

  onPressTab(index) {
    this.props.onPressTab && this.props.onPressTab(index);
    this.setState({ selectedIndex: index });
  }

  setSelectedIndex(index) {
    console.warn("indice", index);
    setTimeout(() => {
      this.setState({ selectedIndex: index });
    }, 200);
  }

  render() {
    let setHeight = { height: L.hProm(49) };
    let {
      selectedStyle,
      unselectedStyle,
      contentSelectedColor,
      contentUnSelectedColor,
      backgroundColor,
    } = this.props;

    //WEB99 style={setHeight}
    return (
      <View>
        <HStack
          
          style={{
            backgroundColor: Colors.footerTabBackground,
            borderTopWidth: 1,
            borderTopColor: Colors.black_12,
            elevation: 0,
            zIndex: 0,
          }}
        >
          
            <Button
              active={this.state.selectedIndex == 0}
              vertical
              // transparent
              onPress={() => this.onPressTab(0)}
              // badge={this.props.ticketsQntity ? true : false}
              style={
                this.state.selectedIndex == 0
                  ? [styles.buttonStyle, selectedStyle]
                  : [styles.buttonStyle, unselectedStyle]
              }
            >
              <View
                style={{
                  width: L.wProm(50),
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <IconMA
                  name="shopping-cart"
                  type="MaterialIcons"
                  style={[
                    styles.icon,
                    {
                      color:
                        this.state.selectedIndex == 0
                          ? contentSelectedColor
                          : contentUnSelectedColor,
                    },
                  ]}
                />
                {this.props.ticketsQntity ? (
                  <View style={styles.badgeContainer}>
                    <Text style={styles.bagTxt}>
                      {this.props.ticketsQntity}
                    </Text>
                  </View>
                ) : null}
              </View>

              <Text
                uppercase={false}
                style={[
                  styles.fontItem,
                  {
                    color:
                      this.state.selectedIndex == 0
                        ? contentSelectedColor
                        : contentUnSelectedColor,
                  },
                ]}
              >
                {I18n.t("vtFooterTabs.buys")}
              </Text>
            </Button>
            {Config.HAS_LOYALY_PROGRAM === "active" ? (
              <Button
                active={this.state.selectedIndex == 3}
                vertical
                transparent
                onPress={() => this.onPressTab(3)}
                // badge={this.props.ticketsQntity ? true : false}
                style={
                  this.state.selectedIndex == 3
                    ? [styles.buttonStyle, selectedStyle]
                    : [styles.buttonStyle, unselectedStyle]
                }
              >
                <View
                  style={{
                    width: L.wProm(50),
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <IconFA
                    name="gift"
                    // type="Ionicons"
                    style={[
                      styles.icon,
                      {
                        color:
                          this.state.selectedIndex == 3
                            ? contentSelectedColor
                            : contentUnSelectedColor,
                      },
                    ]}
                  />
                </View>

                <Text
                  uppercase={false}
                  style={[
                    styles.fontItem,
                    {
                      color:
                        this.state.selectedIndex == 3
                          ? contentSelectedColor
                          : contentUnSelectedColor,
                    },
                  ]}
                >
                  {I18n.t("vtFooterTabs.benefits")}
                </Text>
              </Button>
            ) : null}
            {/* Button sólo para espaciado */}
            <Button
              transparent
              style={{ backgroundColor: Colors.footerTabBackground }}
            />
            {Config.HAS_LOYALY_PROGRAM === "active" ? (
              <Button
                active={this.state.selectedIndex == 4}
                vertical
                transparent
                onPress={() => this.onPressTab(4)}
                // badge={this.props.ticketsQntity ? true : false}
                style={
                  this.state.selectedIndex == 4
                    ? [styles.buttonStyle, selectedStyle]
                    : [styles.buttonStyle, unselectedStyle]
                }
              >
                <View
                  style={{
                    width: L.wProm(50),
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <IconIO
                    name="md-star"
                    type="Ionicons"
                    style={[
                      styles.icon,
                      {
                        color:
                          this.state.selectedIndex == 4
                            ? contentSelectedColor
                            : contentUnSelectedColor,
                      },
                    ]}
                  />
                </View>

                <Text
                  uppercase={false}
                  style={[
                    styles.fontItem,
                    {
                      color:
                        this.state.selectedIndex == 4
                          ? contentSelectedColor
                          : contentUnSelectedColor,
                    },
                  ]}
                >
                  {I18n.t("vtFooterTabs.points")}
                </Text>
              </Button>
            ) : null}
            <Button
              style={
                this.state.selectedIndex == 2
                  ? [styles.buttonStyle, selectedStyle]
                  : [styles.buttonStyle, unselectedStyle]
              }
              transparent
              vertical
              onPress={() => this.onPressTab(2)}
            >
              <IconFA
                name="user"
                type="FontAwesome"
                style={[
                  styles.icon,
                  {
                    color:
                      this.state.selectedIndex == 2
                        ? contentSelectedColor
                        : contentUnSelectedColor,
                  },
                ]}
              />
              <Text
                uppercase={false}
                style={[
                  styles.fontItem,
                  {
                    color:
                      this.state.selectedIndex == 2
                        ? contentSelectedColor
                        : contentUnSelectedColor,
                  },
                ]}
              >
                {I18n.t("vtFooterTabs.profile")}
              </Text>
            </Button>
          
        </HStack>

        <TouchableOpacity
          active={this.state.selectedIndex == 1}
          activeOpacity={0.95}
          onPress={() => this.onPressTab(1)}
          style={[
            styles.buttonContainer,
            {
              borderColor:
                this.state.selectedIndex == 1 ? Colors.primary_color : "grey",
            },
          ]}
        >
          <LinearGradient
            colors={
              this.props.secondary
                ? [Colors.white, Colors.white]
                : [Colors.primaryGradientButton, Colors.secondaryGradientButton]
            }
            start={{ x: 0.2, y: 0 }}
            end={{ x: 1, y: 0 }}
            style={styles.buttonGradient}
          >
            <View style={styles.innerCircleButton}>
              <IconFA
                active
                name="ticket"
                type="FontAwesome"
                style={[
                  styles.icon,
                  {
                    color:
                      this.state.selectedIndex == 1
                        ? contentSelectedColor
                        : contentUnSelectedColor,
                    fontSize: L.h(30),
                    transform: [{ rotate: "45deg" }],
                  },
                ]}
              />
              <Text
                uppercase={false}
                style={[
                  styles.fontItem,
                  {
                    color:
                      this.state.selectedIndex == 1
                        ? contentSelectedColor
                        : contentUnSelectedColor,
                  },
                ]}
              >
                {I18n.t("vtFooterTabs.events")}
              </Text>
            </View>
          </LinearGradient>
        </TouchableOpacity>
      </View>
    );
  }
}

FooterTabsBadgeExample.defaultProps = {
  contentSelectedColor: Colors.darkIndigo,
  contentUnSelectedColor: Colors.darkishPurple,
};

const styles = StyleSheet.create({
  fontItem: {
    fontSize: L.h(11),
    fontFamily: Fonts.DREGULAR,
    marginTop: L.h(4),
  },
  icon: { fontSize: L.h(22) },
  buttonStyle: {
    height: L.hProm(49),
    borderRadius: 0,
    marginTop: L.h(12),
  },
  buttonContainer: {
    position: "absolute",
    bottom: Util.isIos()
      ? Util.isIosXGen()
        ? L.h(42)
        : Util.isRegular()
        ? L.h(8)
        : L.h(15)
      : L.h(5),
    //left: screen.width / 2 - 70 / 2,
    //elevation: 40,
    borderRadius: L.hProm(80) / 2,
    alignSelf: "center",
    backgroundColor: "white", //Colors.blueberry,
    alignItems: "center",
    justifyContent: "center",
  },
  buttonGradient: {
    width: L.wProm(80),
    height: L.hProm(80),
    borderRadius: L.hProm(80) / 2,
    padding: 3,
  },
  innerCircleButton: {
    backgroundColor: Colors.footerTabBackground,
    alignItems: "center",
    justifyContent: "center",
    flex: 1,
    borderRadius: L.hProm(80) / 2,
  },
  bagTxt: {
    fontSize: L.h(9),
    color: "white",
    fontFamily: Fonts.DBOLD,
    textAlign: "center",
  },
  badgeContainer: {
    height: L.hProm(15),
    width: L.wProm(15),
    borderRadius: L.hProm(15) / 2,
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    right: L.w(6),
    top: -L.h(3),
    backgroundColor: "red",
    borderWidth: 1,
    borderColor: "white",
  },
});
