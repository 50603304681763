import AsyncStorage from './AppAsyncStorage';
import { getUniqueId } from 'react-native-device-info';
import Firebase from 'react-native-firebase';
import Util from './Util';
import {PermissionsAndroid} from 'react-native'
// import { Alert } from "react-native";
// import PushNotificationIOS from "@react-native-community/push-notification-ios";
//import PushNotification from "react-native-push-notification";

//import PushNotification from "react-native-push-notification";

// const requestPermission = async () => {
//   messaging()
//     .requestPermission()
//     .then(() => console.log(" >>>>>>>>>>Push Permission OK"))
//     .catch(error => {
//       console.warn(`${error} permission rejected`);
//     });
// };

export const requestPermission = async () => {
  try {
    const granted = await PermissionsAndroid.request(
      PermissionsAndroid.PERMISSIONS.POST_NOTIFICATIONS,
      {
        title: 'Permiso de Notificación',
        message: 'La aplicación necesita permiso para recibir notificaciones',
        buttonPositive: 'Aceptar',
        buttonNegative: 'Cancelar',
      },
    );
    console.log(" >>>>>>>>>> granted", granted);
    if (granted === PermissionsAndroid.RESULTS.GRANTED) {
      console.log('Permiso concedido');
    } else {
      console.log('Permiso denegado');
    }
  } catch (err) {
    console.warn(err);
  }
};

// export const requestUserPermission = async () => {
//   const authorizationStatus = await messaging().requestPermission();

//   if (authorizationStatus) {
//     // console.log("Permission status:", authorizationStatus);
//   }
// };

// export const onNotificationOpenedApp = () => {
//   messaging().onNotificationOpenedApp((remoteMessage) => {
//     console.log(
//       "Notification caused app to open from background state:",
//       remoteMessage.notification
//     );
//     // navigation.navigate(remoteMessage.data.type);
//   });
// };

// export const getInitialNotification = () => {
//   messaging()
//     .getInitialNotification()
//     .then((remoteMessage) => {
//       // console.log(" >>>>>>>>>> remoteMessage", remoteMessage);
//       if (remoteMessage) {
//         // console.log(
//         //   "Notification caused app to open from quit state:",
//         //   remoteMessage.notification
//         // );
//         //setInitialRoute(remoteMessage.data.type); // e.g. "Settings"
//       }
//       //setLoading(false);
//     });
// };

// export const onMessage = (callback) => {
//   messaging().onMessage(async (remoteMessage) => {
//     //console.log(" >>>>>>>>>> remoteMessage", remoteMessage);
//     let details = {
//       alertBody: remoteMessage.data
//         ? remoteMessage.data.notification.body
//         : remoteMessage.notification.body,
//       alertTitle: remoteMessage.data
//         ? remoteMessage.data.notification.title
//         : remoteMessage.notification.title,
//     };
//     PushNotificationIOS.presentLocalNotification(details);
//     // let { notification } = remoteMessage;
//     // PushNotification.localNotification({
//     //   // Android Only Properties
//     //   //id: 0, // (optional) default: Autogenerated Unique ID
//     //   //ticker: "My Notification Ticker", // (optional)
//     //   autoCancel: true, // (optional) default: true
//     //   //largeIcon: "ic_launcher", // (optional) default: "ic_launcher"
//     //   smallIcon: "@drawable/ic_noti", // (optional) default: "ic_notification" with fallback for "ic_launcher"
//     //   //bigText: "My big text that will be shown when notification is expanded", // (optional) default: "message" prop
//     //   //subText: "This is a subText", // (optional) default: none
//     //   color: "#0075be", // (optional) default: system default
//     //   vibrate: true, // (optional) default: true
//     //   //vibration: 300, // vibration length in milliseconds, ignored if vibrate=false, default: 1000
//     //   //tag: "some_tag", // (optional) add tag to message
//     //   //group: "group", // (optional) add group to message
//     //   //ongoing: false, // (optional) set whether this is an "ongoing" notification

//     //   // iOS only properties
//     //   //alertAction: // (optional) default: view
//     //   //category: // (optional) default: null
//     //   //userInfo: // (optional) default: null (object containing additional notification data)

//     //   // iOS and Android properties
//     //   title: notification.title, // (optional, for iOS this is only used in apple watch, the title will be the app name in other devices)
//     //   message: notification.body, // (required)
//     //   //playSound: false, // (optional) default: true
//     //   //number: 10 // (optional) default: none (Cannot be zero)
//     // });

//     callback(remoteMessage);
//   });
// };

export const getFCMToken = async () => {
  // await Firebase.messaging().deleteToken();
  const newToken = await Firebase.messaging().getToken();
  return newToken;
};

export const getNotificationData = async () => {
  // let fcmToken = await AsyncStorage.getItem("fcmToken");
  // if (!fcmToken) {
  // await Firebase.messaging().deleteToken();
  const fcmToken = await Firebase.messaging().getToken();
  // console.log(" >>>>>>>>>> fcmToken", fcmToken);
  await AsyncStorage.setItem('fcmToken', fcmToken);
  let notificationData = {};
  notificationData.fcmToken = fcmToken;
  notificationData.OS = Util.isIos() ? 'iOS' : 'android';
  notificationData.id = getUniqueId();
  // console.log(" >>>>>>>>>>notificationData ", notificationData);
  return notificationData;
  // }
};

// export const checkPermission = async () => {
//   const enabled = await messaging().hasPermission();
//   if (enabled) {
//     // console.log(" >>>>>>>>>>Push Permission OK");
//   } else {
//     requestPermission();
//   }
// };

// export const createChannel = () => {
//   const channel = new firebase.notifications.Android.Channel(
//     "main-channel",
//     "main-channel",
//     firebase.notifications.Android.Importance.Max
//   );
//   messaging().android.createChannel(channel);
// };

// export const notificationListener = () =>
//   messaging().onNotification((notification) => {
//     const localNotification = new firebase.notifications.Notification({
//       sound: "default",
//       show_in_foreground: true,
//     })
//       .setNotificationId(notification.notificationId)
//       .setTitle(notification.title)
//       .setBody(notification.body)
//       .setData(notification.data)
//       .android.setChannelId("main-channel") // e.g. the id you chose above
//       .android.setSmallIcon("ic_noti")
//       .android.setColor("#0091D4") // you can set a color here
//       .android.setPriority(firebase.notifications.Android.Priority.High);
//     firebase
//       .notification()
//       .displayNotification(localNotification)
//       .catch((err) => {
//         // console.log("ERROR NOTIFICACION", err)
//       });
//   });

// export const notificationOpenBackListener = async () => {
//   //const { dispatch, isLoggedIn } = props;
//   const notificationOpen = await messaging().getInitialNotification();
//   if (notificationOpen) {
//     // console.log(" >>>>>>>>>> notificationOpen", notificationOpen);
//     // Agregar el codigo que se considere necesario
//   }
// };

// export const notificationOpenedListener = () =>
//   messaging().onNotificationOpened((notificationOpen) => {
//     // console.log(" >>>>>>>>>> notificationOpen", notificationOpen);
//     // Agregar el codigo que se considere necesario
//   });
