import * as actions from '../actions/ActionTypes';
import _ from 'lodash';
import Config from 'react-native-config';

const initialState = {
  isConnected: true,
  loading: false,
  forceUpdate: false,
  suggestUpdate: false,
  modalMsg: '',
  modalButtonCancel: { text: '', action: null },
  modalButtonOk: { text: '', action: null },
  events: null,
  nextEvents: {},
  eventPaymentData: [],
  anonymousMode: false,
  activeEventTicketTypes: {},
  rrppList: [],
  order: 2,
  menu: 'home', // se usa en web para manejar en mobile en que ruta esta parado el user
  appSettings: {
    unregisteredSell: false,
    facebookLogin: false,
    countryCode: '+54',
    popularCountries: ['ar', 'cl', 'uy', 'py'],
    changeCountryCode: false,
    documentTypes: ['DNI'],
    emailInfoApp: '',
    hasLoyalyProgram: 'unactive',
    hasTicketTransfer: 'unactive',
    instagramInfoApp: '',
    termsPdfUrl: '',
    appPaymentGateway: 'pagos360',
    allowTickets0: false
  },
};

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CHPROPS_GLOBAL:
      return Object.assign({}, state, action.props);
    case actions.CHPROPS_UPDATE_APP:
      return Object.assign({}, state, {
        suggestUpdate: action.suggestUpdate,
        forceUpdate: action.forceUpdate,
      });

    case 'SAVE_SELECTED_TICKETS':
      return Object.assign({}, state, {
        activeEventTicketTypes: action.activeEventTicketTypes,
      });
    case 'SAVE_TICKET_INFORMATION':
      let stateCloned = _.cloneDeep(state);
      stateCloned.events = action.events;
      return { ...stateCloned };
    case 'SAVE_EVENT_PAYMENT_INFORMATION':
      return Object.assign({}, state, {
        eventPaymentData: action.eventPaymentData,
      });
    case 'SAVE_NEXT_EVENTS_INFORMATION':
      return Object.assign({}, state, {
        nextEvents: action.nextEvents,
      });
    case 'SORT_TYPE':
      return Object.assign({}, state, { order: action.order });
    case actions.ACT_AUTH_LOGIN_USER:
      return Object.assign({}, state, { loading: true });
    case actions.SUCCESS_LOGIN:
      return Object.assign({}, state, { loading: false });
    case 'SET_ANONYMOUS_MODE':
      return Object.assign({}, state, { anonymousMode: action.anonymousMode });
    default:
      return state;
  }
}
