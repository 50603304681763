import React from "react";
import { View, Text, Dimensions, StyleSheet, Vibration } from "react-native";
import VTHeader from "../../components/VTHeader";
import Fonts from "../../common/Fonts";
import { Actions } from "react-native-router-flux";
import L from "../../common/Layout";
import { RNCamera } from "react-native-camera";
const screen = Dimensions.get("window");
import I18n from "i18next"

let SCANNER_HEIGHT = L.hProm(200);
let CORNERS_SIZE = L.wProm(60);

export default class Scanner extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { scanning: false };
    this.camera = {};
    this._setScanning = this._setScanning.bind(this);
  }

  headerProps(type) {
    return {
      leftButtons: [
        {
          onPress:
            type === "main"
              ? () => Actions.reset("login")
              : () => Actions.pop(),
          icon: {
            name: type === "main" ? "close" : "chevron-left",
            type: "MaterialIcons",
            style: {
              color: "white",
              fontSize: type === "main" ? L.h(23) : L.h(24),
              fontFamily: Fonts.MEDIUM,
            },
          },
        },
      ],
      titleStyle: {
        fontFamily: Fonts.MEDIUM,
        fontSize: L.h(20),
        color: "white",
      },
      title: "Escanear",
      noShadow: true,
      noStatusBar: true,
      backgroundColor: "transparent",
    };
  }

  _setScanning(value) {
    this.setState({ scanning: value });
  }

  onBarCodeRead(event) {
    const { navigationScreen } = this.props;
    if (!this.state.scanning) {
      Vibration.vibrate();
    }
    let data = event.data.split("@");
    if (data.length === 8 || data.length === 9) {
      let obj = {
        name: data[2],
        lastname: data[1],
        //doc_country_id: 1,
        doc_number: data[4],
        gender: data[3],
        doc_type_id: 1,
        birthDate: data[6],
      };
      Actions.push(navigationScreen, { scanData: obj, ...this.props });
      this._setScanning(true);
    }
    if (data.length === 16 || data.length === 17) {
      let obj = {
        name: data[5],
        lastname: data[4],
        //doc_country_id: 1,
        doc_number: data[1].trim(),
        gender: data[8],
        birthDate: data[7],
        doc_type_id: 1,
      };
      Actions.push(navigationScreen, { scanData: obj, ...this.props });
      this._setScanning(true);
    }
  }

  render() {
    let { scanning } = this.state;
    let borderWidth = 5;
    let backColor = "transparent";
    return (
      <View style={{ flex: 1 }}>
        <RNCamera
          ref={(cam) => {
            this.camera = cam;
          }}
          style={{ flex: 1 }}
          onFocusChanged={() => {}}
          onZoomChanged={() => {}}
          defaultTouchToFocus
          mirrorImage={false}
          onBarCodeRead={this.onBarCodeRead.bind(this)}
        >
          <View style={{ flex: 1 }}>
            <View style={styles.scannerContainer}>
              <View
                style={{
                  flex: 1,
                  backgroundColor: backColor,
                  width: "100%",
                }}
              />
              <View style={styles.scannerWrapper}>
                <View
                  style={{
                    width: "5%",
                    backgroundColor: backColor,
                    height: "100%",
                  }}
                />
                <View style={{ width: "90%" }}>
                  <View
                    style={[styles.scannerMask, { alignItems: "flex-start" }]}
                  >
                    <View
                      style={[
                        styles.scannerCorners,
                        {
                          borderLeftWidth: borderWidth,
                          borderTopWidth: borderWidth,
                          borderColor: scanning ? "green" : "white",
                        },
                      ]}
                    />
                    <View
                      style={[
                        styles.scannerCorners,
                        {
                          borderRightWidth: borderWidth,
                          borderTopWidth: borderWidth,
                          borderColor: scanning ? "green" : "white",
                        },
                      ]}
                    />
                  </View>
                  <View
                    style={[styles.scannerMask, { alignItems: "flex-end" }]}
                  >
                    <View
                      style={[
                        styles.scannerCorners,
                        {
                          borderLeftWidth: borderWidth,
                          borderBottomWidth: borderWidth,
                          borderColor: scanning ? "green" : "white",
                        },
                      ]}
                    />
                    <View
                      style={[
                        styles.scannerCorners,
                        {
                          borderRightWidth: borderWidth,
                          borderBottomWidth: borderWidth,
                          borderColor: scanning ? "green" : "white",
                        },
                      ]}
                    />
                  </View>
                </View>
                <View
                  style={{
                    width: "5%",
                    backgroundColor: backColor,
                    height: "100%",
                  }}
                />
              </View>
              <View
                style={{
                  flex: 1,
                  backgroundColor: backColor,
                  width: "100%",
                }}
              />
              <Text
                style={{
                  color: "white",
                  textAlign: "center",
                  position: "absolute",
                  bottom: L.h(150),
                  left: 0,
                  fontSize: L.h(20),
                  marginHorizontal: L.w(50),
                  fontFamily: Fonts.MEDIUM,
                }}
              >
                {I18n.t("scanner.scanerTitle")}
              </Text>
            </View>
            <VTHeader {...this.headerProps("X")} />
          </View>
        </RNCamera>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  scannerContainer: {
    flex: 1,
    //backgroundColor: "red",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    top: 0,
    left: 0,
    width: screen.width,
    height: screen.height,
  },
  scannerWrapper: {
    width: "100%",
    height: SCANNER_HEIGHT,
    //backgroundColor: "blue",
    borderRadius: 0,
    flexDirection: "row",
    alignItems: "center",
  },
  scannerMask: {
    flexDirection: "row",
    justifyContent: "space-between",
    flex: 1,
    borderRadius: 0,
  },
  scannerCorners: {
    width: CORNERS_SIZE,
    //backgroundColor: "yellow",
    height: CORNERS_SIZE,
    borderColor: "white",
    borderRadius: 0,
  },
});
