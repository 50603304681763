import RRPPConfig from "../rrpp/RRPPConfig";
import firebase from "react-native-firebase";

export default class RRPPService {
  static eventStats(eventId) {
    let token = null;
    return new Promise(async (resolve, reject) => {
      try {
        if (firebase.auth() && firebase.auth().currentUser) {
          await firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((data) => {
              token = data;
            });
        }
        let endpoint = `${RRPPConfig.rrppStats}?eventId=${eventId}`;
        let response = await RRPPConfig.APIConnector.get(endpoint, {
          headers: { Authorization: "Bearer " + token },
        });
        resolve(response);
      } catch (e) {
        reject(e);
      }
    });
  }

  static transferTicket(eventId, userDni, ticketTypeSelected, qnty, isGift) {
    let token = null;
    return new Promise(async (resolve, reject) => {
      try {
        if (firebase.auth() && firebase.auth().currentUser) {
          await firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((data) => {
              token = data;
            });
        }
        let endpoint = `${
          RRPPConfig.transferTicket
        }?eventId=${eventId}&ticketTypeId=${ticketTypeSelected}&userDni=${userDni}&ticketQt=${qnty}&isGift=${isGift}`;
        let response = await RRPPConfig.APIConnector.get(endpoint, {
          headers: { Authorization: "Bearer " + token },
        });
        console.log("responseresponse", response);
        resolve(response);
      } catch (e) {
        console.log("responseresponse", e);

        reject(e);
      }
    });
  }

  static transferTicketNoRegister(
    eventId,
    userDni,
    ticketTypeSelected,
    qnty,
    email,
    isGift
  ) {
    let token = null;
    return new Promise(async (resolve, reject) => {
      try {
        if (firebase.auth() && firebase.auth().currentUser) {
          await firebase
            .auth()
            .currentUser.getIdToken(true)
            .then((data) => {
              token = data;
            });
        }
        let endpoint = `${
          RRPPConfig.transferTicketNoReg
        }?eventId=${eventId}&ticketTypeId=${ticketTypeSelected}&userDni=${userDni}&isGift=${isGift}&ticketQt=${qnty}&userNotRegister=${true}&userNotRegisterEmail=${email}`;

        let response = await RRPPConfig.APIConnector.get(endpoint, {
          headers: { Authorization: "Bearer " + token },
        });
        console.log("responseresponse", response);
        resolve(response);
      } catch (e) {
        console.log("responseresponse", e);

        reject(e);
      }
    });
  }
}
