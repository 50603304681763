import React, { PureComponent } from 'react';
import {
  ActivityIndicator,
  Linking,
  StyleSheet,
  Text,
  View,
  ScrollView,
} from 'react-native';
import { Actions } from 'react-native-router-flux';
import L from '../../common/Layout';
import Util from '../../common/Util';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Colors from '../../common/Colors';
import Fonts from '../../common/Fonts';
import AppMem from '../../common/AppMem';
import VTHeader from '../../components/VTHeader';
import PushCard from '../../components/PushCard';
import { getNotifications, lastRead } from '../../actions/NotificationsActions';
import Icon from 'react-native-vector-icons/FontAwesome';
import I18n from 'i18next';

class Notifications extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { loading: true };
    this.onLoadEnd = this.onLoadEnd.bind(this);
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.getNotifications(this.onLoadEnd);
  }

  onLoadEnd() {
    this.props.lastRead();
    this.setState({ loading: false });
  }

  getHeaderProps() {
    return {
      titleStyle: {
        fontFamily: Fonts.MEDIUM,
        fontSize: L.h(20),
        color: Colors.notificationsTitleColor,
      },
      backgroundColor: Colors.notificationsHeaderBackground,
      //noShadow: true,
      title: I18n.t('notifications.title'),
      leftButtons: [
        {
          onPress: Actions.pop,
          icon: {
            name: 'chevron-left',
            type: 'MaterialIcons',
            style: {
              color: Colors.notificationsTitleColor,
              fontSize: L.h(23),
              marginLeft: L.w(8),
            },
            text: Util.isIos() ? '' : '',
            textStyle: {
              fontSize: L.h(18),
              color: Colors.notificationsTitleColor,
            },
          },
        },
      ],
    };
  }

  handleExternalLink = pag => {
    let modalProps = {
      title: I18n.t('notifications.leavingApp'),
      type: 'link',
      buttonOk: {
        text: I18n.t('notifications.leavingAppAccept'),
        action: () => Linking.openURL(pag),
      },
      buttonCancel: {
        text: I18n.t('notifications.leavingAppCancel'),
        action: () => {},
      },
    };
    AppMem.openModal('alert', modalProps);
  };

  onPressPush = (uri, title) => {
    Actions.webViewScreen({ title, uri });
  };

  /* <EmptyState
              img={require("../../common/assets/iconNoNoti.png")}
              text={"No se registran notificaciones para mostrar"}
            /> */

  render() {
    let { notifications } = this.props;
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: Colors.notificationsBackgroundColor,
        }}
      >
        <VTHeader {...this.getHeaderProps()} />
        <ScrollView>
          <View style={{ marginTop: L.h(12), flex: 1 }} />
          {this.state.loading ? (
            <View style={styles.activity}>
              <ActivityIndicator color={Colors.cerulean} size="small" />
            </View>
          ) : _.isEmpty(notifications) ? (
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                marginHorizontal: L.w(45),
                marginTop: '5%',
                height: 100,
              }}
            >
              <Icon
                name="bell"
                style={{
                  fontSize: L.h(44),
                  color: Colors.secondary_color,
                  marginRight: L.w(12),
                }}
              />
              <Text style={styles.emptyText}>
                {I18n.t('notifications.notificationEmptyState')}
              </Text>
            </View>
          ) : (
            _.map(notifications && notifications, (ei, i) => {
              // console.log(" >>>>>>>>>>ei.creation_time ", ei.creation_time);
              return (
                <PushCard
                  key={i}
                  message={ei.message}
                  title={ei.title}
                  date={ei.creation_time}
                  read={ei.read}
                  url={ei.url}
                  urlType={ei.urlType}
                  onPress={this.onPressPush}
                />
              );
            })
          )}
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  iconContainer: {
    width: L.wProm(55),
    height: L.hProm(55),
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#4173b0',
  },
  row: { flexDirection: 'row', alignItems: 'center' },
  img: {
    alignSelf: 'center',
    height: L.hProm(70),
    backgroundColor: 'blue',
    width: '70%',
  },
  activity: {
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: L.h(5),
  },
  emptyText: {
    color: Colors.emptyNotificationsText,
    alignSelf: 'center',
    fontSize: L.h(18),
    fontFamily: Fonts.DBOLD,
  },
});

function mapStateToProps(store) {
  return {
    notifications: store.notificationsReducer.notifications,
    no_reads: store.notificationsReducer.no_reads,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ getNotifications, lastRead }, dispatch);
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Notifications);
