import React, { useEffect, useState, useCallback } from 'react';
import firebase from 'react-native-firebase';
import Connector from '../../provider/base/Connector';
import { Actions } from 'react-native-router-flux';
import { useDispatch } from 'react-redux';
import { getUserData, listenerToMyProfile } from '../../actions';
import { ActivityIndicator } from 'react-native';
import _ from 'lodash';
import Home from '../Home';

const Redirect = ({ children, style, ...rest }) => {
  const dispatch = useDispatch();
  const [authChecked, setAuthChecked] = useState(false);
  useEffect(() => {
    if (!authChecked) {
      onAuthState();
    }
  }, [authChecked]);

  const redirect = path => {
    Actions.reset(path);
  };

  const onAuthState = useCallback(async () => {
    firebase.auth().onAuthStateChanged(async user => {
      if (!_.isEmpty(user)) {
        const idTokenResult = await user.getIdTokenResult();
        const tokenHeader = `Bearer ${idTokenResult.token}`;
        Connector.defaultHeaders.Authorization = tokenHeader;
        dispatch(
          getUserData(user.uid, () => {
            const providerConfig = {
              password: () => {
                if (user.emailVerified) {
                  setAuthChecked(true); // Marcar que la autenticación ha sido verificada
                  redirect('home');
                } else {
                  Actions.reset('activateAccount');
                  return null;
                }
              },
            };
            providerConfig['password']();
          })
        );
        dispatch(listenerToMyProfile(user.uid));
      } else {
        redirect('home');
      }
    });
  }, [dispatch, setAuthChecked]);

  return <Home />;
};

export default Redirect;
