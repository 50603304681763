import React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import L from "../common/Layout";

const IconButton = ({ onPress, label, icon, containerStyle, textStyle }) => {
  const { buttonText, buttonContainer } = styles;
  return (
    <TouchableOpacity
      onPress={onPress}
      style={[buttonContainer, containerStyle]}
    >
      {icon}
      <Text style={[buttonText, textStyle]}>{label}</Text>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#e9e9e9",
    width: L.wProm(118),
    height: L.hProm(44),
    borderRadius: 22
  },
  buttonText: {
    paddingLeft: L.w(5),
    fontSize: L.h(14),
    lineHeight: L.h(24)
  }
});

export default IconButton;
