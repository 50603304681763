import React, { Component } from 'react';
import {
  Platform,
  View,
  AppState,
  StatusBar,
  Linking,
  Dimensions,
} from 'react-native';
import NetInfo from '@react-native-community/netinfo';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import DeviceInfo from 'react-native-device-info';
import VTModal from './components/VTModal';
import Router from './config/Router';
import AppMem from './common/AppMem';
import { actCheckAppVersion, actionChangeAppProps } from './actions';
import { actValidateToken, splash, saveFCMToken } from './actions/AuthActions';
import * as constants from './common/Constants';
import VTLoaderLogo from './components/VTLoaderLogo';
import VTCalendar from './components/VTCalendar';
import Firebase from 'react-native-firebase';
import L from './common/Layout';
import VTUpdateAppModal from './components/VTUpdateAppModal';
import Util from './common/Util';
import { getNotifications } from './actions/NotificationsActions';
import WebAppNav from './components/web/WebAppNav';
import { requestPermission } from './common/Notifications';
import { Toaster } from 'react-hot-toast';
const height = Dimensions.get('window').height;
class AppRoot extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { appState: AppState.currentState };
    this._handleAppStateChange = this._handleAppStateChange.bind(this);
    this.handleConnectivityChange = this.handleConnectivityChange.bind(this);
    if (!Util.isIos()) {
      StatusBar.setTranslucent(true);
      StatusBar.setBackgroundColor('rgba(0, 0, 0, 0.2)');
    }

    this.refAppLoader = null;
    this.refModal = null;
    this.refCalendar = null;
    if (Platform.OS == 'web') {
      this.refAppLoader = React.createRef();
      this.refModal = React.createRef();
      this.refCalendar = React.createRef();
    }
  }

  componentWillMount() {
    //FIX CONCURRENCIA PARA IOS, SIN EL TIMEOUT LA APP TIRA NETWORK ERROR

    if (Platform.OS != 'web') {
      if (!Util.isIos()) {
        requestPermission();
      }
      setTimeout(
        () =>
          this.props.actCheckAppVersion(res => {
            let appVersion = DeviceInfo.getVersion();
            let beVersion = Util.isIos()
              ? res?.minIosBinaryVersion
              : res?.minAndroidBinaryVersion;
            let storeUrl = Util.isIos()
              ? res?.iosStoreUrl
              : res?.androidStoreUrl;
            let forceVersion = beVersion > appVersion;
            let maintenance = res?.maintenance;
            if (forceVersion || maintenance?.isMaintenance) {
              setTimeout(() => {
                //TODO: Remover este veneno de aca
                AppMem.closeAppLoader();
              }, 5000);
              AppMem.openModal(
                <VTUpdateAppModal
                  onPress={() =>
                    Linking.openURL(storeUrl).catch(err =>
                      console.log('Error ', err)
                    )
                  }
                  maintenance={maintenance?.isMaintenance}
                  message={maintenance?.message}
                />,
                {
                  style: { height: L.hProm(320), borderRadius: 12 },
                  backdropPressToClose: false,
                  swipeToClose: false,
                  mustBeOpen: true,
                }
              );
            } else {
              setTimeout(() => {
                //TODO: Remover este veneno de aca
                AppMem.closeModal();
              }, 3000);
            }
          }),
        3000
      );
      const channel = new Firebase.notifications.Android.Channel(
        'main-channel',
        'main-channel',
        Firebase.notifications.Android.Importance.Max
      );
      Firebase.notifications().android.createChannel(channel);

      //Determina el estado de conexion actual
      NetInfo.isConnected.fetch().then(isConnected => {
        this.props.actionChangeAppProps({ isConnected: isConnected });
        AppMem.isConnected = isConnected;
      });

      // Setea un listener por cambios en el estado de conexion
      NetInfo.isConnected.addEventListener(
        'connectionChange',
        this.handleConnectivityChange
      );
    } else {
      this.props.actCheckAppVersion();
    }
  }

  async componentDidMount() {
    AppState.addEventListener('change', this._handleAppStateChange);

    if (Platform.OS == 'web') {
      AppMem.appLoader = this.refAppLoader.current;
      AppMem.modalRef = this.refModal.current;
      AppMem.calendar = this.refCalendar.current;
    } else {
      AppMem.appLoader = this.refs.loader;
      AppMem.modalRef = this.refs.modal && this.refs.modal;
      AppMem.calendar = this.refs.calendar;
    }

    this.checkPermission();

    if (Platform.OS != 'web') {
      this.getInitialNotif = Firebase.notifications()
        .getInitialNotification()
        .then((notificationOpen: NotificationOpen) => {
          console.log(' >>>>>>>>>> getInitialNotification');
          if (notificationOpen) {
            // eslint-disable-next-line no-undef
            const notification: Notification = notificationOpen.notification;
            console.log(
              '*************************************** getInitialNotification ***************************************',
              notification
            );
            this.handlerNotification(notification, true);
          }
        });
      this.getInitialNotif = Firebase.notifications().onNotificationOpened(
        (notificationOpen: NotificationOpen) => {
          console.log(' >>>>>>>>>> ENTRA ACAAAAAAA 2 ');
          console.log(
            '*************************************** NotificationOpen ***************************************'
          );
          this.handlerNotification(notificationOpen.notification, false);
          // eslint-disable-next-line no-undef
          const notification: Notification = notificationOpen.notification;
        }
      );
      this.notificationDisplayedListener = Firebase.notifications().onNotificationDisplayed(
        notification => {
          if (this.props.user) this.props.getNotifications(() => null);
          console.log(
            '*************************************** notificationDisplayedListener ***************************************',
            notification
          );
        }
      );

      this.notificationListener = Firebase.notifications().onNotification(
        notification => {
          console.log(
            '*************************************** LISTENER FOREGROUN ***************************************'
          );

          const localNotification = new Firebase.notifications.Notification({
            sound: 'default',
            show_in_foreground: true,
          })
            .setNotificationId(notification.notificationId)
            .setTitle(notification.title)
            .setBody(notification.body)
            .setData(notification.data)
            .android.setChannelId('main-channel') // e.g. the id you chose above
            .android.setSmallIcon('ic_noti') // create this icon in Android Studio
            .android.setColor('#ED3237') // you can set a color here
            .android.setPriority(Firebase.notifications.Android.Priority.High);
          Firebase.notifications()
            .displayNotification(localNotification)
            .catch(err => console.log('ERROR NOTIFICACION', err));
          if (this.props.user) this.props.getNotifications(() => null);
        }
      );
    }
  }

  async getToken() {
    fcmToken = await Firebase.messaging().getToken();
    if (fcmToken) {
      //console.log(" >>>>>>>>>> fcmToken", fcmToken);
      //this.props.saveFCMToken(fcmToken);
      this.fcmToken = fcmToken;
    }
  }

  handleConnectivityChange(isConnected) {
    if (isConnected) {
      this.props.actionChangeAppProps({ isConnected: true });
      AppMem.isConnected = true;
      console.log('NetworkConnection ON!');
    } else {
      this.props.actionChangeAppProps({ isConnected: false });
      AppMem.isConnected = false;
      console.log('NetworkConnection OFF!');
    }
  }

  handlerNotification = (notification, initial) => {
    //let { actNotificationData } = this.props;// eslint-disable-next-line react/prop-types
    let notificationHandler = {
      push_screen: notification._data.screen,
      ...notification,
    };
    Firebase.notifications().removeDeliveredNotification(
      notification._notificationId
    );
    notificationHandler.isInitial = initial;
    // eslint-disable-next-line react/no-unused-state
    //actNotificationData( { notifications: notificationHandler } );
  };

  // 1
  async checkPermission() {
    if (Platform.OS != 'web') {
      const enabled = await Firebase.messaging().hasPermission();
      if (enabled) {
        this.getToken();
      } else {
        this.requestPermission();
      }
    }
  }

  // 2
  async requestPermission() {
    try {
      await Firebase.messaging().requestPermission();
      // User has authorised
      this.getToken();
    } catch (error) {
      // User has rejected permissions
      console.log('permission rejected');
    }
  }

  onErrorCallback() {
    let modalProps = {
      title: 'Error al inciar sesión',
      type: 'warning',
      buttonOk: { text: 'Accept', action: () => null },
    };
    AppMem.openModal('alert', modalProps);
  }

  componentWillUnmount() {
    AppState.removeEventListener('change', this._handleAppStateChange);
    NetInfo.isConnected.removeEventListener(
      'connectionChange',
      this.handleConnectivityChange
    );
  }

  _handleAppStateChange(nextAppState) {
    if (Platform.OS != 'web') {
      if (
        this.state.appState.match(/inactive|background/) &&
        nextAppState === 'active'
      ) {
        console.log('App has come to the foreground!');
        if (this.props.user) this.props.getNotifications(() => null);
      } else {
        console.log('App has come to the background!');
      }
      this.setState({ appState: nextAppState });
    }
  }

  render() {
    console.log('==================================== apprut');
    return (
      <View style={{ flex: 1, width: '100%' }}>
        {Platform.OS == 'web' ? (
          <WebAppNav user={this.props.user}>
            <Router />
            <VTModal ref={this.refModal} />
            <Toaster
              position="bottom-right"
              containerStyle={{
                top: 20,
                left: 20,
                bottom: 80,
                right: 50,
              }}
            />
            <VTCalendar ref="calendar" />
            <VTLoaderLogo
              ref={this.refAppLoader}
              style={{ height: '1000px' }}
            />
          </WebAppNav>
        ) : (
          <>
            <Router />
            <VTModal ref="modal" />
            <VTCalendar ref="calendar" />
            <VTLoaderLogo ref="loader" />
          </>
        )}
      </View>
    );
  }
}

function mapStateToProps({ appReducer, authReducer }) {
  return {
    forceUpdate: appReducer.forceUpdate,
    suggestUpdate: appReducer.suggestUpdate,
    isConnected: appReducer.isConnected,
    user: authReducer.user,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actCheckAppVersion,
      actionChangeAppProps,
      actValidateToken,
      splash,
      saveFCMToken,
      getNotifications,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppRoot);
