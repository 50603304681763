import React, { PureComponent } from 'react';
import { Helmet } from 'react-helmet';
import Pagos360Html from './Pagos360Html.web';

import { View } from 'react-native';
import P360Script1 from './P360Script1';
import P360Script2 from './P360Script2';

/*
Uncaught TypeError: Cannot read properties of null (reading 'style')
    at validateEmail (<anonymous>:243:47)
    at HTMLInputElement.onblur ((index):1:1)
*/

export default class Pagos360Web extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <View>
        <Helmet>
          <style type="text/css">{` 
    body {
      background-color: rgb(34, 34, 34);
    }
    
    .spinner {
      height: 50px;
      width: 50px;
      -webkit-animation: rotation 0.6s infinite linear;
      -moz-animation: rotation 0.6s infinite linear;
      -o-animation: rotation 0.6s infinite linear;
      animation: rotation 0.6s infinite linear;
      border-left: 6px solid rgba(0, 174, 239, 0.15);
      border-right: 6px solid rgba(0, 174, 239, 0.15);
      border-bottom: 6px solid rgba(0, 174, 239, 0.15);
      border-top: 6px solid rgba(0, 174, 239, 0.9);
      border-radius: 100%;
    }
    
    @-webkit-keyframes rotation {
      from {
        -webkit-transform: rotate(0deg);
      }
    
      to {
        -webkit-transform: rotate(359deg);
      }
    }
    
    @-moz-keyframes rotation {
      from {
        -moz-transform: rotate(0deg);
      }
    
      to {
        -moz-transform: rotate(359deg);
      }
    }
    
    @-o-keyframes rotation {
      from {
        -o-transform: rotate(0deg);
      }
    
      to {
        -o-transform: rotate(359deg);
      }
    }
    
    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
    
      to {
        transform: rotate(359deg);
      }
    }
    
    .loading-container {
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0px;
      top: 0px;
      width: 100%;
      opacity: 0.7;
      background-color: black;
      color: white;
      z-index: 10;
      display: none;
    }
    
    .amount-container {
      background-color: rgb(200, 159, 123);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 125px;
    }
    
    .amount-text {
      font-family: Open Sans, sans-serif;
      font-size: 14px;
      font-weight: normal;
      line-height: 1;
      letter-spacing: normal;
      top: initial;
      color: #ffffff;
      position: initial;
    }
    
    .amount {
      font-family: Open Sans, sans-serif;
      font-size: 44px;
      font-weight: 600;
      top: initial;
      color: #ffffff;
      position: initial;
      line-height: 1.2;
      letter-spacing: normal;
    }
    
    .container {
      display: flex;
      flex-direction: column;
    }
    
    .row-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-left: 25px;
      margin-right: 25px;
    }
    
    .row-single-container {
      margin-left: 25px;
      margin-right: 25px;
    }
    
    .row-single-container .form__field {
      width: 100%;
    }
    
    .img-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      background-color: #3eb4f1;
      padding-top: 22px;
      padding-bottom: 20px;
    }
    
    /****  floating-Lable style start ****/
    
    .floating-label {
      position: relative;
      margin-bottom: 20px;
    }
    
    select.floating-select {
      padding: 5px 35px 5px 5px;
      font-size: 16px;
      border-radius: 0px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url("../../common/assets/select-icon.png") right no-repeat;
    }
    
    .floating-input,
    .floating-select {
      font-size: 16px;
      padding: 4px 4px;
      display: block;
      width: 100%;
      /* height: 30px; */
      color: #5a5a5a;
      background-color: transparent;
      border: none;
      border-bottom: 1px solid #d8d8d8;
      border-radius: 0px;
    }
    
    .floating-label-error {
      color: red !important;
    }
    
    .floating-input-error {
      border-bottom: 2px solid red !important;
    }
    
    .floating-input:focus,
    .floating-select:focus {
      outline: none;
      border-bottom: 2px solid rgb(242, 77, 124);
    }
    
    .button-container {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding-bottom: 20px;
    }
    
    .button-confirm {
      background-color: rgb(200, 159, 123);
      background: -webkit-linear-gradient(left, rgb(200, 159, 123) 0%, rgb(200, 159, 123) 100%);
      width: 90%;
      height: 44px;
      color: #ffffff;
      font-size: 17px;
      border: 0px;
      border-radius: 22px;
      margin: 5px;
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    }
    
    label {
      color: #999;
      font-size: 14px;
      font-weight: normal;
      font-family: Open Sans, sans-serif;
      position: absolute;
      pointer-events: none;
      /* left: 5px; */
      /* top: -18px; */
      transition: 0.2s ease all;
      -moz-transition: 0.2s ease all;
      -webkit-transition: 0.2s ease all;
    }
    
    ::placeholder {
      /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: rgb(194, 194, 194);
      opacity: 1;
      /* Firefox */
    }
    
    .floating-input:focus~label,
    .floating-input:not(:placeholder-shown)~label {
      /* top: -18px; */
      font-size: 14px;
      color: white;
    }
    
    .floating-place:focus~label,
    .floating-place:focus:placeholder-shown~label {
      /* top: -18px; */
      font-size: 14px;
      color: #9b9b9b;
    }
    
    .floating-place:placeholder-shown~label {
      top: -18px;
      font-size: 14px;
      color: #999;
    }
    
    .floating-select:focus~label,
    .floating-select:not([value=""]):valid~label {
      top: -18px;
      font-size: 14px;
    }
    
    /* active state */
    
    .floating-input:focus~.bar:before,
    .floating-input:focus~.bar:after,
    .floating-select:focus~.bar:before,
    .floating-select:focus~.bar:after {
      width: 50%;
    }
    
    *,
    *:before,
    *:after {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
    
    .floating-textarea {
      min-height: 30px;
      max-height: 260px;
      overflow: hidden;
      overflow-x: hidden;
    }
    
    /* highlighter */
    
    .highlight {
      position: absolute;
      height: 50%;
      width: 100%;
      top: 15%;
      left: 0;
      pointer-events: none;
      opacity: 0.5;
    }
    
    /* active state */
    
    .floating-input:focus~.highlight,
    .floating-select:focus~.highlight {
      -webkit-animation: inputHighlighter 0.3s ease;
      -moz-animation: inputHighlighter 0.3s ease;
      animation: inputHighlighter 0.3s ease;
    }
    
    /* animation */
    
    @-webkit-keyframes inputHighlighter {
      from {
        /*background: #2d94d3;*/
      }
    
      to {
        width: 0;
        background: transparent;
      }
    }
    
    @-moz-keyframes inputHighlighter {
      from {
        /*background: #2d94d3;*/
      }
    
      to {
        width: 0;
        background: transparent;
      }
    }
    
    @keyframes inputHighlighter {
      from {
        /*background: #2d94d3;*/
      }
    
      to {
        width: 0;
        background: transparent;
      }
    }
    
    /****  floating-Lable style end ****/
    /* Tarjeta */
    
    .card {
      width: 320px;
      height: 190px;
      -webkit-perspective: 600px;
      -moz-perspective: 600px;
      perspective: 600px;
      margin: 20px;
    }
    
    .card__part {
      box-shadow: 0px 0px 8px -3px #aaa3a3;
      top: 0;
      position: absolute;
      z-index: 1000;
      left: 0;
      display: inline-block;
      width: 320px;
      height: 190px;
      background-image: url("https://image.ibb.co/bVnMrc/g3095.png"), linear-gradient(to right bottom, rgb(201, 89, 51), #fafafa, #f8f8f8, #f2f2f2, rgb(241, 209, 43));
      /*linear-gradient(to right bottom, #fd8369, #fc7870, #f96e78, #f56581, #ee5d8a)*/
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      border-radius: 8px;
      -webkit-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      -moz-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      -ms-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      -o-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
      -webkit-transform-style: preserve-3d;
      -moz-transform-style: preserve-3d;
      -webkit-backface-visibility: hidden;
      -moz-backface-visibility: hidden;
    }
    
    .card__front {
      padding: 18px;
      -webkit-transform: rotateY(0);
      -moz-transform: rotateY(0);
    }
    
    .card__square {
      border-radius: 5px;
      height: 30px;
    }
    
    .card_numer {
      font-family: Open Sans, sans-serif;
      content: attr(data-title);
      display: block;
      width: 100%;
      word-spacing: 4px;
      font-size: 20px;
      letter-spacing: 2px;
      color: #777;
      text-align: center;
      margin-bottom: 20px;
      margin-top: 20px;
    }
    
    .card__space-75 {
      width: 75%;
      float: left;
    }
    
    .card__space-25 {
      width: 25%;
      float: left;
    }
    
    .card__space-50 {
      width: 50%;
      float: left;
    }
    
    .card__label {
      font-family: Open Sans, sans-serif;
      font-size: 10px;
      text-transform: uppercase;
      color: #777;
      letter-spacing: 1px;
    }
    
    .card__info {
      font-family: Open Sans, sans-serif;
      margin-bottom: 0;
      margin-top: 5px;
      font-size: 13px;
      line-height: 18px;
      color: #777;
      letter-spacing: 1px;
      text-transform: uppercase;
    }
    
    .separator-date {
      align-items: center;
      display: flex;
      margin-left: 5px;
      color: #5a5a5a;
      font-weight: bold;
    }
    
    .container-form {
      display: block;
    }
    
    .card {
      flex: 1;
      margin: 0 auto;
      margin-top: 20px;
    }
    
    .form-class {
      flex: 2;
    }
    
    .dni-field {
      padding-top: 20px;
      flex: 0.8;
    }
    
    .dni-number {
      padding-top: 20px;
      flex: 2;
      margin-left: 10px;
    }
    
    .label-error {
      color: red;
      font-family: Open Sans, sans-serif;
      font-size: 14px;
      padding-top: 5px;
      display: none;
      overflow: hidden;
      white-space: nowrap;
    }
    
    .form__group {
      position: relative;
      padding: 15px 15px;
      width: 90%;
      /* margin-top: 20px;
      width: 80%; */
    }
    
    .form__text {
      position: relative;
      padding: 30px 15px 0px;
      width: 90%;
      font-size: 1.6rem;
      /* margin-top: 20px;
      width: 80%; */
    }
    
    .form__text span {
      font-family: Open Sans, sans-serif;
      color: white;
      font-weight: bold;
    }
    
    .row-single-container .form__group {
      position: relative;
      width: 100%;
    }
    
    .form__field {
      font-family: Open Sans, sans-serif;
      /* width: 100%; */
      border: 0;
      border-bottom: 2px solid #9b9b9b;
      outline: 0;
      font-size: 1.6rem;
      color: #9b9b9b;
      padding: 7px 0;
      background: transparent;
      transition: border-color 0.2s;
    }
    
    .form__field::placeholder {
      color: transparent;
    }
    
    .form__field:placeholder-shown~.form__label {
      font-size: 1.6rem;
      cursor: text;
      top: 20px;
    }
    
    .form__label {
      position: absolute;
      top: 0;
      display: block;
      transition: 0.2s;
      font-size: 1.6rem;
      color: #9b9b9b;
    }
    
    .form__field:focus {
      padding-bottom: 6px;
      font-weight: 700;
      border-bottom-width: 3px;
      border-image: linear-gradient(to right, rgb(86, 161, 209), rgb(123, 223, 255));
      border-image-slice: 1;
    }
    
    .form__field:focus~.form__label {
      position: absolute;
      top: 0;
      display: block;
      transition: 0.2s;
      font-size: 1.6rem;
      color: rgb(123, 223, 255);
      font-weight: 700;
    }
    
    /* reset input */
    .form__field:required,
    .form__field:invalid {
      box-shadow: none;
    }
    
    .jp-card .jp-card-front,
    .jp-card .jp-card-back {
      background: linear-gradient(to right, rgb(86, 161, 209), rgb(123, 223, 255)) !important;
    }
    `}</style>

          <script
            src="https://checkout.pagos360.com/assets/sdk-checkout/pagos360.js"
            type="text/javascript"
          />

          <script>{`
    var dataInput;

    /* No aplica para WEB
    function waitForBridge() {
      if (window.postMessage.length !== 1) {
        setTimeout(waitForBridge, 500);
      }
    }
    window.onload = waitForBridge;*/

    function onMessageRN(data) {
      //alert('MSJ RN ' + data);
      //WEB99 No hace falta el trasnform, lo mandamos directamente en JSON => dataInput = JSON.parse(data.data);
      dataInput = data;
      // console.log("datainput", dataInput.datos_pago)
      var label = document.getElementById("lblAmount");
      // label.innerHTML = dataInput.datos_pago.importeFormat;
      label.innerHTML = dataInput.datos_pago.importeFormat;
      document.querySelector('[data-pagos360="card_holder_doc_number"]').setAttribute('value', dataInput.datos_pago.dni);
      document.querySelector('[data-pagos360="card_holder_doc_number"]').setAttribute('placeholder', dataInput.datos_pago.dni);
      document.querySelector('[data-pagos360="email"]').setAttribute('value', dataInput.datos_pago.email);
      document.querySelector('[data-pagos360="email"]').setAttribute('placeholder', dataInput.datos_pago.email);
    }

    /* No Aplica para WEB
    if (typeof platform !== 'undefined' && platform == 'ios') {
      this.window.addEventListener("message", onMessageRN);
    } else {
      document.addEventListener("message", onMessageRN);
    }*/ 

    var ccErrorNo = 0;
    var ccErrors = new Array()

    ccErrors[0] = "Tarjeta no valida";
    ccErrors[1] = "Formato no valido";
    ccErrors[2] = "Numero no valido";

    var cards = new Array();

    cards[0] = {
      name: "Visa",
      length: "13,16",
      prefixes: "4",
      cvc: "3",
      checkdigit: true
    };
    cards[1] = {
      name: "MasterCard",
      length: "16",
      prefixes: "51,52,53,54,55",
      cvc: "3",
      checkdigit: true
    };
    cards[2] = {
      name: "DinersClub",
      length: "14,16",
      prefixes: "36,38,54,55",
      cvc: "3",
      checkdigit: true
    };
    cards[3] = {
      name: "AmEx",
      length: "15",
      prefixes: "34,37",
      cvc: "4",
      checkdigit: true
    };
    cards[4] = {
      name: "Discover",
      length: "16",
      prefixes: "6011,622,64,65",
      cvc: "4",
      checkdigit: true
    };
    cards[5] = {
      name: "Maestro",
      length: "12,13,14,15,16,18,19",
      prefixes: "5018,5020,5038,6304,6759,6761,6762,6763",
      cvc: "3",
      checkdigit: true
    };
    cards[6] = {
      name: "VisaElectron",
      length: "16",
      prefixes: "4026,417500,4508,4844,4913,4917",
      cvc: "3",
      checkdigit: true
    };
    cards[7] = {
      name: "LaserCard",
      length: "16,17,18,19",
      prefixes: "6304,6706,6771,6709",
      cvc: "3",
      checkdigit: true
    };
    cards[8] = {
      name: "Naranja",
      length: "16",
      prefixes: "589562",
      cvc: "3",
      checkdigit: false
    };
    cards[9] = {
      name: "Cabal",
      length: "16",
      prefixes: "589657",
      cvc: "3",
      checkdigit: false
    };


    function getCreditCardType(number) {
      for (var i = 0; i < cards.length; i++) {
        var prefix = new Array();
        var lengths = new Array();
        prefix = cards[i].prefixes.split(",");
        for (j = 0; j < prefix.length; j++) {
          var exp = new RegExp("^" + prefix[j]);
          if (exp.test(number))
            return cards[i];
        }
      }
      return -1;
    }

    function checkCreditCard() {
      var cardType = getCreditCardType(document.querySelector('[data-pagos360="card_number"]').value);
      if (cardType == -1) {
        ccErrorNo = 0;
        return false;
      }

      var cardNo = document.querySelector('[data-pagos360="card_number"]').value
      var cardexp = /^[0-9]{13,19}$/;
      if (!cardexp.exec(cardNo)) {
        ccErrorNo = 1;
        return false;
      }

      // Now check the modulus 10 check digit - if required
      if (cardType.checkdigit) {
        var checksum = 0; // running checksum total
        var mychar = ""; // next char to process
        var j = 1; // takes value of 1 or 2

        // Process each digit one by one starting at the right
        var calc;
        for (i = cardNo.length - 1; i >= 0; i--) {

          // Extract the next digit and multiply by 1 or 2 on alternative digits.
          calc = Number(cardNo.charAt(i)) * j;

          // If the result is in two digits add 1 to the checksum total
          if (calc > 9) {
            checksum = checksum + 1;
            calc = calc - 10;
          }

          // Add the units element to the checksum total
          checksum = checksum + calc;

          // Switch the value of j
          if (j == 1) {
            j = 2
          } else {
            j = 1
          };
        }

        // All done - if checksum is divisible by 10, it is a valid modulus 10.
        // If not, report an error.
        if (checksum % 10 != 0) {
          ccErrorNo = 2;
          return false;
        }
      }

      // Check it's not a spam number
      if (cardNo == '5490997771092064') {
        ccErrorNo = 2;
        return false;
      }

      // The following are the card-specific checks we undertake.
      var LengthValid = false;
      var PrefixValid = false;
      var undefined;

      // We use these for holding the valid lengths and prefixes of a card type
      var prefix = new Array();
      var lengths = new Array();

      // Load an array with the valid prefixes for this card
      prefix = cardType.prefixes.split(",");

      // Now see if any of them match what we have in the card number
      for (i = 0; i < prefix.length; i++) {
        var exp = new RegExp("^" + prefix[i]);
        if (exp.test(cardNo)) PrefixValid = true;
      }

      // If it isn't a valid prefix there's no point at looking at the length
      if (!PrefixValid) {
        ccErrorNo = 2;
        return false;
      }

      // See if the length is valid for this card
      lengths = cardType.length.split(",");
      for (j = 0; j < lengths.length; j++) {
        if (cardNo.length == lengths[j]) LengthValid = true;
      }

      // See if all is OK by seeing if the length was valid. We only check the length if all else was 
      // hunky dory.
      if (!LengthValid) {
        ccErrorNo = 2;
        return false;
      };

      return true;
    }

    function validateEmail() {
      var valid = true;
      if (document.querySelector('[data-pagos360="email"]').value == "") {
        // email requerido
        document.getElementById('email-error').style.display = 'block';
        document.getElementById('email-error').innerHTML = 'Campo requerido';
        document.querySelector('[data-pagos360="email"]').classList.add("floating-input-error")
        document.getElementById('email-label').classList.add("floating-label-error")
        valid = false;
      } else if (!/^\\w+([\\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,3})+$/.test(
        document.querySelector('[data-pagos360="email"]').value
      )) {
        // email no valido
        document.getElementById('email-error').style.display = 'block';
        document.getElementById('email-error').innerHTML = 'Formato no v&aacute;lido';
        document.querySelector('[data-pagos360="email"]').classList.add("floating-input-error")
        document.getElementById('email-label').classList.add("floating-label-error")
        valid = false;
      } else {
        document.getElementById('email-error').style.display = 'none';
        document.querySelector('[data-pagos360="email"]').classList.remove("floating-input-error")
        document.getElementById('email-label').classList.remove("floating-label-error")
      }
      return valid;
    }


    function validateCardNumber() {
      var valid = true;

      // Coloca los datos de la tarjeta en el campo correspondiente
      var card = document.getElementById("card").value;
      if (card) {
        card = replaceAll(card, ' ', '');
        document.getElementById("card_number").value = card;
      }

      if (
        document.querySelector('[data-pagos360="card_number"]').value == ""
      ) {
        // card number requerido
        //document.getElementById('card-number-error').style.display = 'block';
        //document.getElementById('card-number-error').innerHTML = 'Campo requerido';
        //document.querySelector('[data-pagos360="card_number"]').classList.add("floating-input-error")
        //document.getElementById('card-number-label').classList.add("floating-label-error")
        document.getElementById('card').classList.add("floating-input-error");
        valid = false;
      } else if (!/^\\d+$/.test(
        document.querySelector('[data-pagos360="card_number"]').value
      )) {
        // card number no valido (solo numerico)
        /*document.getElementById('card-number-error').style.display = 'block';
        document.getElementById('card-number-error').innerHTML = 'Formato no válido (ingrese sólo números)';
        document.querySelector('[data-pagos360="card_number"]').classList.add("floating-input-error")
        document.getElementById('card-number-label').classList.add("floating-label-error")*/
        document.getElementById('card').classList.add("floating-input-error");
        valid = false;
      } else if (!checkCreditCard(document.querySelector('[data-pagos360="card_number"]').value)) {
        /*document.getElementById('card-number-error').style.display = 'block';
        document.getElementById('card-number-error').innerHTML = ccErrors[ccErrorNo];
        document.querySelector('[data-pagos360="card_number"]').classList.add("floating-input-error")
        document.getElementById('card-number-label').classList.add("floating-label-error")*/
        document.getElementById('card').classList.add("floating-input-error");
        valid = false;
      } else { // No hay error  99

        /*document.getElementById('card-number-error').style.display = 'none';
        document.querySelector('[data-pagos360="card_number"]').classList.remove("floating-input-error")
        document.getElementById('card-number-label').classList.remove("floating-label-error")*/
        document.getElementById('card').classList.remove("floating-input-error");
      }
      return valid;
    }

    function validateCardExpirationMonthAndYear() {

      //Setea los datos
      var expiry = document.getElementById("expiry").value;
      var newstr = expiry.replace(' / ', '');
      if (newstr.length == 4) {
        document.getElementById("card_expiration_month").value = newstr.substring(0, 2);
        document.getElementById("card_expiration_year").value = newstr.substring(2, 4);
      } else { // esta mal
        //99alert('Formato incorrecto');
      }

      var validMonth = validateCardExpirationMonth();
      if (!validMonth)
        return false;
      var validYear = validateCardExpirationYear();
      if (!validYear)
        return false;


      return true; // valid
    }

    function validateCardExpirationMonth() {
      var valid = true;

      if (
        document.querySelector('[data-pagos360="card_expiration_month"]')
          .value == ""
      ) {
           document.getElementById('expiry').classList.add("floating-input-error");
        valid = false;
      } else if (!/^(0[1-9]|1[0-2])$/.test(
        document.querySelector('[data-pagos360="card_expiration_month"]')
          .value
      )) {
           document.getElementById('expiry').classList.add("floating-input-error");
        valid = false;
      } else {
         document.getElementById('expiry').classList.remove("floating-input-error");
      }

      return valid;
    }

    function validateCardExpirationYear() {
      var valid = true;

      if (
        document.querySelector('[data-pagos360="card_expiration_year"]')
          .value == ""
      ) {
         document.getElementById('expiry').classList.add("floating-input-error");
        valid = false;
      } else if (!/^(\\d{2})$/.test(
        document.querySelector('[data-pagos360="card_expiration_year"]')
          .value
      )) {
         document.getElementById('expiry').classList.add("floating-input-error");
        valid = false;
      } else {
         document.getElementById('expiry').classList.remove("floating-input-error");
      }

      return valid;
    }

    function validateSecurityCode() {
      var valid = true;

      if (
        document.querySelector('[data-pagos360="security_code"]').value == ""
      ) {
             document.querySelector('[data-pagos360="security_code"]').classList.add("floating-input-error");
        valid = false;
      } else if (!/^(\\d{3,4})$/.test(
        document.querySelector('[data-pagos360="security_code"]').value
      )) {
         document.querySelector('[data-pagos360="security_code"]').classList.add("floating-input-error")
        valid = false;
      } else if (!isValidSegCode(document.querySelector('[data-pagos360="security_code"]').value)) {
          document.querySelector('[data-pagos360="security_code"]').classList.add("floating-input-error")
        valid = false;
      } else {
         document.querySelector('[data-pagos360="security_code"]').classList.remove("floating-input-error");
      }

      return valid;
    }

    function validateCardHolderName() {
      var valid = true;

      if (
        document.querySelector('[data-pagos360="card_holder_name"]').value == ""
      ) {
         document.querySelector('[data-pagos360="card_holder_name"]').classList.add("floating-input-error");
        valid = false;
      } else {
         document.querySelector('[data-pagos360="card_holder_name"]').classList.remove("floating-input-error")
      }

      return valid;
    }

    function validateCardHolderDocNumber() {
      var valid = true;

      if (
        document.querySelector('[data-pagos360="card_holder_doc_number"]')
          .value == ""
      ) {
         document.querySelector('[data-pagos360="card_holder_doc_number"]').classList.add("floating-input-error");
        valid = false;
      } else if (!/^(\\d{7,9})$/.test(
        document.querySelector('[data-pagos360="card_holder_doc_number"]').value
      )) {
         document.querySelector('[data-pagos360="card_holder_doc_number"]').classList.add("floating-input-error");
         valid = false;
      } else {
         document.querySelector('[data-pagos360="card_holder_doc_number"]').classList.remove("floating-input-error")
      }

      return valid;
    }

    function isValidSegCode() {
      var cardType = getCreditCardType(document.querySelector('[data-pagos360="card_number"]').value);
      if (cardType.cvc != document.querySelector('[data-pagos360="security_code"]').value.length) {
        return false;
      }
      return true;
    }
    function replaceAll(string, search, replace) {
      return string.split(search).join(replace);
    }

    function fillFormatedData() {

      var expiry = document.getElementById("expiry").value;
      var newstr = expiry.replace(' / ', '');
      if (newstr.length == 4) {
        document.getElementById("card_expiration_month").value = newstr.substring(0, 2);
        document.getElementById("card_expiration_year").value = newstr.substring(2, 4);
      } else { // esta mal
        //99alert('Formato incorrecto');
      }


      var card = document.getElementById("card").value;
      if (card) {
        card = replaceAll(card, ' ', '');
        document.getElementById("card_number").value = card;
      }


    }

    function validate() {
      fillFormatedData();
      let valid = true;
      // Comprobamos cada validación y actualizamos valid en consecuencia
      valid = valid && validateCardNumber();
      valid = valid && validateCardExpirationMonthAndYear();
      valid = valid && validateSecurityCode();
      valid = valid && validateCardHolderName();
      valid = valid && validateCardHolderDocNumber();

      console.log('validateCardNumber()', validateCardNumber());
      console.log('validateCardExpirationMonthAndYear()', validateCardExpirationMonthAndYear());
      console.log('validateSecurityCode()', validateSecurityCode());
      console.log('validateCardHolderName()', validateCardHolderName());
      console.log('validateCardHolderDocNumber()', validateCardHolderDocNumber());
      console.log("valid",valid)

      if (!valid) {
        // Si alguna validación falla, enviamos un mensaje y salimos de la función
        window.reactOnMessage(JSON.stringify({
          valid: true
        }));
        return false;
      }

      // Si todas las validaciones pasan, enviamos un mensaje de carga y llamamos a Pay()
      window.reactOnMessage(JSON.stringify({
        loading: true
      }));
      Pay();
    }


    function Pay() {
      if (
        dataInput &&
        dataInput.datos_pago &&
        dataInput.datos_pago.url_checkout
      ) {
        document.getElementById("loading").style.display = "flex";
        // obtengo en uuid de la solicitud
        const url = dataInput.datos_pago.url_checkout.split("/");
        const uuid = url[url.length - 1];
        // Inicializar Pagos360
        const pagos360 = new Pagos360();
        pagos360.setTimeout(10000);// Cambio el timeout al doble, 10 seg.
        // Obtener formulario
        var form = document.getElementById("formulario");
        var cardType = dataInput.datos_pago.card_type;
        var respWC = {};
        try {
          const channel = pagos360.getPaymentMethod(form, uuid, cardType);


          if (channel) {
            try {
              respWC = pagos360.payWithChannel(form, uuid); // Pago realizado correctamente
              respWC.statusCode = 200;
              respWC.payload = {
                id_preferencia_pago: dataInput.datos_pago.id_preferencia_pago,
                uuid: uuid
              };
              window.reactOnMessage(JSON.stringify(respWC));
              document.getElementById("loading").style.display = "none";
            } catch (e) {
              respWC = e;
              window.reactOnMessage(JSON.stringify(respWC));
              document.getElementById("loading").style.display = "none";
            }

          } else {
            pagos360.createToken(form, (state, response) => {

              try {
                respWC = pagos360.payWithoutChannel(response, form, cardType); // Pago realizado correctamente
                respWC.statusCode = 200;
                respWC.payload = {
                  id_preferencia_pago: dataInput.datos_pago.id_preferencia_pago,
                  uuid: uuid
                };
                window.reactOnMessage(JSON.stringify(respWC));
                document.getElementById("loading").style.display = "none";
              } catch (e) {
                respWC = e;
                window.reactOnMessage(JSON.stringify(respWC));
                document.getElementById("loading").style.display = "none";
              }
            });
          }
        } catch (error) {
          respWC = error;
          document.getElementById("loading").style.display = "none";
          window.reactOnMessage(JSON.stringify(respWC));
        }
      }
    }

    function calculateHeight() {
      var body = document.body,
        html = document.documentElement;
      var height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);

      document.getElementById('loading').style.height = height + "px";
    }
  `}</script>
        </Helmet>

        <Pagos360Html dataPagos360={this.props.dataPagos360} />

        <P360Script1 />

        <P360Script2 />
      </View>
    );
  }
}

//<Pagos360Html dataPagos360={this.props.dataPagos360} ></Pagos360Html>
