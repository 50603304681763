import React from "react";
import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  Image,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import AppMem from "../../common/AppMem";
import { Toast } from "native-base";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import I18n from "i18next";
import { Actions } from "react-native-router-flux";
import _ from "lodash";
import Colors from "../../common/Colors";
import Fonts from "../../common/Fonts";
import L from "../../common/Layout";
import Util from "../../common/Util";
import Header from "../../components/VTHeader";
import { onPressExchangeBenefitButton } from "../../actions/PaymentActions";
var screen = Dimensions.get("window");
class DetailBenefits extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this.onPressExchangeBenefitButton = this.onPressExchangeBenefitButton.bind(
      this
    );
  }

  componentWillMount() {}

  onPressExchangeBenefitButton(benefit, totalPoints) {
    const { fc_points } = benefit;
    if (totalPoints >= fc_points) {
      AppMem.openAppLoader("bars", {
        text: I18n.t("detailBenefits.loaderText"),
        color: Colors.secondary_color,
      });

      this.props.onPressExchangeBenefitButton(benefit, (callback) => {
        AppMem.closeAppLoader("bars", {
          text: I18n.t("detailBenefits.loaderText"),
          color: Colors.secondary_color,
        });
        const { error } = callback;
        if (error) {
          Toast.show({
            text: I18n.t("detailBenefits.errorExchangeBenefit"),
            buttonText: I18n.t("global.accept"),
            type: "danger",
            position: "bottom",
          });
        } else {
          Toast.show({
            text: I18n.t("detailBenefits.successExchangeBenefit"),
            buttonText: I18n.t("detailBenefits.modalOkButton"),
            position: "bottom",
            type: "success",
            duration: 3000,
            onClose: () => Actions.reset("mainView"),
          });
        }
      });
    } else {
      Toast.show({
        text: I18n.t("detailBenefits.errorExchangeBenefit2"),
        buttonText: I18n.t("global.accept"),
        type: "danger",
        position: "bottom",
      });
    }
  }

  render() {
    let { benefit = {}, loyaly = {} } = this.props;
    const { points: loyalyPoints = 0 } = loyaly;
    const { fc_points, fc_title, fc_description, fc_tyc, category } = benefit;
    let { cp_title = "" } = category[0];
    const CATEGORY = {
      drink: "TRAGO",
      drinkKeops: "VASO GRANDE",
      drinkKeops2: "MONJITA MILLER",
      bottleKeops: "BOTELLA ESPUMANTE MUMM",
      bottleKeops2: "BOTELLA VODKA SERNOVA",
      voucherKeops: "VOUCHER KEOPS",
      ticket: "ENTRADA",
      bottle: "BOTELLA",
      voucher: "VOUCHER",
      disccount: "DESCUENTO",
    };
    switch (cp_title) {
      case CATEGORY.drinkKeops:
        imageBenefit = require("../../common/assets/vasoBenefit.jpg");
        break;
      case CATEGORY.drinkKeops2:
        imageBenefit = require("../../common/assets/monjitaBenefit.jpg");
        break;
      case CATEGORY.bottleKeops:
        imageBenefit = require("../../common/assets/mummBenefit.jpg");
        break;
      case CATEGORY.bottleKeops2:
        imageBenefit = require("../../common/assets/sernovaBenefit.jpg");
        break;
      case CATEGORY.voucherKeops:
        imageBenefit = require("../../common/assets/voucherBenefit.jpg");
        break;
      case CATEGORY.bottle:
        imageBenefit = require("../../common/assets/botellaBenefit.png");
        break;
      case CATEGORY.disccount:
        imageBenefit = require("../../common/assets/botellasBenefit.png");
        break;
      case CATEGORY.drink:
        imageBenefit = require("../../common/assets/tragoBenefit.jpg");
        break;
      case CATEGORY.ticket:
        imageBenefit = require("../../common/assets/entradaBenefit.jpg");
        break;
      case CATEGORY.voucher:
        imageBenefit = require("../../common/assets/voucherBenefit1.png");
        break;
      default:
        imageBenefit = require("../../common/assets/exampleBenefitImage.png");
        break;
    }
    return (
      <View style={styles.container}>
        <Header
          title={I18n.t("detailBenefits.headerTitle")}
          leftButtons={[
            {
              onPress: Actions.pop,
              icon: {
                name: "chevron-left",
                type: "FontAwesome",
                style: {
                  color: Colors.mainTitleColor,
                  fontSize: L.h(23),
                  marginLeft: L.w(8),
                },
                text: Util.isIos() ? "" : "",
                textStyle: {
                  fontSize: L.h(18),
                  color: "white",
                },
              },
            },
          ]}
          titleStyle={{
            fontFamily: Fonts.MEDIUM,
            fontSize: L.h(20),
            color: Colors.mainTitleColor,
            top: 0,
          }}
        />
        <View style={styles.currentPointsContainer}>
          <Image
            source={require("../../common/assets/pointsIconCoin.png")}
            resizeMode="contain"
            style={{ height: L.h(18) }}
          />
          <Text style={styles.currentPointsText}>
            {I18n.t("detailBenefits.mainText")}{" "}
            <Text style={{ fontFamily: Fonts.DBOLD }}>{loyalyPoints}</Text>{" "}
            {I18n.t("detailBenefits.mainText2")}
          </Text>
        </View>
        <ScrollView style={{ flex: 1 }}>
          <Image
            source={imageBenefit}
            resizeMode="cover"
            style={styles.imageStyle}
          />
          <View style={styles.barPoints}>
            <View style={styles.amountPointsContainer}>
              <Image
                source={require("../../common/assets/pointsIconCoin.png")}
                resizeMode="contain"
                style={{ height: L.h(21) }}
              />
              <Text style={styles.amountPoints}>{fc_points}</Text>
            </View>
            <TouchableOpacity
              onPress={() =>
                this.onPressExchangeBenefitButton(benefit, loyalyPoints)
              }
              style={styles.submitButton}
            >
              <Text style={styles.textSubmit}>
                {I18n.t("detailBenefits.exchange")}
              </Text>
            </TouchableOpacity>
          </View>
          <View style={{ paddingHorizontal: L.h(21), paddingTop: L.h(20) }}>
            <View
              style={{ flexDirection: "row", justifyContent: "space-between" }}
            >
              <Text style={styles.textCategory}>
                {I18n.t("detailBenefits.benefit")}
              </Text>
              {/* <TouchableOpacity style={styles.buttonHowTo}>
                <Text style={styles.textHowTo}>
                  {I18n.t("detailBenefits.howAddPoints")}
                </Text>
              </TouchableOpacity> */}
            </View>
            <Text style={styles.bigTitle}>{fc_title}</Text>
          </View>
          <View style={styles.listItemContainer}>
            <Text style={styles.textDescription}>{fc_description}</Text>
          </View>
          <View style={styles.containerConditions}>
            <Text style={styles.titleConditions}>
              {I18n.t("detailBenefits.conditions")}
            </Text>
            <Text style={styles.textConditions}>{fc_tyc}</Text>
          </View>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.backgroundTicketDetail,
  },
  currentPointsContainer: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Colors.benefitsSeparatorPoints,
    paddingVertical: L.h(10),
    paddingHorizontal: L.w(15),
  },
  currentPointsText: {
    fontSize: L.h(14),
    fontFamily: Fonts.DREGULAR,
    color: Colors.benefitsSeparatorPointsText,
  },
  barPoints: {
    flexDirection: "row",
  },
  amountPointsContainer: {
    width: "50%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgb(72, 72, 72)",
    paddingVertical: L.h(12),
  },
  amountPoints: {
    color: Colors.benefitsAmountDetailText,
    fontSize: L.h(25),
    fontFamily: Fonts.DBOLD,
    paddingLeft: L.w(5),
  },
  submitButton: {
    width: "50%",
    paddingVertical: L.h(12),
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: Colors.benefitsDetailButtonBackground,
    borderBottomRightRadius: L.h(14),
  },
  textSubmit: {
    fontSize: L.h(25),
    fontFamily: Fonts.DBOLD,
    color: Colors.benefitsDetailButtonText,
  },
  textCategory: {
    fontSize: L.h(14),
    fontFamily: Fonts.DREGULAR,
    color: Colors.benefitsDetailTextCategory,
  },
  textHowTo: {
    fontSize: L.h(14),
    fontFamily: Fonts.DBOLD,
    textDecorationLine: "underline",
    color: Colors.benefitsDetailHowTo,
  },
  bigTitle: {
    fontSize: L.h(23),
    fontFamily: Fonts.DBOLD,
    color: Colors.benefitsDetailBigTitle,
    lineHeight: L.h(30),
    paddingTop: L.h(10),
  },
  textDescription: {
    fontSize: L.h(15),
    fontFamily: Fonts.DREGULAR,
    color: Colors.benefitsDetailTextDesc,
  },
  listItemContainer: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
    justifyContent: "space-between",
    paddingHorizontal: L.w(16),
    paddingTop: L.h(15),
  },
  locationContainer: {
    paddingTop: L.h(20),
  },
  locationText: {
    fontSize: L.h(14),
    fontFamily: Fonts.DREGULAR,
    color: Colors.benefitsDetailLocationText,
    paddingHorizontal: L.w(16),
    paddingBottom: L.h(10),
  },
  containerConditions: {
    paddingVertical: L.h(15),
    paddingHorizontal: L.w(16),
  },
  titleConditions: {
    fontSize: L.h(14),
    fontFamily: Fonts.DBOLD,
    color: Colors.benefitsDetailTitleConditions,
  },
  textConditions: {
    fontSize: L.h(12),
    fontFamily: Fonts.DREGULAR,
    color: Colors.benefitsDetailTextConditions,
  },
  imageStyle: {
    width: screen.width,
    height: L.h(210),
  },
});

function mapStateToProps(store) {
  return {
    loyaly: store.appReducer.loyaly,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onPressExchangeBenefitButton,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailBenefits);
