import _ from 'lodash';
import React, { Component } from 'react';
import { StyleSheet, Text, View, ScrollView } from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Actions } from 'react-native-router-flux';
import Util from '../../common/Util';
import Header from '../../components/VTHeader';
import L from '../../common/Layout';
import Fonts from '../../common/Fonts';
import Colors from '../../common/Colors';
import AppMem from '../../common/AppMem';
import MainButton from '../../components/MainButton';
import Config from 'react-native-config';
import I18n from 'i18next';
import Footer from '../../components/web/Footer';
import { setMenuState } from '../../actions/AppActions';

class TicketFinal extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  componentWillMount() {
    AppMem.closeAppLoader('spinner');
  }

  render() {
    let { setMenuState, productBuy } = this.props;
    return (
      <View style={styles.container}>
        <div class="newslater-section padding-bottom padding-top">
          <div class="container">
            <div
              class="newslater-container bg_img"
              // data-background="./assets/images/newslater/newslater-bg01.jpg"
              // style='background-image: url("./assets/images/newslater/newslater-bg01.jpg");'
            >
              <div class="newslater-wrapper">
                <h5 class="cate">
                  {I18n.t('ticketFinal.congrats', {
                    user: Util.autoCapitalize(this.props.user.proName),
                  })}{' '}
                </h5>
                <h3 class="title" style={{ marginBottom: 20 }}>
                  {' '}
                  {productBuy.event.evtTitle.toUpperCase()}
                </h3>
                <div
                  style={{
                    marginTop: -30,
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Text style={styles.eventInfoCode}>
                    {I18n.t('ticketFinal.operationCode')}
                  </Text>
                  <Text style={styles.eventCode}>{productBuy.paymentId}</Text>
                  {_.map(
                    productBuy.ticketType,
                    (ticketTypeData, ticketType) => {
                      return (
                        <View style={styles.eventTickets}>
                          <Text style={styles.typeTickets}>
                            {ticketTypeData.tktDisplayName.toUpperCase()}
                          </Text>
                          <Text style={styles.numberText}>
                            {ticketTypeData.tktQnty
                              ? ticketTypeData.tktQnty
                              : 0}
                          </Text>
                        </View>
                      );
                    }
                  )}
                </div>
                <a
                  class="custom-button"
                  style={{
                    cursor: 'pointer',
                    marginTop: 16,
                    marginBottom: 16,
                    color: 'white',
                  }}
                  onClick={() => {
                    Actions.reset('shopMain'), setMenuState('shopMain');
                  }}
                >
                  {'IR A MIS COMPRAS'}
                </a>
                <p style={{ marginTop: 8 }}>
                  Cada asistente debe presentar en puerta su propia entrada +
                  DNI
                </p>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </View>
    );
  }

  render1() {
    let { ticket, shopDetails, productBuy } = this.props;
    return (
      <View style={styles.container}>
        <Header
          title={I18n.t('ticketFinal.boughtTicket')}
          home={true}
          titleStyle={{
            fontFamily: Fonts.MEDIUM,
            fontSize: L.h(20),
            color: Colors.mainTitleColor,
          }}
        />
        <ScrollView
          style={styles.container}
          keyboardShouldPersistTaps="always"
          keyboardDismissMode="on-drag"
        >
          <View style={styles.eventContainer}>
            <Text style={styles.initialInfo}>
              {I18n.t('ticketFinal.congrats', {
                user: Util.autoCapitalize(this.props.user.proName),
              })}
            </Text>
            <Text style={styles.eventName}>
              {productBuy.event.evtTitle.toUpperCase()}
            </Text>
            <Text style={styles.eventInfoCode}>
              {I18n.t('ticketFinal.operationCode')}
            </Text>
            <Text style={styles.eventCode}>{productBuy.paymentId}</Text>
            <View>
              <Text style={styles.attentionMsg}>
                {I18n.t('ticketFinal.attention')}{' '}
                <Text style={{ fontFamily: Fonts.DBOLD }}>
                  {I18n.t('ticketFinal.myBoughts')}
                </Text>
                .
              </Text>
            </View>
            <View>
              <Text style={styles.attentionMsg}>
                {I18n.t('ticketFinal.presentDNI')}{' '}
                {I18n.t('ticketFinal.presentDNI2')}{' '}
                {I18n.t('ticketFinal.presentDNI3')}
                <Text style={{ fontFamily: Fonts.DBOLD }}>
                  {I18n.t('ticketFinal.presentDNI2')}{' '}
                  {Config.PRODUCER_NAME.toLowerCase()}
                  {I18n.t('ticketFinal.presentDNI3')}{' '}
                </Text>
              </Text>
            </View>
          </View>
          <Text style={styles.headTickets}>
            {I18n.t('ticketFinal.details')}
          </Text>
          {_.map(productBuy, (ticketTypeData, ticketType) => {
            //productBuy.ticketType
            return (
              <View style={styles.eventTickets}>
                <Text style={styles.typeTickets}>
                  {ticketTypeData.tktDisplayName.toUpperCase()}
                </Text>
                <Text style={styles.numberText}>
                  {ticketTypeData.tktQnty ? ticketTypeData.tktQnty : 0}
                </Text>
              </View>
            );
          })}
          <MainButton
            style={styles.buttonBuy}
            text="Mis Compras"
            onPress={() => Actions.reset('mainView', { screenChoose: true })}
          />
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.mainBackgroundTicketFinal,
  },
  eventContainer: {
    alignItems: 'center',
    paddingTop: L.h(16),
    paddingHorizontal: L.w(20),
    paddingBottom: L.h(35),
  },
  initialInfo: {
    fontSize: L.h(17),
    color: Colors.trueGreen,
    fontFamily: Fonts.DSEMIBOLD,
    textAlign: 'center',
  },
  eventName: {
    fontSize: L.h(17),
    marginTop: L.h(31),
    color: Colors.secondary_color,
    fontFamily: Fonts.DBOLD,
    textAlign: 'center',
  },
  eventInfoCode: {
    fontSize: L.h(14),
    fontFamily: Fonts.DSEMIBOLD,
    marginTop: L.h(24),
    color: Colors.codeTicketFinal,
  },
  eventCode: {
    fontSize: L.h(25),
    fontFamily: Fonts.MEDIUM,
    color: Colors.codeTicketFinal,
    marginBottom: L.h(25),
    textAlign: 'center',
  },
  attentionMsg: {
    fontSize: L.h(14),
    fontFamily: Fonts.DSEMIBOLD,
    lineHeight: L.h(18),
    color: Colors.red,
    textAlign: 'center',
  },
  eventInfo: {
    width: '100%',
    paddingTop: L.h(16),
    paddingHorizontal: L.w(20),
    paddingBottom: L.h(6),
    borderStyle: 'dashed',
    alignItems: 'center',
    borderWidth: L.h(1),
    borderRadius: L.h(1),
    borderColor: Colors.borderRowTicketFinal,
    position: 'relative',
  },
  eventTickets: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: L.h(1),
    borderColor: Colors.borderRowTicketFinal,
    paddingVertical: L.h(10),
    paddingHorizontal: L.h(25),
    backgroundColor: Colors.backgroundRowTicketFinal,
  },
  headTickets: {
    fontSize: L.h(16),
    color: Colors.titleDetailTicketFinal,
    fontFamily: Fonts.DLIGHT,
    paddingLeft: L.w(25),
    paddingBottom: L.h(5),
    borderBottomWidth: L.h(1),
    borderColor: Colors.borderRowTicketFinal,
  },
  numberText: {
    color: Colors.ticketPriceTicketFinal,
    fontSize: L.h(19),
  },
  typeTickets: {
    fontSize: L.h(15),
    fontFamily: Fonts.DREGULAR,
    color: Colors.ticketTypeTicketFinal,
  },
  buttonBuy: {
    justifyContent: 'center',
    marginTop: L.h(20),
    marginBottom: L.h(20),
    marginHorizontal: L.h(20),
    shadowOpacity: 0.3,
    shadowRadius: 3,
    shadowOffset: {
      height: 0,
      width: 0,
    },
    elevation: 3,
  },
});

function mapStateToProps(store) {
  return {
    tickets: {}, // store.ticketReducer.tickets,
    user: store.authReducer.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ setMenuState }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TicketFinal);
