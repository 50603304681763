import _ from 'lodash';
import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  TouchableOpacity,
  Dimensions,
  ImageBackground,
  Platform,
  Image,
} from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import FastImage from 'react-native-fast-image';
import NumericInput from 'react-native-numeric-input';
import { Actions } from 'react-native-router-flux';
import I18n from 'i18next';
import Header from '../../components/VTHeader';
import { Icon, Toast } from 'native-base';
import Fonts from '../../common/Fonts';
import Colors from '../../common/Colors';
import { TOP_PADDING_IOS_FONT } from '../../common/Constants';
import L from '../../common/Layout';
import AppMem from '../../common/AppMem';
import Util from '../../common/Util';
import MainButton from '../../components/MainButton';
import TicketInfo from '../../components/TicketInfo';
import { ticketTypeSelectedListener } from '../../actions/AppActions';
import Icon2 from 'react-native-vector-icons/MaterialIcons';
import Config from 'react-native-config';
import Footer from '../../components/web/Footer';
import moment from 'moment';
import toast from 'react-hot-toast';

const screen = Dimensions.get('window');

class Ticket extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
    this._renderEventTicketType = this._renderEventTicketType.bind(this);
    this.onPressEventConditions = this.onPressEventConditions.bind(this);
    this.onPressBuy = this.onPressBuy.bind(this);
    this.globalTotal = 0;
  }

  componentWillMount() {
    this.props.ticketTypeSelectedListener();
  }

  componentWillReceiveProps(nextProps) {
    _.map(
      nextProps.activeEventTicketTypes[nextProps.event.id].evtTicketsType,
      (ticketTypeData, ticketTypeId) => {
        if (
          this.state[ticketTypeId] &&
          !Util.isAvailableTickeType(
            ticketTypeData.tktVisible,
            Util.timestampToDate(ticketTypeData.tktInitDate.seconds),
            Util.timestampToDate(ticketTypeData.tktEndDate.seconds)
          )
        ) {
          this.setState({ [ticketTypeId]: 0 });
        }
      }
    );
  }
  componentWillUnmount() {
    let allListeners = AppMem.getTTListener;
    allListeners.map(eachListener => {
      eachListener();
    });
    AppMem.setTTListener && AppMem.setTTListener([]);
  }

  _renderEventTicketType(ticketTypeData, ticketTypeId) {
    let { event, activeEventTicketTypes } = this.props;
    let totalAmmountPerType =
      ticketTypeData?.tktPrice *
      (this.state[ticketTypeId] ? this.state[ticketTypeId] : 0);

    this.globalTotal += totalAmmountPerType;
    //  const elementos = [1, 2, 3, 4, 5]; // Aquí debes agregar los elementos que quieres sumar
    //const suma = (this.state.total += totalAmmountPerType); // Suma los elementos utilizando el método reduce
    //  this.setState({ total: suma }); // Actualiza el estado con la suma
    const { tktSoldOut = false, tktQt } = ticketTypeData;
    const ticketTypeIsSoldOut = tktSoldOut ? true : tktQt <= 0 ? true : false;
    return (
      <View style={styles.eventTickets}>
        <View style={styles.numberTickets}>
          {ticketTypeIsSoldOut ? (
            <Text
              style={{
                fontSize: L.h(15),
                fontFamily: Fonts.DREGULAR,
                color: 'red',
              }}
            >
              {I18n.t('ticketDetail.soldOut')}
            </Text>
          ) : (
            <NumericInput
              value={this.state[ticketTypeId]}
              onChange={value => {
                this.setState({ [ticketTypeId]: value });
              }}
              valueType="real"
              rounded
              minValue={0}
              initValue={0}
              type="plus-minus"
              textColor="white"
              maxValue={ticketTypeData.tktMaxQtBuy}
              totalWidth={80}
              iconStyle={{ color: 'white' }}
              containerStyle={{
                backgroundColor: Colors.buttonticketBackgroundTicketDetail,
              }}
              rightButtonBackgroundColor={Colors.buttonticketTicketDetail}
              leftButtonBackgroundColor={Colors.buttonticketTicketDetail}
              borderColor={'transparent'}
            />
          )}
        </View>
        <View style={styles.containerTickets}>
          {ticketTypeData.tktIcon ? (
            <View style={styles.iconContainer}>
              <Icon
                name={
                  ticketTypeData && ticketTypeData.tktIcon
                    ? ticketTypeData.tktIcon
                    : 'ticket'
                }
                type={
                  ticketTypeData && ticketTypeData.tktIconFamily
                    ? ticketTypeData.tktIconFamily
                    : 'FontAwesome'
                }
                style={{
                  color: Colors.buttonticketTextTicketDetail,
                  fontSize: L.h(18),
                  width: 23,
                  height: 23,
                  //  backgroundColor: "red",
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              />
            </View>
          ) : null}
          <View style={{ flex: 1 }}>
            {ticketTypeIsSoldOut ? null : (
              <Text style={styles.availableTickets}>
                {activeEventTicketTypes[event.id] &&
                activeEventTicketTypes[event.id].evtTicketsType &&
                activeEventTicketTypes[event.id].evtTicketsType[ticketTypeId]
                  ? activeEventTicketTypes[event.id].evtTicketsType[
                      ticketTypeId
                    ].tktQt
                  : 0}{' '}
                {I18n.t('ticketDetail.availables')}
              </Text>
            )}
            <Text
              style={[
                styles.typeTickets,
                // {
                //   color: ticketTypeData.tktColor
                //     ? ticketTypeData.tktColor
                //     : Colors.darkGreyThree
                // }
              ]}
            >
              {ticketTypeData.tktName}
            </Text>
          </View>
          <Text style={styles.priceTickets}>
            $ {Util.formatPrice(totalAmmountPerType, true)}
          </Text>
        </View>
      </View>
    );
  }

  onPressEventConditions() {
    let { event } = this.props;
    AppMem.openModal(
      <View style={{ flex: 1, width: screen.width - L.wProm(90) }}>
        <Text
          style={{
            color: '#333333',
            marginTop: L.h(16),
            fontSize: L.h(16),
            textAlign: 'center',
            marginBottom: L.h(8),
          }}
        >
          {I18n.t('ticketDetail.conditions')}
        </Text>
        <ScrollView style={{ height: L.hProm(380), flex: 1 }}>
          <Text
            style={{
              color: '#333333',
              textAlign: 'left',
              flex: 1,
              fontSize: L.h(12),
              marginTop: L.h(8),
            }}
          >
            {event.evtConditions}
          </Text>
        </ScrollView>
        <TouchableOpacity
          style={{
            height: L.hProm(40),
            alignCenter: 'center',
            justifyContent: 'center',
          }}
          onPress={() => AppMem.closeModal()}
        >
          <Text style={{ color: Colors.darkIndigo, textAlign: 'center' }}>
            {I18n.t('ticketDetail.close').toUpperCase()}
          </Text>
        </TouchableOpacity>
      </View>,
      {
        style: {
          height: L.hProm(420),
          borderRadius: 12,
          width: screen.width - L.wProm(60),
        },
        swipeToClose: false,
      }
    );
  }

  checkTicketAvailability() {
    const { event, activeEventTicketTypes } = this.props;
    return Promise.all(
      _.map(this.state, (qntitySelected, key) => {
        if (key) {
          const ticketQtity =
            activeEventTicketTypes[event.id]?.evtTicketsType[key]?.tktQt;
          return ticketQtity - qntitySelected < 0 ? false : true;
        }
      })
    ).then(resultArray => {
      return resultArray.some(result => !result) ? false : true;
    });
  }

  onPressBuy(canNavigate) {
    const { event, user, anonymousMode } = this.props;
    const handleBuy = async () => {
      if (anonymousMode) {
        const modalProps = {
          title: I18n.t('ticketDetail.login'),
          type: 'warning',
          buttonOk: {
            text: I18n.t('ticketDetail.loginPopUp'),
            action: () => Actions.reset('login'),
          },
          buttonCancel: {
            text: I18n.t('ticketDetail.loginCancel'),
            action: () => null,
          },
        };
        AppMem.openModal('alert', modalProps);
        return;
      }

      if (!canNavigate || !(await this.checkTicketAvailability())) {
        Toast.show({
          text: I18n.t('ticketDetail.errorStock'),
          buttonText: I18n.t('global.accept'),
          type: 'danger',
          position: 'bottom',
        });
        toast.error(I18n.t('ticketDetail.errorStock'), { duration: 8000 });
        return;
      }
      // console.log(" >>>>>>>>>>eventeventeventevent ", event);
      // console.log(' >>>>>>>>>> this.state', this.state);
      const filteredData = Object.fromEntries(
        Object.entries(this.state).filter(([key, value]) => value !== 0)
      );
      Actions.push('ticketResume', {
        event,
        shopDetails: { ...filteredData },
      });
    };

    const handleProVerifiedError = () => {
      // const modalProps = {
      //   title: `${I18n.t('ticketDetail.verifiedAccount')}${
      //     Config.EMAIL_INFO_APP
      //   } ${
      //     Config.APP_ID === 'leparc'
      //       ? I18n.t('ticketDetail.verifiedAccount2')
      //       : ''
      //   }`,
      //   type: 'custom',
      //   style: { height: 350 },
      //   buttonOk: {
      //     text: 'Aceptar',
      //     action: () => null,
      //   },
      // };
      // AppMem.openModal('alert', modalProps);
      toast.error(
        `${I18n.t('ticketDetail.verifiedAccount')}${Config.EMAIL_INFO_APP} ${
          Config.APP_ID === 'leparc'
            ? I18n.t('ticketDetail.verifiedAccount2')
            : ''
        }`,
        { duration: 8000 }
      );
    };

    if (event.evtSellVerify) {
      if (!user?.proVerified) {
        handleProVerifiedError();
      } else {
        handleBuy();
      }
    } else {
      if (!_.isEmpty(user)) handleBuy();
      else toast.error('Debes iniciar sesión para adquirir tus tickets');
    }
  }

  // onPressBuy(canNavigate) {
  //   let { event, user, anonymousMode } = this.props;
  //   event.evtSellVerify
  //     ? user && !user.proVerified
  //       ? () => {
  //           console.log(" >>>>>>>>>>1 ");
  //           let modalVerified = {
  //             title: `${I18n.t("ticketDetail.verifiedAccount")}${
  //               Config.EMAIL_INFO_APP
  //             } ${
  //               Config.APP_ID === "leparc"
  //                 ? I18n.t("ticketDetail.verifiedAccount2")
  //                 : ""
  //             }`,
  //             type: "custom",
  //             style: { height: 350 },
  //             buttonOk: {
  //               text: "Aceptar",
  //               action: () => null,
  //             },
  //           };
  //           AppMem.openModal("alert", modalVerified);
  //         }
  //       : async () => {
  //           // No permite Comprar en modo anonimo
  //           console.log(" >>>>>>>>>>2 ");
  //           if (this.props.anonymousMode) {
  //             let modalProps = {
  //               title: I18n.t("ticketDetail.login"),
  //               type: "warning",
  //               buttonOk: {
  //                 text: I18n.t("ticketDetail.loginPopUp"),
  //                 action: () => Actions.reset("login"),
  //               },
  //               buttonCancel: {
  //                 text: I18n.t("ticketDetail.loginCancel"),
  //                 action: () => null,
  //               },
  //             };
  //             AppMem.openModal("alert", modalProps);

  //             return;
  //           }
  //           console.log(" >>>>>>>>>>3 ");
  //           if (canNavigate) {
  //             console.log(" >>>>>>>>>>4 ");
  //             if (await this.checkTicketAvailability()) {
  //               Actions.push("ticketResume", {
  //                 event: event,
  //                 shopDetails: { ...this.state },
  //               });
  //             } else {
  //               Toast.show({
  //                 text: I18n.t("ticketDetail.errorStock"),
  //                 buttonText: I18n.t("global.accept"),
  //                 type: "danger",
  //                 position: "bottom",
  //               });
  //             }
  //           } else {
  //             Toast.show({
  //               text: I18n.t("ticketDetail.errorSelectTickets"),
  //               buttonText: I18n.t("global.accept"),
  //               type: "danger",
  //               position: "bottom",
  //             });
  //           }
  //         }
  //     : async () => {
  //         // No permite Comprar en modo anonimo
  //         console.log(" >>>>>>>>>>5 ");
  //         if (anonymousMode) {
  //           console.log(" >>>>>>>>>>6");
  //           let modalProps = {
  //             title: I18n.t("ticketDetail.errorMustLogin"),
  //             type: "warning",
  //             buttonOk: {
  //               text: I18n.t("ticketDetail.loginPopup"),
  //               action: () => Actions.reset("login"),
  //             },
  //             buttonCancel: {
  //               text: I18n.t("ticketDetail.loginCancel"),
  //               action: () => null,
  //             },
  //           };
  //           AppMem.openModal("alert", modalProps);

  //           return;
  //         }
  //         console.log(" >>>>>>>>>>7 ");
  //         if (canNavigate) {
  //           console.log(" >>>>>>>>>>8");
  //           if (await this.checkTicketAvailability()) {
  //             console.log(" >>>>>>>>>>9 ");
  //             Actions.push("ticketResume", {
  //               event: event,
  //               shopDetails: { ...this.state },
  //             });
  //           } else {
  //             Toast.show({
  //               text: I18n.t("ticketDetail.errorStock"),
  //               buttonText: I18n.t("global.accept"),
  //               type: "danger",
  //               position: "bottom",
  //             });
  //           }
  //         } else {
  //           console.log(" >>>>>>>>>>10 ");
  //           Toast.show({
  //             text: I18n.t("ticketDetail.errorSelectTickets"),
  //             buttonText: I18n.t("global.accept"),
  //             type: "danger",
  //             position: "bottom",
  //           });
  //         }
  //       };
  // }

  render() {
    let { event, activeEventTicketTypes, user } = this.props;
    let { proTags = [] } = user;
    let canNavigate = false;
    if (
      activeEventTicketTypes[event.id] &&
      activeEventTicketTypes[event.id].evtTicketsType
    )
      _.map(
        activeEventTicketTypes[event.id].evtTicketsType,
        (ticketTypeData, ticketTypeId) => {
          if (this.state[ticketTypeId] && this.state[ticketTypeId] > 0) {
            canNavigate = true;
          }
        }
      );
    this.globalTotal = 0;
    return (
      <div
        style={{
          paddingTop: 120,
          backgroundColor: Colors.backgroundHistory,
        }}
      >
        <section class="page-title bg-one" style={{ marginBottom: '1%' }}>
          <div class="container">
            <div class="page-title-area">
              <div
                class="item md-order-1"
                onClick={() => Actions.pop()}
                style={{ cursor: 'pointer' }}
              >
                <a class="custom-button back-button">
                  <i class="flaticon-double-right-arrows-angles" />
                  Volver
                </a>
              </div>
              <div class="item date-item">
                <h4>
                  {/* {moment(eventData.evtDate).format('DD MMMM YYYY')} */}
                  {event?.evtTitle}
                </h4>
              </div>
              <div class="item" />
            </div>
          </div>
        </section>

        <section class="event-book-search" style={{ marginTop: 0 }}>
          <div class="container">
            <div
              class="event-search bg_img"
              data-background="./assets/images/ticket-bg01.jpg"
            >
              <div class="event-search-top">
                <div class="left">
                  <h3 class="title">{event?.evtTitle}</h3>
                  <p>{event?.evtDescription}</p>
                </div>
                <div class="left">
                  <h3 class="title">
                    {"Desde"}
                    {' '}
                    {moment(
                      Util.timestampToDate(event.evtDateTime.seconds)
                    ).format('dddd DD [de] MMMM')}
                  </h3>
                  <h3 class="title" style={{ marginTop: 8 }}>
                    {moment(
                      Util.timestampToDate(event.evtDateTime.seconds)
                    ).format('HH:mm')}{' '}
                    hs
                  </h3>
                </div>
              </div>
              <div class="event-search-bottom">
                <div class="contact-side">
                  {/* <div class="item">
                    <div class="item-thumb">
                      <img
                        src="./assets/images/event/icon/event-icon01.png"
                        alt="event"
                      />
                    </div>
                    <div class="item-content">
                      <span class="up">
                        {moment(
                          Util.timestampToDate(event.evtDateTime.seconds)
                        ).format("dddd DD [de] MMMM")}
                      </span>
                      <span>
                        {moment(
                          Util.timestampToDate(event.evtDateTime.seconds)
                        ).format("HH:mm")}{" "}
                        hs
                      </span>
                    </div>
                  </div> */}
                  <div class="item">
                    <div class="item-thumb">
                      <img
                        src="./assets/images/event/icon/event-icon02.png"
                        alt="event"
                      />
                    </div>
                    {/* <TicketInfo
                  evtDateTime={Util.timestampToDate(event.evtDateTime.seconds)}
                  evtCoordinates={event.evtCoordinates}
                  evtPlace={event.evtPlace}
                  style={{ marginHorizontal: 0 }}
                /> */}
                    <div class="item-content">
                      <span class="up">{event?.evtPlace}</span>
                      <span>{event?.evtPlaceAddress}</span>
                    </div>
                  </div>
                  {/* <div class="item">
                    <div class="item-thumb">
                      <img
                        src="./assets/images/event/icon/event-icon03.png"
                        alt="event"
                      />
                    </div>
                    <div class="item-content">
                      <span class="up">Consultas:</span>
                      <a href="MailTo:hello@Boleto .com">hello@Boleto .com</a>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
        <div class="container" style={{ marginBottom: 80 }}>
          <div class="section-header-1" style={{ marginTop: 30 }}>
            <h2 class="title">Tickets</h2>
          </div>
          {activeEventTicketTypes[event.id] &&
          activeEventTicketTypes[event.id].evtTicketsType
            ? _.map(
                activeEventTicketTypes[event.id].evtTicketsType,
                (ticketTypeData, ticketTypeId) => {
                  let { tktTags = [] } = ticketTypeData;
                  if (tktTags.length > 0) {
                    let res = proTags.filter(item =>
                      tktTags.find(each => each.id == item.id)
                    );
                    if (res.length == 0) {
                      return;
                    }
                  }
                  let activeTicket = Util.isAvailableTickeType(
                    ticketTypeData.tktVisible,
                    ticketTypeData.tktInitDate
                      ? Util.timestampToDate(ticketTypeData.tktInitDate.seconds)
                      : null,
                    ticketTypeData.tktEndDate
                      ? Util.timestampToDate(ticketTypeData.tktEndDate.seconds)
                      : null
                  );
                  return activeTicket
                    ? this._renderEventTicketType(ticketTypeData, ticketTypeId)
                    : null;
                }
              )
            : null}
          <View style={styles.eventButtonBuy}>
            <View style={styles.buttonWrapper}>
              <h3 style={{ marginLeft: '2%', fontWeight: "700" }}>
                <span class="seconds">
                  Total: ${' '}
                  {this.globalTotal == 0
                    ? '0'
                    : Util.formatPrice(this.globalTotal, true)}
                </span>
              </h3>
              <a
                //  href=""
                class="custom-button"
                style={{
                  marginRight: '2%',
                  cursor: 'pointer',
                  color: 'white',
                  textAlign: 'center',
                }}
                onClick={() => this.onPressBuy(canNavigate)}
              >
                Comprar Tickets
              </a>
            </View>
          </View>
        </div>
        <Footer />
      </div>
    );
  }

  render2() {
    let { event, activeEventTicketTypes, user } = this.props;
    let { proTags = [] } = user;
    let canNavigate = false;
    this.globalTotal = 0;
    if (
      activeEventTicketTypes[event.id] &&
      activeEventTicketTypes[event.id].evtTicketsType
    )
      _.map(
        activeEventTicketTypes[event.id].evtTicketsType,
        (ticketTypeData, ticketTypeId) => {
          if (this.state[ticketTypeId] && this.state[ticketTypeId] > 0) {
            canNavigate = true;
          }
        }
      );
    return (
      <View
        style={[
          styles.container,
          { paddingTop: 130, backgroundColor: Colors.backgroundHistory },
        ]}
      >
        <div class="wrapper-area" style={{ marginBottom: 50 }}>
          <ScrollView
            style={[styles.container]}
            keyboardShouldPersistTaps="always"
            keyboardDismissMode="on-drag"
          >
            {/* <ImageBackground
              source={{
                uri: event.evtImg,
                priority: FastImage.priority.high,
              }}
              resizeMode="stretch"
              style={styles.eventImage}
            > */}
            {/* <View style={styles.titleImage}>
              <Text style={styles.titleTicketFont}>
                {event.evtTitle.toUpperCase()}
              </Text>
            </View> */}
            {/* </ImageBackground> */}
            <View
              style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: 8,
              }}
            >
              <Image
                source={{
                  uri: event.evtImg,
                  //  priority: FastImage.priority.high,
                }}
                style={styles.eventImage}
              />
              <View
                style={{
                  flex: 1,
                  paddingRight: '5%',
                }}
              >
                <View style={styles.eventTitle}>
                  <Text style={styles.eventDesc}>
                    {event && event.evtDescription}
                  </Text>
                </View>
                <TicketInfo
                  evtDateTime={Util.timestampToDate(event.evtDateTime.seconds)}
                  evtCoordinates={event.evtCoordinates}
                  evtPlace={event.evtPlace}
                  style={{ marginHorizontal: 0 }}
                />
              </View>
            </View>
            <TouchableOpacity
              style={styles.eventConditions}
              onPress={this.onPressEventConditions}
            >
              <Text
                style={{
                  color: Colors.textEventConditionTicketDetail,
                  fontSize: L.h(13),
                  fontFamily: Fonts.MEDIUM,
                }}
              >
                {I18n.t('ticketDetail.readEventConditions')}
              </Text>
              <Icon2
                name="arrow-forward"
                style={{
                  color: Colors.arrowEventConditionTicketDetail,
                  fontSize: L.h(16),
                }}
              />
            </TouchableOpacity>
            {/* <View style={styles.divider} /> */}
            {activeEventTicketTypes[event.id] &&
            activeEventTicketTypes[event.id].evtTicketsType
              ? _.map(
                  activeEventTicketTypes[event.id].evtTicketsType,
                  (ticketTypeData, ticketTypeId) => {
                    let { tktTags = [] } = ticketTypeData;
                    if (tktTags.length > 0) {
                      let res = proTags.filter(item =>
                        tktTags.find(each => each.id == item.id)
                      );
                      if (res.length == 0) {
                        return;
                      }
                    }
                    let activeTicket = Util.isAvailableTickeType(
                      ticketTypeData.tktVisible,
                      ticketTypeData.tktInitDate
                        ? Util.timestampToDate(
                            ticketTypeData.tktInitDate.seconds
                          )
                        : null,
                      ticketTypeData.tktEndDate
                        ? Util.timestampToDate(
                            ticketTypeData.tktEndDate.seconds
                          )
                        : null
                    );
                    return activeTicket
                      ? this._renderEventTicketType(
                          ticketTypeData,
                          ticketTypeId
                        )
                      : null;
                  }
                )
              : null}
          </ScrollView>

          <View style={styles.eventButtonBuy}>
            <View style={styles.buttonWrapper}>
              <Text
                style={{
                  color: Colors.totalBuyText,
                  marginLeft: L.w(16),
                  fontSize: L.h(19),
                  fontFamily: Fonts.MEDIUM,
                }}
              >
                {I18n.t('ticketDetail.total')}{' '}
                <Text
                  style={{
                    color: Colors.trueGreen,
                    marginLeft: L.w(16),
                    fontFamily: Fonts.MEDIUM,
                  }}
                >
                  $ {Util.formatPrice(this.globalTotal, true)}
                </Text>
              </Text>
              <MainButton
                text={I18n.t('ticketDetail.buyTickets')}
                textStyle={{ fontSize: L.h(16), fontFamily: Fonts.MEDIUM }}
                style={{
                  height: L.hProm(55),
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderRadius: 40,
                  //width: "50%",
                  paddingHorizontal: L.w(18),
                  marginHorizontal: L.w(16),
                  elevation: 2,
                  backgroundColor: canNavigate
                    ? Colors.buttonbuyTicketDetail
                    : 'grey',
                }}
                disabled={canNavigate > 0 ? false : true}
                onPress={
                  event.evtSellVerify
                    ? user && !user.proVerified
                      ? () => {
                          let modalVerified = {
                            title: `${I18n.t('ticketDetail.verifiedAccount')}${
                              Config.EMAIL_INFO_APP
                            } ${
                              Config.APP_ID === 'leparc'
                                ? I18n.t('ticketDetail.verifiedAccount2')
                                : ''
                            }`,
                            type: 'custom',
                            style: { height: 350 },
                            buttonOk: {
                              text: 'Aceptar',
                              action: () => null,
                            },
                          };
                          AppMem.openModal('alert', modalVerified);
                        }
                      : async () => {
                          // No permite Comprar en modo anonimo
                          if (this.props.anonymousMode) {
                            let modalProps = {
                              title: I18n.t('ticketDetail.login'),
                              type: 'warning',
                              buttonOk: {
                                text: I18n.t('ticketDetail.loginPopUp'),
                                action: () => Actions.reset('login'),
                              },
                              buttonCancel: {
                                text: I18n.t('ticketDetail.loginCancel'),
                                action: () => null,
                              },
                            };
                            AppMem.openModal('alert', modalProps);

                            return;
                          }

                          if (canNavigate) {
                            if (await this.checkTicketAvailability()) {
                              Actions.push('ticketResume', {
                                event: event,
                                shopDetails: { ...this.state },
                              });
                            } else {
                              Toast.show({
                                text: I18n.t('ticketDetail.errorStock'),
                                buttonText: I18n.t('global.accept'),
                                type: 'danger',
                                position: 'bottom',
                              });
                            }
                          } else {
                            Toast.show({
                              text: I18n.t('ticketDetail.errorSelectTickets'),
                              buttonText: I18n.t('global.accept'),
                              type: 'danger',
                              position: 'bottom',
                            });
                          }
                        }
                    : async () => {
                        // No permite Comprar en modo anonimo
                        if (this.props.anonymousMode) {
                          let modalProps = {
                            title: I18n.t('ticketDetail.errorMustLogin'),
                            type: 'warning',
                            buttonOk: {
                              text: I18n.t('ticketDetail.loginPopup'),
                              action: () => Actions.reset('login'),
                            },
                            buttonCancel: {
                              text: I18n.t('ticketDetail.loginCancel'),
                              action: () => null,
                            },
                          };
                          AppMem.openModal('alert', modalProps);

                          return;
                        }

                        if (canNavigate) {
                          if (await this.checkTicketAvailability()) {
                            Actions.push('ticketResume', {
                              event: event,
                              shopDetails: { ...this.state },
                            });
                          } else {
                            Toast.show({
                              text: I18n.t('ticketDetail.errorStock'),
                              buttonText: I18n.t('global.accept'),
                              type: 'danger',
                              position: 'bottom',
                            });
                          }
                        } else {
                          Toast.show({
                            text: I18n.t('ticketDetail.errorSelectTickets'),
                            buttonText: I18n.t('global.accept'),
                            type: 'danger',
                            position: 'bottom',
                          });
                        }
                      }
                }
              />
            </View>
            {Util.isIosXGen() ? (
              <View
                style={{
                  width: '100%',
                  height: 34,
                }}
              />
            ) : null}
          </View>
        </div>
        <Footer />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.backgroundTicketDetail,
    //marginBottom: L.h(20)
  },
  eventImage: {
    //  width: "100%",
    //  height: 300,
    //height: 150,
    width: '100%',
    height: 220,
    // borderRadius: 150 / 2,
    // backgroundColor: Colors.backgroundTicketDetail,
    // justifyContent: "flex-end",
    // borderWidth: 5,
    // borderColor: "black",
    // marginHorizontal: "5%",
  },
  buttonWrapper: {
    width: '100%',
    justifyContent: 'space-between',
    //backgroundColor: Colors.backgroundContainerButtonBuy,
    flexDirection: 'row',
    alignItems: 'center',
    height: 80,
  },
  titleImage: {
    paddingHorizontal: L.w(25),
    width: '100%',
    paddingVertical: L.h(15),
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    justifyContent: 'center',
    alignItems: 'center',
  },
  titleTicketFont: {
    color: Colors.paleGrey,
    fontSize: L.h(17),
    fontFamily: Fonts.DREGULAR,
    textAlign: 'center',
  },
  eventTitle: {
    width: '100%',
    //paddingVertical: L.h(10),
  },
  eventDesc: {
    color: Colors.secondary_color,
    fontFamily: Fonts.DBOLD,
    textAlign: 'left',
    fontSize: 20,
  },
  eventInfo: {
    width: '100%',
    padding: L.h(20),
    borderStyle: 'dashed',
    borderWidth: L.h(1),
    borderRadius: L.h(1),
    borderColor: Colors.veryLightPink,
    position: 'relative',
  },
  containerInfo: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: L.h(10),
  },
  iconStyle: {
    color: Colors.darkIndigo,
    fontSize: L.h(16),
  },
  eventPlace: {
    marginLeft: 5,
    color: Colors.purpley,
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
  },
  eventConditions: {
    width: '100%',
    paddingHorizontal: L.w(20),
    paddingVertical: L.h(15),
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTopWidth: L.h(1),
    borderTopColor: Colors.borderEventConditionTicketDetail,
    position: 'relative',
    marginTop: L.h(10),
  },
  divider: {
    width: '100%',
    minHeight: 5,
    backgroundColor: Colors.primary_color,
  },
  eventTickets: {
    width: '100%',
    flexDirection: 'row',
    minHeight: 50,
    borderStyle: 'dashed',
    borderWidth: L.h(1),
    borderRadius: L.h(6),
    borderColor: Colors.borderTicketDetail,
    paddingVertical: L.h(10),
    backgroundColor: Colors.backgroundTicketRowDetail,
  },
  numberTickets: {
    marginHorizontal: '2%',
    alignItems: 'center',
    paddingVertical: L.h(10),
    // borderRightWidth: 1,
    // borderColor: Colors.veryLightPink
  },
  numberText: {
    color: Colors.trueGreen,
  },
  containerTickets: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flex: 2,
    paddingLeft: L.w(8),
    paddingRight: L.w(11),
  },
  selectTicket: {
    color: Colors.purpley,
    fontSize: L.h(10),
  },
  availableTickets: {
    fontSize: L.h(15),
    fontFamily: Fonts.DLIGHT,
    color: Colors.trueGreen,
  },
  typeTickets: {
    fontSize: L.h(18),
    fontFamily: Fonts.DREGULAR,
    color: Colors.ticketNameTicketDetail,
    paddingRight: L.w(11),
  },
  priceTickets: {
    fontSize: L.h(22),
    fontFamily: Fonts.DREGULAR,
    color: Colors.priceTicketDetail,
  },
  eventTotals: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'center',
    paddingVertical: L.h(9),
    marginBottom: L.h(30),
  },
  totalText: {
    fontSize: L.h(19),
    color: Colors.commonDetailText,
  },
  totalPriceText: {
    fontSize: L.h(19),
    color: Colors.trueGreen,
  },
  eventButtonBuy: {
    //position: "absolute",
    width: '100%',
    justifyContent: 'space-between',
    bottom: 0,
    height: L.h(80),
    //backgroundColor: "red",
    left: 0,
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginTop: 16,
  },
  centerEnd: { alignItems: 'center', justifyContent: 'flex-end' },
  placeRow: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: L.h(8),
  },
  iconText: {
    color: Colors.commonDetailText,
    fontSize: Util.isIos() ? L.h(14) : L.h(12),
    marginLeft: L.w(8),
  },
  iconStyle: {
    fontSize: Util.isIosXGen() ? L.h(20) : L.h(14),
    color: Colors.blueberry,
  },
  alignCenter: { alignItems: 'center', flexDirection: 'row' },
  spaceBetween: {
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
  },
  ticketTopWrapper: {
    marginHorizontal: L.w(16),
    paddingVertical: L.h(8),
  },
  iconTextDecoration: {
    color: Colors.purpley,
    textDecorationLine: 'underline',
  },
  iconContainer: {
    borderWidth: 1,
    borderColor: Colors.primary_color,
    borderRadius: L.hProm(40) / 2,
    height: L.hProm(40),
    width: L.wProm(40),
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: L.w(8),
  },
});

function mapStateToProps(store) {
  return {
    tickets: {}, // store.ticketReducer.tickets,
    anonymousMode: store.appReducer.anonymousMode,
    isConnected: store.appReducer.isConnected,
    activeEventTicketTypes: store.appReducer.activeEventTicketTypes,
    user: store.authReducer.user,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      ticketTypeSelectedListener,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Ticket);
