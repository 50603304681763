import _ from "lodash";
import { Icon, Toast } from "native-base";//WEB99 no usar mas ICON de native-base
import IconFA from "react-native-vector-icons/FontAwesome";
import React from "react";
import { Dimensions, StyleSheet, Text, View, Image, Platform } from "react-native";
import { Actions } from "react-native-router-flux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import L from "../../common/Layout";
import IconButton from "../../components/IconButton";
import VTHeader from "../../components/VTHeader";
import Fonts from "../../common/Fonts";
import Color from "../../common/Colors";
import { TOP_PADDING_IOS_FONT } from "../../common/Constants";
import AppMem from "../../common/AppMem";
import I18n from "i18next"
import { buyTicketWithPoints } from "../../actions/PaymentActions";
import Config from "react-native-config";
const screen = Dimensions.get("window");
import Colors from "../../common/Colors";

class CardTypeSelect extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onPressDebit = this.onPressDebit.bind(this);
    this.onPressCredit = this.onPressCredit.bind(this);
    this.onPressPagoFacil = this.onPressPagoFacil.bind(this);
    this.onPressPoints = this.onPressPoints.bind(this);
    this.onPressStripe = this.onPressStripe.bind(this);

    this.state = {
      loading: false,
    };
  }

  componentWillMount() {}

  onPressPoints() {
    let { preferenceData, onSuccesBuy, loyaly = {} } = this.props;
    const { points: loyalyPoints = 0 } = loyaly;
    const { metadata = {} } = preferenceData;
    const { futureProduct = {} } = metadata;
    const { total = 0 } = futureProduct;
    if (loyalyPoints > total) {
      AppMem.openAppLoader("bars", {
        text: I18n.t("cardTypeSelect.loaderText"),
        color: Colors.secondary_color,
      });

      this.props.buyTicketWithPoints(preferenceData, (callback) => {
        AppMem.closeAppLoader("bars", {
          text: I18n.t("cardTypeSelect.loaderText"),
          color: Colors.secondary_color,
        });
        const { error, operationId = 0 } = callback;
        console.log("errorerror", callback);
        if (error) {
          Toast.show({
            text: I18n.t("cardTypeSelect.errorOcurred"),
            buttonText: I18n.t("global.accept"),
            type: "danger",
            position: "bottom",
          });
        } else {
          Toast.show({
            text: `${I18n.t("cardTypeSelect.boughtTickets")} ${operationId}`,
            buttonText: I18n.t("global.accept"),
            position: "bottom",
            type: "success",
            duration: 3000,
            onClose: () => Actions.reset("mainView"),
          });
        }
      });
    } else {
      Toast.show({
        text: I18n.t("cardTypeSelect.noEnoughPoints"),
        buttonText: I18n.t("global.accept"),
        type: "danger",
        position: "bottom",
      });
    }
  }
  onPressDebit() {
    this.navigateFunction("debit_card");
  }
  onPressStripe() {
    Actions.stripeScreen();
  }

  navigateFunction(card_type) {
    let { onSuccesBuy, onErrorBuy, userData, preferenceData } = this.props;
    Actions.pagos360Screen({
      userData: userData,
      card_type: card_type,
      preferenceData: preferenceData,
      onSuccesBuy: onSuccesBuy,
      onErrorBuy: onErrorBuy,
    });
  }
  onPressCredit() {
    this.navigateFunction("credit_card");
  }

  onPressPagoFacil() {}

  render() {
    const { loading } = this.state;
    const getHeaderProps = (headerProps) => {
      let props = {
        leftButtons: [
          {
            onPress: () => {
              if (!this.state.processing) {
                Actions.pop();
              }
            },
            icon: {
              name: "chevron-left",
              type: "FontAwesome",
              style: {
                color: Color.mainTitleColor,
                fontSize: L.h(23),
                marginLeft: L.w(8),
              },
            },
          },
        ],
        titleStyle: {
          fontFamily: Fonts.MEDIUM,
          fontSize: L.h(20),
          color: Color.mainTitleColor,
          top: Platform.OS === "ios" ? TOP_PADDING_IOS_FONT : 0,
        },
        noShadow: true,
        noStatusBar: true,
        moreButtonColor: "white",
        ...headerProps,
      };
      return props;
    };
    const icon = (
      <IconFA
        name="credit-card"
        type="FontAwesome"
        style={{
          color: Color.secondary_color,
          fontSize: L.h(25),
        }}
      />
    );
    return (
      <View
        style={{ flex: 1, backgroundColor: Color.backgroundCardTypeSelect }}
      >
        <VTHeader
          {...getHeaderProps({
            backgroundColor: "transparent",
            title: I18n.t("cardTypeSelect.doPay"),
          })}
        />
        <View style={{ flex: 1 }}>
          <View
            style={{
              justifyContent: "center",
              alignItems: "center",
              marginTop: L.hProm(32),
            }}
          >
            <Text
              style={{
                color: Color.titleCardTypeSelect,
                fontFamily: Fonts.DREGULAR,
              }}
            >
              {I18n.t("cardTypeSelect.selectPaymentMethod")}
            </Text>
          </View>
          <View
            style={{ alignItems: "center", justifyContent: "center" }}
            loading={loading}
            isEmpty={false}
            floatButton={null}
            keyboardShouldPersistTaps={"always"}
          >
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-around",
                marginTop: L.h(28),
              }}
            >
              <IconButton
                onPress={this.onPressCredit}
                label={
                  I18n.t("cardTypeSelect.fintechMethods")
                }
                icon={icon}
                containerStyle={{
                  backgroundColor: Color.buttonCardTypeSelect,
                  width: L.wProm(300),
                  paddingHorizontal: L.wProm(30),
                  height: L.hProm(55),
                  borderRadius: L.h(30),
                }}
                textStyle={{
                  fontFamily: Fonts.DREGULAR,
                  color: Color.buttonTextCardTypeSelect,
                  lineHeight: L.h(17),
                }}
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-around",
                marginTop: L.h(28),
              }}
            >
              <IconButton
                onPress={this.onPressCredit}
                label={I18n.t("cardTypeSelect.creditCard")}
                icon={icon}
                containerStyle={{
                  backgroundColor: Color.buttonCardTypeSelect,
                  width: L.wProm(300),
                }}
                textStyle={{
                  fontFamily: Fonts.DREGULAR,
                  color: Color.buttonTextCardTypeSelect,
                }}
              />
            </View>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "space-around",
                marginTop: L.h(28),
              }}
            >
              <IconButton
                onPress={this.onPressDebit}
                label={I18n.t("cardTypeSelect.debitCard")}
                icon={icon}
                containerStyle={{
                  backgroundColor: Color.buttonCardTypeSelect,
                  width: L.wProm(300),
                }}
                textStyle={{
                  fontFamily: Fonts.DREGULAR,
                  color: Color.buttonTextCardTypeSelect,
                }}
              />

              {/* <IconButton
              onPress={this.onPressPagoFacil}
              label={"Cupón de pago"}
              icon={icon}
              containerStyle={{
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "#e9e9e9",
                width: L.wProm(170),
                height: L.hProm(44),
                borderRadius: 22
              }}
            /> */}
              {/* <IconButton
            onPress={this.onPressCredit}
            label={"Crédito"}
            icon={icon}
          /> */}
            </View>
            {Config.HAS_LOYALY_PROGRAM === "active" ? (
              <View
                style={{
                  flexDirection: "row",
                  justifyContent: "space-around",
                  marginTop: L.h(28),
                }}
              >
                <IconButton
                  onPress={this.onPressPoints}
                  label={I18n.t("cardTypeSelect.payPoints")}
                  icon={icon}
                  containerStyle={{
                    backgroundColor: Color.buttonCardTypeSelect,
                    width: L.wProm(300),
                  }}
                  textStyle={{
                    fontFamily: Fonts.DREGULAR,
                    color: Color.buttonTextCardTypeSelect,
                  }}
                />
              </View>
            ) : null}
          </View>
        </View>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  subMessageContainer: {
    width: screen.width,
    height: L.hProm(112),
    paddingTop: L.h(16),
    paddingBottom: L.h(10),
    paddingLeft: L.h(30),
    paddingRight: L.h(30),
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
  },
  subMessageText: {
    color: "#FFFFFF",
    fontSize: L.h(14),
    lineHeight: L.h(28),
    textAlign: "center",
    marginBottom: L.h(-30),
  },
});

function mapStateToProps(store) {
  return {
    loyalyUseMethods: store.appReducer.loyalyUseMethods,
    loyaly: store.appReducer.loyaly,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      buyTicketWithPoints,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CardTypeSelect);
