import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  Image,
  Alert,
  RefreshControl,
  ActivityIndicator,
} from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Actions } from 'react-native-router-flux';
import L from '../../common/Layout';
import Fonts from '../../common/Fonts';
import Colors from '../../common/Colors';
import { getMyNextEvents, getEventsCollection } from '../../actions/AppActions';
import EventTickets from '../../components/EventTickets';
import I18n from 'i18next';
import SegmentControl from '../../components/SegmentControl';
import Footer from '../../components/web/Footer';
import _ from 'lodash';
import moment from 'moment';
import Util from '../../common/Util';
import HeaderImage from '../../common/assets/ticketDetail-header.jpg';

class ShopMain extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      refresh: false,
      tabIndex: 0,
      loading: false,
    };
    this.onRefresh = this.onRefresh.bind(this);
    this.onPressSegment = this.onPressSegment.bind(this);
  }

  componentWillMount() {
    this.setState({ loading: true });
    console.log(' >>>>>>>>>>reder shop ');
    this.props.getEventsCollection(null, () => {
      console.log(' >>>>>>>>>> evt collect');
      this.props.getMyNextEvents(() =>
        this.setState({ refresh: false, loading: false })
      );
    });
  }
  onPressSegment(index) {
    this.setState({ tabIndex: index });
  }

  renderEvents(eachEvent, eventId) {
    let { nextEvents, getMyNextEvents } = this.props;
    //  if (!_.isEmpty(eachEvent?.tickets)) {
    // console.log(' >>>>>>>>>> nextEvents', eachEvent);
    const {
      evtPlace,
      evtPlaceAddress,
      evtTitle,
      tickets,
      evtDateTime,
      evtImg,
    } = eachEvent;
    return (
      // <EventTickets
      //   key={eachEvent.id}
      //   eventData={eachEvent}
      //   footerText={
      //     !eachEvent.paymentPendingProcess
      //       ? `${eachEvent &&
      //           eachEvent.tickets &&
      //           eachEvent.tickets.length} ${I18n.t('shopMain.ticketsBought')} `
      //       : I18n.t('shopMain.processingTicketPayment')
      //   }
      //   style={{
      //     marginTop: L.h(0),
      //     borderBottomWidth:
      //       Object.entries(nextEvents).length - 1 == eventId ? 1 : 0,
      //   }}
      //   onPress={() => {
      //     if (eachEvent.paymentPendingProcess) {
      //       Alert.alert(I18n.t('shopMain.processingTicketPayment2'));
      //       this.props.getMyNextEvents();
      //     } else {
      //       Actions.shopDetail({
      //         eventId: eventId,
      //         eventData: eachEvent,
      //       });
      //     }
      //   }}
      // />
      <div
        style={{
          paddingTop: 20,
          cursor: 'pointer',
          backgroundColor: Colors.backgroundTicketRowDetail,
        }}
        onClick={() => {
          if (eachEvent.paymentPendingProcess) {
            Alert.alert(I18n.t('shopMain.processingTicketPayment2'));
            getMyNextEvents();
          } else {
            Actions.shopDetail({
              eventId: eventId,
              eventData: eachEvent,
            });
          }
        }}
      >
        <div class="movie-review-item">
          <div class="author">
            <div class="thumb">
              <a>
                <img src={evtImg} alt="cast" />
              </a>
            </div>
            <div class="movie-review-info">
              <span class="reply-date">
                {moment(
                  Util.timestampToDate(evtDateTime && evtDateTime.seconds)
                ).format('dddd')}
              </span>
              <h6 class="subtitle">
                <a>
                  {moment(
                    Util.timestampToDate(evtDateTime && evtDateTime.seconds)
                  ).format('DD MMMM YYYY')}
                </a>
              </h6>
              <span>
                <i class="fas fa-check" /> {tickets.length} tickets disponibles
              </span>
            </div>
          </div>
          <div class="movie-review-content">
            <h6 class="cont-title">{evtTitle}</h6>
            <p>{evtPlace}</p>
          </div>
        </div>
      </div>
    );
    //  }
  }

  onRefresh() {
    this.setState({ refresh: true });
    this.props.getEventsCollection(null, () => {
      this.props.getMyNextEvents(() => this.setState({ refresh: false }));
    });
  }

  mapEvents(eachGlobalEvent, nextEvents) {
    return _.map(nextEvents, (eachEvent, eventId) => {
      if (eachEvent.id === eachGlobalEvent.id)
        return this.renderEvents(eachEvent, eventId);
    });
  }

  render() {
    let { nextEvents, globalEvents } = this.props;
    let { loading, tabIndex } = this.state;
    let ticketId = 1;
    return (
      <View
        style={[
          styles.container,
          { paddingTop: 100, backgroundColor: Colors.backgroundHistory },
        ]}
      >
        {/* <!-- ==========Banner-Section========== --> */}
        <section
          class="details-banner bg_img"
          style={{ backgroundImage: `url(${HeaderImage})` }}
          // data-background="../../common/assets/web/lee-blanchflower-1dW1vEJLlCQ-unsplash.jpg"
        >
          {/* <div class="container">
            <div class="details-banner-wrapper">
              <div class="details-banner-content offset-lg-3">
                <h3 class="title">Venus</h3>
                <div class="tags">
                  <a >English</a>
                  <a >Hindi</a>
                  <a >Telegu</a>
                  <a >Tamil</a>
                </div>
                <a  class="button">
                  horror
                </a>
                <div class="social-and-duration">
                  <div class="duration-area">
                    <div class="item">
                      <i class="fas fa-calendar-alt" />
                      <span>06 Dec, 2020</span>
                    </div>
                    <div class="item">
                      <i class="far fa-clock" />
                      <span>2 hrs 50 mins</span>
                    </div>
                  </div>
                  <ul class="social-share">
                    <li>
                      <a >
                        <i class="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li>
                      <a >
                        <i class="fab fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a >
                        <i class="fab fa-pinterest-p" />
                      </a>
                    </li>
                    <li>
                      <a >
                        <i class="fab fa-linkedin-in" />
                      </a>
                    </li>
                    <li>
                      <a >
                        <i class="fab fa-google-plus-g" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}
        </section>
        {/* <!-- ==========Banner-Section========== --> */}

        {/* <!-- ==========Movie-Section========== --> */}
        <section
          class="movie-details-section padding-bottom"
          style={{ paddingTop: '3%' }}
        >
          <div class="container">
            <div class="row justify-content-center flex-wrap-reverse mb--50">
              <div class="col-lg-3 col-sm-10 col-md-6 mb-50">
                <div class="widget-1 widget-banner">
                  <div class="widget-1-body">
                    <a>
                      <img
                        src="./assets/images/sidebar/banner/banner01.png"
                        alt="banner"
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div class="col-lg-9 mb-50">
                <div class="movie-details">
                  <h3 class="title">mis tickets</h3>
                  <div class="tab summery-review">
                    <ul class="tab-menu">
                      <li
                        onClick={() => this.onPressSegment(0)}
                        className={tabIndex === 0 ? 'active' : ''}
                      >
                        Próximos Eventos
                      </li>
                      <li
                        onClick={() => this.onPressSegment(1)}
                        className={tabIndex !== 0 ? 'active' : ''}
                      >
                        Finalizados
                      </li>
                    </ul>
                    <View style={{ flex: 1 }}>
                      {loading ? (
                        <ActivityIndicator
                          size={'large'}
                          style={{
                            alignSelf: 'center',
                            marginBottom: 40,
                          }}
                        />
                      ) : !_.isEmpty(nextEvents) ? (
                        _.map(globalEvents, (eachGlobalEvent, eventId) => {
                          //_.map(nextEvents, (eachEvent, eventId) => {
                          {
                            /* let formatedDate = moment(eachEvent.evtDate).format(
                "DD [de] MMMM"
              ); */
                          }
                          if (
                            tabIndex === 0 &&
                            eachGlobalEvent.evtState == 'active'
                          ) {
                            return this.mapEvents(eachGlobalEvent, nextEvents);
                          } else if (
                            tabIndex === 1 &&
                            eachGlobalEvent.evtState == 'inactive'
                          ) {
                            return this.mapEvents(eachGlobalEvent, nextEvents);
                          }
                        })
                      ) : (
                        <Image
                          source={require('../../common/assets/errorImage.png')}
                          resizeMode="contain"
                          style={{
                            width: L.wProm(300),
                            height: L.hProm(130),
                            alignSelf: 'center',
                          }}
                        />
                      )}
                    </View>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- ==========Movie-Section========== --> */}
        <Footer />
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.backgroundHistory,
  },
  divider: {
    paddingVertical: L.h(10),
    paddingHorizontal: L.w(18),
    backgroundColor: Colors.dividerBackgroundOne,
  },
  dividerText: {
    color: Colors.dividerTextOne,
    fontSize: L.h(14),
    fontFamily: Fonts.DREGULAR,
  },
  eventContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: L.h(16),
    paddingLeft: L.w(25),
    paddingRight: L.w(35),
    paddingBottom: L.h(15),
    borderBottomWidth: 1,
    borderColor: Colors.black_14,
  },
  eventDescription: { paddingRight: L.w(10) },
  eventDate: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconDate: {
    fontSize: L.h(13),
    marginRight: L.w(5),
  },
  eventText: {
    fontSize: L.h(14),
    color: Colors.greyishBrown,
    fontFamily: Fonts.DLIGHT,
  },
  eventName: {
    fontSize: L.h(17),
    marginTop: L.h(5),
    color: Colors.greyishBrown,
    fontFamily: Fonts.DSEMIBOLD,
  },
  eventTicket: {
    fontSize: L.h(14),
    marginTop: L.h(5),
    color: Colors.secondary_color,
    fontFamily: Fonts.DLIGHT,
  },
  iconRight: {
    fontSize: L.h(20),
    color: Colors.greyishBrown,
  },
});

function mapStateToProps(store) {
  return {
    tickets: {},
    nextEvents: store.appReducer.nextEvents, // store.ticketReducer.tickets,
    globalEvents: store.appReducer.events,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getMyNextEvents,
      getEventsCollection,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopMain);
