import React, { Component } from "react";
import { Icon } from "native-base";
import { View, Text, Image, StyleSheet, ScrollView } from "react-native";
import { Actions } from "react-native-router-flux";
import {
  actLogout,
  sendAgainVerificationEmail,
} from "../../actions/AuthActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import L from "../../common/Layout";
import Colors from "../../common/Colors";
import Fonts from "../../common/Fonts";
import I18n from "i18next"
import moment from "moment";
import AppMem from "../../common/AppMem";
//import hd_keychain from "../../common/assets/hdOk.png";
import VTHeader from "../../components/VTHeader";
import RegisterConstants from "./RegisterConstants";
import MainButton from "../../components/MainButton";
import firebase from "react-native-firebase";

import { reduxForm, reset, change } from "redux-form";

class RegisterF extends React.PureComponent {
  constructor(props) {
    super(props);
    this.reSendEmail = this.reSendEmail.bind(this);
    this.activateAccount = this.activateAccount.bind(this);
  }

  componentWillMount() {
    let { dispatch } = this.props;
    setTimeout(() => {
      AppMem.closeAppLoader();
    }, 500);
    dispatch(reset("register"));
  }

  componentDidMount() {
    setTimeout(() => {
      AppMem.closeAppLoader();
    }, 1000);
  }

  activateAccount() {
    let { actLogout } = this.props;
    actLogout(() => {
      Actions.replace("login");
    });
    // var user = firebase.auth().currentUser;
    // var credential = firebase.auth.EmailAuthProvider.credential(
    //   user._user.email,
    //   "123123"
    // );

    // // Prompt the user to re-provide their sign-in credentials
    // user
    //   .reauthenticateWithCredential(credential)
    //   .then(function(res) {
    //     console.log(" >>>>>>>>>> ok", res);
    //     AppMem.closeAppLoader();
    //     if (res._user.emailVerified) {
    //       Actions.replace("login");
    //     } else {
    //       return null;
    //     }
    //     // User re-authenticated.
    //   })
    //   .catch(function(error) {
    //     console.log(" >>>>>>>>>>error ", error);
    //     // An error happened.
    //   });
  }

  reSendEmail() {
    let { sendAgainVerificationEmail } = this.props;
    AppMem.openAppLoader("customLoader", {
      text: "Enviando",
      color: Colors.cerulean,
    });
    sendAgainVerificationEmail(this.emailSendCallback);
  }

  emailSendCallback() {
    AppMem.closeAppLoader();
    let modalProps = {
      title: "Se envió correctamente",
      type: "success",
      buttonOk: { text: "Aceptar", action: () => null },
    };
    setTimeout(() => {
      AppMem.openModal("alert", modalProps);
    }, 200);
    console.log(" >>>>>>>>>> se envio ok ");
  }

  render() {
    const { handleSubmit } = this.props;
    let current = firebase.auth().currentUser;
    return (
      <View style={localStyle.container}>
        <VTHeader {...RegisterConstants.headerProps("main")} />
        <ScrollView style={{ flex: 1 }} contentContainerStyle={{ flexGrow: 1 }}>
          <View style={localStyle.mainContent}>
            {/* <Text style={localStyle.textInfo}>
            {I18n.t("global.hi")} {current._user.displayName}!
          </Text> */}
            <Text
              style={[
                localStyle.textInfo,
                { fontSize: L.h(25), marginTop: L.h(20) },
              ]}
            >
              {I18n.t("register.signInSteps.sendEmailTxt")}{" "}
              {current._user.email}
            </Text>
            {/* <Image source={hd_keychain} style={localStyle.registerOk} /> */}
            <Text
              style={[
                localStyle.textInfoRegular,
                {
                  marginTop: L.h(40),
                },
              ]}
            >
              {I18n.t("register.signInSteps.textIntro")}
            </Text>
            <MainButton
              text={I18n.t("register.accountActivate")}
              buttonStyle={{
                marginBottom: 0,
                width: "100%",

              }}
              style={{ marginTop: L.h(50), }}
              primaryColor={false}
              onPress={this.activateAccount}
            />
            <MainButton
              text={I18n.t("register.reSendEmail")}
              outline
              secondary
              style={{ marginTop: L.h(20), }}
              buttonStyle={{ marginTop: L.h(25), marginBottom: L.h(80) }}
              onPress={() => this.reSendEmail()}
            />
          </View>
        </ScrollView>
      </View>
    );
  }
}
const localStyle = StyleSheet.create({
  closeButton: {
    padding: 10,
    position: "absolute",
    top: L.h(25),
    left: L.w(15),
  },
  closeIcon: {
    fontSize: L.h(30),
    color: "white",
  },
  container: {
    flex: 1,
    backgroundColor: Colors.backgroundRegisterF,
  },
  mainContent: {
    marginTop: L.h(0),
    paddingTop: L.h(25),
    paddingHorizontal: L.w(44),
    flex: 1,
  },
  registerOk: {
    marginTop: L.h(40),
    alignSelf: "center",
  },
  textInfo: {
    fontSize: L.h(25),
    fontFamily: Fonts.DBOLD,
    color: Colors.textRegisterF,
  },
  textInfoRegular: {
    fontSize: L.h(25),
    color: Colors.textRegisterF,
  },
});

function mapStateToProps(store) {
  return {};
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      // actRegister,
      sendAgainVerificationEmail,
      actLogout,
    },
    dispatch
  );
}
export default reduxForm({
  form: "register",
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RegisterF)
);
