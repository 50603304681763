import React from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  ActivityIndicator,
  RefreshControl,
} from "react-native";
import { Actions } from "react-native-router-flux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { connect } from "react-redux";
import Util from "../../common/Util";
import Header from "../../components/VTHeader";
import L from "../../common/Layout";
import moment from "moment";
import Fonts from "../../common/Fonts";
import Colors from "../../common/Colors";
import { actTicketsInfo } from "../../actions/ProducerActions";
import { actEventStatsRRPP } from "../../actions/RRPPActions";
import EventCardRRPP from "../../components/EventCardRRPP";
import { Toast } from "native-base";
import SegmentControl from "../../components/SegmentControl";
import Config from "react-native-config";
import I18n from "i18next";

class RRPPDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      select: false,
      searchOk: false,
      eventStatsLoader: false,
      tabIndex: 0,
      refresh: false,
    };
    this.callbackEventStats = this.callbackEventStats.bind(this);
    this.onPressSegment = this.onPressSegment.bind(this);
    this.onRefresh = this.onRefresh.bind(this);
  }

  componentDidMount() {
    this.onStart();
  }

  onStart() {
    let { actEventStatsRRPP, actTicketsInfo, event, rrppId } = this.props;
    let eventId = event.id;
    this.setState({ eventStatsLoader: true });
    actEventStatsRRPP(eventId, rrppId, this.callbackEventStats);
    actTicketsInfo(eventId);
  }

  callbackEventStats() {
    this.setState({ eventStatsLoader: false, refresh: false });
  }

  onPressSegment(index) {
    this.setState({ tabIndex: index });
  }

  onRefresh() {
    this.setState({ refresh: true });
    this.onStart();
  }

  renderTicketsData(et, type, eventStats) {
    et = et[type];
    let ticketArr = [];
    et &&
      et.ticketTypes.forEach((el) => {
        ticketArr.push(this.renderType(el, type, eventStats));
      });
    return ticketArr;
  }

  renderType(et, type, eventStats) {
    let invites = null;
    if (type === "rrppsell") {
      invites = eventStats.originTypes["gift-bo"];
    }
    const { tktTypeData, totalAmmountProducer, tktType } = et;
    const { tktProducerName, tktColor } = tktTypeData;

    if (invites) {
      for (let i = 0; i < invites.ticketTypes.length; i++) {
        let e = invites.ticketTypes[i];
        if (e.tktType == tktType) {
          tktTypeData.tktInvitationsQt = e.totalQt || 0;
        }
      }
    }
    return (
      <View
      //key={et.typeId}
      >
        <View
          style={[
            styles.subheaderDetail,
            {
              backgroundColor: tktColor ? tktColor : Colors.twilight,
            },
          ]}
        >
          <Text style={[styles.subheaderText, { flex: 1 }]}>
            {/*ticketInfo.tktProducerName
                  ? ticketInfo.tktProducerName.toUpperCase()
                  : et.typeId
                  ? et.typeId.toUpperCase()
                : ""*/}
            {tktProducerName}
          </Text>
          {/*<Text style={[styles.subheaderText, { fontFamily: Fonts.DSEMIBOLD }]}>
                Se rinden a $
                ticketInfo.tktPriceProducer
                  ? Math.round(ticketInfo.tktPriceProducer)
                : ""
              </Text>*/}
        </View>
        <View style={[{ marginTop: L.h(17) }]}>
          <View style={styles.cardContainer}>
            <View style={styles.singleRowDetail}>
              <Text style={styles.textDetail}>
                {I18n.t("rrppDetail.totalTickets")}{" "}
                {`${
                  type == "standard"
                    ? I18n.t("rrppDetail.referenced")
                    : I18n.t("rrppDetail.sold")
                }`}
              </Text>
              <Text style={styles.textDetailRight}>{et.totalQt}</Text>
            </View>
            {type === "rrppsell" && invites ? (
              <View style={styles.singleRowDetail}>
                <Text style={styles.textDetail}>
                  {I18n.t("rrppDetail.invites")}
                </Text>
                <Text style={styles.textDetailRight}>
                  {tktTypeData.tktInvitationsQt || 0}
                </Text>
              </View>
            ) : null}
            {/*<View style={styles.singleRowDetail}>
                  <Text style={styles.textDetail}>Monto a rendir a Productor</Text>
                  <Text style={styles.textDetailRight}>{et.totalAmmount}</Text>
                </View>*/}
          </View>
          <View
            style={[
              styles.cardContainer,
              { marginVertical: L.h(16), marginHorizontal: L.w(16) },
              styles.shadow,
            ]}
          >
            {type === "standard" ? null : (
              <View style={styles.singleRowDetail}>
                <Text style={[styles.textDetail, { color: Colors.sellGreen }]}>
                  {I18n.t("rrppDetail.amountForProducer")}
                </Text>
                <Text
                  style={[styles.textDetailRight, { color: Colors.sellGreen }]}
                >
                  ${_.round(totalAmmountProducer, 2)}
                </Text>
              </View>
            )}
          </View>
          {type === "standard" ? null : (
            <Text style={styles.comText}>
              {I18n.t("rrppDetail.commisionDetail")}
            </Text>
          )}
        </View>
      </View>
    );
  }

  render() {
    let { eventStatsLoader } = this.state;
    let { event, eventStats, ticketTypes } = this.props;
    let isVencido = moment(new Date()).isAfter(
      Util.timestampToDate(event.evtEndDate.seconds)
    );

    let isActive = event.evtState == "active" ? true : false;
    let ticketsList = _.orderBy(ticketTypes, ["id"], "desc");
    let rrppTicketTypes = eventStats && eventStats.originTypes;
    return (
      <View style={styles.container}>
        <Header
          title={I18n.t("rrppDetail.title")}
          leftButtons={[
            {
              onPress: Actions.pop,
              icon: {
                name: "chevron-left",
                type: "MaterialIcons",
                style: {
                  color: Colors.rrppHeaderTitleText,
                  fontSize: L.h(23),
                  marginLeft: L.w(8),
                },
                text: "",
                textStyle: {
                  fontSize: L.h(18),
                  color: Colors.rrppHeaderTitleText,
                },
              },
            },
          ]}
          rightButtons={[
            {
              onPress: () => {
                !isVencido && isActive
                  ? Actions.transferTicketRRPP({
                      eventId: event.id,
                      ticket: ticketsList,
                      eventName: event.evtTitle,
                    })
                  : Toast.show({
                      text: I18n.t("rrppDetail.unactiveEvent"),
                      buttonText: I18n.t("rrppDetail.accept"),
                      type: "danger",
                      position: "bottom",
                    });
              },
              icon: {
                name: isVencido || !isActive ? null : "ticket",
                type: "MaterialIcons",
                style: { color: "white", fontSize: L.h(20) },
                text:
                  isVencido || !isActive
                    ? I18n.t("rrppDetail.overdue")
                    : I18n.t("rrppDetail.sell"),
                textStyle: {
                  fontSize: L.h(17),
                  color: "white",
                  fontFamily: Fonts.DREGULAR,
                },
              },
            },
          ]}
          titleStyle={{
            fontFamily: Fonts.MEDIUM,
            fontSize: L.h(18),
            color: Colors.rrppHeaderTitleText,
            top: Platform.OS === "ios" ? 2 : 0,
          }}
          rightButtonStyle={[
            !isVencido && isActive
              ? {
                  backgroundColor: Colors.rrppButtonSell,
                  borderRadius: L.h(30),
                  height: L.h(37),
                  alignItems: "center",
                  justifyContent: "center",
                }
              : null,
          ]}
        />
        <ScrollView
          keyboardShouldPersistTaps="always"
          refreshControl={
            <RefreshControl
              refreshing={this.state.refresh}
              onRefresh={this.onRefresh}
            />
          }
        >
          <View style={{ marginHorizontal: L.w(18) }}>
            <EventCardRRPP eventData={event} type={2} />
            <View style={styles.titleCardContainer}>
              <View style={styles.ticketSubTitle}>
                <Text style={styles.subTitleText}>
                  {I18n.t("rrppDetail.organize")}
                  {Config.PRODUCER_NAME.toUpperCase()}
                </Text>
              </View>
            </View>
            <Text style={styles.warningText}>
              {I18n.t("rrppDetail.totalResume")}
            </Text>
          </View>
          {!eventStats ? (
            <View style={{ marginTop: L.h(16), alignSelf: "center" }}>
              <ActivityIndicator size="large" color={Colors.darkIndigo} />
            </View>
          ) : (
            <View>
              {eventStatsLoader ? null : (
                <View style={styles.shadow}>
                  <View
                    style={{
                      flex: 1,
                      marginHorizontal: L.w(18),
                    }}
                  >
                    <View style={styles.cardContainer}>
                      <View style={styles.singleRowDetail}>
                        <Text style={styles.textDetail}>
                          {I18n.t("rrppDetail.soldTickets")}
                        </Text>
                        <Text style={styles.textDetailRight}>
                          {eventStats.originTypes &&
                          eventStats.originTypes.rrppsell &&
                          eventStats.originTypes.rrppsell.totalQt
                            ? eventStats.originTypes.rrppsell.totalQt
                            : 0}
                        </Text>
                      </View>
                      <View style={styles.singleRowDetail}>
                        <Text style={styles.textDetail}>
                          {I18n.t("rrppDetail.referencedTickets")}
                        </Text>
                        <Text style={styles.textDetailRight}>
                          {rrppTicketTypes && rrppTicketTypes.standard
                            ? rrppTicketTypes.standard.totalQt
                            : 0}
                        </Text>
                      </View>
                      <View style={styles.singleRowDetail}>
                        <Text style={styles.textDetail}>Invitaciones</Text>
                        <Text style={styles.textDetailRight}>
                          {rrppTicketTypes && rrppTicketTypes["gift-bo"]
                            ? rrppTicketTypes["gift-bo"].totalQt
                            : 0}
                        </Text>
                      </View>
                    </View>
                  </View>
                  <View style={styles.earnContainer}>
                    <Text style={styles.earnText}>
                      {I18n.t("rrppDetail.totalAmountProducer")}
                    </Text>
                    <Text style={styles.earnMainValue}>
                      {rrppTicketTypes && rrppTicketTypes.rrppsell
                        ? `$${_.round(
                            eventStats.originTypes.rrppsell
                              .totalAmmountProducer,
                            2
                          )}`
                        : `$${0}`}
                    </Text>
                  </View>
                </View>
              )}
              <Text style={[styles.warningText, { marginBottom: L.h(5) }]}>
                {I18n.t("rrppDetail.detailByTicketType")}
              </Text>
              <View
                style={{
                  width: "100%",
                  alignSelf: "center",
                }}
              >
                <SegmentControl
                  values={[
                    I18n.t("rrppDetail.sold2"),
                    I18n.t("rrppDetail.referenced2"),
                  ]}
                  selectedIndex={this.state.tabIndex}
                  height={30}
                  onTabPress={this.onPressSegment}
                  borderRadius={0}
                />
              </View>
              {this.state.tabIndex !== 0 && rrppTicketTypes
                ? this.renderTicketsData(
                    rrppTicketTypes,
                    "standard",
                    eventStats
                  )
                : this.renderTicketsData(
                    rrppTicketTypes,
                    "rrppsell",
                    eventStats
                  )}
            </View>
          )}
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    color: Colors.whiteFour,
    backgroundColor: Colors.backgroundRRPPDetail,
  },
  text14Med: {
    color: Colors.commonDetailText,
    fontSize: L.h(14),
    fontFamily: Fonts.MEDIUM,
  },
  shadow: {
    elevation: 2,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  shadow2: {
    elevation: 2,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  dateContainer: {
    flexDirection: "row",
    marginTop: L.h(20),
    marginBottom: L.h(10),
    justifyContent: "space-between",
    marginHorizontal: L.w(5),
  },
  titleCardContainer: {
    marginHorizontal: L.h(4),
    backgroundColor: "white",
    marginVertical: L.h(12),
  },
  ticketTitle: {
    color: Colors.darkIndigo,
    marginHorizontal: L.w(50),
    textAlign: "center",
    marginBottom: L.h(3),
    fontFamily: Fonts.MEDIUM,
    fontSize: L.h(14),
  },
  ticketTitlePlace: {
    color: Colors.darkIndigo,
    marginHorizontal: L.w(50),
    textAlign: "center",
    marginBottom: L.h(12),
    fontFamily: Fonts.MEDIUM,
    fontSize: L.h(14),
  },
  ticketSubTitle: {
    backgroundColor: Colors.secondary_color,
    paddingVertical: L.h(5),
    borderRadius: 4,
  },
  subTitleText: {
    marginHorizontal: L.w(20),
    textAlign: "center",
    fontSize: L.h(13),
    color: Colors.subtitleTextRRPPDetail,
    fontFamily: Fonts.MEDIUM,
  },
  warningText: {
    fontSize: L.h(14),
    fontFamily: Fonts.DSEMIBOLD,
    color: Colors.resumeTextRRPPDetail,
    marginHorizontal: L.w(30),
    textAlign: "center",
    marginVertical: L.h(16),
  },
  singleRowDetail: {
    flexDirection: "row",
    paddingVertical: L.h(10),
    justifyContent: "space-between",
    borderBottomColor: Colors.black_08,
    borderBottomWidth: 1,
    paddingHorizontal: L.h(10),
  },
  cardContainer: {
    borderRadius: 4,
    elevation: 2,
    margin: L.hProm(4),
    backgroundColor: "white",
  },
  textDetail: {
    fontSize: L.h(13),
    fontFamily: Fonts.DREGULAR,
    color: Colors.greyishBrownThree,
  },
  textDetailRight: {
    fontSize: L.h(13),
    fontFamily: Fonts.MEDIUM,
    color: Colors.greyishBrownThree,
  },
  earnContainer: {
    backgroundColor: Colors.trueGreen,
    marginTop: L.h(20),
    marginBottom: L.h(20),
    paddingVertical: L.h(10),
    paddingHorizontal: L.w(30),
    flexDirection: "row",
    justifyContent: "space-between",
  },
  earnText: {
    color: Colors.earnTextRRPPDetail,
    fontSize: L.h(13),
    fontFamily: Fonts.DREGULAR,
  },
  earnMainValue: {
    color: Colors.earnValueRRPPDetail,
    fontSize: L.h(16),
    fontFamily: Fonts.DSEMIBOLD,
  },
  subheaderDetail: {
    backgroundColor: Colors.twilight,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: L.h(7),
    paddingHorizontal: L.w(18),
    marginTop: L.h(10),
  },
  subheaderText: {
    color: "white",
    fontSize: L.h(13),
  },
  comText: {
    color: Colors.tangerine,
    fontSize: L.h(13),
    fontStyle: "italic",
    textAlign: "center",
    marginBottom: L.h(25),
  },
});

function mapStateToProps({ authReducer, producerReducer, rrppReducer }) {
  return {
    user: authReducer.user,
    eventStats: rrppReducer.eventRRPPStats,
    ticketTypes: producerReducer.ticketTypes,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ actEventStatsRRPP, actTicketsInfo }, dispatch);
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RRPPDetail);
