import React from "react";
import {
  View,
  Text,
  StyleSheet,
  ScrollView,
  ActivityIndicator,
} from "react-native";
import { Actions } from "react-native-router-flux";
import { bindActionCreators } from "redux";
import _ from "lodash";
import { connect } from "react-redux";
import Util from "../../common/Util";
import Header from "../../components/VTHeader";
import L from "../../common/Layout";
import Fonts from "../../common/Fonts";
import Colors from "../../common/Colors";
import {
  actEventStats,
  actTicketsInfo,
  actGetRRPPTicketInfo,
} from "../../actions/ProducerActions";
import I18n from "i18next"
import SegmentControl from "../../components/SegmentControl";

class StatisticsDetail extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      select: false,
      searchOk: false,
      eventStatsLoader: false,
      tabIndex: 0,
    };
    this.callbackEventStats = this.callbackEventStats.bind(this);
    this.onPressSegment = this.onPressSegment.bind(this);
  }

  componentDidMount() {
    let { actTicketsInfo, actGetRRPPTicketInfo, event } = this.props;
    let eventId = event.id;
    this.setState({ eventStatsLoader: true });
    actTicketsInfo(eventId);
    actGetRRPPTicketInfo(eventId, this.callbackEventStats);
  }

  callbackEventStats() {
    this.setState({ eventStatsLoader: false });
  }
  onPressSegment(index) {
    this.setState({ tabIndex: index });
  }
  renderType(et) {
    let { ticketTypes } = this.props;
    let ticketInfo = "";
    ticketTypes &&
      ticketTypes.forEach((tk) => {
        if (et.typeId == tk.id) return (ticketInfo = tk.data);
      });
    return (
      <View key={et.typeId}>
        <View
          style={[
            styles.subheaderDetail,
            {
              backgroundColor: ticketInfo.tktColor
                ? ticketInfo.tktColor
                : Colors.twilight,
            },
          ]}
        >
          <Text style={styles.subheaderText}>
            {ticketInfo.tktProducerName
              ? ticketInfo.tktProducerName.toUpperCase()
              : et.typeId
              ? et.typeId.toUpperCase()
              : ""}
          </Text>
          <Text style={[styles.subheaderText, { fontFamily: Fonts.DSEMIBOLD }]}>
            {I18n.t("statisticsProducer.mainText")}
            {ticketInfo.tktPriceProducer
              ? Math.round(ticketInfo.tktPriceProducer)
              : ""}
          </Text>
        </View>
        <View style={[{ marginTop: L.h(17) }]}>
          <View style={styles.cardContainer}>
            <View style={styles.singleRowDetail}>
              <Text style={styles.textDetail}>
                {I18n.t("statisticsProducer.rrppName")}
              </Text>
              <Text style={styles.textDetailRight}>{et.tktTotalQt}</Text>
            </View>
            <View style={styles.singleRowDetail}>
              <Text style={styles.textDetail}>
                {I18n.t("statisticsProducer.soldByRrpp")}
              </Text>
              <Text style={styles.textDetailRight}>
                {et.tktTotalQtSellByRrpp}
              </Text>
            </View>
            <View style={styles.singleRowDetail}>
              <Text style={styles.textDetail}>
                {I18n.t("statisticsProducer.directSell")}
              </Text>
              <Text style={styles.textDetailRight}>
                {et.tktTotalQtSell - et.tktTotalQtSellByRrpp}
              </Text>
            </View>
            <View style={styles.singleRowDetail}>
              <Text style={[styles.textDetail, { color: Colors.trueGreen }]}>
                {I18n.t("statisticsProducer.sold")}
              </Text>
              <Text
                style={[styles.textDetailRight, { color: Colors.trueGreen }]}
              >
                {et.tktTotalQtSell}
              </Text>
            </View>
          </View>
          <View
            style={[
              styles.cardContainer,
              { marginVertical: L.h(16), marginHorizontal: L.w(16) },
              styles.shadow,
            ]}
          >
            <View style={styles.singleRowDetail}>
              <Text style={[styles.textDetail, { color: Colors.trueGreen }]}>
                {I18n.t("statisticsProducer.amountForProducer")}
              </Text>
              <Text
                style={[styles.textDetailRight, { color: Colors.trueGreen }]}
              >
                ${_.round(et.tktTotalAmmountProducer, 2)}
              </Text>
            </View>
          </View>
          <Text style={styles.comText}>
            {I18n.t("statisticsProducer.statisticsLabel")}
          </Text>
        </View>
      </View>
    );
  }

  renderRRPPList(rrpp) {
    let { tabIndex } = this.state;
    let isSell = tabIndex === 0;
    console.log("************ list", rrpp);
    return (
      <View style={styles.cardContainer}>
        <View style={styles.singleRowDetail}>
          <Text style={styles.textDetail}>
            {rrpp.rrppData.name} {rrpp.rrppData.lastname}
          </Text>
          <Text style={styles.textDetailRight}>
            {isSell
              ? `$${_.round(rrpp.totalAmmountProducer, 2)}`
              : rrpp.totalQt}
          </Text>
        </View>
      </View>
    );
  }

  renderRRPPListDetail(rrpp) {
    let { tabIndex } = this.state;
    let isSell = tabIndex === 0;
    console.log("************ rrpprrpprrpp", rrpp);
    return (
      <View>
        <View style={styles.rrppContainer}>
          <Text style={styles.rrppName}>
            {rrpp.rrppData.name} {rrpp.rrppData.lastname}
          </Text>
        </View>
        <View style={styles.subtitleContainer}>
          <Text style={styles.subtitleText}>
            {I18n.t("statisticsProducer.ticket")}
          </Text>
          <Text style={styles.subtitleText}>
            {isSell
              ? I18n.t("statisticsProducer.shouldReport")
              : I18n.t("statisticsProducer.qntity")}
          </Text>
        </View>
        {rrpp &&
          rrpp.ticketTypes.map((tk) => {
            return this.tktTypes(tk, isSell);
          })}
      </View>
    );
  }

  tktTypes(tkt, isSell) {
    let ticket = tkt.tktTypeData;
    return (
      <View style={styles.shadow}>
        <View
          style={{
            flex: 1,
          }}
        >
          <View style={styles.cardContainer}>
            <View style={styles.singleRowDetail}>
              <Text style={styles.textDetail}>
                {isSell ? tkt.totalQt : null} {isSell ? "-" : null}{" "}
                {ticket.tktProducerName}
              </Text>
              <Text style={styles.textDetailRight}>
                {isSell
                  ? `$${_.round(tkt.totalAmmountProducer, 2)}`
                  : tkt.totalQt}
              </Text>
            </View>
          </View>
        </View>
      </View>
    );
  }

  render() {
    let { eventStatsLoader, tabIndex } = this.state;
    let { detail } = this.props;
    console.log("JSON.STRINFIGY", JSON.stringify(detail));
    let isSell = tabIndex === 0;
    return (
      <View style={styles.container}>
        <Header
          title={I18n.t("statisticsProducer.rrppSold")}
          leftButtons={[
            {
              onPress: Actions.pop,
              icon: {
                name: "chevron-left",
                type: "MaterialIcons",
                style: { color: "white", fontSize: L.h(45) },
                text: Util.isIos() ? "" : "",
                textStyle: {
                  fontSize: L.h(18),
                  color: "white",
                },
              },
            },
          ]}
          titleStyle={{
            fontFamily: Fonts.MEDIUM,
            fontSize: L.h(18),
            color: "white",
            top: Platform.OS === "ios" ? 2 : 0,
          }}
        />
        {eventStatsLoader ? (
          <View style={{ marginTop: L.h(16), alignSelf: "center" }}>
            <ActivityIndicator size="large" color={"black"} />
            <Text style={styles.loadingText}>
              {I18n.t("statisticsProducer.loadingData")}
            </Text>
          </View>
        ) : (
          <ScrollView keyboardShouldPersistTaps="always">
            <SegmentControl
              values={["Vendidas", "Referenciadas"]}
              selectedIndex={this.state.tabIndex}
              height={30}
              onTabPress={this.onPressSegment}
              borderRadius={0}
              tabsContainerStyle={{
                width: "90%",
                alignSelf: "center",
                marginTop: L.h(16),
              }}
            />
            <View style={styles.earnContainer}>
              <Text style={styles.earnText}>
                {I18n.t("statisticsProducer.total")}
              </Text>
            </View>
            <View style={styles.subtitleContainer}>
              <Text style={styles.subtitleText}>
                {I18n.t("statisticsProducer.rrpp")}
              </Text>
              <Text style={styles.subtitleText}>
                {isSell
                  ? I18n.t("statisticsProducer.shouldReport")
                  : I18n.t("statisticsProducer.qntity")}
              </Text>
            </View>
            <View style={styles.shadow}>
              <View
                style={{
                  flex: 1,
                }}
              >
                {isSell
                  ? rrppProducerInfo &&
                    rrppProducerInfo.originTypes.rrppsell.rrppList.map(
                      (rrpp) => {
                        return this.renderRRPPList(rrpp, "rrppSell");
                      }
                    )
                  : rrppProducerInfo &&
                    rrppProducerInfo.originTypes.standard.rrppList.map(
                      (rrpp) => {
                        return this.renderRRPPList(rrpp, "standard");
                      }
                    )}
              </View>
            </View>
            {isSell
              ? rrppProducerInfo &&
                rrppProducerInfo.originTypes.rrppsell.rrppList.map((rrpp) => {
                  return this.renderRRPPListDetail(rrpp);
                })
              : rrppProducerInfo &&
                rrppProducerInfo.originTypes.standard.rrppList.map((rrpp) => {
                  return this.renderRRPPListDetail(rrpp);
                })}
          </ScrollView>
        )}
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    color: Colors.whiteFour,
    backgroundColor: Colors.backgroundProducerDetail,
  },
  text14Med: {
    color: Colors.commonDetailText,
    fontSize: L.h(14),
    fontFamily: Fonts.MEDIUM,
  },
  shadow: {
    elevation: 2,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  shadow2: {
    elevation: 2,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
  },
  dateContainer: {
    flexDirection: "row",
    marginTop: L.h(20),
    marginBottom: L.h(10),
    justifyContent: "space-between",
    marginHorizontal: L.w(5),
  },
  titleCardContainer: {
    marginHorizontal: L.h(4),
    backgroundColor: "white",
    marginVertical: L.h(12),
  },
  ticketTitle: {
    color: Colors.darkIndigo,
    marginHorizontal: L.w(50),
    textAlign: "center",
    marginBottom: L.h(3),
    fontFamily: Fonts.MEDIUM,
    fontSize: L.h(14),
  },
  ticketTitlePlace: {
    color: Colors.darkIndigo,
    marginHorizontal: L.w(50),
    textAlign: "center",
    marginBottom: L.h(12),
    fontFamily: Fonts.MEDIUM,
    fontSize: L.h(14),
  },
  ticketSubTitle: {
    backgroundColor: Colors.secondary_color,
    paddingVertical: L.h(5),
    borderRadius: 4,
  },
  subtitleText: {
    //marginHorizontal: L.w(20),
    //textAlign: "center",
    fontSize: L.h(13),
    color: Colors.steel,
    fontFamily: Fonts.DLIGHT,
  },
  warningText: {
    fontSize: L.h(14),
    fontFamily: Fonts.DSEMIBOLD,
    color: Colors.darkIndigo,
    marginHorizontal: L.w(30),
    textAlign: "center",
    marginVertical: L.h(16),
  },
  singleRowDetail: {
    flexDirection: "row",
    paddingVertical: L.h(10),
    justifyContent: "space-between",
    borderBottomColor: Colors.black_08,
    borderBottomWidth: 1,
    paddingHorizontal: L.h(18),
  },
  cardContainer: {
    //borderRadius: 4,
    elevation: 2,
    //margin: L.hProm(4),
    backgroundColor: "white",
  },
  textDetail: {
    fontSize: L.h(13),
    fontFamily: Fonts.DREGULAR,
    color: Colors.greyishBrownThree,
  },
  textDetailRight: {
    fontSize: L.h(13),
    fontFamily: Fonts.DSEMIBOLD,
    color: Colors.greyishBrownThree,
  },
  earnContainer: {
    backgroundColor: Colors.trueGreen,
    marginTop: L.h(20),
    //marginBottom: L.h(20),
    paddingVertical: L.h(10),
    paddingHorizontal: L.w(18),
    flexDirection: "row",
    justifyContent: "center",
  },
  earnText: {
    color: "white",
    fontSize: L.h(13),
    textAlign: "center",
  },
  earnMainValue: {
    color: "white",
    fontSize: L.h(16),
    fontFamily: Fonts.DSEMIBOLD,
  },
  rrppContainer: {
    backgroundColor: Colors.secondary_color,
    marginTop: L.h(20),
    //marginBottom: L.h(20),
    paddingVertical: L.h(10),
    paddingHorizontal: L.w(18),
    flexDirection: "row",
    justifyContent: "space-between",
  },
  loadingText: {
    fontSize: L.h(16),
    fontFamily: Fonts.DREGULAR,
    letterSpacing: 0,
    color: Colors.secondary_color,
    textAlign: "center",
    marginTop: L.h(8),
  },
  rrppName: {
    color: "white",
    fontSize: L.h(13),
    textAlign: "center",
    fontFamily: Fonts.DSEMIBOLD,
  },
  subtitleContainer: {
    backgroundColor: Colors.whiteSix,
    //marginTop: L.h(20),
    paddingVertical: L.h(10),
    paddingHorizontal: L.w(18),
    flexDirection: "row",
    justifyContent: "space-between",
  },
  subtitleFont: {
    color: Colors.steel,
    fontSize: L.h(13),
  },
  subheaderDetail: {
    backgroundColor: Colors.twilight,
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: L.h(7),
    paddingHorizontal: L.w(18),
    marginTop: L.h(10),
  },
  subheaderText: {
    color: "white",
    fontSize: L.h(13),
  },
  comText: {
    color: Colors.tangerine,
    fontSize: L.h(13),
    fontStyle: "italic",
    textAlign: "center",
    marginBottom: L.h(25),
  },
});

function mapStateToProps({ authReducer, producerReducer }) {
  return {
    user: authReducer.user,
    eventStats: producerReducer.eventStats,
    ticketTypes: producerReducer.ticketTypes,
    rrppProducerInfo: producerReducer.rrppProducerInfo,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { actEventStats, actTicketsInfo, actGetRRPPTicketInfo },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StatisticsDetail);
