import { firestoreApp, firebaseValues } from "./Firebase";
import DataMem from "../../common/AppMem";
import { Platform } from "react-native";
import _ from "lodash";

const originalSend = XMLHttpRequest.prototype.send;
XMLHttpRequest.prototype.send = function(body) {
  if (body === "") {
    originalSend.call(this);
  } else {
    originalSend.call(this, body);
  }
};

export default class FirebaseConnector {
  get(path, id) {
    return new Promise((resolve, reject) => {
      let docRef = firestoreApp.collection(path);
      if (id) {
        docRef = docRef.doc(id);
      }
      docRef
        .get()
        .then((doc) => {
          if (doc && doc.exists && doc.data()) {
            resolve(doc.data());
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          reject({ error: true });
        });
    });
  }

  findCollectionData(path, propName, dataToFind) {
    //"profiles", "proDNI"
    return new Promise(async (resolve, reject) => {
      firestoreApp
        .collection(path)
        .where(propName, "==", dataToFind)
        .get()
        .then((snapshot) => {
          let userExist = null;
          snapshot.forEach((eachUser) => {
            //console.log(" >>>>>>>>>> ", eachUser && eachUser.data());
            if (eachUser.data()) {
              userExist = true;
            }
          });
          if (userExist) {
            reject({ code: "user-exist" });
          } else {
            resolve(userExist);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getRootCollection(path) {
    return new Promise(async (resolve, reject) => {
      let events = [];
      let docRef = firestoreApp.collection(path);
      await docRef
        .get()
        .then((querySnapshot) => {
          let isEmpty = false;
          if (Platform.OS != "web") isEmpty = _.isEmpty(querySnapshot?._docs);
          else isEmpty = _.isEmpty(querySnapshot?.docs);

          if (isEmpty) {
            reject("empty");
          } else {
            querySnapshot.forEach((doc) => {
              events.push(doc.data());
              let artificialIndex = events.length - 1;
              events[artificialIndex].id = doc.id;
              events[artificialIndex].tickets = {};
              events[artificialIndex].evtTicketsType = {};
              Promise.all([
                docRef
                  .doc(doc.id)
                  .collection("evtTicketsType")
                  .get()
                  .then((evtTicketTypeSnapshot) => {
                    evtTicketTypeSnapshot.forEach((type) => {
                      events[artificialIndex].evtTicketsType[
                        type.id
                      ] = type.data();
                    });
                  }),
              ])
                .then(() => {
                  resolve(events);
                })
                .catch((e) => reject(e));
            });
          }
        })
        .catch((error) => {
          console.log(" >>>>>>>>>> error", error);
          reject(error);
        });
    });
  }

  getSubCollectionAndReturnChatId(path, userId, friendId) {
    // Funcion particular unica de los chats
    return new Promise((resolve, reject) => {
      let docRef = firestoreApp
        .collection(path)
        .doc(userId)
        .collection("channels");
      docRef
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let canales = doc.data();
            if (canales[friendId]) {
              resolve(doc.id);
            }
          });
          resolve(null);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getSubCollectionDoc(path, id, subCollection, docId) {
    return new Promise((resolve, reject) => {
      let docRef = firestoreApp.collection(path);
      docRef
        .doc(id)
        .collection(subCollection)
        .doc(docId)
        .get()
        .then((doc) => {
          let data = doc.exists ? doc.data() : {};
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getListenerEvtTicketTypeSelected(path, callback) {
    //Aca esta el tema del bug.
    let docRef = firestoreApp.collection(path);
    let event = {};
    return docRef.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        event[doc.id] = { evtTicketsType: {} };
        let listener = docRef
          .doc(doc.id)
          .collection("evtTicketsType")
          .onSnapshot((evtTicketTypeSnapshot) => {
            if (Platform.OS == "web") {
              // WEB
              evtTicketTypeSnapshot.docChanges().forEach(function(change) {
                //WEB99 En Web la unica dif es que docChanges es un metodo en vez de atributo
                if (change.type === "removed") {
                  delete event[doc.id].evtTicketsType[change.doc.id];
                } else {
                  event[doc.id].evtTicketsType[
                    change.doc.id
                  ] = change.doc.data();
                }
              });
            } else {
              evtTicketTypeSnapshot.docChanges.forEach(function(change) {
                if (change.type === "removed") {
                  delete event[doc.id].evtTicketsType[change.doc.id];
                } else {
                  event[doc.id].evtTicketsType[
                    change.doc.id
                  ] = change.doc.data();
                }
              });
            }
            callback(event);
          });
        DataMem.saveTTListeners(listener);
      });
    });
  }

  getSubCollection(path, id, subCollection) {
    return new Promise((resolve, reject) => {
      let docRef = firestoreApp.collection(path);
      let data = [];
      docRef
        .doc(id)
        .collection(subCollection)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc, index) => {
            let dataToPush = doc.data();
            dataToPush.id = doc.id;
            data.push(dataToPush);
          });
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getSubCollectionOrder(path, id, subCollection, orderBy) {
    return new Promise((resolve, reject) => {
      let docRef = firestoreApp.collection(path);
      let data = [];
      docRef
        .doc(id)
        .collection(subCollection)
        .orderBy(orderBy)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc, index) => {
            let dataToPush = doc.data();
            dataToPush.id = doc.id;
            data.push(dataToPush);
          });
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  delete(path, id) {
    return new Promise((resolve, reject) => {
      firestoreApp
        .collection(path)
        .doc(id)
        .delete()
        .then((doc) => {
          resolve(doc);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  deleteDocFromSubCollection(path, id, subCollection, doc) {
    return new Promise((resolve, reject) => {
      let ref = firestoreApp
        .collection(path)
        .doc(id)
        .collection(subCollection)
        .doc(doc)
        .delete()
        .catch((error) => {
          console.log("LANZA ERROR?", error);
          reject(error);
        });
    });
  }

  deleteFieldFromDocInSubCollection(path, id, subCollection, doc, field) {
    return new Promise((resolve, reject) => {
      let ref = firestoreApp
        .collection(path)
        .doc(id)
        .collection(subCollection)
        .doc(doc);
      ref
        .update({
          [field]: firebaseValues.firestore.FieldValue.delete(),
        })
        .catch((error) => {
          console.log("LANZA ERROR?", error);
          reject(error);
        });
    });
  }

  deleteFieldFromDoc(path, id, field, privacy) {
    return new Promise((resolve, reject) => {
      let ref = firestoreApp.collection(path).doc(id);
      ref
        .update({
          [field]: firebaseValues.firestore.FieldValue.delete(),
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  save(path, obj) {
    return new Promise((resolve, reject) => {
      let docRef = firestoreApp.collection(path);
      firestoreApp
        .collection(path)
        .add(obj)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  update(path, id, obj) {
    return new Promise((resolve, reject) => {
      firestoreApp
        .collection(path)
        .doc(id)
        .set(obj)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  addWithSubCollection(path, userId, subCollection, obj) {
    return new Promise(async (resolve, reject) => {
      firestoreApp
        .collection(path)
        .doc(userId)
        .collection(subCollection)
        .add(obj)
        .then((ref) => {
          resolve(ref.id);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateWithSubCollectionAndMerge(
    path,
    userId,
    subCollection,
    id,
    fieldToSetWithMerge
  ) {
    return new Promise((resolve, reject) => {
      firestoreApp
        .collection(path)
        .doc(userId)
        .collection(subCollection)
        .doc(id)
        .set(fieldToSetWithMerge, { merge: true })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  getGrandChildCollection(
    path,
    id,
    subCollection,
    docId,
    grandChildCollection
  ) {
    return new Promise((resolve, reject) => {
      let docRef = firestoreApp.collection(path);
      let data = [];
      docRef
        .doc(id)
        .collection(subCollection)
        .doc(docId)
        .collection(grandChildCollection)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc, index) => {
            let dataToPush = doc.data();
            dataToPush.id = doc.id;
            data.push(dataToPush);
          });
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
  updateGrandChildSubCollectionAndMerge(
    path,
    userId,
    subCollection,
    id,
    grandChildCollection,
    grandChildId,
    fieldToSetWithMerge
  ) {
    return new Promise((resolve, reject) => {
      firestoreApp
        .collection(path)
        .doc(userId)
        .collection(subCollection)
        .doc(id)
        .collection(grandChildCollection)
        .doc(grandChildId)
        .set(fieldToSetWithMerge, { merge: true })
        .then(() => {
          resolve("OK");
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  updateWithMerge(path, id, fieldToSetWithMerge) {
    return new Promise((resolve, reject) => {
      firestoreApp
        .collection(path)
        .doc(id)
        .set(fieldToSetWithMerge, { merge: true })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          console.log("EJECT ERROR?", error);
          reject(error);
        });
    });
  }

  updateWithMergeFCMArray(path, id, fcmToken) {
    return new Promise((resolve, reject) => {
      firestoreApp
        .collection(path)
        .doc(id)
        .set(
          {
            FCMToken: [fcmToken],
          },
          { merge: true }
        )
        .then(() => {
          resolve();
        })
        .catch((error) => {
          console.log("EJECT ERROR?", error);
          reject(error);
        });
    });
  }

  listenerCollection(path, callback) {
    return new Promise((resolve, reject) => {
      let listener = firestoreApp
        .collection(path)
        .onSnapshot((querySnapshot) => {
          const items = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.exists ? doc.data() : {},
          }));
          if (callback) {
            callback(items);
          }
        });
      DataMem.saveListeners(listener);
    });
  }

  listener(path, id, callback) {
    return new Promise((resolve, reject) => {
      let listener = firestoreApp
        .collection(path)
        .doc(id)
        .onSnapshot((querySnapshot) => {
          let data = querySnapshot.exists ? querySnapshot.data() : {};
          if (callback) {
            callback(data, querySnapshot.id);
          }
        });
      DataMem.saveListeners(listener);
    });
  }

  listenerToHide(path, id, callback) {
    return new Promise((resolve, reject) => {
      let listener = firestoreApp
        .collection(path)
        .doc(id)
        .onSnapshot((querySnapshot) => {
          let data = querySnapshot.exists ? querySnapshot.data() : {};
          if (data.proHideMyProfile || data.proInactiveAccount) {
            data = {};
          }
          if (callback) {
            callback(data, querySnapshot.id);
          }
        });
      DataMem.saveListeners(listener);
    });
  }

  listenerWithSubCollection(path, id, subCollection, callback) {
    return new Promise((resolve, reject) => {
      let listener = firestoreApp
        .collection(path)
        .doc(id)
        .collection(subCollection)
        .onSnapshot((querySnapshot) => {
          const items = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.exists ? doc.data() : {},
          }));
          if (subCollection == "block-received") {
          }
          if (callback) {
            callback(items);
          }
        });

      DataMem.saveListeners(listener);
    });
  }

  listenerWithGrandChildCollection(
    path,
    id,
    subCollection,
    key,
    childCollection,
    callback
  ) {
    return new Promise((resolve, reject) => {
      let listener = firestoreApp
        .collection(path)
        .doc(id)
        .collection(subCollection)
        .doc(key)
        .collection(childCollection)
        .onSnapshot(async (querySnapshot) => {
          const items = await querySnapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.exists ? doc.data() : {},
          }));
          if (callback) {
            callback(items);
          }
        });

      DataMem.saveMPListeners(listener);
    });
  }

  listenerSubCollectionDoc(path, id, subCollection, privacy, callback) {
    return new Promise((resolve, reject) => {
      let listener = firestoreApp
        .collection(path)
        .doc(id)
        .collection(subCollection)
        .doc(privacy)
        .onSnapshot((querySnapshot) => {
          let data = querySnapshot.exists ? querySnapshot.data() : {};
          let chatId = querySnapshot.id;
          if (callback) {
            callback(data, chatId);
          }
        });
      DataMem.saveListeners(listener);
    });
  }
}
