import ProducerService from "../provider/producer/ProducerService";
import _ from "lodash";
import * as actions from "./ActionTypes";
import ErrorManager from "../common/ErrorManager";

export function actEventStats(eventId, callback) {
  return (dispatch) => {
    ProducerService.eventStats(eventId)
      .then((response) => {
        dispatch({
          type: actions.ACT_PRODUCER_CHANGE,
          props: { eventStats: response },
        });

        callback && callback();
      })
      .catch((e) => {
        console.log(" >>>>>>>>>>e ", e);
      });
  };
}
export function actGetRRPPDirectDetail(eventId, callback) {
  return (dispatch) => {
    ProducerService.directDetailRrpp(eventId)
      .then((response) => {
        dispatch({
          type: actions.ACT_PRODUCER_CHANGE,
          props: { directRRPPDetail: response },
        });

        callback && callback();
      })
      .catch((e) => {
        console.log(" >>>>>>>>>>e ", e);
      });
  };
}
export function actGetRRPPReferencedDetail(eventId, callback) {
  return (dispatch) => {
    ProducerService.referencedDetailRrpp(eventId)
      .then((response) => {
        dispatch({
          type: actions.ACT_PRODUCER_CHANGE,
          props: { referencedRRPPDetail: response },
        });

        callback && callback();
      })
      .catch((e) => {
        console.log(" >>>>>>>>>>e ", e);
      });
  };
}
export function actGetProducerDetail(eventId, callback) {
  return (dispatch) => {
    ProducerService.getProducerDetail(eventId)
      .then((response) => {
        dispatch({
          type: actions.ACT_PRODUCER_CHANGE,
          props: { producerRRPPDetail: response },
        });

        callback && callback();
      })
      .catch((e) => {
        console.log(" >>>>>>>>>>e ", e);
      });
  };
}

export function actGetRRPPTicketInfo(eventId, callback) {
  return (dispatch) => {
    ProducerService.rrppTicketInfo(eventId)
      .then((response) => {
        dispatch({
          type: actions.ACT_PRODUCER_CHANGE,
          props: { rrppProducerInfo: response },
        });
        callback && callback();
      })
      .catch((e) => {
        console.log(" >>>>>>>>>>e ", e);
        callback && callback();
      });
  };
}

export function actTicketsInfo(eventId) {
  return (dispatch) => {
    ProducerService.ticketInfo(eventId, (response) => {
      dispatch({
        type: actions.ACT_PRODUCER_CHANGE,
        props: { ticketTypes: response },
      });
    }).catch((e) => {
      console.log(" >>>>>>>>>>e ", e);
    });
  };
}
