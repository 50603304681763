import React from "react";
import { FormControl  } from "native-base"; //WEB99 Content
import Icon from "react-native-vector-icons/Ionicons";

import {
  View,
  Text,
  StyleSheet,
  TouchableOpacity,
  Keyboard,
  ImageBackground,
  Dimensions,
  Platform,
} from "react-native";
import { Actions } from "react-native-router-flux";
import LabelCustomInput from "../../components/LabelCustomInput";
import * as Constants from "../../common/Constants";
import {
  actRegister,
  listenerToMyProfile,
  actCreateSocialAccount,
} from "../../actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import L from "../../common/Layout";
import Colors from "../../common/Colors";
import { getNotificationData } from "../../common/Notifications";
import { initNotifications } from "../../actions/NotificationsActions";
import Fonts from "../../common/Fonts";
import I18n from "i18next"
import AppMem from "../../common/AppMem";
import ErrorManager from "../../common/ErrorManager";
import {
  required,
  validateEmail,   
  password,
} from "../../common/Validations";
import VTHeader from "../../components/VTHeader";
import RegisterConstants from "./RegisterConstants";
import { Field, reduxForm, formValueSelector, change, reset } from "redux-form";
const BG_IMG = require("../../common/assets/background_login.jpg");
import VTTermsModal from "../../components/VTTermsModal";
import UserExistPopUp from "../../components/UserExistPopUp";
import _ from "lodash";
import firebase from "react-native-firebase";
import { GoogleSignin } from "@react-native-google-signin/google-signin";
import { AccessToken } from "react-native-fbsdk";
let screen = Dimensions.get("window");
import Icon2 from "react-native-vector-icons/MaterialIcons";
import AppAsyncStorage from "../../common/AppAsyncStorage";
import moment from "moment"; 
 
//WEB99
const COUNTRIES = '';

class RegisterE extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      check: false,
    };
    this.inputs = {};
    this.createAccount = this.createAccount.bind(this);
    this.successCallback = this.successCallback.bind(this);
    this.onRegisterPress = this.onRegisterPress.bind(this);
    this.onPressAcceptTerms = this.onPressAcceptTerms.bind(this);
    this.onRegisterSuccess = this.onRegisterSuccess.bind(this);
    this.onRegisterError = this.onRegisterError.bind(this);
    this.onPressCheck = this.onPressCheck.bind(this);
    this.createSocialCallback = this.createSocialCallback.bind(this);
    this.reAuth = this.reAuth.bind(this);
  }

  UNSAFE_componentWillMount() {
    this.props.dispatch(change("register", "proAcceptTerms", true));
  }

  createAccount() {
    Keyboard.dismiss();
  }

  successCallback() {
    AppMem.closeAppLoader();
    Actions.registerF();
  }

  getInputProps() {
    let props = {
      borderColor: Colors.registerFieldBorderColor,
      labelStyle: { color: Colors.registerFieldColor },
      inputStyle: { color: Colors.registerTextColor},
      errorIconStyle: { color: Colors.registerErrorColor },
      borderBottomColor: Colors.registerBorderColor,
      blurOnSubmit: false,
      withRef: true,
      autoCapitalize: "none",
      topMargin: L.h(20),
    };
    return props;
  }

  focusNext(key, type) {
    if (!this.props.registerValues[key]) {
      // verifica que si el siguiente input tiene un dato
      // si no tiene dato hace el next
      this.inputs && this.inputs[key].getRenderedComponent().executeFocus();
    } else {
      // si lo tiene cierra el teclado y no hace el next
      Keyboard && Keyboard.dismiss();
    }
  }

  onRegisterSuccess(user) {
    //Actions.replace("activateAccount");

    this.props.dispatch(reset("register"));
    this.props.listenerToMyProfile(
      this.props.iosFaceID ? Util.getCurrentUID() : user._user.uid
    );
    if (Platform.OS != 'web')
        this.setNotificationData();
    AppMem.closeAppLoader();
    // if (this.props.iosFaceID) Actions.reset("mainView");
  }

  setNotificationData = async () => {
    let { initNotifications } = this.props;
    await AppAsyncStorage.removeItem("phoneValidate");
    let notiData = await getNotificationData();
    initNotifications(notiData);
  };

  onRegisterError(error) {
    AppMem.closeAppLoader();
    if (error) {
      let message = "";
      if (error.code !== "user-exist") {
        switch (error.code) {
          case "auth/wrong-password":
            message = I18n.t("login.wrongPassword");
            break;
          case "auth/user-not-found":
            message = I18n.t("login.userNotRegistered");
            break;
          case "auth/invalid-email":
            message = I18n.t("login.invalidEmail");
            break;
          case "auth/weak-password":
            message = I18n.t("login.weakPassword");
            break;

          case "auth/email-already-in-use":
            message = I18n.t("login.emailInUse");
            break;
          case "auth/network-request-failed":
            message = I18n.t("login.verifyYourConection");
            break;
          default:
            console.log('onRegisterError', error);
            message = I18n.t("login.verifyYourConection");
            break;
        }
        let modalProps = {
          title: message,
          type: "error",
          buttonOk: { text: "Ok", action: () => null },
        };
        AppMem.openModal("alert", modalProps);
      } else {
        AppMem.openModal(<UserExistPopUp />, {
          style: {
            height: L.hProm(310),
            borderRadius: 12,
            width: screen.width - L.wProm(60),
          },
          swipeToClose: true,
        });
      }
    }
  }

  renderScanData() {
    let { scanData } = this.props;
    return (
      <View>
        {scanData ? (
          <Field
            name="doc_country_id"
            itemType="ItemList"
            type="picker"
            ref={(input) => {
              this.inputs["doc_country_id"] = input;
            }}
            label="País de documento"
            validate={required}
            component={LabelCustomInput}
            placeholderStyle={{ color: "white" }}
            items={COUNTRIES}
            disabled
            {...this.getInputProps()}
          />
        ) : null}
        {scanData ? (
          <Field
            name="username"
            label={I18n.t("global.username")}
            ref={(input) => {
              this.inputs.username = input;
            }}
            hint="Sin espacios ni caracteres especiales*"
            hintStyle={{ color: "white" }}
            component={LabelCustomInput}
            validate={[required]}
            returnKeyType={"next"}
            autoCapitalize="none"
            onSubmitEditing={() => this.focusNext("email")}
            //onSubmitEditing={Keyboard.dismiss()}
            {...this.getInputProps()}
          />
        ) : null}
      </View>
    );
  }

  onPressAcceptTerms(formValues) {
    AppMem.openAppLoader("bars", {
      text: "Registrando...",
      color: Colors.secondary_color,
    });

    let nForm = Object.assign(
      {
        proVerified: false,
        last_read: moment().unix(),
        init_date: moment().unix(),
      },
      formValues
    );
    nForm.proAcceptWhatsapp = this.state.check;
    let user = { ...nForm };
    AppMem.closeModal();
    if (this.props.socialMedia) {
      this.props.actCreateSocialAccount(
        user,
        () => {
          if (Platform.OS != 'web')
              this.setNotificationData();
          AppMem.closeAppLoader();
          Actions.replace("login");
        },
        (error) => {
          AppMem.closeAppLoader();
          const modalProps = {
            title: "Error al registrar, intenta nuevamente.",
            type: "warning",
            buttonOk: {
              text: "Aceptar",
              action: () => {},
            },
          };
          AppMem.openModal("alert", modalProps);
        }
      );
    } else {
      this.props.actRegister(
        user,
        this.onRegisterSuccess,
        this.onRegisterError
      );
    }
  }

  async createSocialCallback() {
    let user = await firebase.auth().currentUser;
    // create a new firebase credential with the token
    let userProviderGoogle =
      user && user._user.providerData[0].providerId === "google.com";
    let userProviderFacebook =
      user && user._user.providerData[0].providerId === "facebook.com";
    let userProviderApple =
      user && user._user.providerData[0].providerId === "apple.com";
    if (userProviderGoogle) {
      GoogleSignin.getTokens().then((token) => {
        let GGCredential = firebase.auth.GoogleAuthProvider.credential(
          token.idToken,
          token.accessToken
        );
        this.reAuth(GGCredential, user);
      });
    } else if (userProviderFacebook) {
      AccessToken.getCurrentAccessToken().then((token) => {
        let FBCredential = firebase.auth.FacebookAuthProvider.credential(
          token.accessToken
        );
        this.reAuth(FBCredential, user);
      });
    } else if (userProviderApple) {
      Actions.replace("login");
    }
  }

  reAuth(credential, user) {
    // AppMem.openAppLoader("loading", {
    //   text: "Ingresando",
    //   color: Colors.cerulean
    // });
    user
      .reauthenticateWithCredential(credential)
      .then(() => {
        Actions.replace("login");
        // User re-authenticated.
      })
      .catch((error) => {
        console.log(" >>>>>>>>>>error ", error);
        ErrorManager.message(error);
        // An error happened.
      });
  }

  onRegisterPress(formValues) {
    Keyboard.dismiss();
    AppMem.openModal(
      <VTTermsModal
        onPressAccept={() => this.onPressAcceptTerms(formValues)}
      />,
      {
        style: {
          width: "90%",
          height: L.hProm(560),
          backgroundColor: "white",
          borderRadius: 5,
        },
        swipeToClose: false,
      }
    );
  }

  onPressCheck() {
    this.setState({ check: !this.state.check });
  }

  render() {
    let { handleSubmit, socialMedia } = this.props;
    let { currentUser } = firebase.auth();
    return (
      <ImageBackground source={BG_IMG} style={{ flex: 1 }} resizeMode="cover">
        <View style={localStyle.container}>
          <VTHeader {...RegisterConstants.headerProps("back")} />
        
            <View style={localStyle.mainContent}>
              <Text style={localStyle.textInfo}>
                {I18n.t("register.signInSteps.textFewMore")}
              </Text>
              <FormControl style={localStyle.form}>
                {this.renderScanData()}
                {socialMedia &&
                currentUser._user &&
                currentUser._user.email ? null : (
                  <Field
                    name="proEmail"
                    label={I18n.t("global.email")}
                    ref={(input) => {
                      this.inputs.email = input;
                    }}
                    trimWhiteSpace
                    component={LabelCustomInput}
                    validate={[required, validateEmail]}
                    returnKeyType={"next"}
                    onSubmitEditing={() => this.focusNext("phone")}
                    {...this.getInputProps()}
                  />
                )}
                <Field
                  name="proPhone"
                  label={"Número de teléfono"}
                  //ref="phone"
                  ref={(input) => {
                    this.inputs.phone = input;
                  }}
                  trimWhiteSpace
                  component={LabelCustomInput}
                  validate={[required]}
                  keyboardType="numeric"
                  returnKeyType={"next"}
                  //secureTextEntry
                  onSubmitEditing={() => this.focusNext("instagram")}
                  {...this.getInputProps()}
                />
                <TouchableOpacity
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  onPress={this.onPressCheck}
                >
                  <Icon2
                    name={
                      this.state.check ? "check-box" : "check-box-outline-blank"
                    }
                    style={{ fontSize: L.h(30), color: Colors.iconRegisterWhatsapp }}
                  />
                  <Text style={{ color: Colors.textRegisterWhatsapp, marginLeft: L.w(8) }}>
                    Acepto recibir info por Whatsapp
                  </Text>
                </TouchableOpacity>
                <Field
                  name="proInstagram"
                  label={"Instagram (opcional)"}
                  // ref="instagram"
                  ref={(input) => {
                    this.inputs.instagram = input;
                  }}
                  trimWhiteSpace
                  component={LabelCustomInput}
                  //validate={[required]}
                  //keyboardType=""
                  returnKeyType={"next"}
                  //secureTextEntry
                  onSubmitEditing={handleSubmit(this.onRegisterPress)}
                  {...this.getInputProps()}
                />
                {this.props.socialMedia ? null : (
                  <Field
                    name="proPassword"
                    label={I18n.t("global.password")}
                    ref="password"
                    ref={(input) => {
                      this.inputs.password = input;
                    }}
                    trimWhiteSpace
                    component={LabelCustomInput}
                    validate={[required, password]}
                    returnKeyType={"next"}
                    secureTextEntry
                    onSubmitEditing={handleSubmit(this.onRegisterPress)}
                    {...this.getInputProps()}
                  />
                )}
              </FormControl>
              <TouchableOpacity
                style={localStyle.buttonAction}
                onPress={handleSubmit(this.onRegisterPress)}
              >
                <Icon
                  name="md-arrow-round-forward"
                  style={localStyle.buttonActionIcon}
                />
                <Text style={localStyle.buttonActionText}>
                  {I18n.t("global.after")}
                </Text>
              </TouchableOpacity>
            </View>
           
        </View>
      </ImageBackground>
    );
  }
}
const localStyle = StyleSheet.create({
  closeButton: {
    padding: 10,
    position: "absolute",
    top: L.h(25),
    left: L.w(15),
  },
  closeIcon: {
    fontSize: L.h(30),
    color: "white",
  },
  container: {
    flex: 1,
    //backgroundColor: Colors.cerulean
  },
  mainContent: {
    paddingTop: L.h(20),
    paddingHorizontal: L.w(44),
  },
  form: {
    marginTop: L.h(8),
  },
  textInfo: {
    fontSize: L.h(25),
    fontFamily: Fonts.DBOLD,
    color: Colors.textRegisterE,
  },
  buttonAction: {
    padding: L.h(10),
    marginTop: L.h(15),
    alignItems: "center",
    justifyContent: "center",
    marginBottom: L.h(80),
  },
  buttonActionIcon: {
    fontSize: L.h(100),
    color: Colors.textIconRegisterE,
    marginBottom: L.h(-20),
  },
  buttonActionText: {
    fontSize: L.h(21),
    fontFamily: Fonts.DBOLD,
    color: Colors.textButtonRegisterE,
  },
});

function mapStateToProps(store) {
  const selector = formValueSelector("register");
  return {
    registerValues: selector(
      store,
      "username",
      "email",
      "password",
      "repassword",
      "proEmail"
    ),
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actRegister,
      listenerToMyProfile,
      actCreateSocialAccount,
      initNotifications,
    },
    dispatch
  );
}
export default reduxForm({
  form: "register",
  destroyOnUnmount: false,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(RegisterE)
);
