import React from 'react';
import {
  View,
  StyleSheet,
  Keyboard,
  Alert,
  Dimensions,
  TouchableOpacity,
  Text,
  Platform,
} from 'react-native';
import { Actions } from 'react-native-router-flux';
import { connect } from 'react-redux';
import S from 'string';
import { Field, reduxForm, formValueSelector, change } from 'redux-form';
import I18n from 'i18next';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Icon, Fab, Toast } from 'native-base';
import L from '../../common/Layout';
import style from './style';
import { saveProfile } from '../../actions/AuthActions';
import VTHeader from '../../components/VTHeader';
import renderInput from '../../components/LabelCustomInput';
import Colors from '../../common/Colors';
import AppMem from '../../common/AppMem';
import Util from '../../common/Util';
import { required, documentLength } from '../../common/Validations';
import Fonts from '../../common/Fonts';
import moment from 'moment';
import IconFA from 'react-native-vector-icons/FontAwesome';
import UserExistPopUp from '../../components/UserExistPopUp';
import CountryCodePicker from '../../components/CountryPicker';
import Config from 'react-native-config';
import Loader from '../../components/Loader';
import toast from 'react-hot-toast';
let screen = Dimensions.get('window');

let DATE_FORMAT = Config.TENANT_HAS_BIRTHDATE_MIGRATED
  ? 'DD-MM-YYYY'
  : 'DD [de] MMMM YYYY';
class EditProfile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { editable: true, active: false, loading: false };
    this.inputs = {};
    this.focusNext = this.focusNext.bind(this);
  }

  componentWillMount() {
    let { user } = this.props;
    let intialData = {
      proName: user && user.proName ? S(user?.proName).capitalize().s : null,
      proLastName:
        user && user.proLastName ? S(user?.proLastName).capitalize().s : null,
      proBirthDate: user?.proBirthDate
        ? moment.unix(user?.proBirthDate?.seconds)
        : '',
      proEmail: user && user.proEmail,
      proGender: user && user.proGender ? user.proGender : null,
      proInstagram: user && user.proInstagram ? user.proInstagram : null,
      //proPhone: user && user.proPhone ? user.proPhone : null,
      // proDNI: user && user.proDNI ? user.proDNI : null,
      // proDniType: user && user.proDniType ? user.proDniType : null,
    };
    this.props.initialize(intialData);
  }

  getScrollViewProps() {
    return {
      alwaysBounceVertical: false,
      bounces: false,
      keyboardShouldPersistTaps: 'always',
    };
  }

  getInputProps() {
    let props = {
      editable: true,
      maxWidth: '100%',
      borderColor: Colors.secondary_color,
      placeholderStyle: { color: Colors.profileFieldColorEdit },
      labelStyle: { color: Colors.profileFieldColorEdit },
      inputStyle: { color: Colors.profileFieldColorEdit },
      errorIconStyle: { color: Colors.profileFieldColorEdit },
      withRef: true,
    };
    return props;
  }

  renderInputName() {
    return (
      <View style={[localStyle.inputRow, { marginTop: L.h(16) }]}>
        {/* <View style={{ flex: 1 }}>
          <Icon name="user" type="FontAwesome" style={localStyle.iconSt} />
        </View> */}
        <View style={{ flex: 6 }}>
          <Field
            name="proName"
            label={I18n.t('editProfile.name')}
            ref={input => {
              this.inputs.name = input;
            }}
            component={renderInput}
            validate={required}
            returnKeyType="next"
            // onSubmitEditing={() => this.focusNext("lastName")}
            autoCapitalize="words"
            {...this.getInputProps()}
          />
        </View>
      </View>
    );
  }

  renderInputPhone() {
    let { user, appSettings } = this.props;
    return (
      <TouchableOpacity
        style={[localStyle.inputRow, { marginBottom: L.h(8) }]}
        onPress={() =>
          Actions.validatePhone({ title: 'Cambiar teléfono', profile: true })
        }
      >
        {/* <View style={{ flex: 1 }}>
          <Icon name="phone" type="FontAwesome" style={localStyle.iconSt} />
        </View> */}
        <View style={localStyle.phoneContainer}>
          <Text style={localStyle.phoneLabel}>
            {I18n.t('editProfile.phone')}
          </Text>
          <Text style={localStyle.phoneText}>
            {(user && user?.proCountryCode) || appSettings?.countryCode}-
            {user && user.proPhone ? user.proPhone : null}
          </Text>
        </View>
      </TouchableOpacity>
    );
  }

  renderInputInstagram() {
    return (
      <View style={[localStyle.inputRow]}>
        {/* <View style={{ flex: 1 }}>
          <Icon name="instagram" type="FontAwesome" style={localStyle.iconSt} />
        </View> */}
        <View style={{ flex: 6 }}>
          <Field
            name="proInstagram"
            label={I18n.t('editProfile.instagram')}
            ref={input => {
              this.inputs.name = input;
            }}
            trimWhiteSpace
            component={renderInput}
            // validate={required}
            returnKeyType="next"
            //onSubmitEditing={() => this.focusNext("lastName")}
            autoCapitalize="words"
            {...this.getInputProps()}
          />
        </View>
      </View>
    );
  }

  renderInputLastName() {
    return (
      <View style={localStyle.inputRow}>
        {/* <View style={{ flex: 1 }}>
          <Icon name="user" type="FontAwesome" style={localStyle.iconSt} />
        </View> */}
        <View style={{ flex: 6 }}>
          <Field
            name="proLastName"
            label={I18n.t('editProfile.surname')}
            ref={input => {
              this.inputs.lastName = input;
            }}
            component={renderInput}
            validate={required}
            returnKeyType="next"
            onSubmitEditing={Keyboard.dismiss}
            autoCapitalize="words"
            {...this.getInputProps()}
          />
        </View>
      </View>
    );
  }

  focusNext(key) {
    if (!this.props.editProfile[key]) {
      // verifica que si el siguiente input tiene un dato
      // si no tiene dato hace el next
      this.inputs && this.inputs[key].getRenderedComponent().executeFocus();
    } else {
      // si lo tiene cierra el teclado y no hace el next
      Keyboard && Keyboard.dismiss();
    }
  }

  onPressFAB(formValues) {
    AppMem.openAppLoader();
    this.setState({ loading: true });
    let { ticketResume } = this.props;
    if (formValues) {
      let values = formValues;
      if (values.proBirthDate) {
        // dispatch(
        //   change('editProfile', 'proBirthDate', new Date(values.proBirthDate))
        // );
        values = Object.assign(
          {},
          { ...formValues, proBirthDate: new Date(values.proBirthDate) }
        );
      }
      this.props.saveProfile(
        values,
        () => {
          AppMem.closeAppLoader();
          this.setState({ loading: false });
          ticketResume ? Actions.pop() : null;
          setTimeout(() => {
            // Toast.show({
            //   text: I18n.t('editProfile.successUpdateProfile'),
            //   buttonText: I18n.t('editProfile.buttonText'),
            //   position: 'bottom',
            //   type: 'success',
            // });
            toast.success(I18n.t('editProfile.successUpdateProfile'));
          }, 200);
        },
        e => {
          AppMem.closeAppLoader();
          this.setState({ loading: false });
          if (e.code == 'user-exist') {
            // AppMem.openModal(<UserExistPopUp />, {
            //   style: {
            //     height: L.hProm(310),
            //     borderRadius: 12,
            //     width: screen.width - L.wProm(60),
            //   },
            //   swipeToClose: true,
            // });
            toast.error('Ese dni ya se encuentra registrado');
          } else {
            //Alert.alert(I18n.t('editProfile.error'));
            toast.error(I18n.t('editProfile.error'));
          }
        }
      );
    }
  }

  renderToast() {
    Toast.show({
      text: I18n.t('editProfile.requiredFields'),
      buttonText: I18n.t('editProfile.buttonText'),
      position: 'bottom',
      type: 'danger',
    });
  }

  renderFab() {
    let { invalid, handleSubmit } = this.props;
    return (
      <Fab
        //active={this.state.active}
        direction="up"
        containerStyle={{}}
        style={{
          backgroundColor: Colors.floatingButtonColor,
          top: Util.isIosXGen() ? -L.h(30) : null,
        }}
        position="bottomRight"
        onPress={handleSubmit(this.onPressFAB.bind(this))}
      >
        <Icon name={'save'} type="MaterialIcons" />
      </Fab>
    );
  }

  renderBirthDate() {
    let mayorEdad = moment().subtract(18, 'years');
    return (
      <View style={localStyle.inputRow}>
        {/* <View style={{ flex: 1 }}>
          <IconFA
            name="calendar"
            style={[localStyle.iconSt, { marginRight: L.w(12) }]}
          />
        </View> */}
        <View style={{ flex: 6 }}>
          <Field
            name="proBirthDate"
            label={I18n.t('register.birthDate')}
            borderColor={Colors.rouge}
            ref={input => {
              this.inputs.birthDate = input;
            }}
            //validate={[required]}
            component={renderInput}
            labelStyle={{ color: Colors.black_54 }}
            inputStyle={{ color: Colors.black_54 }}
            placeholderStyle={{ color: 'black' }}
            itemType="DatePicker"
            maxDate={mayorEdad}
            {...this.getInputProps()}
          />
        </View>
      </View>
    );
  }

  renderDNI() {
    let { appSettings } = this.props;
    return (
      <View style={localStyle.inputRow}>
        {/* <View style={{ flex: 1 }}>
          <IconFA
            name="calendar"
            style={[localStyle.iconSt, { marginRight: L.w(12) }]}
          />
        </View> */}
        <View style={{ flex: 6 }}>
          <Field
            name="proDniType"
            itemType="ItemList"
            type="picker"
            // ref={(input) => {
            //   this.inputs["proDniType"] = input;
            // }}
            label={I18n.t('editProfile.documentType')}
            // validate={required}
            component={renderInput}
            placeholderStyle={{ color: 'white' }}
            items={appSettings?.documentTypes?.map((ed, i) => ({
              label: ed,
              value: i + 1,
            }))}
            {...this.getInputProps()}
          />
        </View>
      </View>
    );
  }

  renderDNIn() {
    return (
      <View style={localStyle.inputRow}>
        {/* <View style={{ flex: 1 }}>
          <IconFA
            name="calendar"
            style={[localStyle.iconSt, { marginRight: L.w(12) }]}
          />
        </View> */}
        <View style={{ flex: 6 }}>
          <Field
            name="proDNI"
            label={I18n.t('editProfile.documentNumber')}
            // ref={(input) => {
            //   this.inputs.doc_number = input;
            // }}
            docMask
            component={renderInput}
            validate={[documentLength]}
            returnKeyType={'next'}
            //onSubmitEditing={handleSubmit(this.onPressForward)}
            {...this.getInputProps()}
          />
        </View>
      </View>
    );
  }

  renderGender() {
    return (
      <View style={localStyle.inputRow}>
        {/* <View style={{ flex: 1 }}>
          <Icon
            name="venus-mars"
            type="FontAwesome"
            style={[
              localStyle.iconSt,
              { marginRight: L.w(Util.isIos() ? 14 : 10) },
            ]}
          />
        </View> */}
        <View style={{ flex: 6 }}>
          <Field
            name="proGender"
            itemType="ItemList"
            type="picker"
            ref={input => {
              this.inputs.gender = input;
            }}
            label={I18n.t('editProfile.gender')}
            //validate={required}
            component={renderInput}
            items={[
              { label: I18n.t('editProfile.genderMan'), value: 'male' },
              { label: I18n.t('editProfile.genderWoman'), value: 'female' },
            ]}
            {...this.getInputProps()}
          />
        </View>
      </View>
    );
  }

  renderRightButtons() {
    let { invalid, handleSubmit } = this.props;
    let isInvalid = invalid;
    let renderButton = [
      {
        onPress: isInvalid
          ? this.renderToast
          : handleSubmit(this.onPressFAB.bind(this)),
        icon: {
          text: Util.isIos()
            ? this.state.active
              ? I18n.t('editProfile.save')
              : I18n.t('editProfile.edit')
            : I18n.t('editProfile.save').toUpperCase(),
          textStyle: {
            fontSize: L.h(17),
            color: 'white',
            fontFamily: Fonts.MEDIUM,
            top: L.h(2),
          },
        },
      },
    ];
    let buttons = Util.isIos()
      ? renderButton
      : this.state.active
      ? renderButton
      : null;
    return buttons;
  }

  render() {
    let { user, ticketResume, handleSubmit, editProfile } = this.props;
    let { loading } = this.state;
    return (
      <View
        style={[
          style.container,
          { backgroundColor: Colors.profileBackgroundEdit },
        ]}
      >
        <div class="padding-top">
          <section class="page-title bg-one">
            <div class="container">
              <div class="page-title-area">
                <div
                  class="item md-order-1"
                  onClick={() => Actions.pop()}
                  style={{ cursor: 'pointer' }}
                >
                  <a class="custom-button back-button">
                    <i class="flaticon-double-right-arrows-angles" />
                    Volver
                  </a>
                </div>
                <div class="item date-item">
                  {/* <h4>Finalizar Compra</h4> */}
                </div>
                <div class="item" />
              </div>
            </div>
            <section class="account-section">
              <div class="padding-bottom">
                <div class="account-area">
                  <h4 class="title" style={{ marginLeft: 28 }}>
                    Editar perfil
                  </h4>
                  <div class="container">
                    {!ticketResume ? this.renderInputName() : null}
                    {!ticketResume ? this.renderInputLastName() : null}
                    {/* {!ticketResume ? this.renderInputPhone() : null} */}
                    {!user.proDNI ? this.renderDNI() : null}
                    {!user.proDNI ? this.renderDNIn() : null}
                    {!ticketResume ? this.renderInputInstagram() : null}
                    {this.renderBirthDate()}
                    {!ticketResume ? this.renderGender() : null}
                    {/* {this.renderFab()} */}
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 24,
                      }}
                    >
                      <a
                        class="custom-button back-button"
                        style={{
                          cursor: 'pointer',
                          alignSelf: 'center',
                        }}
                        onClick={handleSubmit(this.onPressFAB.bind(this))}
                      >
                        Guardar
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
        {loading ? <Loader text={'Cargando...'} /> : null}
      </View>
    );
  }
}

const localStyle = StyleSheet.create({
  inputRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: L.w(16),
  },
  iconSt: {
    fontSize: L.h(30),
    color: Colors.greyishBrownThree,
    marginRight: L.w(8),
  },
  phoneContainer: {
    flex: 6,
    borderBottomWidth: 2,
    borderColor: Colors.secondary_color,
  },
  phoneLabel: {
    color: Colors.profileFieldColorEdit,
    fontSize: L.h(12),
    fontFamily: Fonts.DLIGHT,
  },
  phoneText: {
    fontSize: L.h(18),
    fontFamily: Fonts.DREGULAR,
    color: Colors.profileFieldColorEdit,
  },
});

function mapStateToProps(store) {
  const selector = formValueSelector('editProfile');
  return {
    appSettings: store.appReducer.appSettings,
    editProfile: selector(
      store,
      'proName',
      'proLastName',
      'proBirthDate',
      'proGender',
      'proEmail'
    ),
    user: store.authReducer.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    saveProfile(userData, callbackSuccess, callbackError) {
      dispatch(saveProfile(userData, callbackSuccess, callbackError));
    },
  };
}
export default reduxForm({
  form: 'editProfile',
  destroyOnUnmount: false,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditProfile)
);
