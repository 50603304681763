import React from 'react';
import { View, StyleSheet, Keyboard, Alert, TextInput } from 'react-native';
import { Actions } from 'react-native-router-flux';
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { Icon, Fab, Toast, FormControl } from 'native-base';
import L from '../../common/Layout';
import style from './style';
import { changePassword, actLogout } from '../../actions/AuthActions';
import VTHeader from '../../components/VTHeader';
import ThemeColors from '../../common/ThemeColors';
import renderInput from '../../components/LabelCustomInput';
import Colors from '../../common/Colors';
import AppMem from '../../common/AppMem';
import I18n from 'i18next';
import Util from '../../common/Util';
import { required, password } from '../../common/Validations';
import Fonts from '../../common/Fonts';
import Loader from '../../components/Loader';
import toast from 'react-hot-toast';

class EditPassword extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = { editable: true, active: false, loading: false };
    this.inputs = {};
  }

  componentWillMount() {
    let { user } = this.props;
    let intialData = {
      proEmail: user.proEmail,
    };
    this.props.initialize(intialData);
  }

  getScrollViewProps() {
    return {
      alwaysBounceVertical: false,
      bounces: false,
      keyboardShouldPersistTaps: 'always',
    };
  }

  getInputProps() {
    let props = {
      editable: this.state.editable,
      maxWidth: '89%',
      type: 'password',
      secureTextEntry: true,
      borderColor: Colors.secondary_color,
      placeholderStyle: { color: Colors.profileFieldColorEdit },
      labelStyle: { color: Colors.profileFieldColorEdit },
      inputStyle: {
        color: Colors.profileFieldColorEdit,
        fontSize: '1rem',
        fontWeight: 'bold',
      },
      errorIconStyle: { color: 'white' },
      withRef: true,
    };
    return props;
  }

  onPressFAB(formValues) {
    if (formValues.password && formValues.newPassword) {
      if (!this.state.editable) {
        this.setState({
          editable: !this.state.editable,
          active: !this.state.active,
        });
      } else {
        AppMem.openAppLoader();
        this.setState({ loading: true });
        if (formValues) {
          this.props.changePassword(
            formValues.password,
            formValues.newPassword,
            callbackData => {
              AppMem.closeAppLoader();
              this.setState({ loading: false });
              if (callbackData == 'OK') {
                // Toast.show({
                //   text: I18n.t('register.passwordSuccesfullyUpdated'),
                //   buttonText: I18n.t('global.accept'),
                //   type: 'success',
                //   position: 'bottom',
                //   onClose: () =>
                //     this.props.actLogout(() => {
                //       Actions.reset('login');
                //     }),
                // });
                this.props.dispatch(reset('editPassword'));
                toast.success(I18n.t('register.passwordSuccesfullyUpdated'));
              } else {
                // Toast.show({
                //   text:
                //     callbackData.code == 'auth/wrong-password'
                //       ? I18n.t('login.wrongPassword')
                //       : I18n.t('register.errorOcurred'),
                //   buttonText: I18n.t('global.accept'),
                //   type: 'danger',
                //   position: 'bottom',
                //   onClose: () => null,
                // });

                toast.error(
                  callbackData.code == 'auth/wrong-password'
                    ? 'El Password ingresado es incorrecto'
                    : I18n.t('register.errorOcurred')
                );
              }
            }
          );
        }
      }
    } else {
      // Toast.show({
      //   text: I18n.t('editPassword.requiredFields'),
      //   buttonText: I18n.t('global.accept'),
      //   type: 'danger',
      //   position: 'bottom',
      // });
      toast.error(I18n.t('editPassword.requiredFields'));
    }
  }

  renderOldPassword() {
    return (
      <View style={[localStyle.inputRow, { marginTop: L.h(16) }]}>
        {/* <Icon
          name="lock"
          type="MaterialIcons"
          style={[
            localStyle.iconSt,
            { marginRight: L.w(Util.isIos() ? 14 : 10) },
          ]}
        /> */}
        <Field
          name="password"
          label={I18n.t('editPassword.currentPassword')}
          ref={input => {
            this.inputs.email = input;
          }}
          component={renderInput}
          validate={[required, password]}
          autoCapitalize="none"
          {...this.getInputProps()}
        />
      </View>
    );
  }

  renderPassword() {
    return (
      <View style={localStyle.inputRow}>
        {/* <Icon
          name="lock"
          type="MaterialIcons"
          style={[
            localStyle.iconSt,
            { marginRight: L.w(Util.isIos() ? 14 : 10) },
          ]}
        /> */}
        <Field
          name="newPassword"
          label={I18n.t('editPassword.newPassword')}
          ref={input => {
            this.inputs.email = input;
          }}
          component={renderInput}
          validate={[required, password]}
          autoCapitalize="none"
          {...this.getInputProps()}
        />
      </View>
    );
  }

  renderRightButtons() {
    let { handleSubmit } = this.props;
    let renderButton = [
      {
        onPress: handleSubmit(this.onPressFAB.bind(this)),
        icon: {
          text: Util.isIos()
            ? I18n.t('editPassword.save')
            : I18n.t('editPassword.save').toUpperCase(),
          textStyle: {
            fontSize: L.h(17),
            color: ThemeColors.primary1Color,
            fontFamily: Fonts.MEDIUM,
            top: L.h(2),
          },
        },
      },
    ];
    return renderButton;
  }

  render() {
    let { handleSubmit } = this.props;
    let { loading } = this.state;
    return (
      <View
        style={[
          style.container,
          { backgroundColor: Colors.profileBackgroundEdit },
        ]}
      >
        <div class="padding-top">
          <section class="page-title bg-one">
            <div class="container">
              <div class="page-title-area">
                <div
                  class="item md-order-1"
                  onClick={() => Actions.pop()}
                  style={{ cursor: 'pointer' }}
                >
                  <a class="custom-button back-button">
                    <i class="flaticon-double-right-arrows-angles" />
                    Volver
                  </a>
                </div>
                <div class="item date-item">
                  {/* <h4>Finalizar Compra</h4> */}
                </div>
                <div class="item" />
              </div>
            </div>
            <section class="account-section">
              <div class="padding-bottom">
                <div class="account-area">
                  <h4 class="title" style={{ marginLeft: 28 }}>
                    Cambiar password
                  </h4>
                  <div class="container">
                    <FormControl>
                      {this.renderOldPassword()}
                      {this.renderPassword()}
                    </FormControl>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 24,
                      }}
                    >
                      <a
                        class="custom-button back-button"
                        style={{
                          cursor: 'pointer',
                          alignSelf: 'center',
                        }}
                        onClick={handleSubmit(this.onPressFAB.bind(this))}
                      >
                        Guardar
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
        {loading ? <Loader text={'Cargando...'} /> : null}
      </View>
    );
  }
}

const localStyle = StyleSheet.create({
  inputRow: {
    flexDirection: 'row',
    alignItems: 'center',
    marginHorizontal: L.w(16),
  },
  iconSt: {
    fontSize: L.h(30),
    color: Colors.greyishBrownThree,
    marginRight: L.w(8),
  },
});

function mapStateToProps(store) {
  const selector = formValueSelector('editProfile');
  return {
    editProfile: selector(
      store,
      'proName',
      'proLastName',
      'proBirthDate',
      'proGender',
      'proEmail'
    ),
    user: store.authReducer.user,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    changePassword(password, newPassword, callback) {
      dispatch(changePassword(password, newPassword, callback));
    },
    actLogout(onSuccess) {
      dispatch(actLogout(onSuccess));
    },
  };
}
export default reduxForm({
  form: 'editPassword',
  destroyOnUnmount: false,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(EditPassword)
);
