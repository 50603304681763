import AppMem from "./AppMem";
export default class ErrorManager {
  static errorManagerCode(code) {
    let msgError = `Error de Sistema (code ${code})`;
    switch (code) {
      case 1:
        msgError = "Usuario no autorizado";
        break;
      case 2:
        msgError = "Token expirado";
        break;
      case 4:
        msgError = "Email no verificado";
        break;
      case 8:
        msgError = "No se puede eliminar porque tiene dependencias.";
        break;
      case 13:
        msgError = "Email ya existe";
        break;
      case 20:
        msgError = "Error de Sistema (code_verify_mobile)";
        break;
      case 22:
        msgError = "Código incorrecto";
        break;
      case 25:
        // user_visit type organizer are required
        msgError = "Error de Sistema (user_visit type organizer)";
        break;
      case 26:
        // user_visit type invited are required
        msgError = "Agregar un invitado al menos.";
        break;
      case 52:
        msgError = "El campo user no existe";
        break;
      case 56:
        msgError = "El visitante ya registró un ingreso";
        break;
      case 57:
        msgError = "El visitante ya registró un egreso";
        break;
      case 58:
        msgError = "Error de Sistema (comment)";
        break;
      case 59:
        msgError =
          "El Guardia no tiene autorización para la entidad de esta visita";
        break;
      case 60:
        msgError = "Entrada denegada. La visita esta fuera del rango de fecha";
        break;
      case 61:
        msgError = "Error de Sistema (user_id_guard)";
        break;
      case 62:
        msgError = "Entrada denegada. La visita esta fuera del rango de fecha";
        break;
      case 64:
        msgError = "Error de Sistema (place_id_guard)";
        break;
      case 66:
        msgError = "Error de Sistema (document_type_id)";
        break;
      case 67:
        msgError = "Error de Sistema (document_number)";
      case 69:
        msgError = "Email o Contraseña incorrectas.";
        break;
      case 71:
        msgError = "Vehículo no existe.";
        break;
      case 73:
        msgError = "Error de Sistema (attached_path)";
        break;
      case 74:
        msgError = "Error de Sistema (attached_url)";
        break;
      case 80:
        msgError =
          "El documento fué aprobado en alguna entidad y no se puede actualizar.";
        break;
      case 81:
        msgError = "Error de Sistema (vehicle_id)";
        break;
      case 82:
        msgError = "Unauthorized vehicle_id";
        break;
      case 94:
        msgError = "La fecha y hora no pueden ser anteriores a las actuales.";
        break;
      case 500:
        msgError = "Error de Sistema (500)";
        break;
    }
    return msgError;
  }

  static errorManager(error) {
    console.log("Error Manager ### ", error);
    let msgError = "Error de Sistema (Desconocido)";
    AppMem.closeAppLoader();
    if (error.status) {
      switch (error.status) {
        case 404:
          msgError = this.errorManagerCode(error.code);
        case 500:
          msgError = this.errorManagerCode(error.code);
          break;
        case 503:
          msgError = "Error de Sistema (Servidor 503)";
          break;
        case 555:
          msgError = this.errorManagerCode(error.code);
          break;
        default:
      }
    } else if (error.code) {
      switch (error.code) {
        case "001":
          msgError = "Error de Sistema (Time out 001)";
          break;
        case "404":
          msgError = "Error de Sistema (404)";
          break;
        case "500":
          msgError = "Error de Sistema (500)";
          break;
        case "storage/unauthorized":
          msgError = "No está autorizado para guardar la imágen.";
          break;
        case "storage/unauthenticated":
          msgError = "No está autorizado para guardar la imágen.";
          break;
        case "auth/account-exists-with-different-credential":
          msgError =
            "Ya existe una cuenta con la misma dirección de correo electrónico. Prueba Iniciando sesión usando otro proveedor.";
          break;
        default:
      }
    }
    if (error.header) {
      msgError = "Error de Sistema (Server)";
      if (error.header.status) {
        switch (error.header.status) {
          case 404:
            msgError = "Error de Sistema (404)";
            break;
          default:
        }
      }
    }
    return msgError;
  }
}
