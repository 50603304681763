import _ from 'lodash';
import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  ScrollView,
  ActivityIndicator,
} from 'react-native';
import { Field, reduxForm, formValueSelector, reset } from 'redux-form';
import { bindActionCreators } from 'redux';
import LabelCustomInput from '../../components/LabelCustomInput';
import I18n from 'i18next';
import { connect } from 'react-redux';
import AppMem from '../../common/AppMem';
import { Actions } from 'react-native-router-flux';
import Util from '../../common/Util';
import Header from '../../components/VTHeader';
import L from '../../common/Layout';
import { Toast } from 'native-base';
import IconFA from 'react-native-vector-icons/FontAwesome';
import Fonts from '../../common/Fonts';
import {
  onCreatePreference,
  onCreateStripePreference,
  successfulPay,
  startToListen,
  getPaymentInformation,
  onCreateFreeTickets,
} from '../../actions/PaymentActions';
import Colors from '../../common/Colors';
import MainButton from '../../components/MainButton';
import TicketInfo from '../../components/TicketInfo';
import moment from 'moment';
import Config from 'react-native-config';
import toast from 'react-hot-toast';

class TicketResume extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      editable: false,
      active: false,
      selectedOption: 'others',
      loading: false,
      total: 0
    };
    this.pagos360Pay = this.pagos360Pay.bind(this);
    this.onSuccesBuy = this.onSuccesBuy.bind(this);
    this.handleOptionChange = this.handleOptionChange.bind(this);
    this.getButtonLabel = this.getButtonLabel.bind(this);
    this.handleFreeTicketsPayment = this.handleFreeTicketsPayment.bind(this);
    this.inputs = {};
    this.producerPrice = 0;
    this.globalTotal = 0;
    this.totalPoints = 0;
    this.product = { ticketType: {}, event: {} };
  }

  componentWillMount() {
    this.props.dispatch(reset('ticketResume'));
    //TODO REMOVER CON UN LISTENER
  }

  checkNavigation(paymentId) {
    let { eventPaymentData } = this.props;
    let shouldNavigate = false;
    eventPaymentData.map(eachBuyingEventTicket => {
      let eachData = eachBuyingEventTicket.data;
      if (eachData.tktBuyOperationId == paymentId) {
        shouldNavigate = true;
      }
    });

    return shouldNavigate;
  }
  onSuccesBuy(data, product) {
    let paymentId = data.payload.id_preferencia_pago;
    console.log(' >>>>>>>>>> success buy ');
    AppMem.openAppLoader('bars', {
      text: I18n.t('ticketResume.loaderText'),
      color: Colors.secondary_color,
    });
    product.paymentId = paymentId;

    //VER DE UBICAR ESTO

    let shouldNavigate = this.checkNavigation(paymentId);
    if (shouldNavigate) {
      Actions.reset('ticketFinal', { productBuy: product });
    } else {
      switch (Config.APP_PAYMENT_GATEWAY) {
        case 'stripe':
          this.stripeHandler(product);
          break;
        default:
          this.pagos360Handler(paymentId, product);
          break;
      }
    }
  }

  pagos360Handler(paymentId, product) {
    this.props.getPaymentInformation(paymentId, paymentInfo => {
      let response = paymentInfo.response;
      let buyData = {
        status: response.state,
        paymentId: paymentId,
        uniqueIdPayment: product.uniqueIdPayment,
      };
      if (buyData.status == 'paid') {
        this.props.successfulPay(buyData, response => {
          AppMem.openAppLoader('bars', {
            text: 'Cargando...',
            color: Colors.secondary_color,
          });
          if (response.response == 'OK') {
            Actions.reset('ticketFinal', { productBuy: product });
          } else {
            // AppMem.closeAppLoader('bars', {
            //   text: 'Cargando...',
            //   color: Colors.secondary_color,
            // });
            // Toast.show({
            //   text: I18n.t('ticketResume.failedBuy') + Config.PRODUCER_NAME,
            //   buttonText: I18n.t('global.accept'),
            //   type: 'danger',
            //   position: 'bottom',
            // });
            toast.error(
              I18n.t('ticketResume.failedBuy') + Config.PRODUCER_NAME,
              { duration: 8000 }
            );
            toast.custom(
              t => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    background: '#fff',
                    padding: '16px',
                    borderRadius: '8px',
                    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                  }}
                >
                  <span>This is a toast message!</span>
                  <button
                    onClick={() => {
                      handleButtonClick();
                      toast.dismiss(t.id); // Cierra la notificación
                    }}
                  >
                    Click me
                  </button>
                </div>
              ),
              {
                duration: 5000, // Duración en milisegundos
                position: 'top-center',
              }
            );
          }
        });
      }
    });
  }

  stripeHandler(product) {
    Actions.reset('ticketFinal', { productBuy: product });
    AppMem.closeAppLoader('bars', {
      text: 'Cargando...',
      color: Colors.secondary_color,
    });
  }

  getInputPropsCard() {
    let props = {
      maxWidth: '80%',
      borderColor: Colors.blueberry,
      textInput: [
        {
          width: 300,
        },
      ],
    };
    return props;
  }
  getInputProps() {
    let props = {
      maxWidth: '100%',
      borderColor: Colors.blueberry,
      textInput: [
        {
          width: 300,
        },
      ],
      //withRef: true
    };
    return props;
  }

  ticketInfo(ticketTypeData, ticketTypeId, shopDetails) {
    let qnty = shopDetails[ticketTypeId] ? shopDetails[ticketTypeId] : 0;
    let totalAmmountPerType = ticketTypeData.tktPrice * qnty;
    let totalAmmountProducerPerType = ticketTypeData.tktPriceProducer * qnty;
    let totalAmmountLoyaltiPerType = ticketTypeData?.tktLoyaltiPoints
      ? ticketTypeData?.tktLoyaltiPoints * qnty
      : 0;
    this.globalTotal += totalAmmountPerType;
    this.producerPrice += totalAmmountProducerPerType;
    this.totalPoints += totalAmmountLoyaltiPerType;
    this.product.ticketType[ticketTypeId] = {
      ...ticketTypeData,
      tktEndDate: ticketTypeData.tktEndDate
        ? Util.timestampToDate(ticketTypeData.tktEndDate.seconds)
        : ticketTypeData.tktEndDate,
      tktInitDate: ticketTypeData.tktInitDate
        ? Util.timestampToDate(ticketTypeData.tktInitDate.seconds)
        : ticketTypeData.tktInitDate,
      totalTktPrice: totalAmmountPerType,
      tktQnty: qnty,
    };
    this.product['total'] = this.globalTotal;
    this.setState({ total: this.globalTotal });
    return (
      <li>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h6 class="subtitle">
            <span>
              {shopDetails[ticketTypeId] ? shopDetails[ticketTypeId] : 0}{' '}
              {ticketTypeData.tktName}
            </span>
          </h6>
          <h6 class="subtitle">
            <span>${Util.formatPrice(totalAmmountPerType)}</span>
          </h6>
        </div>
        {ticketTypeData?.tktLoyaltiPoints ? (
          <span class="info">+ {ticketTypeData?.tktLoyaltiPoints} puntos</span>
        ) : null}
      </li>
    );
  }

  _renderEventTicketType(ticketTypeData, ticketTypeId, shopDetails) {
    let qnty = shopDetails[ticketTypeId] ? shopDetails[ticketTypeId] : 0;
    let totalAmmountPerType = ticketTypeData.tktPrice * qnty;
    this.globalTotal += totalAmmountPerType;
    this.product.ticketType[ticketTypeId] = {
      ...ticketTypeData,
      tktEndDate: ticketTypeData.tktEndDate
        ? Util.timestampToDate(ticketTypeData.tktEndDate.seconds)
        : ticketTypeData.tktEndDate,
      tktInitDate: ticketTypeData.tktInitDate
        ? Util.timestampToDate(ticketTypeData.tktInitDate.seconds)
        : ticketTypeData.tktInitDate,
      totalTktPrice: totalAmmountPerType,
      tktQnty: qnty,
    };
    this.product['total'] = this.globalTotal;
    this.setState({ total: this.globalTotal })
    return (
      <View style={styles.eventTickets}>
        <View style={styles.numberTickets}>
          <Text style={styles.numberText}>
            {shopDetails[ticketTypeId] ? shopDetails[ticketTypeId] : 0}
          </Text>
        </View>
        <View style={styles.containerTickets}>
          <View style={{ flex: 1 }}>
            <Text style={styles.typeTickets}>{ticketTypeData.tktName}</Text>
          </View>
          <Text style={styles.priceTickets}>$ {totalAmmountPerType}</Text>
        </View>
      </View>
    );
  }

  renderAddModal = () => {
    let modalProps = {
      title: I18n.t('ticketResume.addModal'),
      type: 'primary',
      buttonOk: {
        text: I18n.t('ticketResume.complete'),
        action: () => Actions.push('editProfile', { ticketResume: true }),
      },
      buttonCancel: {
        text: I18n.t('ticketResume.cancel'),
        action: () => null,
      },
      swipeToClose: true,
      mustBeOpen: true,
      backdropPressToClose: true,
      buttonCancel: { text: I18n.t('ticketResume.cancel'), action: () => null },
      // input: [
      //   {
      //     placeholder: insta ? "Instagram" : "Email",
      //     onChangeText: (text) => this.onChangeTextEmail(text, type),
      //     placeholderTextColor: "grey",
      //     onSubmitEditing: () => this.onPressFinish(insta),
      //     returnKeyType: "send",
      //     width: "85%",
      //     autoCapitalize: "none",
      //     keyboardType: "email-address",
      //     style: { fontSize: L.h(16) },
      //     autoFocus: true,
      //   },
      // ],
    };
    toast(I18n.t('ticketResume.addModal'), { icon: '😉' });
  };

  handleOptionChange(event) {
    this.setState({ selectedOption: event });
  }

  navigateFunction(card_type) {
    let { onSuccesBuy, onErrorBuy, userData, preferenceData } = this.props;
    Actions.pagos360Screen({
      userData: userData,
      card_type: card_type,
      preferenceData: preferenceData,
      onSuccesBuy: onSuccesBuy,
      onErrorBuy: onErrorBuy,
    });
  }

  getButtonLabel = (total) => {
    const { appSettings } = this.props;
    let buttonLabel = "Realizar Pago";
    if (appSettings && appSettings.allowTickets0 && total === 0) {
      buttonLabel = "Adquirir Tickets";
    }
    return buttonLabel;
  }

  renderPaymentMethods = (total) => {
    const { appSettings } = this.props;
    const { selectedOption } = this.state;

    if (appSettings && appSettings.allowTickets0 && total === 0) {
      return null;
    }
    return (
      <div class="col-lg-8">
        <div class="checkout-widget checkout-card mb-0">
          <h5 class="title">Métodos de pago</h5>
          <ul class="payment-option">
            {/* <li
              class={selectedOption === 'others' ? 'active' : ''}
              onClick={() => this.handleOptionChange('others')}
              style={{ cursor: 'pointer' }}
            >
              <a>
                <img
                  src="./assets/images/payment/card.png"
                  alt="payment"
                />
                <span>MercadoPago{'\n'}UALA</span>
              </a>
            </li>*/}
            <li
              class={selectedOption === 'others_2' ? 'active' : ''}
              onClick={() => this.handleOptionChange('others_2')}
              style={{ cursor: 'pointer' }}
            >
              <a>
                <img
                  src="./assets/images/payment/card.png"
                  alt="payment"
                />
                <span>Tarjetas de Billeteras Viruales</span>
              </a>
            </li>
            <li
              class={selectedOption === 'credit_card' ? 'active' : ''}
              onClick={() => this.handleOptionChange('credit_card')}
              style={{ cursor: 'pointer' }}
            >
              <a>
                <img
                  src="./assets/images/payment/card.png"
                  alt="payment"
                />
                <span>Tarjeta de crédito</span>
              </a>
            </li>
            <li
              class={selectedOption === 'debit_card' ? 'active' : ''}
              onClick={() => this.handleOptionChange('debit_card')}
              style={{ cursor: 'pointer' }}
            >
              <a>
                <img
                  src="./assets/images/payment/card.png"
                  alt="payment"
                />
                <span>Tarjeta de débito</span>
              </a>
            </li>
          </ul>
          <p class="notice">
            Al presionar "REALIZAR PAGO" estás de acuerdo con los{' '}
            <a>términos y condiciones</a>
          </p>
        </div>
      </div>
    )
  }

  render() {
    let {
      event,
      shopDetails,
      handleSubmit,
      activeEventTicketTypes,
      user,
      appSettings
    } = this.props;
    let { loading, total } = this.state;
    const {
      evtId,
      evtInitDate,
      evtEndDate,
      evtTitle,
      evtDateTime,
      evtConditions,
      evtCoordinates,
      evtPlace,
      evtPlaceAdress,
      evtState,
    } = event;
    //  console.log(" >>>>>>>>>>event ", event);
    //  this.product.event.evtId = event.id;
    this.product.event = {
      evtId: event.id,
      evtInitDate: evtInitDate
        ? Util.timestampToDate(evtInitDate.seconds)
        : evtInitDate,
      evtEndDate: evtEndDate
        ? Util.timestampToDate(evtEndDate.seconds)
        : evtEndDate,
      evtTitle,
      evtDate: evtDateTime
        ? Util.timestampToDate(evtDateTime.seconds)
        : evtDateTime,
      evtConditions,
      evtCoordinates,
      evtPlace,
      evtPlaceAdress,
      evtState,
    };
    this.globalTotal = 0;
    this.producerPrice = 0;
    this.totalPoints = 0;
    let itemsRelPub = [];

    _.forEach(this.props.rrppList, function(rrpp) {
      //console.log(rrpp.lastname);
      if (rrpp.isActive) {
        itemsRelPub.push({
          label: rrpp.lastname + ', ' + rrpp.name,
          value: rrpp.id,
        });
      }
    });
    return (
      <div style={{ backgroundColor: Colors.backgroundTicketResume, paddingTop: 110 }}>
        <div class="event-facility padding-bottom">
          <section class="page-title bg-one" style={{ marginBottom: '1%' }}>
            <div class="container">
              <div class="page-title-area">
                <div
                  class="item md-order-1"
                  onClick={() => Actions.pop()}
                  style={{ cursor: 'pointer' }}
                >
                  <a class="custom-button back-button">
                    <i class="flaticon-double-right-arrows-angles" />
                    Volver
                  </a>
                </div>
                <div class="item date-item">
                  <h4>
                    {/* {moment(eventData.evtDate).format('DD MMMM YYYY')} */}
                    {evtTitle}
                  </h4>
                </div>
                <div class="item" />
              </div>
            </div>
          </section>
          <div class="container">
            <div class="row">
              <section
                class="event-book-search"
                style={{ width: '100%', marginTop: 0, marginBottom: 16 }}
              >
                <div class="container">
                  <div
                    class="event-search bg_img"
                    data-background="./assets/images/ticket-bg01.jpg"
                  >
                    <div class="event-search-top">
                      <div class="left">
                        <h3 class="title">{event?.evtDescription}</h3>
                      </div>
                      <div class="left">
                        <h3 class="title">
                        {"Desde"}
                          {' '}
                          {moment(
                            Util.timestampToDate(event.evtDateTime.seconds)
                          ).format('dddd DD [de] MMMM')}
                        </h3>
                        <h3 class="title" style={{ marginTop: 8 }}>
                          {moment(
                            Util.timestampToDate(event.evtDateTime.seconds)
                          ).format('HH:mm')}{' '}
                          hs
                        </h3>
                      </div>
                    </div>
                    <div class="event-search-bottom">
                      <div class="contact-side">
                        <div class="item">
                          <div class="item-thumb">
                            <img
                              src="./assets/images/event/icon/event-icon02.png"
                              alt="event"
                            />
                          </div>
                          <div class="item-content">
                            <span class="up">{event?.evtPlace}</span>
                            <span>{event?.evtPlaceAddress}</span>
                          </div>
                        </div>
                        {/* <div class="item">
                    <div class="item-thumb">
                      <img
                        src="./assets/images/event/icon/event-icon03.png"
                        alt="event"
                      />
                    </div>
                    <div class="item-content">
                      <span class="up">Consultas:</span>
                      <a href="MailTo:hello@Boleto .com">hello@Boleto .com</a>
                    </div>
                  </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              {this.renderPaymentMethods(total)}
              <div class={`col-lg-4 ${appSettings && appSettings.allowTickets0 && total === 0 ? 'ml-auto' : ''}`}>
                <div class="booking-summery bg-one">
                  <h4 class="title" style={{ textAlign: 'left' }}>
                    Tu compra
                  </h4>
                  {_.map(shopDetails, (data, ticketTypeId) => {
                    let ticketTypeData =
                      activeEventTicketTypes[event.id].evtTicketsType[
                        ticketTypeId
                      ];
                    return (
                      <ul>
                        {this.ticketInfo(
                          ticketTypeData,
                          ticketTypeId,
                          shopDetails
                        )}
                      </ul>
                    );
                  })}
                  {_.isEmpty(itemsRelPub) ? null : (
                    <View
                      style={{
                        alignItems: 'center',
                        width: '100%',
                        marginTop: 12,
                        marginBottom: -28,
                      }}
                    >
                      <Field
                        name="rrppSel"
                        itemType="ItemList"
                        type="picker"
                        ref={input => {
                          this.inputs.rrppSel = input;
                        }}
                        label={I18n.t('ticketResume.rrpp')}
                        component={LabelCustomInput}
                        defaultLabel={I18n.t('ticketResume.selectRRPP')}
                        items={itemsRelPub}
                        labelStyle={{
                          color: Colors.comboLabelColor,
                        }}
                        inputStyle={{
                          color: Colors.comboTextColor,
                        }}
                        containerStyle={{
                          borderBottomWidth: 0,
                        }}
                        withRef={true}
                        maxWidth={'100%'}
                        placeholderStyle={{ color: 'white' }}
                        borderColor={Colors.blueberry}
                      />
                    </View>
                  )}
                  <ul>
                    <li>
                      <span class="info">
                        <span>Precio</span>
                        <span>
                          ${Util.formatPrice(this.producerPrice, true)}
                        </span>
                      </span>
                      <span class="info">
                        <span>Cargo de servicio</span>
                        <span>
                          $
                          {Util.formatPrice(
                            this.globalTotal - this.producerPrice,
                            true
                          )}
                        </span>
                      </span>
                      {this.totalPoints ? (
                        <span class="info">
                          <span>Puntos</span>
                          <span>+{this.totalPoints}</span>
                        </span>
                      ) : null}
                    </li>
                  </ul>
                </div>
                <div class="proceed-area  text-center">
                  {/* <select>
                    <option value="opcion1">Opción 1</option>
                    <option value="opcion2">Opción 2</option>
                    <option value="opcion3">Opción 3</option>
                  </select> */}
                  <h6 class="subtitle">
                    <span>Total a pagar</span>
                    <span>${Util.formatPrice(this.globalTotal, true)}</span>
                  </h6>
                  {loading ? (
                    <ActivityIndicator style={styles.activity} size={'large'} />
                  ) : (
                    <a
                      class="custom-button back-button"
                      style={{ cursor: 'pointer' }}
                      onClick={
                        user.proDNI
                          ? handleSubmit(form => this.paymentGatewayHandler(form))
                          : () => this.renderAddModal()
                      }
                    >
                      {this.getButtonLabel(total)}
                    </a>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render2() {
    let {
      event,
      shopDetails,
      handleSubmit,
      activeEventTicketTypes,
      user,
    } = this.props;
    const {
      evtId,
      evtInitDate,
      evtEndDate,
      evtTitle,
      evtDateTime,
      evtConditions,
      evtCoordinates,
      evtPlace,
      evtPlaceAdress,
      evtState,
    } = event;
    this.product.event = {
      evtId,
      evtInitDate: evtInitDate
        ? Util.timestampToDate(evtInitDate.seconds)
        : evtInitDate,
      evtEndDate: evtEndDate
        ? Util.timestampToDate(evtEndDate.seconds)
        : evtEndDate,
      evtTitle,
      evtDate: evtDateTime
        ? Util.timestampToDate(evtDateTime.seconds)
        : evtDateTime,
      evtConditions,
      evtCoordinates,
      evtPlace,
      evtPlaceAdress,
      evtState,
    };
    this.globalTotal = 0;

    let itemsRelPub = [];

    _.forEach(this.props.rrppList, function(rrpp) {
      //console.log(rrpp.lastname);
      if (rrpp.isActive) {
        itemsRelPub.push({
          label: rrpp.lastname + ', ' + rrpp.name,
          value: rrpp.id,
        });
      }
    });

    return (
      <View style={styles.container}>
        <View
          style={{
            flex: 1,
            backgroundColor: Colors.backgroundHistory,
            paddingTop: 120,
          }}
        >
          <div class="wrapper-area" style={{ marginBottom: 50 }}>
            <Header
              title={I18n.t('ticketResume.title')}
              leftButtons={[
                {
                  onPress: Actions.pop,
                  icon: {
                    name: 'chevron-left',
                    type: 'FontAwesome',
                    style: {
                      color: Colors.mainTitleColor,
                      fontSize: L.h(23),
                      marginLeft: L.w(8),
                    },
                    text: Util.isIos() ? '' : '',
                    textStyle: {
                      fontSize: L.h(18),
                      color: 'white',
                    },
                  },
                },
              ]}
              titleStyle={{
                fontFamily: Fonts.MEDIUM,
                fontSize: L.h(20),
                color: Colors.mainTitleColor,
              }}
            />
            <ScrollView
              style={styles.container}
              keyboardShouldPersistTaps="always"
              keyboardDismissMode="on-drag"
            >
              <View style={styles.eventContainer}>
                <Text style={styles.initialInfo}>
                  {I18n.t('ticketResume.checkoutProcess')}
                </Text>
                <Text style={styles.eventName}>
                  {event.evtTitle.toUpperCase()}
                </Text>
              </View>
              <TicketInfo
                evtDateTime={Util.timestampToDate(event.evtDateTime.seconds)}
                evtCoordinates={event.evtCoordinates}
                evtPlace={event.evtPlace}
              />
              {_.map(shopDetails, (data, ticketTypeId) => {
                let ticketTypeData =
                  activeEventTicketTypes[event.id].evtTicketsType[ticketTypeId];
                return data
                  ? this._renderEventTicketType(
                      ticketTypeData,
                      ticketTypeId,
                      shopDetails
                    )
                  : null;
              })}
              <View style={styles.eventTotals}>
                <Text style={styles.totalText}>
                  {I18n.t('ticketResume.total')}{' '}
                </Text>
                <Text style={styles.totalPriceText}>${this.globalTotal}</Text>
              </View>

              {/* <View style={styles.divider}>
                <Text style={styles.dividerText}>
                  Relaciones Públicas (Opcional)
                </Text>
              </View> */}
              {/*style={localStyle.inputRow}*/}
              {/* <View style={{ alignItems: "center", width: "100%" }}>
                <Field
                  name="rrppSel"
                  itemType="ItemList"
                  type="picker"
                  ref={(input) => {
                    this.inputs.rrppSel = input;
                  }}
                  label={I18n.t("ticketResume.rrpp")}
                  component={LabelCustomInput}
                  defaultLabel={I18n.t("ticketResume.selectRRPP")}
                  items={itemsRelPub}
                  labelStyle={{ color: Colors.comboLabelColor }}
                  inputStyle={{ color: Colors.comboTextColor }}
                  withRef={true}
                  maxWidth={"91%"}
                  placeholderStyle={{ color: "white" }}
                  borderColor={Colors.blueberry}
                />
              </View> */}
              {/*
                style={[
                localStyle.iconSt,
                { marginRight: L.w(Util.isIos() ? 14 : 10) }
              ]}



                validate={required}
                disabled={!this.state.editable}

                editable: this.state.editable,
                
                
                
                

            */}
              <View style={styles.divider}>
                <Text style={styles.dividerText}>
                  {I18n.t('ticketResume.paymentInfo')}
                </Text>
              </View>
              {/* <Text style={styles.textProcess}>
            Para procesar el pago, ingrese los datos solicitados
          </Text> */}
              <View style={styles.secureContainer}>
                <IconFA
                  name="shield"
                  type="FontAwesome"
                  style={styles.iconShield}
                />
                <Text
                  style={{
                    fontSize: L.h(13),
                    color: Colors.adviceTextTicketResume,
                    fontFamily: Fonts.DREGULAR,
                    marginTop: L.h(2),
                  }}
                >
                  {I18n.t('ticketResume.securePayment')}
                </Text>
              </View>
              <MainButton
                //secondary
                style={[styles.buttonBuy, { cursor: 'pointer' }]}
                text="Realizar Pago"
                onPress={
                  user.proDNI
                    ? handleSubmit(form => {
                        if (this.product.total && this.product.total > 0) {
                          this.paymentGatewayHandler(form);
                        } else {
                          // Toast.show({
                          //   text: I18n.t('ticketResume.selectTickets'),
                          //   buttonText: I18n.t('global.accept'),
                          //   type: 'danger',
                          //   position: 'bottom',
                          // });
                          toast.error(I18n.t('ticketResume.selectTickets'), {
                            duration: 8000,
                          });
                        }
                      })
                    : () => this.renderAddModal()
                }
              />
            </ScrollView>
          </div>
        </View>
      </View>
    );
  }

  paymentGatewayHandler(form) {
    const { appSettings } = this.props;
    if (appSettings && appSettings.allowTickets0 && this.product.total === 0 && !_.isEmpty(this.product)) {
      return this.handleFreeTicketsPayment(form);
    } else {
      if (this.product.total && this.product.total > 0) {
        switch (Config.APP_PAYMENT_GATEWAY) {
          case 'stripe':
            this.stripePay(form);
            break;
          default:
            this.pagos360Pay(form);
        }
      } else {
        toast.error(I18n.t('ticketResume.selectTickets'), {
          duration: 8000,
        });
      }
    }
  }

  handleFreeTicketsPayment = (form) => {
    const { onCreateFreeTickets, user } = this.props;
    this.setState({ loading: true });
    onCreateFreeTickets(
      this.product.total,
      user.proEmail,
      this.product,
      form.rrppSel,
      async preferenceData => {
        this.setState({ loading: false });
        if (
          preferenceData && preferenceData.buyOperationId &&
          preferenceData.result && preferenceData.result === "OK"
        ) {
          const paymentId = preferenceData.buyOperationId;
          const finalProduct = this.product;
          finalProduct.paymentId = paymentId;
          return Actions.reset('ticketFinal', { productBuy: finalProduct });
        } else {
          return toast.error(preferenceData.error || I18n.t('ticketResume.freeTicketFail'), {
            duration: 8000,
          });
        }
      }
    );
  }

  stripePay(form) {
    let { user } = this.props;
    AppMem.openAppLoader('bars', {
      text: 'Cargando...',
      color: Colors.secondary_color,
    });
    console.log(' >>>>>>>>>> this.product', this.product);
    this.props.onCreateStripePreference(
      this.product.total,
      user.proEmail,
      this.product,
      form.rrppSel,
      async preferenceData => {
        if (preferenceData && preferenceData.id && this.product.total) {
          Actions.stripeScreen({
            // userData: this.props.userData,
            preferenceData: preferenceData,
            onSuccesBuy: data => {
              this.onSuccesBuy(data, this.product);
            },
            onErrorBuy: () => {
              this.errorResponse();
              // Toast.show({
              //   text: I18n.t('ticketResume.validInfo'),
              //   buttonText: I18n.t('global.accept'),
              //   type: 'danger',
              //   position: 'bottom',
              // });
              toast.error(I18n.t('ticketResume.validInfo'), { duration: 8000 });
            },
          });
          AppMem.closeAppLoader('bars', {
            text: 'Cargando...',
            color: Colors.secondary_color,
          });
        } else {
          toast.error(
            preferenceData.error || I18n.t('ticketResume.failedPreference'),
            { duration: 8000 }
          );
        }
      }
    );
  }

  pagos360Pay(form) {
    let { user } = this.props;
    let { selectedOption } = this.state;
    // AppMem.openAppLoader("bars", {
    //   text: "Cargando...",
    //   color: Colors.secondary_color,
    // });
    this.setState({ loading: true });
    // console.log(" >>>>>>>>>>  this.product ", this.product);
    this.props.onCreatePreference(
      this.product.total,
      user.proEmail,
      this.product,
      null,
      async preferenceData => {
        // console.log("preferenceDatapreferenceData", preferenceData);

        if (preferenceData && preferenceData.id && this.product.total) {
          Actions.pagos360Screen({
            userData: this.props.userData,
            preferenceData: preferenceData,
            card_type:
              selectedOption === 'others' || selectedOption === 'others_2'
                ? 'credit_card'
                : selectedOption,
            onSuccesBuy: data => {
              this.setState({ loading: false });
              this.onSuccesBuy(data, this.product);
            },
            onErrorBuy: () => {
              this.setState({ loading: false });
              this.errorResponse();
              // Toast.show({
              //   text: I18n.t('ticketResume.validInfo'),
              //   buttonText: I18n.t('global.accept'),
              //   type: 'danger',
              //   position: 'bottom',
              // });
              toast.error(I18n.t('ticketResume.validInfo'), { duration: 8000 });
            },
          });
          this.setState({ loading: false });
          // AppMem.closeAppLoader("bars", {
          //   text: "Cargando...",
          //   color: Colors.secondary_color,
          // });
        } else {
          this.setState({ loading: false });
          // AppMem.closeAppLoader("bars", {
          //   text: "Cargando...",
          //   color: Colors.secondary_color,
          // });
          // Toast.show({
          //   text: response.error || I18n.t("ticketResume.failedPreference"),
          //   buttonText: I18n.t("global.accept"),
          //   type: "danger",
          //   position: "bottom",
          // });
          toast.error(
            preferenceData.error || I18n.t('ticketResume.failedPreference'),
            { duration: 8000 }
          );
        }
      }
    );
  }

  errorResponse() {
    let allListeners = AppMem.getMPListener;
    allListeners.map(eachListener => {
      eachListener();
    });
    AppMem.setMPListener && AppMem.setMPListener([]);
    AppMem.closeAppLoader('bars', {
      text: 'Cargando...',
      color: Colors.secondary_color,
    });
  }
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.backgroundTicketResume,
  },
  eventContainer: {
    alignItems: 'center',
    paddingVertical: L.h(16),
    paddingHorizontal: L.w(16),
  },
  initialInfo: {
    fontSize: L.h(13),
    color: Colors.introTextTicketResume,
    fontFamily: Fonts.DLIGHT,
  },
  eventName: {
    fontSize: L.h(16),
    color: Colors.secondary_color,
    fontFamily: Fonts.DBOLD,
    textAlign: 'center',
  },
  eventInfo: {
    width: '100%',
    paddingTop: L.h(16),
    paddingHorizontal: L.w(20),
    paddingBottom: L.h(6),
    borderStyle: 'dashed',
    alignItems: 'center',
    borderWidth: L.h(1),
    borderRadius: L.h(1),
    borderColor: Colors.borderTicketResume,
    position: 'relative',
  },
  containerInfo: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: L.h(10),
  },
  iconStyle: {
    color: Colors.darkIndigo,
    fontSize: L.h(16),
  },
  eventPlace: {
    marginLeft: 5,
    color: Colors.purpley,
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
  },
  divider: {
    paddingTop: L.h(10),
    paddingBottom: L.h(7),
    paddingHorizontal: L.w(18),
    backgroundColor: Colors.dividerBackgroundOne,
  },
  dividerText: {
    color: Colors.dividerTextOne,
    fontSize: L.h(13),
    fontFamily: Fonts.DREGULAR,
  },
  eventTickets: {
    width: '100%',
    flexDirection: 'row',
    borderStyle: 'dashed',
    borderWidth: L.h(1),
    borderRadius: L.h(1),
    borderColor: Colors.borderTicketResume,
    alignItems: 'center',
    paddingVertical: L.h(10),
  },
  numberTickets: {
    width: L.w(70),
    alignItems: 'center',
    justifyContent: 'center',
    //paddingTop: L.h(5)
  },
  numberText: {
    color: Colors.ticketNumbersTicketResume,
    fontSize: 13,
  },
  containerTickets: {
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    flex: 1,
    paddingLeft: L.w(11),
    paddingRight: L.w(11),
  },
  typeTickets: {
    fontSize: L.h(13),
    fontFamily: Fonts.DREGULAR,
    color: Colors.ticketTypeTicketResume,
    alignItems: 'center',
  },
  priceTickets: {
    fontSize: L.h(15),
    fontFamily: Fonts.DREGULAR,
    color: Colors.ticketPriceTicketResume,
    //paddingRight: L.w(11)
  },
  eventTotals: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    paddingVertical: L.h(15),
    marginBottom: L.h(5),
    paddingRight: L.w(29),
  },
  totalText: {
    fontSize: L.h(19),
    color: Colors.ticketResumeTotalText,
    fontFamily: Fonts.DREGULAR,
  },
  totalPriceText: {
    fontSize: L.h(19),
    color: Colors.trueGreen,
    fontFamily: Fonts.DBOLD,
  },
  buttonBuy: {
    justifyContent: 'center',
    marginTop: L.h(20),
    marginBottom: L.h(20),
    marginHorizontal: L.h(20),
    shadowOpacity: 0.3,
    shadowRadius: 3,
    shadowOffset: {
      height: 0,
      width: 0,
    },
    elevation: 3,
    height: 60,
    //borderWidth: 1
    //borderColor: "white"
  },
  textProcess: {
    color: Colors.commonDetailText,
    fontSize: L.h(14),
    fontFamily: Fonts.DSEMIBOLD,
    textAlign: 'center',
    marginTop: L.h(20),
  },
  secureContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: L.h(11),
  },
  iconShield: {
    color: Colors.adviceIconTicketResume,
    fontSize: L.h(16),
    marginRight: L.w(5),
  },
  cardContainer: {
    flexDirection: 'row',
    marginTop: L.h(16),
    alignItems: 'flex-end',
  },
  iconCard: {
    color: Colors.black_54,
    fontSize: L.h(25),
  },
  cardText: {
    fontSize: L.w(10),
    color: Colors.commonDetailText,
  },
});

function mapStateToProps(store) {
  //console.log("storeforn", store.form);
  const selector = formValueSelector('ticketResume');

  return {
    ticketResumeForm: selector(store, 'rrppSel'),
    user: store.authReducer.user,
    eventPaymentData: store.appReducer.eventPaymentData,
    activeEventTicketTypes: store.appReducer.activeEventTicketTypes,
    rrppList: store.appReducer.rrppList,
    appSettings: store.appReducer.appSettings,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      onCreatePreference,
      onCreateStripePreference,
      successfulPay,
      startToListen,
      getPaymentInformation,
      onCreateFreeTickets,
    },
    dispatch
  );
}

export default reduxForm({
  form: 'ticketResume',
  destroyOnUnmount: false,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(TicketResume)
);
