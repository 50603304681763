import { Actions } from "react-native-router-flux";
import Fonts from "../../common/Fonts";
import L from "../../common/Layout";
import Colors from "../../common/Colors";
import firebase, { config } from "react-native-firebase";

const headerProps = (type, onPressRight) => {
  let props = {
    leftButtons: onPressRight
      ? null
      : [
          {
            onPress:
              type === "main" || type === "E1"
                ? () => {
                    Actions.reset("login");
                    firebase.auth().signOut();
                  }
                : () => Actions.pop(),
            // icon: {
            //   name: type === "main" ? "close" : "arrow-left",
            //   style: {
            //     color: "white",
            //     fontSize: type === "main" ? L.h(40) : L.h(33),
            //     fontFamily: Fonts.MEDIUM
            //   }
            // }
            icon: {
              name: type === "main" ? "close" : "chevron-left",
              type: "FontAwesome",
              style: {
                color: Colors.mainTitleColor,
                fontSize: L.h(23),
                marginLeft: L.w(8),
              },
              text: "",
              textStyle: {
                fontSize: L.h(18),
                color: Colors.registerTitle,
              },
            },
            // icon: {
            //   name: type === "main" ? "close" : "chevron-left",
            //   type: "MaterialIcons",
            //   style: {
            //     color: "white",
            //     fontSize: type === "main" ? L.h(30) : L.h(33),
            //     fontFamily: Fonts.MEDIUM,
            //     borderWidth: 1
            //   }
            // }
          },
        ],
    rightButtons: onPressRight
      ? [
          {
            onPress: () => onPressRight && onPressRight(),
            icon: {
              name: "exit-to-app",
              type: "MaterialIcons",
              textStyle: {
                color: "white",
                fontSize: L.h(17),
                fontFamily: Fonts.MEDIUM,
              },
              style: {
                color: "white",
                fontSize: type === "main" ? L.h(30) : L.h(33),
                fontFamily: Fonts.MEDIUM,
              },
            },
          },
        ]
      : [],
    titleStyle: {
      //fontFamily: Fonts.MEDIUM,
      fontSize: L.h(20),
      fontFamily: Fonts.DSEMIBOLD,
      color: Colors.registerTitle,
    },
    title: "Registro",
    noShadow: true,
    noStatusBar: true,
    backgroundColor: "transparent",
  };
  return props;
};

export default {
  headerProps,
};
