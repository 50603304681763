import * as actions from "../actions/ActionTypes";

const initialState = {
  eventStats: null
};

export default function producerReducer(state = initialState, action) {
  if (action.type === actions.ACT_PRODUCER_CHANGE) {
    return Object.assign({}, state, action.props);
  }
  return state;
}
