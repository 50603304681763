let _isDrawerOpen = false;
let _drawerRef = null;
let _modalRef = null;
let _appLoader = null;
let _calendar = null;
let _globalArrayListeners = []
let _mpListener = []
let _ttypeListener = []
let _isConnected = true;

class AppMem {


  static saveListeners(listener) {
		_globalArrayListeners.push(listener);
	}

	static get getGlobalListener() {
		return _globalArrayListeners
	}

	static set setGlobalListener(array) {
		_globalArrayListeners = array
  }

  static saveTTListeners(listener) {
		_ttypeListener.push(listener);
	}

	static get getTTListener() {
		return _ttypeListener
	}

	static set setTTListener(array) {
		_ttypeListener = array
  }


	static saveMPListeners(listener) {
		_mpListener.push(listener);
	}

	static get getMPListener() {
		return _mpListener
	}

	static set setMPListener(array) {
		_mpListener = array
  }
  
  static get drawerRef() {
    return _drawerRef;
  }

  static set drawerRef(value) {
    _drawerRef = value;
  }

  static get isDrawerOpen() {
    return _isDrawerOpen;
  }

  static set isDrawerOpen(value) {
    _isDrawerOpen = value;
  }

  static openDrawer() {
    _drawerRef.open();
  }

  static closeDrawer() {
    _drawerRef.close();
  }

  static get modalRef() {
    return _modalRef;
  }

  static set modalRef(value) {
    _modalRef = value;
  }

  static get isModalOpen() {
    return _isModalOpen;
  }

  static set isModalOpen(value) {
    _isModalOpen = value;
  }

  static openModal(children, props, child) {
    _modalRef.open(children, props, child);
  }

  static closeModal() {
    _modalRef.close();
  }

  static get appLoader() {
    return _appLoader;
  }

  static set appLoader(value) {
    _appLoader = value;
  }

  static openAppLoader(type, props) {
    _appLoader.open(type, props);
  }

  static closeAppLoader() {
    _appLoader.close();
  }

  static get calendar() {
    return _calendar;
  }

  static set calendar(value) {
    _calendar = value;
  }
  
  static get isConnected() {
    return _isConnected;
  }

  static set isConnected(value) {
    _isConnected = value;
  }
}

export default  AppMem;
