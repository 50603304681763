import React from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import L from '../common/Layout';
import Colors from '../common/Colors';
import Fonts from '../common/Fonts';

const ChipTag = ({ onPress, Label, icon, containerStyle, textStyle }) => {
  const { chipText, chipContainer } = styles;
  return (
    <View style={[styles.chipContainer, containerStyle]}>
      <Text style={styles.chipText}>{Label}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  chipContainer: {
    backgroundColor: 'rgba(123, 155, 58, 0.85)',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'white',
    borderRadius: L.h(35),
    paddingHorizontal: L.w(10),
    paddingVertical: L.h(3),
    marginRight: L.w(16),
    marginBottom: L.h(8),
  },
  chipText: {
    fontFamily: Fonts.DREGULAR,
    fontSize: L.h(13),
    color: 'white',
  },
});

export default ChipTag;
