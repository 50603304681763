import { Platform } from "react-native";
import Config from "react-native-config";

export const APP_VERSION = "1.0.2";
// expresiones regulares para validaciones
export const REGEX_AMEX_LENGTH = /^3[47][0-9]{2}-[0-9]{6}-[0-9]{5}$/;
export const REGEX_CREDIT_CARD_LENGTH = /^[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}$/;
export const LETTER_REGEX = /^[a-z-áéíóú ]+$/i;
export const NUMBER_REGEX = /^[0-9]+$/i;
export const REGEX_AMEX = /^3[47][0-9]{13}$/;
export const REGEX_AMEXMASK = /^3[47][0-9]*$/;
export const REGEX_AMEXMASKINPUT = /^3[47][0-9.-]*/;
export const REGEX_COUNTRY_CODE = /^\d{1,3}$/;
export const REGEX_CODE_AREA = /^\d{1,4}$/;
export const REGEX_NUMBER = /^\d{5,11}$/;
export const REGEX_EMAIL = /^[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
// StatusBarHeight
const version = Platform.Version < 21 ? 0 : 25;
export const STATUS_BAR_HEIGHT = Platform.OS === "ios" ? 20 : version;

export const TOP_PADDING_IOS_FONT=0;//WEB99

export const APP_RATING_URL =
  Platform.OS === "ios"
    ? Config.STORE_IOS_URL
    : Config.STORE_ANDROID_URL;
