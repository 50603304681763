import React, { PureComponent } from "react";
import { Toast } from "native-base"; //WEB99 Content
import { Actions } from "react-native-router-flux";
import {
  Text,
  View,
  Image,
  StyleSheet,
  ImageBackground,
  ActivityIndicator,
  Keyboard,
} from "react-native";
import { bindActionCreators } from "redux";
import { reduxForm, Field } from "redux-form";
import { connect } from "react-redux";
import L from "../../common/Layout";
import Fonts from "../../common/Fonts";
import VTHeader from "../../components/VTHeader";
import { validateCode, actLogout } from "../../actions";
import renderInput from "../../components/LabelCustomInput";
import MainButton from "../../components/MainButton";
import { required } from "../../common/Validations";
import AppAsyncStorage from "../../common/AppAsyncStorage";
import Colors from "../../common/Colors";
const BG_IMG = require("../../common/assets/background_login.jpg");
import I18n from "i18next"

class ValidateCode extends PureComponent {
  constructor() {
    super();
    this.state = {
      intervalId: null,
      phone: "",
      code: "",
      error_phone: false,
      count: null,
      showCountDown: false,
      textButtomEnviar: I18n.t("validateCode.sendCode"),
      codeValidate: null,
      minutes: 0,
    };
    this.startCount = this.startCount.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.signOut = this.signOut.bind(this);
    this.callback = this.callback.bind(this);
  }

  componentDidMount() {
    this.startCount();
  }

  async signOut() {
    let { actLogout } = this.props;
    await actLogout();
    Actions.reset("login");
  }

  startCount() {
    //if (!hasErrors) {
    // CAMBIAMOS EL ESTADO PARA MOSTRAR EL TEXTO QUE MUESTRA EL CONTEO
    this.setState({ showCountDown: true });
    // FECHA ACTUAL BASE
    var oldDate = new Date().getTime();
    // CAMBIAMOS EL TEXT DEL BOTON
    this.setState({
      textButtomEnviar: I18n.t("validateCode.reSendCode"),
      count: null,
    });
    // SI EXISTE UN INTEVAL CREADO SE LIMPIA
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
    // INICIAMOS EL INTERVAL
    this.state.intervalId = setInterval(
      function() {
        // OBTENEMOS EL TOTAL DE SEGUNDO ENTRE LA FECHA INICIAL BASE Y LA FECHA VARIABLE
        var delta = Math.abs(oldDate - new Date().getTime()) / 1000;

        // MATH PARA CALCULAR LOS MINUTOS
        var minutes = Math.floor(delta / 60) % 60;
        minutes = minutes.toFixed(0);
        minutes =
          minutes.toString().length == 1
            ? "0" + minutes.toString()
            : minutes.toString();

        // CALCULO DE SEGUNDOS
        var seconds = delta % 60;
        seconds = seconds.toFixed(0);
        seconds =
          seconds.toString().length == 1
            ? "0" + seconds.toString()
            : seconds.toString();

        this.setState({ count: minutes + ":" + seconds, minutes });
      }.bind(this),
      1000
    );
  }

  submitForm(values) {
    Keyboard.dismiss();
    //Actions.registerF();
    let { validateCode } = this.props;
    this.setState({ codeValidate: true });
    validateCode(values.code, this.callback);
  }

  async callback(res) {
    let { socialMedia, profile, emailVerified } = this.props;
    this.setState({ codeValidate: false });
    if (res) {
      await AppAsyncStorage.setItem("phoneValidate", "ok");
      if (profile) {
        Toast.show({
          text: I18n.t("validateCode.validateSuccess"),
          buttonText: I18n.t("validateCode.validateButtonOk"),
          position: "bottom",
          type: "success",
          duration: 6000,
        });
      }
      socialMedia || profile || emailVerified
        ? Actions.reset("mainView", profile ? { screenChoose: 2 } : {})
        : Actions.reset("registerF");
    } else {
      Toast.show({
        text: I18n.t("validateCode.validateError"),
        buttonText: I18n.t("validateCode.validateButtonOk"),
        position: "bottom",
        type: "danger",
        duration: 4000,
      });
    }
  }

  componentWillUnmount() {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  }
  getInputProps() {
    let props = {
      borderColor: Colors.registerFieldColor,
      labelStyle: { color: Colors.registerFieldColor },
      inputStyle: {
        color: Colors.registerFieldColor,
        fontSize: L.h(17),
      },
      errorIconStyle: { color: Colors.registerFieldColor },
      borderBottomColor: Colors.registerFieldColor,
      blurOnSubmit: false,
      withRef: true,
      labelStyle: { color: Colors.forgotPassFieldColor },
      inputStyle: { color: Colors.forgotPassTextColor },
      errorIconStyle: { color: Colors.forgotPassErrorColor },
    };
    return props;
  }

  headerProps() {
    let { profile } = this.props;
    let props = {
      leftButtons: [
        {
          onPress: Actions.pop,
          icon: {
            name: "chevron-left",
            style: {
              color: Colors.mainTitleColor,
              fontSize: L.h(23),
              marginLeft: L.w(8),
            },
          },
        },
      ],
      rightButtons: [],
      titleStyle: {
        //fontFamily: Fonts.MEDIUM,
        fontSize: L.h(20),
        color: Colors.mainTitleColor,
      },
      title: "Validar código",
      noShadow: true,
      noStatusBar: true,
      backgroundColor: "transparent",
    };
    return props;
  }

  render() {
    let { handleSubmit } = this.props;
    let { count, codeValidate, minutes } = this.state;
    return (
      <ImageBackground source={BG_IMG} style={{ flex: 1 }} resizeMode="cover">
        <View style={{ flex: 1 }}>
          {/* <StatusBarView /> */}
          <VTHeader {...this.headerProps()} />
          
            <View style={{ width: "80%", alignSelf: "center" }}>
              <Text style={styles.ingreseSuNmeroDe}>
                {I18n.t("validateCode.validateMainText")}
              </Text>
              <Field
                style={{ marginBottom: L.h(16) }}
                label={I18n.t("validateCode.validateRecivedCode")}
                autoCapitalize="none"
                name="code"
                type="text"
                component={renderInput}
                maxLength={6}
                keyboardType={"numeric"}
                validate={[required]}
                onSubmitEditing={handleSubmit(this.submitForm)}
                {...this.getInputProps()}
              />
              <Text style={styles.desdeQueSeE}>
                {count ? count + ` ${I18n.t("validateCode.validateRecivedCode2")}` : null}
              </Text>
              {codeValidate ? (
                <View style={{ alignSelf: "center" }}>
                  <ActivityIndicator size="large" color="orange" />
                </View>
              ) : (
                <View>
                  <MainButton
                    text={I18n.t("validateCode.validateMainButton")}
                    style={{ marginVertical: L.h(30) }}
                    onPress={handleSubmit(this.submitForm)}
                  />
                  <Text style={styles.resendText}>
                    {I18n.t("validateCode.validateMainText2")}{" "}
                    <Text style={{ fontFamily: Fonts.DBOLD }}>
                      {I18n.t("validateCode.validateMainText3")}
                    </Text>
                  </Text>
                  <MainButton
                    text={I18n.t("validateCode.validateMainText3")}
                    style={{ marginBottom: L.h(20), backgroundColor: "white" }}
                    textStyle={{
                      fontFamily: Fonts.DBOLD,
                      fontSize: L.h(16),
                      letterSpacing: 0,
                      textAlign: "center",
                      color:
                        minutes == "00" || minutes == "01" ? "grey" : "#d62b43",
                    }}
                    disabled={minutes == "00" || minutes == "01" ? true : false}
                    secondary
                    onPress={Actions.pop}
                  />
                </View>
              )}
            </View>
         
          <Image
            // source={require("../../common/assets/bgBottom1.png")}
            resizeMode="contain"
            style={{ position: "absolute", right: 0, bottom: L.h(60) }}
          />
        </View>
      </ImageBackground>
    );
  }
}
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  validarTelfono: {
    fontSize: L.h(20),
    fontFamily: Fonts.MEDIUM,
    letterSpacing: -0.15,
    color: "#ffffff",
    textAlign: "center",
  },
  keyIcon: {
    width: L.wProm(41),
    height: L.hProm(41),
    flex: 1,
  },
  closeButton: {
    width: L.wProm(56),
    height: L.hProm(30),
    alignItems: "center",
    justifyContent: "center",
  },
  ingreseSuNmeroDe: {
    fontFamily: Fonts.DREGULAR,
    fontSize: L.h(23),
    letterSpacing: -0.55,
    color: Colors.registerTextColor,
    marginTop: L.h(55),
    marginBottom: L.h(30),
  },
  ingreseSuNmeroDe2: {
    fontFamily: Fonts.DREGULAR,
    fontSize: L.h(12),
    letterSpacing: -0.29,
    color: Colors.registerTextColor,
    marginTop: L.h(8),
    marginBottom: L.h(30),
    textAlign: "center",
  },
  desdeQueSeE: {
    fontFamily: Fonts.DBOLD,
    fontSize: L.h(12),
    letterSpacing: -0.29,
    color: Colors.registerTextColor,
    textAlign: "center",
    marginBottom: L.h(16),
  },
  resendText: {
    color: Colors.registerResendText,
    fontFamily: Fonts.DREGULAR,
    textAlign: "center",
    lineHeight: L.h(18),
  },
});

const mapStateToProps = (state) => {
  const { code, sended_phone, user } = state.authReducer;
  return { code, sended_phone, user };
};

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      validateCode,
      actLogout,
    },
    dispatch
  );
}

export default reduxForm({
  form: "validateCode",
  destroyOnUnmount: true,
})(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ValidateCode)
);
