import i18n from "i18next";
import { en, es } from "./translations/index";
import { initReactI18next } from "react-i18next";
import * as RNLocalize from "react-native-localize";
const { languageTag, languageCode } = RNLocalize.getLocales()[0];

const resources = { es, en };

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    compatibilityJSON: "v3",
    resources,
    lng: languageCode,
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
