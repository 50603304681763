import React from "react";
import { View, Platform } from "react-native";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Container } from "native-base";
import { Actions } from "react-native-router-flux";
import Config from "react-native-config";
import _ from "lodash";
import Fonts from "../common/Fonts";
import VTHeader from "../components/VTHeader";
import AppMem from "../common/AppMem";
import VTFooterTabs from "../components/VTFooterTabs";
import Home from "./Home";
import Profile from "./profile/ProfileMain";
import ShopMain from "./shop/shopMain";
import ProducerStatics from "./producer/StatisticsMain";
import WalletMain from "./wallet/WalletMain";
import MainRRPP from "./rrpp/MainRRPP";
import Beneficios from "./benefits/MainBenefits";
import ThemeColors from "../common/ThemeColors";
import Colors from "../common/Colors";
import I18n from "i18next";
import { TOP_PADDING_IOS_FONT } from "../common/Constants";
import moment from "moment";
import L from "../common/Layout";
import {
  getMyNextEvents,
  getRRPPs,
  orderEvents,
  getMyLoyalyProgram,
  getLoyalyUseMethods,
} from "../actions/AppActions";

class MainView extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      screenChoose: 1,
      title: I18n.t("mainView.events"),
    };
    this.footerTab = {};
    this.onPressTab = this.onPressTab.bind(this);
    this.onPressMainOption = this.onPressMainOption.bind(this);
    this.orderEvents = this.orderEvents.bind(this);
    this.title = null;
  }

  componentWillMount() {
    // this.props.getTicketsCollection();
    this.props.getMyNextEvents();
    if (Config.HAS_LOYALY_PROGRAM === "active") {
      this.props.getMyLoyalyProgram();
      this.props.getLoyalyUseMethods();
    }
    this.props.getRRPPs();
    if (AppMem.appLoader) {
      AppMem.closeAppLoader();
    }
    if (this.props.screenChoose) {
      this.setState({ screenChoose: 0, title: I18n.t("mainView.myBoughts") });
    }
  }

  onPressTab(index) {
    let title = "";
    if (this.props.anonymousMode && index !== 1) {
      let modalProps = {
        title: I18n.t("mainView.login"),
        type: "warning",
        buttonOk: {
          text: I18n.t("mainView.loginButton"),
          action: () => Actions.reset("login"),
        },
        buttonCancel: {
          text: I18n.t("mainView.loginButtonCancel"),
          action: () => null,
        },
      };
      AppMem.openModal("alert", modalProps);
      this.footerTab.setSelectedIndex(1);
      return;
    }

    if (Config.HAS_LOYALY_PROGRAM === "active" && Platform.OS != 'web') {
      switch (index) {
        case 0:
          title = I18n.t("mainView.myBoughts");
          break;
        case 1:
          title = I18n.t("mainView.events");
          break;
        case 2:
          title = I18n.t("mainView.profile");
          break;
        case 3:
          title = I18n.t("mainView.benefits");
          break;
        case 4:
          title = I18n.t("mainView.points");
          break;
        default:
          title = I18n.t("mainView.nextEvents");
      }
    } else {
      switch (index) {
        case 0:
          title = I18n.t("mainView.myBoughts");
          break;
        case 1:
          title = "Eventos";
          break;
        case 2:
          title = I18n.t("mainView.profile");
          break;
        default:
          title = I18n.t("mainView.nextEvents");
      }
    }
    this.setState({ screenChoose: index, title });
  }

  openDrawer() {
    AppMem.openDrawer();
  }

  closeDrawer() {
    AppMem.closeDrawer();
  }

  getHeaderProps() {
    let { user } = this.props;
    let props = {
      titleStyle: {
        fontFamily: Fonts.MEDIUM,
        fontSize: L.h(20),
        color: Colors.mainTitleColor,
        top: Platform.OS === "ios" ? TOP_PADDING_IOS_FONT : 0,
      },
      rightButtons:
        this.state.screenChoose == 2 || this.state.screenChoose == 0
          ? user && user.proDNI
            ? [
                {
                  onPress: Actions.notifications,
                  icon: {
                    name: "bell",
                    style: {
                      color: Colors.notificationsHomeIcon,
                      fontSize: L.h(22),
                    },
                    //textStyle: { fontSize: L.h(18), color: "black" },
                  },
                  noti: true,
                  // image: this.props.order,
                },
              ]
            : []
          : user && user.proDNI
          ? [
              {
                onPress: this.orderEvents,
                icon: {
                  name: "funnel",
                  style: { color: "white", fontSize: L.h(30) },
                  // textStyle: { fontSize: L.h(18), color: "black" },
                },
                image: this.props.order,
              },
              {
                onPress: Actions.notifications,
                icon: {
                  name: "bell",
                  style: {
                    color: Colors.notificationsHomeIcon,
                    fontSize: L.h(22),
                  },
                  //textStyle: { fontSize: L.h(18), color: "black" },
                },
                noti: true,
                // image: this.props.order,
              },
            ]
          : [
              {
                onPress: this.orderEvents,
                icon: {
                  name: "funnel",
                  style: { color: "white", fontSize: L.h(30) },
                  // textStyle: { fontSize: L.h(18), color: "black" },
                },
                image: this.props.order,
              },
            ],
      title: this.state.title,
      home: true,
      moreButtonColor: ThemeColors.primary1Color,
      isMainHome: this.state.screenChoose == 1,
      no_reads: this.props.no_reads_menu,
    };
    return props;
  }

  orderEvents() {
    let { events, order } = this.props;
    this.props.orderEvents(events, order);
  }

  renderScreen() {
    let { claims } = this.props;
    let selectedScreen = this.state.screenChoose;
    if (Config.HAS_LOYALY_PROGRAM === "active" && Platform.OS != 'web') { //En Web no mostramos puntos
      switch (this.state.screenChoose) {
        case 0:
          selectedScreen = <ShopMain />;
          break;
        case 1:
          selectedScreen =
            claims && claims.rrppId ? (
              <MainRRPP rrppId={claims.rrppId} />
            ) : claims && claims.producerId ? (
              <ProducerStatics producerId={claims.producerId} />
            ) : (
              <Home />
            );
          break;
        case 2:
          selectedScreen = <Profile />;
          break;
        case 3:
          selectedScreen = <Beneficios />;
          break;
        case 4:
          selectedScreen = <WalletMain />;
          break;
        default:
          selectedScreen;
      }
    } else {
      switch (this.state.screenChoose) {
        case 0:
          selectedScreen = <ShopMain />;
          break;
        case 1:
          selectedScreen =
            claims && claims.rrppId ? (
              <MainRRPP rrppId={claims.rrppId} />
            ) : claims && claims.producerId ? (
              <ProducerStatics producerId={claims.producerId} />
            ) : (
              <Home />
            );
          break;
        case 2:
          selectedScreen = <Profile />;
          break;
        default:
          selectedScreen;
      }
    }

    return selectedScreen;
  }

  onPressMainOption(option) {
    let index = "";
    switch (option) {
      case "contact":
        index = 0;
        break;
      case "home":
        index = 1;
        break;
      case "profile":
        index = 2;
        break;
      default:
        index = 1;
    }

    this.refs.footerTab.onPressTab(index);
  }

  renderHeader() {
    return <VTHeader {...this.getHeaderProps()} />;
  }

  render() {
    let { nextEvents } = this.props;
    let qntity = 0;
    let actualDate = Math.floor(Date.now() / 1000);
    _.map(nextEvents, (eachEvent) => {
      if (eachEvent?.evtDateTime?.seconds > actualDate) {
        qntity += eachEvent?.tickets?.length;
      }
    });
    return (
      <Container style={{ backgroundColor:'#0a1e5e' }}>
        {this.renderHeaderWeb()}
        {this.renderScreen()}
        <VTFooterTabs
          ref={(footerTab) => {
            this.footerTab = footerTab;
          }}
          onPressTab={this.onPressTab}
          backgroundColor="white"
          selectedStyle={{ backgroundColor: Colors.footerTabBackground }}
          unselectedStyle={{ backgroundColor: Colors.footerTabBackground }}
          ticketsQntity={qntity}
          screenChoose={this.state.screenChoose}
          contentSelectedColor={Colors.footerTabSelected}
          contentUnSelectedColor={"grey"}
        />
      </Container>
    );

    /*
    <Container style={{ backgroundColor: Colors.homeBackgroundColor }}>
        {this.renderHeader()}
        <View style={{ flex: 1 }}>{this.renderScreen()}</View>
        <VTFooterTabs
          ref={(footerTab) => {
            this.footerTab = footerTab;
          }}
          onPressTab={this.onPressTab}
          backgroundColor="white"
          selectedStyle={{ backgroundColor: Colors.footerTabBackground }}
          unselectedStyle={{ backgroundColor: Colors.footerTabBackground }}
          ticketsQntity={qntity}
          screenChoose={this.state.screenChoose}
          contentSelectedColor={Colors.footerTabSelected}
          contentUnSelectedColor={"grey"}
        />
      </Container>
      */
  }



  renderHeaderWeb(){


    return (<header class="header-section">
    <div class="container">
        <div class="header-wrapper">
            <div class="logo">
                <a href="index.html">
                    <img src="./assets/images/logo/logo.png" alt="logo"/>
                </a>
            </div>
            <ul class="menu">
                <li>
                    <a onClick={()=> this.onPressTab(1)}>EVENTOS</a>
                </li>
                <li>
                    <a onClick={()=> this.onPressTab(0)}>MIS COMPRAS</a>
                </li>
                <li>
                    <a onClick={()=> this.onPressTab(2)}>MI PERFIL</a>
                </li> 
                <li class="header-button pr-0">
                    <a href="sign-up.html">REGISTRARSE</a>
                </li>
            </ul>
            <div class="header-bar d-lg-none">
      <span></span>
      <span></span>
      <span></span>
    </div>
        </div>
    </div>
</header>);

  }


}

function mapStateToProps({ appReducer, authReducer, notificationsReducer }) {
  return {
    nextEvents: appReducer.nextEvents,
    anonymousMode: appReducer.anonymousMode,
    claims: authReducer.claims,
    events: appReducer.events,
    order: appReducer.order,
    user: authReducer.user,
    no_reads_menu: notificationsReducer.no_reads_menu,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getMyNextEvents,
      getMyLoyalyProgram,
      getLoyalyUseMethods,
      getRRPPs,
      orderEvents,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainView);
