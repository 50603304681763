import firebase from 'react-native-firebase';
import * as actions from './ActionTypes';
import AuthService from '../provider/auth/AuthService';
import AppMem from '../common/AppMem';
import Util from '../common/Util';
import AsyncStorage from '@react-native-async-storage/async-storage';

export function changePassword(password, newPassword, callbackData) {
  return dispatch => {
    asyncChangePassword(password, newPassword)
      .then(data => {
        callbackData(data);
      })
      .catch(e => {
        callbackData(e);
      });
  };
}

export function onDeleteAccountPress(callback) {
  return (dispatch, getStore) => {
    AuthService.deleteAccount()
      .then(data => {
        callback(data);
      })
      .catch(e => {
        callback({ error: e });
      });
  };
}

export function changeEmail(newEmail, password, callback) {
  return dispatch => {
    asyncChangeEmail(newEmail, password)
      .then(data => {
        callback(data);
      })
      .catch(e => {
        callback(e);
      });
  };
}

async function asyncChangePassword(password, newPassword) {
  let response = await AuthService.changePassword(password, newPassword); // Si vamos a guardar datos aca tenemos que limpiar
  return response;
}
async function asyncChangeEmail(newMail, password) {
  try {
    let response = await AuthService.changeEmail(newMail, password); // Si vamos a guardar datos aca tenemos que limpiar
    return response;
  } catch (error) {
    return error;
  }
}

export function actVerifyDni(data, callback) {
  return () => {
    AuthService.verifyDni(data)
      .then(res => {
        callback && callback(res);
      })
      .catch(e => {
        console.log(' >>>>>>>>>> Error al guardar token fcm ', e);
        callback && callback(e);
      });
  };
}

export function actLogout(onSuccess) {
  let { currentUser } = firebase.auth();
  return dispatch => {
    // AuthService.saveProfileData(
    //   { FCMToken: null },
    //   currentUser && currentUser.uid,
    //   null
    // ).then((data) => {
    let allListeners = AppMem.getGlobalListener;
    allListeners.map(eachListener => {
      eachListener();
    });
    AppMem.setGlobalListener && AppMem.setGlobalListener([]);
    AuthService.signOut().then(() => {
      dispatch({
        type: actions.ACT_SPLASH,
        showingSplash: true,
      });
      dispatch({
        type: 'SAVE_NEXT_EVENTS_INFORMATION',
        nextEvents: {},
      });
      dispatch({
        type: 'ACT_AUTH_LOGOUT',
        user: {},
        claims: {},
        userId: null,
      });
      onSuccess();
    }); // Si vamos a guardar datos aca tenemos que limpiar
    // })
  };
}

const clearAppData = async function() {
  try {
    const keys = await AsyncStorage.getAllKeys();
    await AsyncStorage.multiRemove(keys);
  } catch (error) {
    console.error('Error clearing app data.');
  }
};

export function saveFCMToken(fcmToken) {
  return () => {
    AuthService.saveFCMToken(fcmToken)
      .then(() => {
        //okCallback();
      })
      .catch(e => {
        console.log(' >>>>>>>>>> Error al guardar token fcm ', e);
        //errorCallBack(e);
      });
  };
}

export function listenerToMyProfile(profileId) {
  return (dispatch, getStore) => {
    asyncListenerToMyProfile(profileId, response => {
      dispatch({
        type: 'ACT_AUTH_GET_USER_DATA',
        user: response,
      });
    });
  };
}

export function saveDataToUser(data, okCallback, errorCallBack) {
  return () => {
    try {
      asyncSaveProfileData(data)
        .then(res => okCallback())
        .catch(e => errorCallBack(e));
    } catch (e) {
      console.log('error save user data', e);
    }
  };
}

export function saveProfile(data, okCallback, errorCallBack) {
  return () => {
    try {
      asyncSaveEditProfileData(data)
        .then(res => {
          console.log(' >>>>>>>>>> okCallback');
          okCallback();
        })
        .catch(e => {
          console.log(' >>>>>>>>>> errorCallBack', e);
          errorCallBack(e);
        });
    } catch (e) {
      console.log('error save user data', e);
    }
  };
}

export function saveDNIDataToUser(data, okCallback, errorCallBack) {
  return () => {
    AuthService.saveDNIData(data)
      .then(() => {
        okCallback();
      })
      .catch(e => {
        errorCallBack(e);
      });
  };
}

export function saveEmailDataToUser(data, okCallback, errorCallBack) {
  return () => {
    AuthService.saveEmailData(data)
      .then(() => {
        okCallback && okCallback();
      })
      .catch(e => {
        console.log(' >>>>>>>>>> inte', e);
        errorCallBack && errorCallBack(e);
      });
  };
}

async function asyncSaveProfileData(data) {
  try {
    return await AuthService.saveProfileData(data);
  } catch (e) {
    console.log('ERROR profile', e);
    return e;
  }
}

async function asyncSaveEditProfileData(data) {
  try {
    return await AuthService.saveEditProfileData(data);
  } catch (e) {
    console.log('ERROR profile', e);
    throw e;
  }
}

async function asyncListenerToMyProfile(profileId, callback) {
  try {
    let response = await AuthService.listenerProfileById(profileId, callback);
    return response;
  } catch (error) {}
}

export function actValidateToken(token, onSuccessCallBack, onErrorCallback) {
  return dispatch => {
    AuthService.validateTokenService(token)
      .then(user => {
        dispatch({
          type: actions.ACT_USER_DATA,
          userData: user.results[0],
          tokenOK: true,
        });
        onSuccessCallBack && onSuccessCallBack();
      })
      .catch(e => {
        console.log('====================================');
        console.log('ValidateTokenError', e);
        console.log('====================================');
        onErrorCallback && onErrorCallback(e);
      });
  };
}

export function splash(bool = false) {
  return dispatch => {
    dispatch({
      type: actions.ACT_SPLASH,
      showingSplash: bool,
    });
  };
}

export function actLogin(user, successCallback, errorCallback) {
  return dispatch => {
    AuthService.login(user.email, user.password)
      .then(async user => {
        addFCMToken(user._user.uid);
        asyncGetUser(user._user.uid).then(userData => {
          dispatch({
            type: 'ACT_AUTH_GET_USER_DATA',
            userId: user._user.uid,
            user: userData,
          });
          successCallback(user);
        });
      })
      .catch(e => {
        errorCallback && errorCallback(e);
      });
  };
}

export function actSetClaims() {
  return async dispatch => {
    let claims = await Util.getCurrentClaims();
    dispatch({
      type: actions.ACT_AUTH_USER_CHANGE,
      props: {
        claims,
      },
    });
  };
}

async function asyncGetUser(userId) {
  try {
    return await AuthService.getUserData(userId);
  } catch (e) {
    console.log(' >>>>>>>>>> getUserData ', e);
  }
}

export function getUserData(userId, successCallback, errorCallback) {
  return dispatch =>
    asyncGetUser(userId)
      .then(resp => {
        // console.log(" >>>>>>>>>> resp", resp);
        dispatch({
          type: 'ACT_AUTH_GET_USER_DATA',
          userId: userId,
          user: resp,
        });
        successCallback && successCallback(resp);
      })
      .catch(e => {
        console.log(' >>>>>>>>>>  error get user data', e);
        errorCallback && errorCallback(e);
      });
}

export function addFCMToken(useriD) {
  return dispatch => {
    try {
      AuthService.addFCMToken(useriD);
    } catch (e) {
      console.log('error al obtener el token', e);
    }
  };
}

export function actRegister(user, successCallback, errorCallback) {
  return () => {
    AuthService.createAccount(user)
      .then(user => {
        AuthService.sendVerificationEmail();
        addFCMToken(user._user.uid);
        successCallback && successCallback(user);
      })
      .catch(err => {
        console.log('errerrerrerrerrerr', err);
        errorCallback(err);
      });
  };
}

export function actCreateSocialAccount(user, successCallback, errorCallback) {
  return () => {
    AuthService.createSocialAccount(user)
      .then(user => {
        //AuthService.sendVerificationEmail();
        addFCMToken(firebase.auth().currentUser._user.uid);
        successCallback && successCallback(user);
      })
      .catch(err => {
        console.log('errerrerrerrerrerr', err);
        errorCallback(err);
      });
  };
}

export function codeGeneratorActions(
  phone,
  countryCode,
  callback,
  errorCallback
) {
  return dispatch => {
    AuthService.codeGenerator(phone, countryCode)
      .then(response => {
        dispatch({
          type: actions.ACT_AUTH_USER_CHANGE,
          props: { code: response.code, sended_phone: phone },
        });
        callback && callback();
      })
      .catch(e => {
        console.log(' >>>>>>>>>> error al generar codigo', e);
        errorCallback && errorCallback(e);
      });
  };
}

export function validateCode(code, callback) {
  return dispatch => {
    AuthService.validateCode(code)
      .then(() => {
        dispatch({
          type: actions.ACT_AUTH_USER_CHANGE,
          props: { code: code },
        });
        callback && callback(true);
      })
      .catch(e => {
        console.log(' >>>>>>>>>> error al generar codigo', e);
        callback && callback(false);
      });
  };
}

export function codeValidationActions(code, phone, callback) {
  return dispatch => {
    asyncCodeValidation(code, response => {
      dispatch({ type: CODE_VALIDATION, payload: response });
      if (response) {
        AppAsync.setItem('phone', phone).then(() => {
          callback && callback(true);
        });
      } else {
        callback && callback(false);
      }
    });
  };
}

async function asyncCodeValidation(code, callback) {
  try {
    await AuthService.codeValidation(code, res => {
      callback(res);
    });
  } catch (error) {
    callback(false);
  }
}

export function sendAgainVerificationEmail(callback) {
  return dispatch => {
    try {
      AuthService.sendVerificationEmail()
        .then(() => {
          callback && callback();
        })
        .catch(e => {
          callback && callback(e);
        });
    } catch (e) {
      console.log('error al obtener el token', e);
      callback && callback(e);
    }
  };
}
export function actForgotPassword(email, successCallback, errorCallback) {
  return dispatch => {
    AuthService.passwordRecovery(email)
      .then(resp => {
        successCallback();
      })
      .catch(err => {
        errorCallback(err);
      });
  };
}
