import React, { Component } from "react";
import {
  StyleSheet,
  View,
  Text,
  TouchableOpacity,
  TextInput,
} from "react-native";
import { Input, Label } from "native-base"; //WEB99 Item lo cambie por View
import IconMA from "react-native-vector-icons/MaterialIcons";
import LinearGradient from "react-native-linear-gradient";
import Fonts from "../../common/Fonts";
import L from "../../common/Layout";
import Colors from "../../common/Colors";
 

var styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    width: "100%",
  },
  button: {
    padding: L.hProm(12),
    justifyContent: "center",
    alignItems: "center",
  },
  buttonCancel: {
    borderRightWidth: 1,
    borderColor: "rgb(221, 222, 222)",
    flex: 0.99,
  },
  buttonText: {
    fontSize: L.h(18),
    color: "rgb(240, 61, 68)",
    fontFamily: Fonts.MEDIUM,
    textAlign: "center",
  },
  modalContainer: {
    flex: 1,
    width: "100%",
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  linearGradient: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 3,
  },
  iconContainer: {
    backgroundColor: "rgba(255,255,255,0.1)",
    width: L.wProm(40),
    height: L.hProm(40),
    borderRadius: L.hProm(40) / 2,
    alignItems: "center",
    justifyContent: "center",
  },
  iconStyle: {
    color: "white",
    fontSize: L.h(30),
  },
  modalMsg: {
    alignSelf: "center",
    color: "rgb(98, 101, 101)",
    fontSize: L.h(16),
    fontFamily: Fonts.MEDIUM,
    textAlign: "center",
  },
  bodyContainer: {
    flex: 1.3,
    alignItems: "center",
    justifyContent: "center",
    padding: L.hProm(8),
  },
  modalHeader: {
    height: L.hProm(58),
    borderTopLeftRadius: 3,
    borderTopRightRadius: 3,
  },
  footerContainer: {
    height: L.hProm(48),
    alignItems: "center",
    justifyContent: "center",
    borderTopWidth: 1,
    borderTopColor: "rgb(221, 222, 222)",
    flexDirection: "row",
    alignItems: "center",
  },
});
const icon = {
  error: "times-circle-o",
  success: "check-square-o",
  warning: "exclamation-triangle",
};
class VTInputModal extends Component {
  _renderButton = (text, onPress, color) => (
    <TouchableOpacity onPress={onPress} style={{ flex: 1, width: "100%" }}>
      <View style={styles.button}>
        <Text style={[styles.buttonText, { color: color }]} numberOfLines={1}>
          {text}
        </Text>
      </View>
    </TouchableOpacity>
  );

  componentDidMount() {
    // this.refs["inputRef0"].focus();
  }

  _renderButtonCancel = (text, onPress, color) => (
    <TouchableOpacity onPress={onPress} style={{ flex: 1, width: "100%" }}>
      <View style={[styles.button, styles.buttonCancel]}>
        <Text
          style={[styles.buttonText, { color: "rgb(98, 101, 101)" }]}
          numberOfLines={1}
        >
          {text}
        </Text>
      </View>
    </TouchableOpacity>
  );

  renderItem(input, i) {
    let widthP = 80 / (i + 1) + "%";
    return (
      <View
        style={{ width: input.width, marginTop: L.h(16), marginRight: L.w(4) }}
        key={i}
      >
        <Input {...input} ref={"inputRef" + i} />
      </View>
    );
  }

  renderInputs(input) {
    let inputList = [];
    if (input && input.length) {
      input.forEach((element, i) => {
        inputList.push(this.renderItem(element, i));
      });
      return (
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {inputList}
        </View>
      );
    } else {
      return (
        <Item style={{ width: "80%", marginTop: L.h(16) }}>
          <Input {...input} />
        </Item>
      );
    }
  }

  _renderModalContent(modalMsg, buttonOk, buttonCancel, close, type, input) {
    let iconName = "";
    let primaryColor = "";
    let secondaryColor = "";
    switch (type) {
      case "error":
        iconName = "cancel";
        primaryColor = "rgb(240, 61, 68)";
        secondaryColor = "rgb(174, 24, 30)";
        c;
        break;
      case "warning":
        iconName = "error";
        primaryColor = "rgb(255, 188, 0)";
        secondaryColor = "rgb(204, 140, 0)";
        break;
      case "success":
        iconName = "check-circle";
        primaryColor = "rgb(40, 185, 129)";
        secondaryColor = "rgb(12, 113, 48)";
        break;
      case "primary":
        iconName = "equalizer";
        primaryColor = Colors.primaryMainModal;
        secondaryColor = Colors.secondaryMainModal;
        break;
      default:
        iconName = "close-circle";
        primaryColor = "rgb(240, 61, 68)";
        secondaryColor = "rgb(174, 24, 30)";
        break;
    }
    return (
      <View style={styles.modalContainer}>
        <View style={[styles.modalHeader, { backgroundColor: primaryColor }]}>
          <LinearGradient
            colors={[primaryColor, secondaryColor]}
            style={styles.linearGradient}
            start={{ x: 0, y: 0 }}
            end={{ x: 1, y: 0 }}
          >
            <View style={styles.iconContainer}>
              <IconMA style={styles.iconStyle} name={iconName} />
            </View>
          </LinearGradient>
        </View>
        <View style={styles.bodyContainer}>
          <Text style={styles.modalMsg}>{modalMsg}</Text>
          {this.renderInputs(input)}
        </View>
        <View style={styles.footerContainer}>
          {buttonCancel
            ? this._renderButtonCancel(
                buttonCancel.text,
                () => {
                  buttonCancel.action();
                  close();
                },
                primaryColor
              )
            : null}
          {this._renderButton(
            buttonOk.text,
            () => {
              buttonOk.action && buttonOk.action();
            },
            primaryColor
          )}
        </View>
      </View>
    );
  }
  render() {
    const { title, buttonOk, buttonCancel, close, type, input } = this.props;
    return (
      <View style={styles.container}>
        {this._renderModalContent(
          title,
          buttonOk,
          buttonCancel,
          close,
          type,
          input
        )}
      </View>
    );
  }
}

export default VTInputModal;
