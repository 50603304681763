import * as actions from "../actions/ActionTypes";

const initialState = {
  eventRRPPStats: null,
  giftAvailable: false,
};

export default function rrppReducer(state = initialState, action) {
  if (action.type === "ACT_RRPP_CHANGE") {
    return Object.assign({}, state, action.props);
  }
  return state;
}
