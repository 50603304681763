
import Config from "react-native-config";

let _env = Config.APP_ENVIRONMENT;

class Settings {
  
  static getTicketeraId() {
    return Config.TICKETERA_ID;
  }
  static getTenantHasBirthdateMigrated() {
    return Config.TENANT_HAS_BIRTHDATE_MIGRATED;
  }

  static CloudFunctions() {
    return Config.API_BASE;
  }

  static get environment() {
    return _env;
  }
}

export default  Settings;
