import * as actions from "../actions/ActionTypes";
import _ from "lodash";
const initialState = {
  notifications: [],
  no_reads: 0,
  no_reads_menu: 0,
};

export default function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.CHANGE_APP:
      return Object.assign({}, state, action.props);
    default:
      return state;
  }
}
