import { combineReducers } from "redux";

import { reducer as formReducer } from "redux-form";
import appReducer from "./AppReducer";
import authReducer from "./AuthReducer";
import producerReducer from "./ProducerReducer";
import rrppReducer from "./RRPPReducer";
import notificationsReducer from "./NotificationsReducer";

const rootReducer = combineReducers({
  appReducer,
  authReducer,
  producerReducer,
  rrppReducer,
  notificationsReducer,
  form: formReducer,
});

export default rootReducer;
