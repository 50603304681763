import React, { createRef } from 'react';
import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  Keyboard,
  ToastAndroid,
  ActivityIndicator,
  Platform,
} from 'react-native';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Icon, Toast } from 'native-base'; //WEB99 Body
import AppMem from '../common/AppMem';
import Util from '../common/Util';
import Colors from '../common/Colors';
import CardTicket from '../components/CardTicket';
import {
  initNotifications,
  getNotifications,
} from '../actions/NotificationsActions';
import { getNotificationData } from '../common/Notifications';
import {
  saveEmailDataToUser,
  saveDNIDataToUser,
  saveFCMToken,
} from '../actions/AuthActions';
import { getEventsCollection } from '../actions/AppActions';
import firebase from 'react-native-firebase';
import L from '../common/Layout';
import Fonts from '../common/Fonts';
import UserExistPopUp from '../components/UserExistPopUp';
import Config from 'react-native-config';
import _ from 'lodash';
import I18n from 'i18next';
import Footer from '../components/web/Footer';
import { Actions } from 'react-native-router-flux';
import HeaderImage from '../common/assets/account-bg.jpg';
import StatisticsImage from '../common/assets/statistics-bg.png';

let screen = Dimensions.get('window');
let numberOfItems = 2;

class Home extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      bigCard: true,
      showModalFilters: false,
      order: 'lastpost',
      limitShow: 99,
      email: null,
      insta: '',
    };
    this.onPressFinish = this.onPressFinish.bind(this);
    this.onChangeTextEmail = this.onChangeTextEmail.bind(this);
    this.errorCallback = this.errorCallback.bind(this);
    this.onPressCloseUserExist = this.onPressCloseUserExist.bind(this);
    this.ticketCallback = this.ticketCallback.bind(this);
    this.owlCarouselRef = createRef();
  }

  componentWillMount() {
    let { user } = this.props;
    this.props.getEventsCollection(null, this.ticketCallback);
    if (AppMem.appLoader) {
      setTimeout(() => {
        AppMem.closeAppLoader();
      }, 1000);
    }
    if (user && user && user.proDNI && !user.proEmail) {
      this.openEmailModal('email');
    } else if (
      user &&
      user &&
      user.proDNI &&
      !user.proInstagram &&
      Platform.OS != 'web'
    ) {
      // En Web no vamos a pedir el IG
      this.openEmailModal('insta');
    }
  }

  async componentDidMount() {
    if (Platform.OS != 'web') {
      let { initNotifications, getNotifications } = this.props;
      let notiData = await getNotificationData();
      initNotifications(notiData);
      getNotifications(() => null);
    }
  }

  ticketCallback() {
    //DO NOTHING
  }

  async getToken() {
    fcmToken = await firebase.messaging().getToken();
    if (fcmToken) {
      //console.log(" >>>>>>>>>> fcmToken", fcmToken);
      this.props.saveFCMToken(fcmToken);
    }
  }

  renderButtonContent(iconName, text) {
    return (
      <View>
        <View style={styles.buttonBody}>
          <Icon name={iconName} style={styles.buttonIcon} />
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.buttonText} numberOfLines={3}>
            {text}
          </Text>
        </View>
      </View>
    );
  }

  openEmailModal(type) {
    let { user } = this.props;
    let insta = type == 'insta';
    let modalProps = {
      title:
        `${I18n.t('home.welcome')}` +
        Config.PRODUCER_NAME +
        '!' +
        '\n' +
        `${insta ? I18n.t('home.addInstagram') : I18n.t('home.addInstagram2')}`,
      type: 'primary',
      buttonOk: {
        text: insta ? I18n.t('home.accept') : I18n.t('home.endRegister'),
        action: () => this.onPressFinish(insta),
      },
      buttonCancel: insta
        ? {
            text: I18n.t('home.cancel'),
            action: () => null,
          }
        : null,
      swipeToClose: insta ? true : false,
      mustBeOpen: true,
      backdropPressToClose: insta ? true : false,
      //buttonCancel: { text: "Cancel", action: () => null },
      input: [
        {
          placeholder: insta ? I18n.t('home.instagram') : I18n.t('home.email'),
          onChangeText: text => this.onChangeTextEmail(text, type),
          placeholderTextColor: 'grey',
          onSubmitEditing: () => this.onPressFinish(insta),
          returnKeyType: 'send',
          width: '85%',
          autoCapitalize: 'none',
          keyboardType: 'email-address',
          style: { fontSize: L.h(16) },
          autoFocus: true,
        },
      ],
    };
    AppMem.openModal('input', modalProps);
  }

  onChangeTextEmail(value, type) {
    this.setState({
      [type]: value,
    });
  }

  onPressFinish(isInstagram) {
    let { email, insta } = this.state;
    let trimValue = email ? email.trim() : null;
    let emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if ((isInstagram && insta) || emailRegex.test(trimValue)) {
      Keyboard.dismiss();
      //AppMem.closeModal();
      AppMem.openAppLoader('bars', {
        text: I18n.t('home.loaderText'),
        color: Colors.secondary_color,
      });
      let data = insta
        ? { proInstagram: Util.trimSpace(insta) }
        : {
            proEmail: Util.trimSpace(email),
          };
      this.props.saveEmailDataToUser(
        data,
        this.successCallback,
        this.errorCallback
      );
    } else {
      if (Util.isIos()) {
        Toast.show({
          text: `${I18n.t('home.addInstagramIOS')}${
            isInstagram ? I18n.t('home.instagram') : I18n.t('home.email')
          } ${I18n.t('home.addInstagramIOS2')}`,
          position: 'top',
          buttonText: I18n.t('home.accept'),
          type: 'danger',
          duration: 5000,
        });
      } else {
        ToastAndroid.showWithGravityAndOffset(
          `${I18n.t('home.addInstagramIOS')}${
            isInstagram ? I18n.t('home.instagram') : I18n.t('home.email')
          } ${I18n.t('home.addInstagramIOS2')}`,
          ToastAndroid.SHORT,
          ToastAndroid.BOTTOM,
          25,
          50
        );
      }
    }
  }

  successCallback() {
    AppMem.closeModal();
    AppMem.closeAppLoader();
    setTimeout(() => {
      let modalProps = {
        title:
          I18n.t('home.successRegister') +
          '\n' +
          I18n.t('home.successRegister2'),
        type: 'success',
        buttonOk: { text: I18n.t('home.start'), action: () => null },
      };
      AppMem.openModal('alert', modalProps);
    }, 200);
  }

  errorCallback(error) {
    AppMem.closeAppLoader();
    if (error) {
      AppMem.openModal(
        <UserExistPopUp onPressClose={this.onPressCloseUserExist} email />,
        {
          style: {
            height: L.hProm(310),
            borderRadius: 12,
            width: screen.width - L.wProm(60),
          },
          swipeToClose: false,
        }
      );
    }
  }

  onPressCloseUserExist() {
    setTimeout(() => {
      this.openEmailModal();
    }, 500);
  }

  renderCardTicket(event) {
    event.item = event; // Agregado en WEB

    let formatedDate = moment(
      Util.timestampToDate(event.item.evtDateTime.seconds)
    ).format('DD/MM/YYYY');
    let today = new Date();
    let evtState = event.item.evtState === 'active';
    let activeEvent =
      moment(today).isAfter(
        Util.timestampToDate(event.item.evtInitDate.seconds)
      ) &&
      moment(today).isBefore(
        Util.timestampToDate(event.item.evtEndDate.seconds)
      );

    if (activeEvent && evtState) {
      return (
        <CardTicket
          key="ID ID"
          ticketId="ID ID"
          title={event.item.evtTitle}
          author="Lucas Spinozzi"
          numRows={1}
          activeEvent={activeEvent && evtState}
          id={event.item.id}
          place={event.item.evtPlace}
          date={formatedDate}
          img={event.item.evtImg}
          event={event.item}
          disabledBuy={true}
        />
      );
    }
  }

  renderEventItemWeb(event) {
    //Agregado en WEB
    event.item = event;
    let formatedDay = moment(
      Util.timestampToDate(event.item.evtDateTime.seconds)
    ).format('DD');
    let formatedMonth = moment(
      Util.timestampToDate(event.item.evtDateTime.seconds)
    ).format('MMM');
    let today = new Date();
    let evtState = event.item.evtState === 'active';
    let activeEvent =
      moment(today).isAfter(
        Util.timestampToDate(event.item.evtInitDate.seconds)
      ) &&
      moment(today).isBefore(
        Util.timestampToDate(event.item.evtEndDate.seconds)
      );

    if (activeEvent && evtState) {
      return (
        <div
          className="col-sm-6 col-lg-4"
          onClick={() => Actions.push('ticketDetail', { event: event })}
        >
          <div className="event-grid">
            <div className="movie-thumb c-thumb">
              <a style={{ cursor: 'pointer' }}>
                <img
                  src={event.item.evtImg}
                  alt="event"
                  style={{ height: 200 }}
                />
              </a>
              {/* <div className="event-date">
                <h6 className="date-title">{formatedDay}</h6>
                <span>{formatedMonth}</span>
              </div> */}
            </div>
            <div
              className="movie-content bg-one"
              style={{ backgroundColor: Colors.white }}
            >
              <h5 className="title m-0">
                <a style={{ color: 'black', cursor: 'pointer' }}>
                  {event.item.evtTitle}
                </a>
              </h5>
              <div className="movie-rating-percent">
                <span style={{ color: 'black' }}>{event.item.evtPlace}</span>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  componentDidUpdate(prevProps) {
    // Comprobar si los datos han cambiado antes de actualizar Owl Carousel
    if (prevProps.events !== this.props.events) {
      // Actualizar Owl Carousel
      this.inicializarOwlCarousel();
    }
  }

  inicializarOwlCarousel() {
    // Verificar que la referencia sea válida antes de usarla
    if (this.owlCarouselRef.current) {
      $(this.owlCarouselRef.current).owlCarousel({
        //  loop: true,
        margin: 10,
        responsiveClass: true,
        autoplay: true,
      });
    }
  }

  renderEvt(event) {
    let day = moment(Util.timestampToDate(event.evtDateTime.seconds)).format(
      'DD'
    );
    let month = moment(Util.timestampToDate(event.evtDateTime.seconds)).format(
      'MMM'
    );
    // let hours = moment(Util.timestampToDate(event.evtDateTime.seconds)).format(
    //   'HH:ss'
    // );
    // let dayString = moment(
    //   Util.timestampToDate(event.evtDateTime.seconds)
    // ).format('dddd');
    let today = new Date();
    let evtState = event.evtState === 'active';
    let activeEvent =
      moment(today).isAfter(Util.timestampToDate(event.evtInitDate.seconds)) &&
      moment(today).isBefore(Util.timestampToDate(event.evtEndDate.seconds));

    let { proTags = [] } = this.props.user;
    let { evtTags = [] } = event;
    if (evtTags.length > 0) {
      let res = proTags.filter(item =>
        evtTags.find(each => each.id == item.id)
      );
      if (res.length == 0) {
        return;
      }
    }
    if (activeEvent && evtState) {
      return (
        <div class="col-sm-6 col-lg-4" style={{ cursor: 'pointer' }}>
          <div class="event-grid">
            <div class="movie-thumb c-thumb">
              <a onClick={() => Actions.push('ticketDetail', { event: event })}>
                <img src={event.evtImg} alt="event" />
              </a>
              {/* <div class="event-date">
                <h6 class="date-title">{day}</h6>
                <span>{month}</span>
              </div> */}
            </div>
            <div class="movie-content bg-one">
              <h5 class="title m-0">
                <a
                  onClick={() => Actions.push('ticketDetail', { event: event })}
                >
                  {event.evtTitle}
                </a>
              </h5>
              <div class="movie-rating-percent">
                <span>{event.evtPlace}</span>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  renderEvtWrapper(events) {
    return (
      <div class="row mb-10 justify-content-left">
        {_.map(events, (ev, index) => this.renderEvt(ev, index))}
      </div>
    );
  }

  render() {
    let { events = null } = this.props;
    //  console.log('RE RENDER EN WEB', events);
    let today = new Date();
    let isActiveAndDated = _.filter(events, ev => {
      if (
        ev.evtState === 'active' &&
        moment(today).isAfter(Util.timestampToDate(ev.evtInitDate.seconds)) &&
        moment(today).isBefore(Util.timestampToDate(ev.evtEndDate.seconds))
      ) {
        return ev;
      }
    });
    return (
      <div
        style={{
          flex: 1,
          backgroundColor: Colors.backgroundHistory,
        }}
      >
        {/* <!-- ==========Banner-Section========== --> */}
        <section className="banner-section">
          <div
            className="banner-bg bg_img bg-fixed"
            style={{ backgroundImage: `url(${HeaderImage})` }}
            // data-background="../common/assets/web/aditya-chinchure-ZhQCZjr9fHo-unsplash.jpg"
          />
          <div className="container">
            <div className="banner-content">
              <h1 className="title  cd-headline clip">
                <span className="d-block">Tu lugar</span> para la coctelería{' '}
                <span className="color-theme cd-words-wrapper p-0 m-0">
                  <b className="is-visible">en la Argentina</b>
                  <b> en la Argentina</b>
                </span>
              </h1>
              <p>Seguro y confiable.</p> 
            </div>
          </div>
        </section>
        {/* <!-- ==========Banner-Section========== --> */}
        <section className="padding-top">
          <div className="container">
            <div className="article-section padding-bottom">
              {/* <div className="section-header-1">
                <h2 className="title">eventos</h2>
              </div> */}
              <div className="section-header-2">
                <div className="left">
                  <h2 className="title">Eventos</h2>
                  <p>¡Bienvenido a tu destino de experiencias únicas!</p>
                </div>
                {events ? (
                  <ul className="tab-menu">
                    {/* <li className="active">Nuevos</li> */}
                    {/* <li>Próximamente</li>
                    <li>Exclusivos</li> */}
                  </ul>
                ) : null}
              </div>
              <div className="tab-area mb-30-none">
                <div className="tab-item active">
                  {events && isActiveAndDated.length > 0 ? (
                    this.renderEvtWrapper(events)
                  ) : (
                    <p>No hay eventos disponibles.</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section
          class="statistics-section padding-top padding-bottom bg_img pb-lg-0"
          // data-background="./assets/images/statistics/statistics-bg01.jpg"
          style={{ backgroundImage: `url(${StatisticsImage})` }}
        >
          <div class="container">
            <div class="section-header-3">
              <span class="cate">¡Gracias!</span>
              <h2 class="title">Juntos hacemos BACW</h2>
              <p>
                Gracias a ustedes, seguimos haciendo una gran comunidad BACW
              </p>
            </div>
            <div class="statistics-wrapper">
              <div class="row mb--20">
                <div class="col-md-4">
                  <div class="stat-item">
                    <div class="stat-thumb">
                      <img
                        src="./assets/images/statistics/stat01.png"
                        alt="statistics"
                      />
                    </div>
                    <div class="stat-content">
                      <h3
                        class="counter-item odometer odometer-auto-theme"
                        data-odometer-final="40.000"
                      >
                        <div class="odometer-inside">
                          <span class="odometer-digit">
                            <span class="odometer-digit-spacer">8</span>
                            <span class="odometer-digit-inner">
                              <span class="odometer-ribbon">
                                <span class="odometer-ribbon-inner">
                                  <span class="odometer-value">+</span>
                                </span>
                              </span>
                            </span>
                          </span>
                          <span class="odometer-digit">
                            <span class="odometer-digit-spacer">8</span>
                            <span class="odometer-digit-inner">
                              <span class="odometer-ribbon">
                                <span class="odometer-ribbon-inner">
                                  <span class="odometer-value">4</span>
                                </span>
                              </span>
                            </span>
                          </span>
                          <span class="odometer-digit">
                            <span class="odometer-digit-spacer">8</span>
                            <span class="odometer-digit-inner">
                              <span class="odometer-ribbon">
                                <span class="odometer-ribbon-inner">
                                  <span class="odometer-value">0</span>
                                </span>
                              </span>
                            </span>
                          </span>
                          <span class="odometer-digit">
                            <span class="odometer-digit-spacer">8</span>
                            <span class="odometer-digit-inner">
                              <span class="odometer-ribbon">
                                <span class="odometer-ribbon-inner">
                                  <span class="odometer-value">0</span>
                                </span>
                              </span>
                            </span>
                          </span>
                          <span class="odometer-digit">
                            <span class="odometer-digit-spacer">8</span>
                            <span class="odometer-digit-inner">
                              <span class="odometer-ribbon">
                                <span class="odometer-ribbon-inner">
                                  <span class="odometer-value">0</span>
                                </span>
                              </span>
                            </span>
                          </span>
                          <span class="odometer-digit">
                            <span class="odometer-digit-spacer">8</span>
                            <span class="odometer-digit-inner">
                              <span class="odometer-ribbon">
                                <span class="odometer-ribbon-inner">
                                  <span class="odometer-value">0</span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                      </h3>
                      <span class="info">Tickets online</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="stat-item">
                    <div class="stat-thumb">
                      <img
                        src="./assets/images/statistics/stat02.png"
                        alt="statistics"
                      />
                    </div>
                    <div class="stat-content">
                      <h3
                        class="counter-item odometer odometer-auto-theme"
                        data-odometer-final="+5.000"
                      >
                        <div class="odometer-inside">
                          <span class="odometer-digit">
                            <span class="odometer-digit-spacer">8</span>
                            <span class="odometer-digit-inner">
                              <span class="odometer-ribbon">
                                <span class="odometer-ribbon-inner">
                                  <span class="odometer-value">+</span>
                                </span>
                              </span>
                            </span>
                          </span>
                          <span class="odometer-digit">
                            <span class="odometer-digit-spacer">8</span>
                            <span class="odometer-digit-inner">
                              <span class="odometer-ribbon">
                                <span class="odometer-ribbon-inner">
                                  <span class="odometer-value">5</span>
                                </span>
                              </span>
                            </span>
                          </span>
                          <span class="odometer-digit">
                            <span class="odometer-digit-spacer">8</span>
                            <span class="odometer-digit-inner">
                              <span class="odometer-ribbon">
                                <span class="odometer-ribbon-inner">
                                  <span class="odometer-value">0</span>
                                </span>
                              </span>
                            </span>
                          </span>
                          <span class="odometer-digit">
                            <span class="odometer-digit-spacer">8</span>
                            <span class="odometer-digit-inner">
                              <span class="odometer-ribbon">
                                <span class="odometer-ribbon-inner">
                                  <span class="odometer-value">0</span>
                                </span>
                              </span>
                            </span>
                          </span>
                          <span class="odometer-digit">
                            <span class="odometer-digit-spacer">8</span>
                            <span class="odometer-digit-inner">
                              <span class="odometer-ribbon">
                                <span class="odometer-ribbon-inner">
                                  <span class="odometer-value">0</span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                      </h3>
                      <span class="info">Eventos en BACW</span>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="stat-item">
                    <div class="stat-thumb">
                      <img
                        src="./assets/images/statistics/stat03.png"
                        alt="statistics"
                      />
                    </div>
                    <div class="stat-content">
                      <h3
                        class="counter-item odometer odometer-auto-theme"
                        data-odometer-final="40"
                      >
                        <div class="odometer-inside">
                          <span class="odometer-digit">
                            <span class="odometer-digit-spacer">8</span>
                            <span class="odometer-digit-inner">
                              <span class="odometer-ribbon">
                                <span class="odometer-ribbon-inner">
                                  <span class="odometer-value">4</span>
                                </span>
                              </span>
                            </span>
                          </span>
                          <span class="odometer-digit">
                            <span class="odometer-digit-spacer">8</span>
                            <span class="odometer-digit-inner">
                              <span class="odometer-ribbon">
                                <span class="odometer-ribbon-inner">
                                  <span class="odometer-value">0</span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                      </h3>
                      <span class="info">Artistas en 2024</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <Footer />
      </div>
    );
  }
}

const styles = StyleSheet.create({
  list: {
    flex: 1,
  },
  bgHeader: {
    // backgroundImage: 'url("../common/assets/account-bg.jpg)'
  },
  upperContainer: {
    height: 300,
    width: '100%',
    justifyContent: 'flex-end',
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  bottomContainer: {
    paddingHorizontal: 20,
  },
  buttonStyle: {
    backgroundColor: 'red',
    marginHorizontal: L.w(8),
    marginVertical: L.h(8),
    width: screen.width / numberOfItems - L.w(16),
    height: screen.width / numberOfItems - L.w(16),
    padding: L.hProm(4),
  },
  buttonsWrapper: {
    width: '100%',
    flex: 1,
    alignItems: 'center',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  buttonBody: { flex: 1, justifyContent: 'flex-end', alignItems: 'center' },
  buttonIcon: { fontSize: L.h(32), color: 'white', fontFamily: Fonts.MEDIUM },
  buttonText: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: Fonts.MEDIUM,
    marginTop: L.h(4),
    fontSize: L.h(16),
  },
  finderContainer: {
    height: 40,
    backgroundColor: 'white',
    flexDirection: 'row',
    alignItems: 'center',
    paddingLeft: 5,
    borderRadius: 6,
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.5,
  },
  activity: {
    alignSelf: 'center',
    paddingTop: L.h(50),
    width: '100%',
    height: '100%',
  },
  displayName: {
    color: 'white',
    fontSize: 20,
    alignSelf: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    padding: 4,
    paddingHorizontal: 12,
    borderRadius: 4,
  },
  searchIcon: {
    color: '#4f4f4f',
  },
  sliderMain: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    // height: 20
  },
  slideContainer: {
    flex: 1,
    flexDirection: 'column',
  },
  slideImgBg: {
    height: 120,
    padding: 5,
  },
  slideIcon: {
    alignSelf: 'flex-end',
    backgroundColor: 'transparent',
  },
  slideCategory: {
    fontSize: 10,
    color: '#1BB6B1',
    marginVertical: 5,
  },
  slideInfo: {
    fontSize: 10,
    color: '#4f4f4f',
    marginVertical: 5,
  },
  finderInput: { flex: 2 },
  instructions: {
    textAlign: 'center',
    color: '#333333',
    marginBottom: 5,
  },
  sampleText: {
    fontSize: L.h(24),
    fontFamily: Fonts.MEDIUM,
    color: Colors.black_87,
    marginHorizontal: L.w(8),
    marginTop: L.h(16),
    marginBottom: L.h(8),
  },
});

function mapStateToProps(store) {
  return {
    user: store.authReducer.user,
    events: store.appReducer.events,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getEventsCollection,
      saveEmailDataToUser,
      saveDNIDataToUser,
      saveFCMToken,
      initNotifications,
      getNotifications,
    },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Home);
