import React from "react";
import { Text, StyleSheet, View } from "react-native";
import { Icon, Fab } from "native-base";
import L from "../common/Layout";
import Colors from "../common/Colors";
import CardRowList from "../components/CardRowList";

const CardDataMultiple = ({
  onPress,
  refer,
  iconName,
  iconType,
  title,
  children,
  marginBottom,
  titleBorderRightRadius,
  titleBorderLeftRadius,
  buttonIcon,
  buttonIconDelete,
  document,
  onPressDelete,
  complex,
  visible,
  buttonIconUnAssign,
  isHd,
  onPressUnAssign
}) => {
  const onPressButton = () => {
    onPress(refer);
  };
  let {
    cardContainer,
    titleCardContainer,
    titleCard,
    fabContainer,
    fabStyle,
    fabContainerDelete,
    fabStyleDelete
    //fabContainerAssign
  } = styles;
  let fabContainerAssign = {
    right: buttonIconDelete ? L.hProm(98) : L.w(50),
    padding: L.hProm(0),
    margin: L.hProm(0),
    bottom: L.hProm(10)
  };

  let labelSize =
    buttonIcon && buttonIconDelete && buttonIconUnAssign
      ? "50%"
      : (buttonIcon && buttonIconDelete) || (buttonIcon && buttonIconUnAssign)
      ? "65%"
      : buttonIcon
      ? "80%"
      : document
      ? "60%"
      : "100%";

  return (
    <View
      style={[
        cardContainer,
        {
          marginBottom: marginBottom
        }
      ]}>
      <View
        style={{
          height: buttonIcon || buttonIconDelete ? L.hProm(48) : L.hProm(36),
          //backgroundColor: "red",
          width: "100%",
          justifyContent: "flex-end"
        }}>
        <View
          style={[
            titleCardContainer,
            {
              borderTopRightRadius: titleBorderRightRadius,
              borderTopLeftRadius: titleBorderLeftRadius
            }
          ]}>
          <View
            style={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              borderTopRightRadius: titleBorderRightRadius,
              borderTopLeftRadius: titleBorderLeftRadius,
              backgroundColor: Colors.scanRegisterRowHeader
              // backgroundColor: "blue"
            }}>
            <Text style={[titleCard, { width: labelSize }]} numberOfLines={2}>
              {title}
            </Text>
            {document ? (
              <Text style={[titleCard, { marginRight: L.w(8) }]}>
                {document}
              </Text>
            ) : null}
          </View>
        </View>
        {buttonIcon || buttonIconDelete || buttonIconUnAssign ? (
          <View
            style={{
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "row",
              position: "absolute",
              top: L.h(52),
              right: 0,
              width: "100%"
              //backgroundColor: "yellow"
            }}>
            {buttonIcon ? (
              <Fab
                active={true}
                containerStyle={fabContainer}
                style={fabStyle}
                position="bottomRight"
                onPress={onPress}>
                <Icon
                  name={iconName}
                  type={iconType}
                  style={{ color: Colors.cerulean, fontSize: L.hProm(21) }}
                />
              </Fab>
            ) : null}
            {buttonIconDelete ? (
              <Fab
                active={true}
                containerStyle={fabContainerDelete}
                style={fabStyleDelete}
                position="bottomRight"
                onPress={onPressDelete}>
                <Icon
                  name={isHd ? "lock-open" : "delete"}
                  type={"MaterialIcons"}
                  style={{
                    color: isHd ? "#04AA86" : Colors.pastelRed,
                    fontSize: L.hProm(21)
                  }}
                />
              </Fab>
            ) : null}
            {buttonIconUnAssign ? (
              <Fab
                active={true}
                containerStyle={fabContainerAssign}
                style={fabStyleDelete}
                position="bottomRight"
                onPress={onPressUnAssign}>
                <Icon
                  name={"block"}
                  type={"MaterialIcons"}
                  style={{ color: Colors.pastelRed, fontSize: L.hProm(21) }}
                />
              </Fab>
            ) : null}
          </View>
        ) : null}
      </View>
      <View style={{ backgroundColor: "white" }}>{children}</View>
      {complex ? (
        <CardRowList type="complex" label={false} visible={visible} />
      ) : null}
    </View>
  );
};
CardDataMultiple.defaultProps = {
  iconName: "home",
  title: "Título",
  buttonIcon: true,
  marginBottom: L.hProm(24),
  titleBorderRightRadius: L.hProm(6),
  titleBorderLeftRadius: L.hProm(6)
};

const styles = StyleSheet.create({
  cardContainer: {
    borderTopRightRadius: L.hProm(6),
    borderTopLeftRadius: L.hProm(6),
    borderBottomLeftRadius: L.hProm(6),
    borderBottomRightRadius: L.hProm(6)
  },
  titleCardContainer: {
    backgroundColor: Colors.brightSkyBlue,
    borderTopRightRadius: CardDataMultiple.titleBorderRightRadius,
    borderTopLeftRadius: CardDataMultiple.titleBorderLeftRadius
  },
  titleCard: {
    color: Colors.scanRegisterHeaderTitle,
    fontSize: L.hProm(13),
    marginVertical: L.hProm(8),
    marginLeft: L.wProm(10)
  },
  fabContainer: {
    right: L.hProm(0),
    padding: L.hProm(0),
    margin: L.hProm(0),
    bottom: L.hProm(10)
  },
  fabContainerDelete: {
    right: L.hProm(50),
    padding: L.hProm(0),
    margin: L.hProm(0),
    bottom: L.hProm(10)
  },
  fabContainerAssign: {
    right: L.hProm(98),
    padding: L.hProm(0),
    margin: L.hProm(0),
    bottom: L.hProm(10)
  },
  fabStyle: {
    backgroundColor: "white",
    width: L.wProm(40),
    height: L.hProm(40)
  },
  fabStyleDelete: {
    backgroundColor: "white",
    width: L.wProm(40),
    height: L.hProm(40)
  }
});

export default CardDataMultiple;
