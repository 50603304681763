module.exports = {
  primary_color: "rgb(29, 29, 27)",
  secondary_color: "rgb(192, 161, 96)",
  darkText: "rgb(82,97,107)",
  lightText: "rgb(142,142,147)",
  black: "#000000",
  blackTwo: "#1A1A1A",
  blackThree: "#000000",
  black_08: "#00000014",
  black_10: "#0000001b",
  black_12: "#0000001e",
  black_14: "#00000024",
  black_20: "#00000033",
  black_25: "#00000040",
  black_28: "#00000048",
  black_29: "#0000004a",
  black_38: "#00000061",
  black_54: "#0000008a",
  black_82: "#000000d1",
  black_87: "#000000dd",
  white: "#FFFFFF",
  whiteTwo: "#E5E5E5",
  whiteThree: "#F4F4F4",
  whiteFour: "#F5F5F5",
  whiteFive: "#D9D9D9",
  whiteSix: "#F6F6F6",
  white_10: "#ffffff1a",
  white_30: "#ffffff4d",
  white_40: "#ffffff67",
  white_50: "#ffffff80",
  white_60: "#ffffff9a",
  white_70: "#ffffffb3",
  white_80: "#ffffffcd",
  white_90: "#ffffffe6",

  //Grey
  commonGray: "rgb(84, 84, 84)",
  brownGrey: "rgb(178,178,178)",
  greyishBrown: "#545454",
  greyishBrownTwo: "rgb(66,66,66)",
  greyishBrownThree: "rgb(90,89,86)",
  paleGrey: "rgb(240, 245, 249)",
  pinkishGrey: "#C1C1C1",
  steel: "#8E8E93",
  underlayGrey: "#CCCCCC",

  //Red & Green Colors
  greenOne: "#449C3C",
  trueGreen: "rgb(193, 237, 103)",
  sellGreen: "#449C3C",
  invalid: "#930217",
  red: "rgb(217, 37, 67)",
  redTwo: "rgb(226, 0, 0)",

  //Others
  blueberry: "rgb(41,145,214)",
  cerulean: "rgb(0,140,208)",
  darkishPurple: "rgb(75, 34, 117)",
  darkIndigo: "rgba(34,117,172, 0.85)",
  midBlue: "rgba(34,117,172, 0.85)",
  purpley: "rgb(119,90,218)",
  tangerine: "rgb(255, 131, 24)",
  twilight: "rgb(81,79,146)",
  veryLightPink: "rgb(214,214,214)",

  //MainButtons
  primaryGradientButton: "rgb(192, 161, 96)",
  secondaryGradientButton: "rgb(192, 161, 96)",
  secondaryButtonColor: "rgb(247,130,147)",
  primaryButtonText: "rgb(29, 29, 27)",
  secondaryButtonText: "rgb(29, 29, 27)",
  floatingButtonColor: "rgb(192, 161, 96)",

  //MainFields
  comboLabelColor: "white",
  comboTextColor: "white",

  //Gradient Colors
  gradientColorOne: "rgb(192, 161, 96)",
  gradientColorTwo: "rgb(192, 161, 96)",
  gradientColorThree: "rgb(192, 161, 96)",
  gradientColorFour: "rgb(192, 161, 96)",
  gradientColorFive: "rgb(192, 161, 96)",
  gradientColorSix: "rgb(192, 161, 96)",
  gradientColorSeven: "rgb(192, 161, 96)",
  gradientColorSeparatorOne: "rgb(216, 253, 125)",
  gradientColorSeparatorTwo: "rgb(216, 253, 125)",
  gradientColorSeparatorThree: "rgb(216, 253, 125)",
  gradientColorSeparatorFour: "rgb(216, 253, 125)",
  gradientColorSeparatorFive: "rgb(216, 253, 125)",
  gradientColorSeparatorSix: "rgb(216, 253, 125)",
  gradientColorSeparatorSeven: "rgb(216, 253, 125)",

  //Loginmain
  backgroundLoginMain: "rgb(0, 0, 0)",

  //Footer Colors
  footerTabBackground: "rgb(29, 29, 27)",
  footerTabSelected: "rgb(192, 161, 96)",
  footerSingleTabBackground: "rgb(29, 29, 27)",

  //Header Colors
  mainTitleColor: "rgb(29, 29, 27)",
  borderBottomHeader: "rgb(192, 161, 96)",

  //Divider
  dividerTextOne: "rgb(29, 29, 27)",
  dividerBackgroundOne: "rgb(192, 161, 96)",

  //Login Colors
  omitColor: "white",
  loginFieldLabelColor: "white",
  loginFieldTextColor: "white",
  loginFieldBorderColor: "white",
  loginErrorColor: "rgb(217, 37, 67)",
  loginForgotPassText: "white",
  loginRevealPassIcon: "white",
  loginHint: "white",
  forgotPassText: "white",
  forgotPassBackIcon: "white",
  forgotPassFieldColor: "white",
  forgotPassTextColor: "white",
  forgotPassBorderColor: "white",
  forgotPassErrorColor: "rgb(217, 37, 67)",
  confirmWhatsappCode: "white",

  //Register Colors
  registerTitle: "rgb(29, 29, 27)",
  registerFieldColor: "white",
  registerTextColor: "white",
  registerResendText: "white",
  registerBorderColor: "white",
  registerErrorColor: "rgb(217, 37, 67)",
  textRegisterA: "white",
  textButtonRegisterA: "white",
  textIconRegisterA: "white",
  textRegisterB: "white",
  textRegisterC: "white",
  textButtonRegisterC: "white",
  textIconRegisterC: "white",
  textRegisterD: "white",
  textButtonRegisterD: "white",
  textIconRegisterD: "white",
  textRegisterE: "white",
  textButtonRegisterE: "white",
  textIconRegisterE: "white",
  iconRegisterWhatsapp: "white",
  textRegisterWhatsapp: "white",
  backgroundRegisterF: "rgb(29, 29, 27)",
  textRegisterF: "white",
  scanRegisterRowHeader: "rgb(0, 0, 0)",
  scanRegisterHeaderTitle: "rgb(29, 29, 27)",
  scanRegisterRowLabel: "rgb(0, 0, 0)",
  scanVisibleOk: "rgb(26, 185, 42)",
  scanVisibleNot: "#930217",

  //Home
  homeBackgroundColor: "rgb(29, 29, 27)",
  notificationsHomeIcon: "rgb(29, 29, 27)",

  //Notifications
  notificationsBackgroundColor: "rgb(29, 29, 27)",
  notificationsHeaderBackground: "rgb(192, 161, 96)",
  notificationsTitleColor: "rgb(29, 29, 27)",
  emptyNotificationsText: "white",
  notiMessageColor: "rgb(29, 29, 27)",
  notiDateColor: "rgb(192, 161, 96)",

  //History
  backgroundTabColor: "rgb(33,212,253)",
  textTabColor: "rgb(75,34,117)",
  borderTabColor: "rgb(75,34,117)",
  backgroundHeaderList: "rgb(246, 246, 246)",
  userAccesTime: "rgb(192, 161, 96)",
  backgroundHistory: "rgb(29, 29, 27)",
  eventHistoryBackground: "rgb(29, 29, 27)",
  historyBorderDate: "rgb(192, 161, 96)",
  historyBorderCardEvent: "#00000033",
  historyArrow: "white",
  historyDateColor: "white",
  historyHour: "white",
  historyBorderImage: "rgb(192, 161, 96)",
  historyEventName: "rgb(192, 161, 96)",

  //TicketFinal Colors
  background: "white",
  ticketTitle: "rgb(247,130,147)",
  ticketOwner: "rgb(192, 161, 96)",

  //Ticket & shop
  introTextTicketResume: "white",
  borderTicketResume: "rgb(88, 88, 88)",
  iconResumeColor: "#000000d1",
  backgroundCardTypeSelect: "rgb(192, 161, 96)",
  titleCardTypeSelect: "white",
  buttonCardTypeSelect: "white",
  buttonTextCardTypeSelect: "rgb(29, 29, 27)",

  //ShopDetail
  commonDetailText: "#000000dd",

  //TransferTicket
  findButton: "rgb(192, 161, 96)",
  backgroundTransferTicket: "rgb(29, 29, 27)",
  introTransferTicket: "white",
  ticketTitleTransferTicket: "rgb(192, 161, 96)",
  warningTextTransferTicket: "white",
  fieldColorTransferTicket: "white",
  textColorTransferTicket: "white",
  borderTransferTicket: "black",
  nameTransferTicket: "white",
  dniTransferTicket: "white",
  personBackgroundTransferTicket: "rgb(29, 29, 27)",

  //Profile
  profileName: "rgb(192, 161, 96)",
  profileIconColor: "white",
  profileArrowColor: "white",
  profileOptionText: "white",
  profileBackground: "rgb(29, 29, 27)",
  profileSupportText: "white",
  profileDNIText: "white",
  profileBackgroundEdit: "rgb(29, 29, 27)",
  profileFieldColorEdit: "white",
  profileVerifiedText: "rgb(193, 237, 103)",
  profileVerifiedIcon: "rgb(193, 237, 103)",
  profileNonVerifiedText: "rgb(242, 201, 12)",
  profileNonVerifiedIcon: "rgb(242, 201, 12)",
  profileDeleteText: "rgb(217, 37, 67)",
  profilePointsCardOne: "rgb(192, 161, 96)",
  profilePointsCardTwo: "rgb(192, 161, 96)",

  //Modal
  primaryErrorModal: "rgb(240, 61, 68)",
  secondaryErrorModal: "rgb(174, 24, 30)",
  primaryWarningModal: "rgb(255, 188, 0)",
  secondaryWarningModal: "rgb(204, 140, 0)",
  primarySuccessModal: "rgb(40, 185, 129)",
  secondarySuccessModal: "rgb(12, 113, 48)",
  primaryMainModal: "rgb(192, 161, 96)",
  secondaryMainModal: "rgb(192, 161, 96)",
  primaryAlertCustomModal: "black",
  secondaryAlertCustomModal: "grey",

  //Eventos
  backgroundTicketsHome: "rgb(245, 245, 245)",
  homeBaseContainer: "rgb(0, 0, 0)",
  locationContainerHome: "rgba(0, 0, 0, 0.7)",
  dayNameContainerHome: "rgba(255, 255, 255, 0.7)",
  dateContainerHome: "rgba(255, 255, 255, 0.7)",
  hourContainerHome: "rgba(0, 0, 0, 0.7)",
  hourTextHome: "white",
  dateTextHome: "rgb(29, 29, 27)",
  dayNameTextHome: "rgb(29, 29, 27)",
  locationTextHome: "rgb(192, 161, 96)",
  ticketTitleTextHome:"rgb(29, 29, 27)",
  backgroundTicketDetail: "rgb(29, 29, 27)",
  textTicketDetail: "white",
  textEventConditionTicketDetail: "white",
  arrowEventConditionTicketDetail: "white",
  borderEventConditionTicketDetail: "rgb(214, 214, 214)",
  borderTicketDetail: "rgb(88, 88, 88)",
  titleTextTicketDetail: "white",
  textEventDescriptionTicketDetail: "white",
  iconLocationTicketDetail: "rgb(192, 161, 96)",
  iconTextLocationTextDetail: "rgb(192, 161, 96)",
  conditionsContainerTicketDetail: "rgb(29, 29, 27)",
  ticketsTitleTicketDetail: "white",
  bgEventTicketsContainerTicketDetail: "rgb(29, 29, 27)",
  bgEventTicketsIconContainer:"rgba(255, 255, 255, 0.2)",
  priceTicketDetail: "white",
  buttonbuyTicketDetail: "rgb(192, 161, 96)",
  buttonticketTicketDetail: "rgb(192, 161, 96)",
  buttonticketTextTicketDetail: "white",
  buttonticketBackgroundTicketDetail: "white",
  backgroundContainerButtonBuy: "rgb(72, 72, 72)",
  totalBuyText: "rgb(0, 0, 0)",
  totalPriceBuyText: "rgb(0, 0, 0)",
  ticketNameTicketDetail: "white",
  backgroundTicketResume: "rgb(0, 0, 0)",
  ticketResumeTotalText: "white",
  ticketNumbersTicketResume: "white",
  ticketTypeTicketResume: "white",
  ticketPriceTicketResume: "white",
  adviceTextTicketResume: "white",
  adviceIconTicketResume: "white",
  backgroundTicketFinal: "rgb(29, 29, 27)",
  codeTicketFinal: "white",
  titleDetailTicketFinal: "white",
  backgroundRowTicketFinal: "rgb(29, 29, 27)",
  borderRowTicketFinal: "rgb(105, 105, 105)",
  ticketTypeTicketFinal: "white",
  ticketPriceTicketFinal: "white",

  //Ticket final
  mainBackgroundTicketFinal: "rgb(0, 0, 0)",
  backgroundTicketFinal: "white",
  titleTicketFinal: "rgb(192, 161, 96)",
  subtitleTicketFinal: "white",
  separatorTicketFinal: "rgb(214, 214, 214)",
  subcodeIntroTicketFinal: "rgb(192, 161, 96)",
  subcodeTicketFinal: "black",
  validateTicketFinal: "black",
  ticketNameTicketFinal: "black",
  buttonTransferTicketFinal: "rgb(192, 161, 96)",
  dniTicketFinal: "black",
  alertmessageTicketFinal: "rgb(255, 131, 24)",
  dotActiveTicketFinal: "rgb(192, 161, 96)",
  dotInactiveTicketFinal: "white",
  ticketQRBackgroundColor: "white",

  //RRPP
  producerHeaderTitleText: "rgb(29, 29, 27)",
  rrppHeaderTitleText: "rgb(29, 29, 27)",
  rrppHomeBackground: "rgb(29, 29, 27)",
  rrppEventBackgroundCard: "rgb(29, 29, 27)",
  rrppBorderEventCard: "rgb(192, 161, 96)",
  rrppYearEventCard: "white",
  rrppDayEventCard: "white",
  rrppMonthEventCard: "white",
  rrppArrowEventCard: "white",
  rrppNameEventCard: "white",
  activeEvent: "rgb(193, 237, 103)",
  inactiveEvent: "rgb(217, 37, 67)",
  backgroundRRPPDetail: "rgb(29, 29, 27)",
  subtitleTextRRPPDetail: "rgb(29, 29, 27)",
  resumeTextRRPPDetail: "white",
  textDetailResumeRRPPDetail: "white",
  earnTextRRPPDetail: "rgb(29, 29, 27)",
  earnValueRRPPDetail: "rgb(29, 29, 27)",
  rrppButtonSell: "#449C3C",
  backgroundSellRRPP: "rgb(0, 0, 0)",
  introSellRRPP: "white",
  eventSellRRPP: "rgb(192, 161, 96)",
  rrppAvailableText: "#449C3C",
  rrppSellGiftCost: "white",
  rrppAvailableNumber: "#449C3C",
  rrppAvailableTotalPrice: "#449C3C",
  eventCardSell: "rgb(29, 29, 27)",
  eventSubtitleCardSellRRPP: "white",
  labelCardEventSellRRPP: "white",
  inputCardEventSellRRPP: "white",
  placeholderCardEventSellRRPP: "white",
  numericButtonEventSellRRPP: "rgb(192, 161, 96)",
  numericButtonTextEventSellRRPP: "rgb(0, 0, 0)",
  numericBorderEventSellRRPP: "transparent",
  numericTextFieldEventSellRRPP: "rgb(0, 0, 0)",
  findButtonSellRRPPColor:"rgb(0, 0, 0)",
  warningSellRRPP: "white",
  fieldBuyerSellRRPP: "white",
  findButtonSellRRPP: "rgb(192, 161, 96)",
  borderSellRRPP: "black",
  nameSellRRPP: "white",
  personBackgroundSellRRPP: "rgb(29, 29, 27)",
  textSellRRPPDetail: "rgb(29, 29, 27)",

  //Producer
  producerHomeBackground: "rgb(0, 0, 0)",
  producerEventBackground: "rgb(29, 29, 27)",
  producerMainTabBackground: "rgb(29, 29, 27)",
  producerMainTabBorder: "rgb(29, 29, 27)",
  producerMainActiveTabBorder: "rgb(192, 161, 96)",
  producerMainEventImageBorder: "black",
  producerMainTabText: "white",
  producerMainActiveTabText: "white",
  producerBorderEventCard: "#00000033",
  producerYearEventCard: "white",
  producerDayEventCard: "white",
  producerMonthEventCard: "white",
  producerArrowEventCard: "white",
  producerNameEventCard: "white",
  backgroundProducerDetail: "rgb(0, 0, 0)",
  subtitleTextProducerDetail: "rgb(29, 29, 27)",
  resumeTextProducerDetail: "white",
  textDetailResumeProducerDetail: "white",
  earnTextProducerDetail: "rgb(29, 29, 27)",
  earnValueProducerDetail: "rgb(29, 29, 27)",

  //Benefits
  benefitsSeparatorPoints:  "rgb(192, 161, 96)",
  benefitsSeparatorPointsText: "rgb(29, 29, 27)",
  benefitsTitle: "rgb(192, 161, 96)",
  benefitCategoryButtonBackground: "rgb(192, 161, 96)",
  benefitCategoryButtonBorder: "rgb(192, 161, 96)",
  benefitCategoryButtonText: "rgb(29, 29, 27)",
  cardBenefitBackground: "rgb(29, 29, 27)",
  cardBenefitType: "white",
  cardBenefitTitle: "white",
  cardBenefitButtonBackground: "rgb(192, 161, 96)",
  cardBenefitButtonText: "white",
  benefitsAmountDetailText: "white",
  benefitsDetailButtonBackground: "rgb(192, 161, 96)",
  benefitsDetailButtonText: "white",
  benefitsDetailTextCategory: "white",
  benefitsDetailHowTo: "white",
  benefitsDetailBigTitle: "rgb(192, 161, 96)",
  benefitsDetailTextDesc: "white",
  benefitsDetailLocationText: "rgb(192, 161, 96)",
  benefitsDetailTitleConditions: "white",
  benefitsDetailTextConditions: "white",

  //Wallet
  walletMainBackground: "rgb(29, 29, 27)",
  walletTextPoints: "white",
  walletPoints: "white",
  walletDescPoints: "white",
  walletMovementsTitleText: "white",
  walletMovementBackground: "rgb(29, 29, 27)",
  walletMovementCardBorder: "rgb(81, 81, 81)",
  walletMovementTitle: "rgb(192, 161, 96)",
  walletMovementDate: "white"
};
