import React, { Component } from 'react';
import { FormControl } from 'native-base'; //WEB99 Form
import IconMA from 'react-native-vector-icons/MaterialIcons';

import {
  View,
  Dimensions,
  Text,
  Image,
  StyleSheet,
  TouchableOpacity,
  ImageBackground,
} from 'react-native';
import { Col, Row, Grid } from 'react-native-easy-grid';
import { Actions } from 'react-native-router-flux';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import L from '../../common/Layout';
import { required, validateEmail } from '../../common/Validations';
import { actForgotPassword } from '../../actions';
import Fonts from '../../common/Fonts';
import Colors from '../../common/Colors';
import renderInput from '../../components/LabelCustomInput';
import MainButton from '../../components/MainButton';
import I18n from 'i18next';
import toast from 'react-hot-toast';

let screen = Dimensions.get('window');
const BG_IMG = require('../../common/assets/background_login.jpg');

class ForgotPassword extends React.PureComponent {
  state = {
    showingSplash: true,
  };

  onForgotPasswordPress(formValues) {
    let email = formValues.user.email;
    this.props.actForgotPassword(
      email,
      this.onForgotPasswordSuccess.bind(this),
      this.onForgotPasswordError.bind(this)
    );
  }

  onForgotPasswordSuccess() {
    toast.success('Se te envió un e-mail para reestablecer la contraseña');
    Actions.reset('login');
  }

  onForgotPasswordError(e) {
    // Toast.show({
    //   text: I18n.t('forgotPassword.forgotPasswordError'),
    //   position: 'bottom',
    //   type: 'danger',
    //   buttonText: I18n.t('forgotPassword.forgotPasswordAcceptButtonError'),
    // }); // console.log('error callback for login')
    toast.error(I18n.t('forgotPassword.forgotPasswordError'));
  }

  getInputProps() {
    return {
      borderColor: Colors.forgotPassBorderColor,
      labelStyle: { color: Colors.forgotPassFieldColor },
      inputStyle: { color: Colors.forgotPassTextColor },
      errorIconStyle: { color: Colors.forgotPassErrorColor },
      borderBottomColor: Colors.forgotPassBorderColor,
      autoCapitalize: 'none',
    };
  }

  render() {
    const { handleSubmit } = this.props;
    return (
      <View style={{ flex: 1, backgroundColor: Colors.profileBackground }}>
        <div class="padding-top">
          <section class="page-title bg-one">
            <div class="container">
              <div class="page-title-area">
                <div
                  class="item md-order-1"
                  onClick={() => Actions.pop()}
                  style={{ cursor: 'pointer' }}
                >
                  <a class="custom-button back-button">
                    <i class="flaticon-double-right-arrows-angles" />
                    Volver
                  </a>
                </div>
                <div class="item date-item">
                  {/* <h4>Finalizar Compra</h4> */}
                </div>
                <div class="item" />
              </div>
            </div>
            <section class="account-section">
              <div class="padding-bottom">
                <div class="account-area">
                  <h4 class="title" style={{ marginLeft: 28 }}>
                    {I18n.t('forgotPassword.recoveryPassword')}
                  </h4>
                  <div class="container">
                    <FormControl style={{ marginHorizontal: L.w(24) }}>
                      <Field
                        name="user.email"
                        label={I18n.t('forgotPassword.emailLabel')}
                        component={renderInput}
                        validate={[validateEmail, required]}
                        {...this.getInputProps()}
                      />
                    </FormControl>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: 24,
                      }}
                    >
                      <a
                        class="custom-button back-button"
                        style={{
                          cursor: 'pointer',
                          alignSelf: 'center',
                        }}
                        onClick={handleSubmit(
                          this.onForgotPasswordPress.bind(this)
                        )}
                      >
                        Guardar
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </View>
    );
  }
}
const localStyle = StyleSheet.create({
  buttonSignIntext: {
    fontSize: L.h(16),
    color: Colors.black_87,
    fontFamily: Fonts.MEDIUM,
  },
  imgContent: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  linearGradient: {
    flex: 1,
    position: 'absolute',
    top: 0,
    left: 0,
    height: screen.height,
    width: screen.width,
  },
  logo: {
    width: L.wProm(250),
    height: L.hProm(80),
    marginTop: L.h(80),
  },
  loginSeparator: {
    alignSelf: 'center',
  },
  sloganTxt: {
    fontSize: L.h(18),
    marginHorizontal: L.w(56),
    textAlign: 'left',
    alignSelf: 'flex-end',
    color: 'white',
  },
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      actForgotPassword,
    },
    dispatch
  );
}
export default reduxForm({
  form: 'forgotPassword',
})(
  connect(
    null,
    mapDispatchToProps
  )(ForgotPassword)
);
