import * as actions from './ActionTypes';
import AppService from '../provider/app/AppService';
import AuthService from '../provider/auth/AuthService';
import AppAsyncStorage from '../common/AppAsyncStorage';
import AppMem from '../common/AppMem';
import Util from '../common/Util';
import _ from 'lodash';
import moment from 'moment';

export function actionChangeAppProps(props) {
  return (dispatch, getStore) => {
    dispatch({
      type: 'CHPROPS_GLOBAL',
      props: props,
    });
  };
}

async function asyncUploadPhoto(file, userId) {
  let response = null;
  response = await AuthService.uploadFoto(file, userId);
  return response;
}

export function setAnonymousMode(anonymousMode) {
  //let anonymousModeVar = anonymousMode;
  return (dispatch, getStore) => {
    dispatch({
      type: 'SET_ANONYMOUS_MODE',
      anonymousMode: anonymousMode,
    });
  };
}

export function setMenuState(menu) {
  return dispatch => {
    dispatch({
      type: 'CHPROPS_GLOBAL',
      props: { menu },
    });
  };
}

export function getEventsCollectionByRRPP(rrppId, callback) {
  return dispatch => {
    asyncGetEvents()
      .then(response => {
        let dataToShow = rrppId
          ? response.filter(ev => {
              return ev.evtSellRrppEnable == true;
            })
          : response;
        const sortedArray = Util.orderByDateSeconds(dataToShow, rrppId ? 1 : 2); //rrppId ? 1 : 2
        dispatch({
          type: 'SAVE_TICKET_INFORMATION',
          events: sortedArray,
        });
        dispatch({
          type: 'SORT_TYPE',
          order: rrppId ? 1 : 2, //rrppId ? 1 : 2
        });
        callback && callback();
      })
      .catch(e => {
        callback && callback();
        console.log('ERROR ERROR ERROR ', e);
      });
  };
}

export function getEventsCollection(producerId, callback) {
  return dispatch => {
    asyncGetEvents()
      .then(response => {
        let dataToShow =
          producerId && producerId !== '1'
            ? response.filter(ev => {
                return ev.evtProducer == producerId;
              })
            : response;
        const sortedArray = Util.orderByDateSeconds(
          dataToShow,
          1 //producerId ? 1 : 2
        );
        dispatch({
          type: 'SAVE_TICKET_INFORMATION',
          events: sortedArray,
        });
        dispatch({
          type: 'SORT_TYPE',
          order: 1, //producerId ? 1 : 2,
        });
        callback && callback();
      })
      .catch(e => {
        callback && callback();
        console.log('ERROR ERROR ERROR ', e);
      });
  };
}

export function orderEvents(events, order) {
  return dispatch => {
    const sortedArray = Util.orderByDateSeconds(events, order == 1 ? 2 : 1);
    dispatch({
      type: 'SAVE_TICKET_INFORMATION',
      events: sortedArray,
    });
    dispatch({
      type: 'SORT_TYPE',
      order: order == 1 ? 2 : 1,
    });
  };
}

export function getMyNextEvents(callback) {
  if (!AppMem.isConnected) {
    // DESCONECTADO, sin internet, trae datos offline.
    return (dispatch, getStore) => {
      AppAsyncStorage.getMyEventsOffline().then(response => {
        let resArr = _.map(response, ev => {
          return ev;
        });
        let sortedArr = Util.orderByDateSeconds(resArr, 1);
        //console.log(" >>>>>>>>>>sortedArr ", sortedArr);
        dispatch({
          type: 'SAVE_NEXT_EVENTS_INFORMATION',
          nextEvents: sortedArr,
        });
        callback && callback(resArr);
      });
    };
  } else {
    //CONECTADO*/
    return (dispatch, getStore) => {
      asyncGetMyNextEvents()
        .then(response => {
          AppAsyncStorage.setMyEventsOffline(response); // Guarda los ultimos tickets en memoria
          //console.log(" >>>>>>>>>>response ", response);
          let resArr = _.map(response, ev => {
            return ev;
          });
          let sortedArr = Util.orderByDateSeconds(resArr, 2);
          dispatch({
            type: 'SAVE_NEXT_EVENTS_INFORMATION',
            nextEvents: sortedArr,
          });
          callback && callback(resArr);
        })
        .catch(e => {
          console.log('ERROR ERROR ERROR ', e);
        });
    };
  }
}

export function getRRPPs(callback) {
  return (dispatch, getStore) => {
    asyncGetRRPPs()
      .then(response => {
        dispatch({
          type: 'CHPROPS_GLOBAL',
          props: { rrppList: response },
        });
        callback && callback(response);
      })
      .catch(e => {
        console.error('ERROR ERROR ERROR ', e);
      });
  };
}
export function getMyLoyalyProgram(callback) {
  return (dispatch, getStore) => {
    asyncGetMyLoyalyProgram()
      .then(response => {
        dispatch({
          type: 'CHPROPS_GLOBAL',
          props: { loyaly: response },
        });
        callback && callback();
      })
      .catch(e => {
        console.log('ERROR ERROR ERROR ', e);
      });
  };
}
export function getLoyalyUseMethods(callback) {
  return (dispatch, getStore) => {
    asyncGetLoyalyUseMethods()
      .then(response => {
        dispatch({
          type: 'CHPROPS_GLOBAL',
          props: { loyalyUseMethods: response },
        });
        callback && callback();
      })
      .catch(e => {
        console.log('LOYALY USE METHODS ERROR ', e);
      });
  };
}

async function asyncGetRRPPs() {
  return await AppService.getRevendedores();
}
async function asyncGetMyLoyalyProgram() {
  return await AppService.getLoyalyProgram();
}
async function asyncGetLoyalyUseMethods() {
  return await AppService.getLoyalyUseMethods();
}

export function onTransferTicket(
  friendData,
  ticketData,
  eventId,
  eventData,
  callback
) {
  return (dispatch, getStore) => {
    asyncTrasnferTicket(friendData, ticketData, eventId, eventData)
      .then(response => {
        callback('OK');
      })
      .catch(e => {
        callback('ERROR');
      });
  };
}

async function asyncTrasnferTicket(riendData, ticketData, eventId, eventData) {
  return await AppService.transferTicket(
    riendData,
    ticketData,
    eventId,
    eventData
  );
}

export function searchPersonByDocument(document, callback) {
  return (dispatch, getStore) => {
    asyncGetPersonData(document)
      .then(response => {
        console.log(' >>>>>>>>>> response', response);
        callback(response);
      })
      .catch(e => {
        callback('No encontrado');
      });
  };
}

async function asyncGetPersonData(document) {
  return await AppService.getPersonData(document);
}

async function asyncGetMyNextEvents() {
  return await AuthService.getMyNextEvents();
}
async function asyncGetEvents(callback) {
  try {
    let response = await AuthService.getEvents();
    return response;
  } catch (error) {
    return error;
  }
}

export function ticketListener() {
  return (dispatch, getStore) => {
    asyncListenerToTickets(response => {
      dispatch({
        type: 'SAVE_TICKET_INFORMATION',
        tickets: response,
      });
    });
  };
}

async function asyncListenerToTickets(callback) {
  try {
    let response = await AuthService.listenerTickets(callback);
    return response;
  } catch (error) {}
}

export function ticketTypeSelectedListener() {
  return (dispatch, getStore) => {
    asyncTicketTypeSelectedListener(response => {
      if (response) {
        dispatch({
          type: 'SAVE_SELECTED_TICKETS',
          activeEventTicketTypes: response,
        });
      }
    });
  };
}

async function asyncTicketTypeSelectedListener(callback) {
  return AuthService.ticketTypeSelectedListener(callback);
}

export function actCheckAppVersion(callback) {
  try {
    return dispatch => {
      AppService.settingListener(res => {
        dispatch({
          type: 'ACT_RRPP_CHANGE',
          props: {
            giftAvailable: _.filter(res, es => es.id == 'rrpp')[0]?.data
              ?.giftAvailable,
          },
        });
        dispatch({
          type: 'CHPROPS_GLOBAL',
          props: {
            appSettings: _.filter(res, es => es.id == 'config')[0]?.data,
          },
        });
        callback && callback(res[0]?.data);
      });
    };
  } catch (error) {
    callback && callback(error);
    // Si no puede chequear la version continua
  }
}

export const uploadPhotoInFirebaseStorage = (
  file,
  userId,
  retroFunction
) => () => {
  asyncUploadPhoto(file, userId)
    .then(() => {
      retroFunction('OK');
    })
    .catch(error => {
      retroFunction(error);
    });
};

async function asyncCheckAppVersion(logicId, nativeId, platform) {
  try {
    return await AppService.checkUpdate(logicId, nativeId, platform);
  } catch (error) {
    // console.log('error checking app version', error);
  }
}

export function getErrorExample(type, errorCallback) {
  return dispatch => {
    AppService.getErrorMsgExample(type).then(resp => {
      dispatch({
        type: actions.CHPROPS_UPDATE_APP,
        errorManager: resp,
      });
      errorCallback(resp);
    });
  };
}
