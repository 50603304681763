import React, { Component } from 'react';
import {
  Text,
  View,
  ScrollView,
  Image,
  ImageBackground,
  TouchableHighlight,
  TouchableOpacity,
  Platform,
} from 'react-native';
import { Toast } from 'native-base';
import Icon from 'react-native-vector-icons/MaterialIcons';
import { Actions } from 'react-native-router-flux';
import Fonts from '../../common/Fonts';
import L from '../../common/Layout';
import { bindActionCreators } from 'redux';
import ImagePicker from 'react-native-image-picker';
import I18n from 'i18next';
import { connect } from 'react-redux';
import VTHeader from '../../components/VTHeader';
import ChipTag from '../../components/ChipTag';
import FastImage from 'react-native-fast-image';
import { actLogout, onDeleteAccountPress } from '../../actions/AuthActions';
import { uploadPhotoInFirebaseStorage } from '../../actions/AppActions';
import AppMem from '../../common/AppMem';
import style from './style';
import Colors from '../../common/Colors';
import Util from '../../common/Util';
import Config from 'react-native-config';
import LinearGradient from 'react-native-linear-gradient';
import Footer from '../../components/web/Footer';

const mockData = {
  logoutLabel: I18n.t('profileMain.closeSession'),
  deleteAccount: I18n.t('profileMain.deleteAccount'),
  onSelectOption: option => {
    const pages = {
      profile: Actions.editProfile,
      wallet: Actions.editPassword,
      privacity: Actions.editEmail,
    };
    const navigate = pages[option];

    if (navigate) navigate();
  },
  options: [
    {
      type: 'profile',
      name: I18n.t('profileMain.myProfile'),
      icon: (
        <Icon
          name="person"
          type="FontAwesome"
          style={{ color: Colors.profileIconColor, fontSize: L.h(30) }}
        />
      ),
    },
    {
      type: 'privacity',
      name: I18n.t('profileMain.updateEmail'),
      icon: (
        <Icon
          name="mail"
          style={{ color: Colors.profileIconColor, fontSize: L.h(20) }}
        />
      ),
    },
    {
      type: 'wallet',
      name: I18n.t('profileMain.updatePassword'),
      icon: (
        <Icon
          name="lock"
          style={{ color: Colors.profileIconColor, fontSize: L.h(25) }}
        />
      ),
    },
  ],
};

class Profile extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      image:
        this.props.user && this.props.user.proProfileImage
          ? this.props.user.proProfileImage
          : null,
    };
    this.onLogoutPress = this.onLogoutPress.bind(this);
    this.onDeleteAccountPress = this.onDeleteAccountPress.bind(this);
    this.onPressCamera = this.onPressCamera.bind(this);
    this.imgCallback = this.imgCallback.bind(this);
    this.retroFunction = this.retroFunction.bind(this);
  }

  onSelectOption = option => event => {
    const { onSelectOption } = mockData;
    const type = option && option.type;

    if (onSelectOption) onSelectOption(type);
  };

  onLogoutPress = () => {
    // AppMem.openModal("spinner");
    this.props.actLogout(() => {
      Actions.reset('home');
      // AppMem.closeModal("spinner");
    });
  };

  onDeleteAccountPress = () => {
    let modalProps = {
      title: I18n.t('profileMain.deleteAccountLabel'),
      type: 'primary',
      buttonCancel: {
        text: I18n.t('profileMain.cancelButton'),
        action: () => null,
      },
      buttonOk: {
        text: 'Ok',
        action: () => {
          AppMem.openAppLoader('bars', {
            text: I18n.t('profileMain.loaderText'),
            color: Colors.secondary_color,
          });
          this.props.onDeleteAccountPress(callback => {
            AppMem.closeAppLoader('bars', {
              text: I18n.t('profileMain.loaderText'),
              color: Colors.secondary_color,
            });
            const { error } = callback;
            if (error) {
              Toast.show({
                text: I18n.t('profileMain.error'),
                buttonText: I18n.t('global.accept'),
                type: 'danger',
                position: 'bottom',
              });
            } else {
              Toast.show({
                text: I18n.t('profileMain.successDelete'),
                buttonText: I18n.t('global.accept'),
                position: 'bottom',
                type: 'success',
                duration: 1500,
                onClose: () => {
                  this.props.actLogout(() => {
                    Actions.reset('login');
                  });
                },
              });
            }
          });
        },
      },
    };
    AppMem.openModal('alert', modalProps);
  };

  onClosePress = event => {
    const { onClose } = mockData;

    if (onClose) onClose();
  };

  getHeaderContentProps() {
    return {
      middleNode: (
        <Text style={style.title}>{I18n.t('profileMain.myProfile')}</Text>
      ),
      rightNode: (
        <TouchableOpacity onPress={this.onClosePress}>
          <Text style={style.action}>{I18n.t('profileMain.close')}</Text>
        </TouchableOpacity>
      ),
    };
  }

  getScrollViewProps() {
    return {
      style: [style.scrollView, { marginTop: 0, paddingTop: 0 }],
      alwaysBounceVertical: false,
      bounces: false,
    };
  }

  renderHeader() {
    return (
      <VTHeader
        backgroundColor="rgb(40,5,74)"
        titleStyle={{
          fontFamily: Fonts.MEDIUM,
          fontSize: L.h(18),
          color: 'white',
          top: Platform.OS === 'ios' ? 2 : 0,
        }}
        title={I18n.t('profileMain.profile')}
        back
      />
    );
  }

  renderOption(option, index, options) {
    const optionsCount = options.length - 1;
    const containerStyle = {
      borderTopWidth: index === 0 ? 1 : 0,
      borderTopColor: index === 0 ? '#dfdfdf' : 'transparent',
      borderBottomWidth: index === optionsCount ? 1 : 0,
      borderBottomColor: index === optionsCount ? '#dfdfdf' : 'transparent',
    };
    const optionContainerTextStyle = {
      borderTopWidth: index !== 0 ? 1 : 0,
      borderTopColor: index !== 0 ? '#dfdfdf' : 'transparent',
    };
    const { name, icon } = option;

    return (
      <View
        key={index}
        style={[style.optionContainer, containerStyle, { height: 60 }]}
      >
        <TouchableHighlight
          style={[style.optionTouchable]}
          onPress={this.onSelectOption(option)}
          underlayColor="#CCCCCC"
        >
          <View style={style.container1}>
            <View style={style.optionContainerIcon}>
              {icon || <Text>ICON</Text>}
            </View>
            <View style={[style.optionContainerText, optionContainerTextStyle]}>
              <View style={style.optionLabel}>
                <Text
                  style={{
                    color: Colors.profileOptionText,
                    fontFamily: Fonts.DREGULAR,
                    fontSize: 22,
                  }}
                >
                  {name}
                </Text>
              </View>
              <View style={style.optionArrow}>
                <Icon
                  size={30}
                  name="arrow-forward"
                  style={{ color: Colors.profileArrowColor }}
                />
              </View>
            </View>
          </View>
        </TouchableHighlight>
      </View>
    );
  }

  retroFunction(image) {
    AppMem.closeAppLoader();
    this.setState({ image });
  }

  onImageSelect(image, isFromGallery) {
    AppMem.openAppLoader('bars', {
      text: I18n.t('profileMain.loaderText'),
      color: Colors.secondary_color,
    });
    this.setState({ image });
    this.props.uploadPhotoInFirebaseStorage(
      image,
      this.props.userId,
      this.retroFunction
    );
    Actions.pop();
  }

  onPressCamera() {
    const options = {
      title: I18n.t('profileMain.chooseOption'),
      takePhotoButtonTitle: I18n.t('profileMain.takePhoto'),
      chooseFromLibraryButtonTitle: I18n.t('profileMain.choosePhoto'),
      cancelButtonTitle: I18n.t('profileMain.cancelButton'),
      allowsEditing: true,
      quality: 0.4,
      maxWidth: 500,
      maxHeight: 500,
      storageOptions: {
        skipBackup: true,
        path: 'images',
      },
    };
    ImagePicker.showImagePicker(options, response => {
      if (response.didCancel) {
        console.log('User cancelled image picker');
      } else if (response.error) {
        console.log('ImagePicker Error: ', response.error);
      } else if (response.customButton) {
      } else {
        AppMem.openAppLoader();
        this.props.uploadPhotoInFirebaseStorage(
          response.uri,
          this.props.userId,
          () => this.retroFunction(response.uri)
        );
      }
    });
  }

  imgCallback(res, img) {
    this.setState({ avatarSource: img }, AppMem.closeAppLoader());
  }

  render() {
    const { options, logoutLabel, deleteAccount } = mockData;
    const { user, loyaly = {} } = this.props;
    const { proTags = [] } = user;
    const { points: loyalyPoints = 0 } = loyaly;
    let name = user.proName ? Util.autoCapitalize(user.proName) : null;
    let lastName = user.proLastName
      ? Util.autoCapitalize(user.proLastName)
      : null;
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: Colors.backgroundHistory,
          paddingTop: 130,
        }}
      >
        <div
          class="wrapper-area"
          style={{ borderRadius: 30, marginBottom: 50 }}
        >
          <div class="movie-list">
            <div class="cast-item">
              <div class="cast-thumb" style={{ height: 200, width: 200 }}>
                <a>
                  <img
                    src={
                      this.state.image
                        ? this.state.image
                        : require('../../common/assets/profileIos.png')
                    }
                    alt="cast"
                  />
                </a>
              </div>
            </div>
            {/* <div class="movie-thumb c-thumb">
              <img
                src={
                  this.state.image
                    ? this.state.image
                    : require('../../common/assets/profileIos.png')
                }
                alt="banner"
              />
            </div> */}
            <div class="movie-content bg-one">
              <h5
                class="title"
                onClick={Actions.editProfile}
                style={{ cursor: 'pointer' }}
              >
                <a style={{ cursor: 'pointer' }} href="#">
                  {name} {lastName}
                </a>
                <Icon
                  size={22}
                  name="edit"
                  style={{
                    color: Colors.profileArrowColor,
                    marginLeft: 8,
                  }}
                />
              </h5>
              <p class="duration">DNI {user.proDNI}</p>
              <div>
                {proTags.length > 0 ? (
                  <View
                    style={{
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    {proTags.map(tag =>
                      tag.isTagVisible ? (
                        <ChipTag
                          containerStyle={{ backgroundColor: tag.tagColor }}
                          Label={tag.tagName}
                        />
                      ) : null
                    )}
                  </View>
                ) : null}
              </div>
              <div class="release">
                {user.proVerified === true ? (
                  <View style={style.verifiedContainer}>
                    <Text style={[style.verifiedUser, { fontSize: 24 }]}>
                      {I18n.t('profileMain.verifiedUser')}
                    </Text>
                    <Icon name="verified-user" style={style.verifiedIcon} />
                  </View>
                ) : (
                  <View style={style.verifiedContainer}>
                    <Text style={[style.nonVerifiedUser, { fontSize: 24 }]}>
                      {I18n.t('profileMain.unverifiedUser')}
                    </Text>
                    <Icon
                      name="error-outline"
                      style={[style.nonVerifiedIcon, { fontSize: 24 }]}
                    />
                  </View>
                )}
              </div>
              <div class="book-area">
                <div class="book-ticket">
                  {/* <div class="react-item">
                    <a href="#0">
                      <div class="thumb">
                        <img
                          src="./assets/images/icons/heart.png"
                          alt="icons"
                        />
                      </div>
                      <span>Modificar contraseña</span>
                    </a>
                  </div> */}
                  <div class="react-item mr-auto">
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={Actions.editPassword}
                    >
                      <div class="thumb">
                        <i className="fas fa-key" style={{ color: 'white' }} />
                      </div>
                      <span>Modificar contraseña</span>
                    </a>
                  </div>
                  <div class="react-item">
                    <a
                      class="popup-video"
                      style={{ cursor: 'pointer' }}
                      onClick={this.onLogoutPress}
                    >
                      <div class="thumb">
                        <i
                          className="fas fa-sign-out-alt"
                          style={{ color: 'white' }}
                        />
                      </div>
                      <span>Cerrar sesión</span>
                    </a>
                  </div>
                </div>
              </div>
              <Text
                style={[
                  //  style.supportText,
                  {
                    fontSize: 16,
                    alignSelf: 'center',
                    color: Colors.profileSupportText,
                  },
                ]}
              >
                {Config.INSTAGRAM_INFO_APP
                  ? `${I18n.t('profileMain.consult')} ${
                      Config.INSTAGRAM_INFO_APP
                    }`
                  : `${I18n.t('profileMain.consult2')} ${
                      Config.EMAIL_INFO_APP
                    }`}
              </Text>
            </div>
          </div>
          {/* <div class="col-lg-3 col-sm-10 col-md-6 mb-50">
            <div class="widget-1 widget-banner">
              <div class="widget-1-body">
                <a>
                  <img
                    src={
                      this.state.image
                        ? this.state.image
                        : require('../../common/assets/profileIos.png')
                    }
                    alt="banner"
                  />
                </a>
              </div>
            </div>
          </div> */}
        </div>
        <Footer />
      </View>
    );
  }
}

function mapStateToProps(store) {
  return {
    user: store.authReducer.user,
    userId: store.authReducer.userId,
    loyaly: store.appReducer.loyaly,
  };
}
function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    { actLogout, uploadPhotoInFirebaseStorage, onDeleteAccountPress },
    dispatch
  );
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile);
