import * as actions from "./ActionTypes";
import AppService from "../provider/app/AppService";
import _ from "lodash";
import { Actions } from "react-native-router-flux";
import { Platform } from "react-native";

export function initNotifications(data) {
  return () => {
    AppService.initNotifications(data)
      .then(resp => {
        // console.log(" >>>>>>>>>> resp", resp);
        // dispatch({
        //   type: actions.USER_DATA,
        //   props: { profileImg: { uri: resp.url } },
        // });
        //callback();
      })
      .catch(e => {
        // console.log(" >>>>>>>>>> error al subir img", e);
        //callback(e);
      });
  };
}

export function lastRead() {
  return dispatch => {
    AppService.lastRead()
      .then(resp => {
        // console.log(" >>>>>>>>>> resp", resp);
        dispatch({
          type: actions.CHANGE_APP,
          props: {
            no_reads_menu: 0,
          },
        });
        //callback();
      })
      .catch(e => {
        // console.log(" >>>>>>>>>> error al subir img", e);
        //callback(e);
      });
  };
}

export function getNotifications(callback) {
  if (Platform.OS !== "web") {
    return (dispatch) => {
      let tipo = null;
      AppService.getNotifications(tipo)
        .then((resp) => {
          dispatch({
            type: actions.CHANGE_APP,
            props: {
              notifications: resp.notifications,
              no_reads: resp.no_reads,
              no_reads_menu:
                Actions.currentScene == "notifications" ? 0 : resp.no_reads,
            },
          });
          callback && callback();
        })
        .catch((e) => {
          // console.log(" >>>>>>>>>> error al subir img", e);
          callback(e);
        });
    };
  }
}
