import React from 'react';
import {
  Animated,
  Text,
  TextInput,
  TouchableWithoutFeedback,
  View,
  StyleSheet,
  Dimensions,
  Keyboard,
  TouchableOpacity,
} from 'react-native';
//import { Item, Picker, Form } from "native-base"; //WEB99
import { FormControl, Select } from 'native-base';
import Icon from 'react-native-vector-icons/Ionicons';
import Icon2 from 'react-native-vector-icons/MaterialIcons';
import BaseInput from './BaseInput';
import Fonts from '../common/Fonts';
import L from '../common/Layout';
import Colors from '../common/Colors';
import Util from '../common/Util';
import I18n from 'i18next';
const screen = Dimensions.get('window');
import AppMem from '../common/AppMem';
//WEB99 import DatePicker from "react-native-datepicker";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
//import { DatePicker } from '@mui/x-date-pickers/DatePicker';
//import TextField from '@mui/material/TextField';
//import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
const PADDING = L.hProm(12);

//WEB99 import Form from 'react-bootstrap/Form';

//WEB99

const Item = {};
const Picker = {};

export default class LabelCustomInput extends BaseInput {
  /*constructor(props) {
    this.renderDatePicker = this.renderDatePicker.bind(this);
  }*/

  componentWillMount() {}

  componentDidMount() {
    this.inputRef = React.createRef();
    if (this.props.input.value) {
      this._onFocus();
    }
  }
  mixingFocus() {
    this._onFocus();
    this.props.input.onFocus();
  }

  mixingOnchange(event) {
    this._onChange(event);
    this.props.input.onChange(event);
  }

  mixingBlur() {
    this._onBlur();
    this.props.input.onBlur();
  }

  renderItem() {
    switch (this.props.itemType) {
      case 'inputType':
        return this.renderInput();
        break;
      case 'DatePicker':
        return this.renderDatePicker();
        break;
      case 'ItemList':
        return this.renderItemList();
        break;
      default:
        return this.renderInput();
    }
  }

  onValueChangePicker(value) {
    requestAnimationFrame(() => {
      this.props.input.onChange(value);
    });
    this.props.onChangeItem && this.props.onChangeItem(value);
  }

  renderItemList() {
    Keyboard && Keyboard.dismiss();
    this._onFocus();
    let {
      input: { onChange, value, ...inputProps },
      children,
      ...pickerProps
    } = this.props;
    return (
      <View
        style={{
          flexDirection: 'row',
          alignItems: 'center',
          top: 26,
          //width: '100%',
        }}
      >
        {this.props.listIcon ? (
          <Icon
            name={'locate'}
            style={{
              color: Colors.black_54,
              fontSize: L.h(20),
              marginRight: L.w(8),
            }}
          />
        ) : null}
        <Select
          iosHeader={I18n.t('labelCustomInput.placeholder')}
          selectedValue={this.props.input.value}
          placeholder={I18n.t('labelCustomInput.placeholder')}
          placeholderStyle={this.props.placeholderStyle}
          onValueChange={this.onValueChangePicker}
          textStyle={[{ left: -L.w(8) }, this.props.inputStyle]}
          headerBackButtonText={I18n.t('labelCustomInput.back')}
          //minWidth={500}
          flex={1}
          maxHeight={42}
          //  width={'100%'}
          borderWidth={0}
          fontSize={16}
          {...inputProps}
          {...pickerProps}
          ref="pickerPicker"
          note={false}
          mode="dialog"
          style={[this.props.inputStyle, { paddingLeft: 0 }]}
        >
          {this.renderPickerItems()}
        </Select>
        {!this.props.disabled ? null : (
          <View
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              backgroundColor: 'transparent',
              height: L.hProm(50),
              width: '100%',
            }}
          />
        )}
      </View>
    );
  }

  renderPickerItems() {
    let itemList = [];
    this.props.items &&
      this.props.items.forEach((item, index) => {
        itemList.push(
          <Select.Item key={index} label={item.label} value={item.value} />
        );
      });
    return itemList;
  }

  onCloseModal() {
    setTimeout(() => {
      if (this.props.input.value) {
        return null;
      } else {
        this._cancelSelector();
      }
    }, 10);
  }

  renderDatePicker() {
    //return (<DesktopDatePicker></DesktopDatePicker>)
    /* ( <Button   
          {...this.props} variant="contained">Contained</Button>);*/
    //value={this.props.input.value ? null : this.props.input.value}
    let { maxDate, input } = this.props;
    return (
      <View>
        <View style={{ height: L.hProm(22) }}>{this.renderLabel()}</View>
        <DesktopDatePicker
          style={{ width: screen.width }}
          onChange={date => {
            this.onChangeDate(date);
          }}
          maxDate={maxDate}
          value={input.value || null}
          orientation="landscape"
          popperPlacement="bottom-end"
          slotProps={{ textField: { variant: 'standard' } }}
          sx={{
            '.MuiInputBase-input': {
              fontSize: 17,
              color: 'white',
              height: 50,
              '&:before': {
                border: 0,
              },
            },
            '.MuiInputBase-root': {
              border: 0,
              '&:hover': {
                border: 0,
                '&:before': {
                  border: 0,
                },
                '&:not:before': {
                  border: 0,
                },
              },
              '&:before': {
                border: 0,
              },
              '&:after': {
                border: 0,
              },
              '&:not(.Mui-disabled):hover::before': {
                border: 0,
              },
            },
            '.MuiIconButton-root': {
              color: 'white',
            },
            '&.MuiFormControl-root': {
              '&.MuiTextField-root': {
                border: 'none',
              },
              '&.MuiOutlinedInput': {
                color: 'white',
              },
            },
          }}
        />
      </View>
    );

    /*
    let pickerProps = this.props.dateProps;
    return (
      <View>
        <View style={{ height: L.hProm(22) }}>{this.renderLabel()}</View>
        <DatePicker
          style={{ width: screen.width }}
          date={this.props.input.value}
          //mode="date"
          placeholder={this.props.input.value}
          confirmBtnText={I18n.t("labelCustomInput.confirm")}
          cancelBtnText={I18n.t("labelCustomInput.cancel")}
          x
            btnTextConfirm: {
              color: "black",
              fontFamily: Fonts.DBOLD,
              lineHeight: 20,
              top: 2,
            },
            btnTextCancel: {
              fontFamily: Fonts.DBOLD,
              lineHeight: 20,
              top: 2,
            },
            dateTouchBody: {
              justifyContent: "flex-start",
            },
            disabled: { backgroundColor: "black" },
            datePickerCon: { backgroundColor: "#9B9B9B" },
            datePicker: { color: "red" },
            dateInput: {
              flex: 1,
              height: L.hProm(25),
              borderWidth: 0,
              borderColor: this.props.borderColor,
              alignItems: "flex-start",
              justifyContent: "center",
            },
            dateText: [styles.textInput, { top: 0 }, this.props.inputStyle],
            // ... You can check the source to find the other keys.
          }}
          onDateChange={(date) => this.onChangeDate(date)}
          {...pickerProps}
        />
      </View>
    );*/
  }

  //99 WEB Tenemos que revisar en que formato viene:
  onChangeDate(date) {
    let {
      input: { onChange },
    } = this.props;
    let dateFormat = date.toDate();
    this.setState({ date: date }, onChange(date));
    this.props.onChangeDate && this.props.onChangeDate(date);
  }

  executeFocus() {
    if (this.refs.input) {
      this._cancelSelector();
      this.refs.input.focus();
    } else if (this.refs.pickerPicker) {
      return null;
      //console.log("entra por el picker", this.refs.pickerPicker.Open());
      //this.refs.pickerPicker.Open();
    } else {
      this._cancelSelector();
    }
  }

  onChangeText = text => {
    let { trimWhiteSpace } = this.props;
    if (trimWhiteSpace) {
      this.props.input.onChange(
        text &&
          text
            .replace(/(^\s*)|(\s*$)/gi, '') // removes leading and trailing spaces
            .replace(/[ ]{1,}/gi, '') // replaces multiple spaces
            .replace(/\n +/, '\n')
      );
    } else {
      this.props.input.onChange(text);
    }
  };

  renderInput() {
    const {
      label,
      containerStyle,
      inputStyle,
      height: inputHeight,
      input: { value },
      keyboardType,
      maxLength,
      secureTextEntry,
      blurOnSubmit,
      onSubmitEditing,
      returnKeyType,
    } = this.props;
    const { width } = this.state;

    //WEB99
    /*if (this.props.meta.error) {
      hasError = true;
    }*/
    //ref="input" //WEB99
    return [
      <TextInput
        ref={this.inputRef}
        {...this.props}
        style={[
          styles.textInput,
          inputStyle,
          {
            width,
            height: 50,
            borderBottomWidth: 0,
          },
          this.props.icon
            ? {
                marginLeft: L.w(26),
              }
            : null,
        ]}
        value={value}
        onBlur={this.mixingBlur.bind(this)}
        onChange={this.mixingOnchange.bind(this)}
        onChangeText={this.onChangeText}
        onFocus={this.mixingFocus.bind(this)}
        onDragStart={this.props.input.onDragStart}
        onDrop={this.props.input.onDragStart}
        underlineColorAndroid={'transparent'}
        maxLength={maxLength}
        keyboardType={keyboardType}
        secureTextEntry={this.state.secureTextEntry}
        blurOnSubmit={blurOnSubmit}
        onSubmitEditing={onSubmitEditing}
        returnKeyType={returnKeyType}
      />,
      secureTextEntry ? (
        <TouchableOpacity
          key={2}
          style={{
            position: 'absolute',
            right: 0,
            top: L.h(19),
            zIndex: 999,
          }}
          onPress={this._onPressEye}
        >
          <Icon
            name={this.state.secureTextEntry ? 'ios-eye' : 'ios-eye-off'}
            style={{
              padding: 8,
              color: Colors.loginRevealPassIcon,
              fontSize: L.h(28),
            }}
          />
        </TouchableOpacity>
      ) : null,
    ];
  }

  openCalendar() {
    AppMem.calendar.open();
  }

  renderLabel() {
    let { label, labelStyle } = this.props;
    let { focusedAnim } = this.state;
    return (
      <View
        style={[
          styles.labelContainer,
          {
            height: this.props.itemType === 'ItemList' ? null : L.hProm(60),
          },
        ]}
      >
        <Text style={[styles.label, labelStyle]}>{label}</Text>
      </View>
    );
  }

  render() {
    const {
      containerStyle,
      maskColor,
      borderColor,
      height: inputHeight,
      hintStyle,
      itemType,
      maxWidth,
      editable,
      meta: { touched, error, warning },
    } = this.props;
    const { focusedAnim } = this.state;

    let width = screen.width;
    let hasError = false;
    if (this.props.meta.error) {
      //console.log("entreaaaa");
      hasError = true;
    }

    //WEB99 let DynamicView = itemType === "ItemList" ? View : Item;
    let DynamicView = View;

    return (
      <View
        style={[
          {
            marginTop: this.props.topMargin,
            width: '100%',
          },
          { width: maxWidth },
        ]}
      >
        <DynamicView
          style={[
            styles.container,
            {
              // height: inputHeight + PADDING,
              height: 72,
              //width: containerWidth,
              borderBottomColor:
                hasError && this.props.meta.touched
                  ? Colors.invalid
                  : this.props.borderBottomColor
                  ? this.props.borderBottomColor
                  : this.props.borderColor,
            },
            containerStyle,
            { marginLeft: 0 },
          ]}
          onLayout={this._onLayout}
          error={hasError && touched}
        >
          {this.props.icon ? (
            <Icon
              name={this.props.icon}
              style={{
                color: 'white',
                fontSize: L.h(20),
                top: L.h(9),
              }}
            />
          ) : null}
          {this.renderItem()}
          {itemType === 'DatePicker' ? null : (
            <TouchableWithoutFeedback
              onPress={itemType === 'ItemList' ? null : this.focus}
            >
              {this.renderLabel()}
            </TouchableWithoutFeedback>
          )}
          <View style={[styles.labelMask, { backgroundColor: maskColor }]} />
          {/* <Animated.View
          style={[
            styles.border,
            {
              height:
                !this.props.input.value && itemType === "ItemList"
                  ? 0
                  : L.h(!editable ? 0 : 3),
            },
            {
              width: focusedAnim.interpolate({
                inputRange: [0, 1],
                outputRange: [0, width],
              }),
              backgroundColor:
                hasError && this.props.meta.touched
                  ? Colors.invalid
                  : !editable
                  ? Colors.black_54
                  : borderColor,
            },
          ]}
        /> */}
          {/*itemType === "DatePicker" || */}
          {itemType === 'ItemList' && Util.isIos() ? (
            <Icon2
              name="arrow-drop-down"
              style={[
                styles.arrowStyle,
                { right: itemType === 'DatePicker' ? 0 : L.w(8) },
                this.props.labelStyle,
              ]}
            />
          ) : null}
        </DynamicView>
        {hasError && this.props.meta.touched ? (
          <View
            style={[
              { flexDirection: 'row', alignItems: 'center' },
              this.props.errorContainer,
            ]}
          >
            {this.props.errorIcon ? (
              <Icon2
                name="cancel"
                style={{
                  color: this.props.errorIcon.color
                    ? this.props.errorIcon.color
                    : Colors.invalid,
                  fontSize: L.h(13),
                  top: L.h(2),
                }}
              />
            ) : null}
            <Text
              style={[
                {
                  fontSize: L.h(12),
                  marginLeft: L.h(8),
                  marginTop: L.h(4),
                  color: Colors.black_87,
                  fontFamily: Fonts.DLIGHT,
                },
                this.props.errorIconStyle,
              ]}
            >
              {this.props.meta.error}
            </Text>
          </View>
        ) : (
          <Text style={[styles.hintText, hintStyle]}>{this.props.hint}</Text>
        )}
      </View>
    );
  }
}

LabelCustomInput.defaultProps = {
  borderColor: 'red',
  height: L.hProm(48),
  animationDuration: 200,
  dateMask: 'DD/MM/YYYY',
  startDate: new Date(),
  minDate: new Date(1915, 1, 10),
  maxDate: new Date(2315, 1, 10),
  placeholderStyle: {
    color: 'black',
  },
  items: [{ label: 'Argentina', value: 'AR' }],
  errorIcon: {
    color: Colors.invalid,
  },
};

const styles = StyleSheet.create({
  container: {
    borderBottomWidth: 2,
    borderBottomColor: '#b9c1ca',
    width: '100%',
    //marginHorizontal: L.w(17)
  },
  arrowStyle: {
    fontSize: L.h(30),
    color: Colors.black_54,
    position: 'absolute',
    bottom: L.h(11),
    right: 0,
  },
  hintText: {
    fontSize: L.h(11),
    fontFamily: Fonts.DLIGHT,
    color: Colors.bloodOrange,
    alignSelf: 'center',
    marginTop: L.h(2),
  },
  labelContainer: {
    position: 'absolute',
    marginTop: L.h(6),
    width: '100%',
    height: L.hProm(60),
  },
  label: {
    fontSize: L.h(16),
    fontFamily: Fonts.DLIGHT,
    color: Colors.black_54,
  },
  textInput: {
    position: 'absolute',
    //bottom: 0,
    left: 0,
    padding: 0,
    color: Colors.black_87,
    fontSize: L.h(18),
    fontFamily: Fonts.DREGULAR,
    top: L.h(20),
    zIndex: 999,
  },
  labelMask: {
    height: L.hProm(24),
    width: PADDING,
  },
  border: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: L.h(3),
  },
});
