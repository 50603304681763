import React from "react";
import { View, Text, StyleSheet, Image } from "react-native";
import L from "../common/Layout";
import Colors, { black_08 } from "../common/Colors";
import moment from "moment";
import { TouchableOpacity } from "react-native";
import Fonts from "../common/Fonts";

const PushCard = ({
  style,
  title,
  message,
  date,
  read,
  url,
  urlType,
  onPress,
}) => (
  <TouchableOpacity
    style={[
      styles.container,
      style,
      { borderLeftWidth: read ? 0 : 5, borderColor: Colors.mediumBlue },
    ]}
    onPress={url ? () => onPress && onPress(url, title) : null}
    activeOpacity={url ? 0.8 : 1}
  >
    <View style={{ flex: 1 }}>
      <View style={styles.iconContainer}>
        <Image
          source={
            require("../common/assets/notiCardIcon.png")
            // urlType == 1
            //   ? require("../common/assets/infoNotiIcon.png")
            //   : urlType == 2
            //   ? require("../common/assets/iconNotiPromo.png")
            //   : urlType == 3
            //   ? require("../common/assets/iconNotiCalendar.png")
            //   : urlType == 4
            //   ? require("../common/assets/iconNotiImportant.png")
            //   : require("../common/assets/infoNotiIcon.png")
          }
          resizeMode="contain"
          style={{ width: L.wProm(32), height: L.hProm(32) }}
        />
      </View>
    </View>
    <View style={styles.rightContainer}>
      <Text style={styles.titleStyle}>{title}</Text>
      <Text style={styles.messageStyle}>{message}</Text>
      <Text style={styles.dateStyle}>
        {moment.unix(date).format("DD MMMM HH:mm")}
      </Text>
    </View>
    {read ? null : <View style={styles.notiIcon} />}
    {!url ? null : (
      <View style={styles.more}>
        <Text style={[styles.titleStyle, { color: Colors.lightBlue }]}>
          Ver más
        </Text>
      </View>
    )}
  </TouchableOpacity>
);

PushCard.defaultProps = {
  title: "Este finde viene Gigante, te lo vas a perder?",
  message:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed pharetra, nisi ac viverra cursus, felis ligula ullamcorper enim.",
  date: "15 nov",
};

const styles = StyleSheet.create({
  container: {
    width: "90%",
    borderRadius: 6,
    backgroundColor: "#ffffff",
    shadowColor: "#0000001e",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowRadius: 12,
    shadowOpacity: 1,
    alignSelf: "center",
    elevation: 3,
    flexDirection: "row",
    paddingVertical: L.h(16),
    marginVertical: L.h(8),
  },
  more: {
    position: "absolute",
    bottom: L.h(16),
    right: L.w(9),
  },
  notiIcon: {
    width: L.wProm(9),
    height: L.hProm(9),
    borderRadius: L.hProm(9) / 2,
    backgroundColor: "#e7392e",
    position: "absolute",
    top: L.h(9),
    right: L.w(9),
  },
  rightContainer: { flex: 4.5, marginLeft: L.w(16), marginRight: L.w(22) },
  iconContainer: {
    width: L.wProm(32),
    height: L.hProm(32),
    borderRadius: L.hProm(32) / 2,
    //backgroundColor: Colors.brightCyan,
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "flex-end",
  },
  messageStyle: {
    fontFamily: Fonts.DREGULAR,
    fontSize: L.h(12),
    letterSpacing: 0,
    color: Colors.notiMessageColor,
    marginTop: L.h(6),
    marginBottom: L.h(8),
  },
  titleStyle: {
    fontFamily: Fonts.DBOLD,
    fontSize: L.h(13),
    letterSpacing: 0,
    color: "black",
  },
  dateStyle: {
    fontFamily: Fonts.DREGULAR,
    fontSize: L.h(12),
    letterSpacing: 0,
    color: Colors.notiDateColor,
  },
});

export default PushCard;
