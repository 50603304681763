import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
//import App from './App';
import App from './app/App';
import reportWebVitals from './reportWebVitals';
import { NativeBaseProvider} from "native-base";


/***** (+) Agregado para cargar fuentes de iconos */
// Use prebuilt version of RNVI in dist folder //WEB99
import Icon from 'react-native-vector-icons/dist/FontAwesome';
// Generate required css
import iconFontFA from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import iconFontMA from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';
import iconFontIO from 'react-native-vector-icons/Fonts/Ionicons.ttf'; 

import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

function cargarIconFont(iconFont, fontFamily){
  const iconFontStyles = `@font-face {
    src: url(${iconFont});
    font-family: ${fontFamily};
  }`;
  // Create stylesheet
  const style = document.createElement('style');
  style.type = 'text/css';
  if (style.styleSheet) {
    style.styleSheet.cssText = iconFontStyles;
  } else {
    style.appendChild(document.createTextNode(iconFontStyles));
  }
  // Inject stylesheet
  document.head.appendChild(style);
}

cargarIconFont(iconFontFA, 'FontAwesome');
cargarIconFont(iconFontMA, 'MaterialIcons');
cargarIconFont(iconFontIO, 'Ionicons'); 

/***** (-) Agregado para cargar fuentes de iconos */

/***** (+) Agregado para cargar fuentes */

function cargarFont(fontFamily){
  const fontStyles = `@font-face {
    src: url(/static/fonts/${fontFamily}.ttf);
    font-family: ${fontFamily};
  }`;
  // Create stylesheet
  const style = document.createElement('style');
  style.type = 'text/css';
  if (style.styleSheet) {
    style.styleSheet.cssText = fontStyles;
  } else {
    style.appendChild(document.createTextNode(fontStyles));
  }
  // Inject stylesheet
  document.head.appendChild(style);
}

//WEB99 TODOWEB - Dinamizar
cargarFont('TitilliumWeb-Black');
cargarFont('TitilliumWeb-Bold');
cargarFont('TitilliumWeb-Light');
cargarFont('TitilliumWeb-Semibold');
cargarFont('Roboto');
cargarFont('Roboto_medium');
 
/***** (-) Agregado para cargar fuentes */

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <NativeBaseProvider>
          <LocalizationProvider dateAdapter={AdapterMoment}>
                        <App />
          </LocalizationProvider>
    </NativeBaseProvider>
  </React.StrictMode>
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();




/*
import IconMA from "react-native-vector-icons/MaterialIcons";
import IconIO from "react-native-vector-icons/Ionicons";
import IconFA from "react-native-vector-icons/FontAwesome";
*/

 



