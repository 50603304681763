import React from "react";
import { Text, TouchableOpacity, StyleSheet, View } from "react-native";
import { Icon, Fab } from "native-base";
import Util from "../common/Util";
import L from "../common/Layout";
import Colors from "../common/Colors";
import Fonts from "../common/Fonts";
import I18n from "i18next"

const CardRowList = ({
  label,
  value,
  visible,
  showDivider,
  type,
  complex,
  onPressRow,
  labelRowStyle,
  valueRowStyle
}) => {
  let {
    rowContainer,
    labelRowColor,
    valueRowColor,
    visibleOk,
    visibleNot,
    divider
  } = styles;
  if (complex) type === "complex";
  let rowType = <View />;
  switch (type) {
    case "complex":
      rowType = (
        <View>
          {label ? (
            <View style={labelRowColor}>
              <Text style={[labelRowColor, labelRowStyle]}>{label}</Text>
              <Text style={[valueRowColor, valueRowStyle]}>{value}</Text>
            </View>
          ) : null}
          <View style={rowContainer}>
            <Text style={[labelRowColor, labelRowStyle]}>
              {I18n.t("global.visibleData")}
            </Text>
            <Text style={visible ? visibleOk : visibleNot}>
              {visible ? I18n.t("global.yes") : I18n.t("global.no")}
            </Text>
          </View>
        </View>
      );
      break;
    case "button":
      rowType = (
        <TouchableOpacity style={rowContainer} onPress={onPressRow}>
          <Text style={[labelRowColor, labelRowStyle]}>{label}</Text>
          <Text style={[valueRowColor, valueRowStyle]}>{value}</Text>
        </TouchableOpacity>
      );
      break;
    case "textArea":
      rowType = (
        <View
          style={[rowContainer, { flexDirection: "column" }]}
          onPress={onPressRow}>
          <Text
            style={[labelRowColor, { marginBottom: L.h(11) }, labelRowStyle]}>
            {label}
          </Text>
          <Text style={[valueRowColor, valueRowStyle]}>{value}</Text>
        </View>
      );
      break;
    default:
      rowType = (
        <View style={rowContainer}>
          <Text style={[labelRowColor, labelRowStyle]}>{label}</Text>
          <Text style={[valueRowColor, valueRowStyle]}>{value}</Text>
        </View>
      );
      break;
  }
  return (
    <View>
      {rowType}
      {showDivider ? <View style={divider} /> : null}
    </View>
  );
};
CardRowList.defaultProps = {
  label: "Label",
  value: "Value",
  visibleType: false,
  showDivider: false,
  complex: false
};

const styles = StyleSheet.create({
  rowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    borderBottomWidth: L.hProm(1),
    borderBottomColor: Colors.veryLightPink,
    paddingVertical: L.hProm(9),
    paddingHorizontal: L.wProm(11),
    backgroundColor: "white"
  },
  labelRowColor: {
    color: Colors.scanRegisterRowLabel
  },
  valueRowColor: {
    color: Colors.black_82
  },
  visibleOk: {
    color: Colors.scanVisibleOk,
    fontFamily: Fonts.DSEMIBOLD
  },
  visibleNot: {
    color: Colors.scanVisibleNot,
    fontFamily: Fonts.DSEMIBOLD
  },
  divider: {
    backgroundColor: Colors.brightSkyBlue,
    height: L.h(6)
  }
});

export default CardRowList;
