import React from 'react';
import {
  StyleSheet,
  Text,
  View,
  Dimensions,
  Image,
  ImageBackground,
  ScrollView,
  RefreshControl,
  ActivityIndicator,
} from 'react-native';

import moment from 'moment';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import LinearGradient from 'react-native-linear-gradient';
import Colors from '../../common/Colors';
import Fonts from '../../common/Fonts';
import L from '../../common/Layout';
import Util from '../../common/Util';
import IconIO from 'react-native-vector-icons/Ionicons';
import { getMyLoyalyProgram } from '../../actions/AppActions';
import I18n from 'i18next';

var screen = Dimensions.get('window');
const PAGE_SIZE = 15;

class WalletMain extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      refresh: false,
      isLoading: true,
    };
    this.onRefresh = this.onRefresh.bind(this);
  }

  componentWillMount() {
    this.onRefresh();
  }

  onRefresh() {
    this.props.getMyLoyalyProgram(() => this.setState({ isLoading: false }));
  }

  render() {
    const { user, loyaly = {} } = this.props;
    const { isLoading } = this.state;
    const { points: loyalyPoints = 0, movements = [] } = loyaly;
    const sortedArray = Util.orderMovementsByDateSeconds(movements, 2); //rrppId ? 1 : 2

    if (isLoading)
      return (
        <ActivityIndicator
          size={'large'}
          style={{ marginTop: L.h(16) }}
          color={Colors.darkIndigo}
        />
      );
    return (
      <View style={{ flex: 1, backgroundColor: Colors.walletMainBackground }}>
        {/* <ScrollView
          contentContainerStyle={{ flex: 1 }}
          alwaysBounceVertical={false}
          bounces={false}
        > */}
        <View>
          <LinearGradient
            LinearGradient
            colors={[Colors.profilePointsCardOne, Colors.profilePointsCardTwo]}
            start={{ x: 0.0001, y: 0 }}
            end={{ x: 1.05, y: 0 }}
            style={styles.linearGradient}
          >
            <ImageBackground
              source={require('../../common/assets/bgUserPointsProfile.png')}
              resizeMode="cover"
              style={styles.userPointsImage}
            >
              <View style={styles.userPointsContainer}>
                <Text style={styles.textTitleUserPoints}>
                  {I18n.t('walletMain.yourPoints')}
                </Text>
                <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <Image
                    source={require('../../common/assets/pointsIconCoin.png')}
                    resizeMode="contain"
                    style={{ height: L.h(23) }}
                  />
                  <Text style={styles.numberUserPoints}>{loyalyPoints}</Text>
                </View>
                {/* <Text style={styles.descPoints}>1 punto = AR$ 1</Text> */}
              </View>
            </ImageBackground>
          </LinearGradient>
        </View>
        <View style={styles.movementContainer}>
          <Text style={styles.ultimosText}>
            {I18n.t('walletMain.lastMovements')}
          </Text>
          {/* <TouchableOpacity style={styles.btnMovements}>
              <Text style={styles.btnMovementsText}>Más movimientos</Text>
              <IconIO
                name="ios-arrow-forward"
                // type="Ionicons"
                style={styles.iconArrow}
              />
            </TouchableOpacity> */}
        </View>
        <ScrollView
          style={{ flex: 1 }}
          keyboardShouldPersistTaps="always"
          refreshControl={
            <RefreshControl
              refreshing={this.state.refresh}
              onRefresh={this.onRefresh}
            />
          }
          // alwaysBounceVertical={false}
          // bounces={false}
        >
          {sortedArray.length == 0 ? (
            <View style={styles.emptyStateContainer}>
              <Image
                source={require('../../common/assets/emptyStateMovements.png')}
                resizeMode="contain"
                style={{ height: L.h(128) }}
              />
            </View>
          ) : (
            sortedArray.map(mov => (
              <View style={styles.listItemContainer}>
                <View style={styles.listItemContent}>
                  <View style={{ width: '75%' }}>
                    <Text style={styles.titleChip}>
                      {mov.mo_description ||
                        `${I18n.t('walletMain.exchanged')}${mov.pr_name}`}
                    </Text>
                    <Text style={styles.dateChip}>
                      {moment.unix(mov.mo_date._seconds).format('DD/MM/YYYY')}
                    </Text>
                  </View>
                  {mov.mo_movtype !== 's' ? (
                    <View
                      style={[
                        styles.chipPoints,
                        { backgroundColor: 'rgb(234, 79, 79)' },
                      ]}
                    >
                      <Text style={styles.chipText}>- {mov.mo_points}</Text>
                    </View>
                  ) : (
                    <View
                      style={[
                        styles.chipPoints,
                        { backgroundColor: 'rgb(193, 237, 103)' },
                      ]}
                    >
                      <Text style={styles.chipText}>+ {mov.mo_points}</Text>
                    </View>
                  )}
                </View>
                {mov.mo_movtype !== 's' ? (
                  <View style={styles.leftView}>
                    <IconIO name="md-arrow-down" style={styles.iconDown} />
                  </View>
                ) : (
                  <View style={styles.leftView}>
                    <IconIO name="md-arrow-up" style={styles.iconUp} />
                  </View>
                )}
              </View>
            ))
          )}
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  movementContainer: {
    marginHorizontal: L.w(20),
    marginVertical: L.h(20),
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  linearGradient: {
    height: L.h(115),
    width: screen.width,
  },
  userPointsImage: {
    width: '100%',
    height: L.h(115),
    justifyContent: 'center',
  },
  userPointsContainer: {
    paddingLeft: L.w(25),
    justifyContent: 'center',
  },
  textTitleUserPoints: {
    fontSize: L.h(14),
    lineHeight: L.h(22),
    fontFamily: Fonts.DBOLD,
    color: Colors.walletTextPoints,
    textShadowColor: 'rgba(0, 0, 0, 0.8)',
    textShadowOffset: { width: 0, height: 0 },
    textShadowRadius: 10,
    top: L.h(10),
  },
  numberUserPoints: {
    fontSize: L.h(32),
    fontFamily: Fonts.DBOLD,
    color: Colors.walletPoints,
    marginLeft: L.w(5),
  },
  descPoints: {
    fontSize: L.h(14),
    lineHeight: L.h(15),
    fontFamily: Fonts.DREGULAR,
    color: Colors.walletDescPoints,
    marginLeft: L.w(5),
  },
  ultimosText: {
    fontSize: L.h(14),
    lineHeight: L.h(15),
    fontFamily: Fonts.DREGULAR,
    color: Colors.walletMovementsTitleText,
  },
  btnMovements: {
    backgroundColor: 'rgb(123, 223, 255)',
    flexDirection: 'row',
    paddingVertical: L.h(7),
    paddingHorizontal: L.w(10),
    alignItems: 'center',
    borderRadius: L.h(50),
  },
  btnMovementsText: {
    color: 'rgb(34, 34, 34)',
    fontSize: L.h(14),
    fontFamily: Fonts.DREGULAR,
  },
  iconArrow: {
    color: 'rgb(34, 34, 34)',
    fontSize: L.h(25),
    marginLeft: L.w(5),
  },
  listItemContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingHorizontal: L.w(10),
    marginBottom: L.h(15),
  },
  listItemContent: {
    backgroundColor: Colors.walletMovementBackground,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: L.h(7),
    paddingRight: L.w(10),
    paddingLeft: L.w(25),
    paddingVertical: L.h(7),
    borderWidth: L.h(1),
    borderColor: Colors.walletMovementCardBorder,
  },
  titleChip: {
    color: Colors.walletMovementTitle,
    fontSize: L.h(14),
    fontFamily: Fonts.DBOLD,
  },
  dateChip: {
    color: Colors.walletMovementDate,
    fontSize: L.h(14),
    fontFamily: Fonts.DREGULAR,
  },
  chipPoints: {
    borderRadius: L.h(30),
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: L.w(10),
    height: L.h(30),
  },
  chipText: {
    color: 'rgb(34, 34, 34)',
    fontSize: L.h(14),
    fontFamily: Fonts.DBOLD,
  },
  leftView: {
    backgroundColor: 'rgb(34, 34, 34)',
    alignItems: 'center',
    borderRadius: L.h(35) / 2,
    height: L.h(35),
    width: L.h(35),
    position: 'absolute',
    left: L.w(0),
    justifyContent: 'center',
    alignItems: 'center',
  },
  iconDown: {
    fontSize: L.h(20),
    color: 'rgb(234, 79, 79)',
  },
  iconUp: {
    fontSize: L.h(20),
    color: 'rgb(68, 192, 165)',
  },
  emptyStateContainer: {
    height: screen.height / 2,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

function mapStateToProps(store) {
  return {
    loyaly: store.appReducer.loyaly,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getMyLoyalyProgram,
    },
    dispatch
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WalletMain);
